import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createEvent} from "../../redux/features/events/eventsSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import EventsForm from "./EventsForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const EventsNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.events);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/events": "Events",
    "/events/new": "New Event",
  };

  const onCancel = () => {
    history.push("/events");
  };
  const onSubmit = async values => {
    const eventObj = {
      id: null,
      ...values,
    };

    const res = await dispatch(createEvent(eventObj));
    // If event was created successfully redirect to events page and show user
    if (!res.error) {
      history.push({
        pathname: "/events",
        state: {
          showSuccess: true,
          snackbarContent: `Your Event '${eventObj.title}' was successfully created!`,
          snackbarLink: `/events/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Event
          </Typography>
          <EventsForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Event"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventsNew;
