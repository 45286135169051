import React from "react";

//Utils
import {useForm} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ButtonsContainer from "./ButtonsContainer";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
}));

const EndpointsForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, register} = useForm({
    defaultValues: {
      image_end_point: settingsList.image_end_point.value,
      static_end_point: settingsList.static_end_point.value,
    },
  });

  const formSubmit = data => {
    const settingArray = [
      {...settingsList.image_end_point, value: data.image_end_point},
      {...settingsList.static_end_point, value: data.static_end_point},
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Image End Point */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="image_end_point"
            label="Images Endpoint"
            type="url"
            required
          />
        </Grid>

        {/* Static End Point */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="static_end_point"
            label="Static Endpoint"
            type="url"
            required
          />
        </Grid>

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default EndpointsForm;
