import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchRedirects,
  editRedirect,
  createRedirect,
  deleteRedirect,
} from "../../../redux/features/settings/redirectsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// Icons
import tableIcons from "../../Table/TableIcons";

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  resize: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

const RedirectsTableEdit = () => {
  //Styles
  const classes = useStyles();

  // Local State
  const [formattedData, setFormattedData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {redirectsList, isLoading, error} = useSelector(
    state => state.redirects
  );

  useEffect(() => {
    dispatch(fetchRedirects());
  }, [dispatch]);

  useEffect(() => {
    // Need to spread array so it doesn't fail
    setFormattedData(formatData(redirectsList));
  }, [redirectsList]);

  // Need to spread array so it doesn't fail
  const formatData = redirectsList => {
    return redirectsList.map(o => ({
      ...o,
    }));
  };

  const handleAlertClose = () => {
    setOpenSnackbar(false);
  };

  return (
    //  {/* Redirect Information */}
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Disclaimer: Changes will not be active on store site until the
            server is built again.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Redirect List"
            isLoading={isLoading}
            icons={tableIcons}
            columns={[
              {
                title: "ID",
                field: "redirect_id",
                width: 30,
                editable: "never",
              },
              {
                title: "From URL",
                field: "from_url",
                initialEditValue: "from",
                editComponent: props => (
                  <TextField
                    value={props.value}
                    required
                    onChange={e => props.onChange(e.target.value)}
                    helperText="Start with '/' if not external link"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                ),
              },
              {
                title: "To URL",
                field: "to_url",
                initialEditValue: "to",
                editComponent: props => (
                  <TextField
                    value={props.value}
                    required
                    onChange={e => props.onChange(e.target.value)}
                    helperText="Start with '/' if not external link"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                ),
              },
              {
                title: "Type",
                field: "type",
                render: rowData => (
                  <>
                    {rowData.type.charAt(0).toUpperCase() +
                      rowData.type.slice(1)}
                  </>
                ),
                initialEditValue: "shallow",
                editComponent: props => (
                  <FormControl>
                    <Select
                      value={props.value}
                      onChange={e => props.onChange(e.target.value)}
                      classes={{
                        select: classes.resize,
                      }}>
                      <MenuItem value={"shallow"}>Shallow</MenuItem>
                      <MenuItem value={"deep"}>Deep</MenuItem>
                      <MenuItem value={"external"}>External</MenuItem>
                    </Select>
                  </FormControl>
                ),
              },
            ]}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    const res = await dispatch(createRedirect(newData));

                    // if no error fetch redirects
                    if (!res.error) {
                      dispatch(fetchRedirects());
                      resolve();
                    } else {
                      // Else reject
                      reject();
                    }
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    const res = await dispatch(editRedirect(newData));

                    // if no error fetch redirects
                    if (!res.error) {
                      dispatch(fetchRedirects());
                      setSnackbarContent(
                        `The Redirect was Updated successfully!`
                      );
                      setOpenSnackbar(true);
                      resolve();
                    } else {
                      // Else reject
                      setSnackbarContent(`The Redirect was not Updated`);
                      setOpenSnackbar(true);
                      reject();
                    }
                  }, 1000);
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    const res = await dispatch(
                      deleteRedirect(oldData.redirect_id)
                    );

                    // if no error fetch redirects
                    if (!res.error) {
                      dispatch(fetchRedirects());
                      setSnackbarContent(
                        `The Redirect was Deleted successfully!`
                      );
                      setOpenSnackbar(true);
                      resolve();
                    } else {
                      // Else reject
                      setSnackbarContent(`The Redirect was not Deleted!`);
                      setOpenSnackbar(true);
                      reject();
                    }
                  }, 1000);
                }),
            }}
            data={formattedData}
            options={{
              search: true,
              actionsColumnIndex: -1,
            }}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
            }}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAlertClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default RedirectsTableEdit;
