import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../../localStorage";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    mode: loadState("ui.mode") || "light",
    palette: loadState("ui.palette") || {
      primary: { main: "#2196f3" },
      secondary: { main: "#f50057" },
    },
  },
  reducers: {
    setUIMode(state, { payload }) {
      state.mode = payload;
    },
    toggleUIMode(state) {
      if (state.mode === "light") {
        state.mode = "dark";
      } else if (state.mode === "dark") {
        state.mode = "light";
      }
    },
    changePalette(state, { payload }) {
      state.palette = payload;
    },
    resetPallette(state) {
      state.palette = {
        primary: { main: "#2196f3" },
        secondary: { main: "#f50057" },
      };
    },
  },
});

export const {
  setUIMode,
  toggleUIMode,
  changePalette,
  resetPallette,
} = uiSlice.actions;

export default uiSlice.reducer;
