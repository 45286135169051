import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const galleryUrl = process.env.REACT_APP_API_BASE_URL + "/v1/galleries";

const galleriesInitialState = {
  galleriesList: [],
  currentPageGallery: {},
  isLoadingGallery: false,
  errorGallery: null,
};

function startLoadingGallery(state) {
  state.isLoadingGallery = true;
}

function loadingFailedGallery(state, {payload}) {
  state.isLoadingGallery = false;
  state.errorGallery = payload;
}

const galleries = createSlice({
  name: "galleries",
  initialState: galleriesInitialState,
  reducers: {
    getGalleryStart: startLoadingGallery,
    getGalleriesStart: startLoadingGallery,
    createGalleryStart: startLoadingGallery,
    editGalleryStart: startLoadingGallery,
    deleteGalleryStart: startLoadingGallery,
    getGallerySuccess(state, {payload}) {
      state.currentPageGallery = payload;
      state.isLoadingGallery = false;
      state.errorGallery = null;
    },
    getGalleriesSuccess(state, {payload}) {
      state.galleriesList = payload;
      state.isLoadingGallery = false;
      state.errorGallery = null;
    },
    createGallerySuccess(state) {
      state.isLoadingGallery = false;
      state.errorGallery = null;
    },
    editGallerySuccess(state) {
      state.isLoadingGallery = false;
      state.errorGallery = null;
    },
    deleteGallerySuccess(state) {
      state.isLoadingGallery = false;
      state.errorGallery = null;
    },
    getGalleryFailure: loadingFailedGallery,
    getGalleriesFailure: loadingFailedGallery,
    createGalleryFailure: loadingFailedGallery,
    editGalleryFailure: loadingFailedGallery,
    deleteGalleryFailure: loadingFailedGallery,
  },
});

export const {
  getGalleryStart,
  getGalleriesStart,
  createGalleryStart,
  editGalleryStart,
  deleteGalleryStart,
  getGallerySuccess,
  getGalleriesSuccess,
  createGallerySuccess,
  editGallerySuccess,
  deleteGallerySuccess,
  getGalleryFailure,
  getGalleriesFailure,
  createGalleryFailure,
  editGalleryFailure,
  deleteGalleryFailure,
} = galleries.actions;

export default galleries.reducer;

export const fetchGalleries = () => async (dispatch, getState) => {
  try {
    dispatch(getGalleriesStart());
    const res = await axios.get(galleryUrl, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getGalleriesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getGalleriesFailure(errorObj));
  }
};

export const fetchGallery = id => async (dispatch, getState) => {
  try {
    dispatch(getGalleryStart());
    const res = await axios.get(`${galleryUrl}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getGallerySuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getGalleryFailure(errorObj));
  }
};

export const createGallery = galleryObj => async (dispatch, getState) => {
  try {
    dispatch(createGalleryStart());
    const res = await axios.post(galleryUrl, galleryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createGallerySuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createGalleryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editGallery = galleryObj => async (dispatch, getState) => {
  try {
    dispatch(editGalleryStart());
    const res = await axios.put(galleryUrl, galleryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editGallerySuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editGalleryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteGallery = galleryId => async (dispatch, getState) => {
  try {
    dispatch(deleteGalleryStart());
    const res = await axios.delete(galleryUrl, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: galleryId},
    });
    dispatch(deleteGallerySuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteGalleryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
