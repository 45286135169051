import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  createAddon,
  fetchCustomAddons,
} from "../../redux/features/addons/addonsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {VariableSizeList} from "react-window";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";

// Styling
const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const filter = createFilterOptions();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType={List}
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
const NumberFormatCustom = props => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      fixedDecimalScale
      decimalScale={2}
      allowNegative={false}
    />
  );
};

const CustomAddonDialog = ({
  open,
  handleClose,
  onAddonCreate,
  readOnly = false,
  defaultValueName,
}) => {
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const {error, isLoading} = useSelector(state => state.addons);

  // Local State
  const [name, setName] = useState(defaultValueName || "");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("Daily/Person");
  const [ageId, setAgeId] = useState(5);
  const [price, setPrice] = useState(null);
  const [taxRate, setTaxRate] = useState(null);
  const [isFormReady, setFormReady] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (price && name && description && type && ageId && taxRate) {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  }, [name, description, type, ageId, price, taxRate]);

  useEffect(() => {
    setName(defaultValueName);
  }, [defaultValueName]);

  const onClose = () => {
    setName("");
    setDescription("");
    setType("Daily/Person");
    setAgeId(5);
    setPrice(null);
    setTaxRate(null);
    setFormReady(false);
    setShowError(false);
    handleClose();
  };

  const formSubmit = async e => {
    /// Make sure the wrapper form doesn't submit
    e.preventDefault();
    e.stopPropagation();

    const values = {
      price,
      taxRate,
      name,
      description,
      type,
      status: "Published",
      ageId,
      use_in: "custom",
    };

    const addonObj = {
      id: null,
      ...values,
    };

    const res = await dispatch(createAddon(addonObj));
    // If addon was created successfully close modal and add to the addons array
    if (!res.error) {
      handleClose();
      onAddonCreate(res.id);
    } else {
      setShowError(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle id="form-dialog-title">Add a new custom addon</DialogTitle>
      <form className={classes.form} onSubmit={formSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Error Alert */}
            {error && showError && !isLoading && (
              <Grid item xs={12}>
                <Alert severity="error">
                  There was an error creating this Addon. Try again later.
                </Alert>
              </Grid>
            )}
            {/* Addon Name  */}
            <Grid item xs={12}>
              <TextField
                className={classes.maxWidth}
                required
                name="name"
                label="Name"
                fullWidth
                value={name}
                InputProps={{
                  readOnly,
                }}
                onChange={e => setName(e.target.value)}
              />
            </Grid>

            {/* Addon Description */}
            <Grid item xs={12}>
              <TextField
                className={classes.maxWidth}
                required
                name="description"
                label="Description"
                multiline
                fullWidth
                value={description}
                InputProps={{
                  readOnly,
                }}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>

            {/* Addon Type  */}
            <Grid item xs={12} lg={6}>
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Type
                </InputLabel>
                <Select
                  value={type}
                  inputProps={{readOnly}}
                  onChange={e => setType(e.target.value)}>
                  <MenuItem value={"Daily/Person"}>Daily/Person</MenuItem>
                  <MenuItem value={"One-Time/Person"}>One-Time/Person</MenuItem>
                  <MenuItem value={"Daily/In-Room"}>Daily/In-Room</MenuItem>
                  <MenuItem value={"One-Time/In-Room"}>
                    One-Time/In-Room
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Addon Age Identifier */}
            <Grid item xs={12} lg={6}>
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Applies To
                </InputLabel>
                <Select
                  value={ageId}
                  inputProps={{readOnly}}
                  onChange={e => setAgeId(e.target.value)}>
                  <MenuItem value={1}>Babies</MenuItem>
                  <MenuItem value={2}>Children</MenuItem>
                  <MenuItem value={3}>Adults</MenuItem>
                  <MenuItem value={4}>Seniors</MenuItem>
                  <MenuItem value={5}>All</MenuItem>
                  <MenuItem value={6}>All/No Babies</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Addon Price */}
            <Grid item xs={12} lg={6}>
              <TextField
                className={classes.maxWidth}
                required
                label="Price"
                value={price}
                onChange={e => setPrice(e.target.value)}
                inputProps={{
                  thousandSeparator: true,
                }}
                InputProps={{
                  readOnly,
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Addon Tax Rate */}
            <Grid item xs={12} lg={6}>
              <TextField
                className={classes.maxWidth}
                required
                label="Tax Rate"
                name="taxRate"
                value={taxRate}
                onChange={e => setTaxRate(e.target.value)}
                inputProps={{
                  isAllowed: values => {
                    const {formattedValue, floatValue} = values;
                    return formattedValue === "" || floatValue <= 100;
                  },
                }}
                InputProps={{
                  readOnly,
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading || !isFormReady}>
              Create Addon
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CustomProductsComboBox = ({
  id,
  fieldLabel,
  onChange,
  defaultValues,
  readOnly,
}) => {
  const [value, setValue] = useState(defaultValues || []);
  const [open, toggleOpen] = useState(false);
  const [defaultValueName, setDefaultValueName] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {customAddonsList, customIsLoading, customError} = useSelector(
    state => state.addons
  );

  useEffect(() => {
    // Load Addons
    dispatch(fetchCustomAddons());
  }, [dispatch]);

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  const handleClose = () => {
    toggleOpen(false);
  };

  // After creating custom addon, add it to the list
  const onAddonCreate = async newAddonId => {
    const newAddonsList = await dispatch(fetchCustomAddons());
    if (!customIsLoading && !customError) {
      const newAddonObj = newAddonsList.find(addon => addon.id === newAddonId);
      setValue([...value, newAddonObj]);
    }
  };

  return (
    <>
      {!customIsLoading ? (
        <Autocomplete
          id={id}
          options={customAddonsList}
          autoHighlight
          multiple
          selectOnFocus
          disableCloseOnSelect
          forcePopupIcon
          freeSolo
          loading={customIsLoading}
          getOptionLabel={product => product.name}
          value={value}
          onChange={(event, newValues) => {
            const lastInserted = newValues[newValues.length - 1];
            if (lastInserted && typeof lastInserted.inputValue === "string") {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                setDefaultValueName(lastInserted.inputValue);
                toggleOpen(true);
              });
              return;
            }

            if (lastInserted && lastInserted.inputValue) {
              setDefaultValueName(lastInserted.inputValue);
              toggleOpen(true);

              return;
            } else {
              setValue(newValues);
            }
          }}
          disabled={readOnly}
          ListboxComponent={ListboxComponent}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          renderTags={(value, getTagProps) => {
            return value.flatMap((product, index) => {
              if (product.inputValue) {
                return [];
              }
              return <Chip label={product.name} {...getTagProps({index})} />;
            });
          }}
          renderOption={(product, {selected}) => {
            const labelId = `transfer-list-item-${product.id}-label`;

            if (product.inputValue) {
              return <ListItemText id={labelId} primary={product.name} />;
            }
            return (
              <>
                <ListItemIcon>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={product.name}
                  secondary={`${product.type} - ${product.identifier}`}
                />
              </>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={fieldLabel}
              fullWidth
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={48} animation="wave" />
      )}

      <CustomAddonDialog
        open={open}
        handleClose={handleClose}
        defaultValueName={defaultValueName}
        onAddonCreate={onAddonCreate}
      />
    </>
  );
};

export default CustomProductsComboBox;
