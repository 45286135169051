import React from "react";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

// Components
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonWithLoading = ({
  loading,
  children,
  onClick,
  buttonClassname,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        className={buttonClassname}
        disabled={loading}
        onClick={onClick}
        {...restProps}>
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default ButtonWithLoading;
