import React, {useState, useEffect} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";

// Styles
import {makeStyles} from "@material-ui/core/styles";

// Routing
import {withRouter, Redirect} from "react-router-dom";
import {logIn} from "../../redux/features/auth/authSlice";

// Components
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Copyright from "../Text/Copyright";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url('https://img.stayinmcallen.com/o/embassy-main.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paperContainer: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
}));

const LoginForm = ({onSubmit}) => {
  const [formValues, setFormValues] = useState({email: "", password: ""});

  // Hooks
  const classes = useStyles();

  const handleChange = event => {
    setFormValues({...formValues, [event.target.name]: event.target.value});
  };

  return (
    <form
      className={classes.form}
      onSubmit={e => {
        e.preventDefault();
        onSubmit(formValues);
      }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        autoFocus
        value={formValues.email}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={formValues.password}
        onChange={handleChange}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}>
        Sign In
      </Button>
      <Box mt={5}>
        <Copyright />
      </Box>
    </form>
  );
};

const Login = ({location}) => {
  const [from, setFrom] = useState({pathname: "/"});

  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  const auth = useSelector(state => {
    return state.auth;
  });
  const {isLoggedIn, invalidLogin} = auth;

  useEffect(() => {
    if (location && location.state) {
      setFrom(location.state.from);
    }
  }, [location]);

  const onSubmit = async ({email, password}) => {
    await dispatch(logIn({email, password}));
  };

  if (isLoggedIn) {
    return <Redirect to={from} />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paperContainer}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {invalidLogin && (
            <Alert className={classes.alert} severity="error">
              Invalid email or password! Please try again.
            </Alert>
          )}
          <LoginForm onSubmit={onSubmit} />
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(Login);
