import React from "react";

// utils
import moment from "moment";
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFiles from "../../FormFields/UploadFiles";
import CheckboxWithState from "../../FormFields/CheckboxWithState";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const NumberFormatCustom = props => {
  const {inputRef, onChange, ...other} = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {value: values.value},
        });
      }}
      isNumericString
      fixedDecimalScale
      decimalScale={2}
      allowNegative={false}
    />
  );
};

const RealNumber = props => {
  const {inputRef, onChange, ...other} = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {value: values.value},
        });
      }}
      isNumericString
      fixedDecimalScale
      decimalScale={0}
      allowNegative={false}
    />
  );
};

const PackageForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    title: "",
    link: "",
    short_description: "",
    start: moment(),
    end: moment().add(1, "days"),
    featured: 0,
    order_index: 1000,
    price: null,
    photoObj: null,
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  // Form hook
  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {featured, photoObj, start, end, price, ...restOfData} = data;

    const formattedValues = {
      ...restOfData,
      price: Number(price.replaceAll(",", "")),
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
      featured: featured ? 1 : 0,
      photoId: photoObj ? photoObj.photoId : null,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      {/* PackageCard Title */}
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="title"
            label="Title"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* PackageCard Link */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            type="url"
            name="link"
            label="Link"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* PackageCard Short Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="short_description"
            label="Short Description"
            multiline
            required
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* PackageCard Start Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="start"
            defaultValue={defaultValues.start}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Start Date"
                format="MM/dd/yyyy"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change start date",
                }}
              />
            )}
          />
        </Grid>

        {/* PackageCard End Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="end"
            defaultValue={defaultValues.end}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="End Date"
                format="MM/dd/yyyy"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change end date",
                }}
              />
            )}
          />
        </Grid>

        {/* Display Priority */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="order_index"
            label="Display Priority"
            required
            type="number"
            defaultValue={defaultValues.order_index}
            InputProps={{
              readOnly,
              inputComponent: RealNumber,
            }}
          />
        </Grid>

        {/* Package Card Price */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="price"
            label="Price"
            defaultValue={defaultValues.price}
            inputProps={{
              thousandSeparator: true,
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            required
          />
        </Grid>

        {/* Package Card Picture */}
        <Grid item xs={12}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Featured Image"
                buttonLabel="Set Featured Image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* PackageCard Featured */}
        <Grid item xs={12} md={4}>
          <Controller
            name={`featured`}
            control={control}
            defaultValue={defaultValues.featured}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Featured"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PackageForm;
