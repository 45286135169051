import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/condos";

const condosInitialState = {
  condosList: [],
  currentPageCondo: {},
  isLoading: false,
  error: null,
  myvrMap: [],
  isLoadingMyVRMap: false,
  errorMyVRMap: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}
function startLoadingMyVRMap(state) {
  state.isLoadingMyVRMap = true;
}
function loadingFailedMyVRMap(state, {payload}) {
  state.isLoadingMyVRMap = false;
  state.errorMyVRMap = payload;
}

const condos = createSlice({
  name: "condos",
  initialState: condosInitialState,
  reducers: {
    getCondoStart: startLoading,
    getCondosStart: startLoading,
    getMyVRMapStart: startLoadingMyVRMap,
    createCondoStart: startLoading,
    editCondoStart: startLoading,
    deleteCondoStart: startLoading,
    getCondoSuccess(state, {payload}) {
      state.currentPageCondo = payload;
      state.isLoading = false;
      state.error = null;
    },
    getCondosSuccess(state, {payload}) {
      state.condosList = payload;
      state.isLoading = false;
      state.error = null;
    },
    getMyVRMapSuccess(state, {payload}) {
      state.myvrMap = payload;
      state.isLoadingMyVRMap = false;
      state.errorMyVRMap = null;
    },
    createCondoSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editCondoSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteCondoSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getCondoFailure: loadingFailed,
    getCondosFailure: loadingFailed,
    getMyVRMapFailure: loadingFailedMyVRMap,
    createCondoFailure: loadingFailed,
    editCondoFailure: loadingFailed,
    deleteCondoFailure: loadingFailed,
  },
});

export const {
  getCondoStart,
  getCondosStart,
  createCondoStart,
  editCondoStart,
  deleteCondoStart,
  getCondoSuccess,
  getCondosSuccess,
  createCondoSuccess,
  editCondoSuccess,
  deleteCondoSuccess,
  getCondoFailure,
  getCondosFailure,
  createCondoFailure,
  editCondoFailure,
  deleteCondoFailure,
  getMyVRMapStart,
  getMyVRMapSuccess,
  getMyVRMapFailure,
} = condos.actions;

export default condos.reducer;

export const fetchCondos = () => async (dispatch, getState) => {
  try {
    dispatch(getCondosStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCondosSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getCondosFailure(errorObj));
  }
};

export const fetchMyVRMap = () => async (dispatch, getState) => {
  try {
    dispatch(getMyVRMapStart());
    const res = await axios.get(`${url}/myvr-map`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getMyVRMapSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getMyVRMapFailure(errorObj));
  }
};

export const fetchCondo = id => async (dispatch, getState) => {
  try {
    dispatch(getCondoStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCondoSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getCondoFailure(errorObj));
  }
};

export const createCondo = condoObj => async (dispatch, getState) => {
  try {
    dispatch(createCondoStart());
    const res = await axios.post(url, condoObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createCondoSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createCondoFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editCondo = condoObj => async (dispatch, getState) => {
  try {
    dispatch(editCondoStart());
    const res = await axios.put(url, condoObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editCondoSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editCondoFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteCondo = condoId => async (dispatch, getState) => {
  try {
    dispatch(deleteCondoStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: condoId},
    });
    dispatch(deleteCondoSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteCondoFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
