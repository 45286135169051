import React, {useState, useEffect} from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import UploadFiles from "./UploadFiles";
import {Editor} from "@tinymce/tinymce-react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles(theme => ({
  editorLabel: {
    position: "relative",
  },
  input: {
    zIndex: "-2",
    position: "absolute",
    top: 0,
  },
}));

const RichTextEditor = ({
  readOnly,
  value,
  onChange,
  height = 500,
  label,
  required = false,
  withPictureUploader = false,
  customPlugins = [],
  customToolbar = "",
  customExtendedValidElements = "",
  customValidChildren = "",
}) => {
  // Styles
  const classes = useStyles();

  const [content, setContent] = useState(value);

  useEffect(() => {
    onChange(content);
  }, [content, onChange]);

  const addPictureToPageEditor = picture => {
    setContent(
      content +
        `<img src='${process.env.REACT_APP_IMG_PATH}/r/300/${picture.photoName}' />`
    );
  };
  return (
    <>
      <FormControl required={required} fullWidth>
        <InputLabel
          shrink
          htmlFor="rich-text-editor"
          variant="standard"
          className={readOnly ? "" : classes.editorLabel}>
          {label}
        </InputLabel>
        <Input
          id="rich-text-editor"
          className={classes.input}
          value={content ? 1 : ""}
        />
      </FormControl>
      {withPictureUploader ? (
        <UploadFiles
          buttonLabel="Add Picture"
          label=""
          singleFile
          hidePreviews
          disableClear
          onSave={photoArray => addPictureToPageEditor(photoArray[0])}
          readOnly={readOnly}
        />
      ) : (
        <Box pt={1}></Box>
      )}
      <Editor
        value={content}
        disabled={readOnly}
        init={{
          height,
          menubar: false,
          plugins:
            customPlugins.length > 0
              ? customPlugins
              : [
                  "advlist  autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen ",
                  "insertdatetime media table paste code help wordcount",
                ],
          toolbar:
            customToolbar !== ""
              ? customToolbar
              : "undo redo | formatselect | bold italic backcolor  |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent  |code| removeformat  help",
          extended_valid_elements:
            customExtendedValidElements !== ""
              ? customExtendedValidElements
              : "span[id|class|style],script[src|async|defer|type|charset],iframe[style|class|src|frameborder|allowfullscreen]",
          valid_children: customValidChildren,
          forced_root_block: false,
          verify_html: false,
        }}
        onEditorChange={(content, editor) => {
          setContent(content);
        }}
      />
    </>
  );
};

export default RichTextEditor;
