import React, {useState, useEffect} from "react";

// Utils
import {validateUrl} from "../../../../helpers/stringFormatters";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(theme => ({
  maxWidth: {width: "100%", marginBottom: theme.spacing(2)},
}));

const RoutesFields = ({settingsList, onChange}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [homepageUrl, setHomepageUrl] = useState(
    // If homepage url is not empty set value after / if is empty set empty string
    settingsList.routes.value.homepage.url.split("/").length > 1
      ? settingsList.routes.value.homepage.url.split("/")[1]
      : ""
  );
  const [homepageTitle, setHomepageTitle] = useState(
    settingsList.routes.value.homepage.title
  );
  const [pagesUrlError, setPagesUrlError] = useState(false);
  const [pagesUrl, setPagesUrl] = useState(
    settingsList.routes.value.pages.url.split("/")[1]
  );
  const [pagesTitle, setPagesTitle] = useState(
    settingsList.routes.value.pages.title
  );

  const [myAccountUrl, setMyAccountUrl] = useState(
    settingsList.routes.value.myaccount.url.split("/")[1]
  );
  const [myAccountTitle, setMyAccountTitle] = useState(
    settingsList.routes.value.myaccount.title
  );

  const [packagesUrl, setPackagesUrl] = useState(
    settingsList.routes.value.packages.url.split("/")[1]
  );
  const [packagesTitle, setPackagesTitle] = useState(
    settingsList.routes.value.packages.title
  );

  const [hotelsUrl, setHotelsUrl] = useState(
    settingsList.routes.value.hotels.url.split("/")[1]
  );
  const [hotelsTitle, setHotelsTitle] = useState(
    settingsList.routes.value.hotels.title
  );

  const [condosUrl, setCondosUrl] = useState(
    settingsList.routes.value.condos.url.split("/")[1]
  );
  const [condosTitle, setCondosTitle] = useState(
    settingsList.routes.value.condos.title
  );

  const [eventsUrl, setEventsUrl] = useState(
    settingsList.routes.value.events.url.split("/")[1]
  );
  const [eventsTitle, setEventsTitle] = useState(
    settingsList.routes.value.events.title
  );

  const [bookingUrl, setBookingUrl] = useState(
    settingsList.routes.value.booking.url.split("/")[1]
  );
  const [bookingTitle, setBookingTitle] = useState(
    settingsList.routes.value.booking.title
  );

  const [checkoutUrl, setCheckoutUrl] = useState(
    settingsList.routes.value.checkout.url.split("/")[1]
  );
  const [checkoutTitle, setCheckoutTitle] = useState(
    settingsList.routes.value.checkout.title
  );

  const [confirmationUrl, setConfirmationUrl] = useState(
    settingsList.routes.value.confirmation.url.split("/")[1]
  );
  const [confirmationTitle, setConfirmationTitle] = useState(
    settingsList.routes.value.confirmation.title
  );

  const [contactUrl, setContactUrl] = useState(
    settingsList.routes.value.contact.url.split("/")[1]
  );
  const [contactTitle, setContactTitle] = useState(
    settingsList.routes.value.contact.title
  );

  const [orderUrl, setOrderUrl] = useState(
    settingsList.routes.value.order
      ? settingsList.routes.value.order.url.split("/")[1]
      : "/order"
  );
  const [orderTitle, setOrderTitle] = useState(
    settingsList.routes.value.order
      ? settingsList.routes.value.order.title
      : "Order"
  );

  const [meetingsUrl, setMeetingsUrl] = useState(
    settingsList.routes.value.meetings
      ? settingsList.routes.value.meetings.url.split("/")[1]
      : "/meetings"
  );
  const [meetingsTitle, setMeetingsTitle] = useState(
    settingsList.routes.value.meetings
      ? settingsList.routes.value.meetings.title
      : "Meetings"
  );

  useEffect(() => {
    const homepage = {
      url: "/" + homepageUrl,
      title: homepageTitle,
    };
    const pages = {
      url: "/" + pagesUrl,
      title: pagesTitle,
    };
    const myaccount = {
      url: "/" + myAccountUrl,
      title: myAccountTitle,
    };
    const packages = {
      url: "/" + packagesUrl,
      title: packagesTitle,
    };
    const hotels = {
      url: "/" + hotelsUrl,
      title: hotelsTitle,
    };
    const condos = {
      url: "/" + condosUrl,
      title: condosTitle,
    };
    const events = {
      url: "/" + eventsUrl,
      title: eventsTitle,
    };
    const booking = {
      url: "/" + bookingUrl,
      title: bookingTitle,
    };
    const checkout = {
      url: "/" + checkoutUrl,
      title: checkoutTitle,
    };
    const confirmation = {
      url: "/" + confirmationUrl,
      title: confirmationTitle,
    };
    const contact = {
      url: "/" + contactUrl,
      title: contactTitle,
    };
    const order = {
      url: "/" + orderUrl,
      title: orderTitle,
    };
    const meetings = {
      url: "/" + meetingsUrl,
      title: meetingsTitle,
    };

    const routesValue = JSON.stringify({
      ...settingsList.routes.value,
      homepage,
      pages,
      myaccount,
      packages,
      hotels,
      condos,
      events,
      booking,
      checkout,
      confirmation,
      contact,
      order,
      meetings,
    });

    onChange(routesValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    settingsList,
    homepageUrl,
    homepageTitle,
    pagesUrl,
    pagesTitle,
    myAccountUrl,
    myAccountTitle,
    packagesUrl,
    packagesTitle,
    hotelsUrl,
    hotelsTitle,
    condosUrl,
    condosTitle,
    eventsUrl,
    eventsTitle,
    bookingUrl,
    bookingTitle,
    checkoutUrl,
    checkoutTitle,
    confirmationUrl,
    confirmationTitle,
    contactUrl,
    contactTitle,
    orderUrl,
    orderTitle,
    meetingsUrl,
    meetingsTitle,
  ]);

  const onPagesUrlChange = e => {
    const value = validateUrl(e.target.value);
    if (value !== "pages") {
      setPagesUrlError(false);
      setPagesUrl(value);
    } else {
      setPagesUrlError(true);
    }
  };

  return (
    <>
      <Grid item xs={12} style={{paddingBottom: 0}}>
        <Typography component="p" variant="h5">
          Routes
        </Typography>
      </Grid>
      {/* Homepage */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="homepageUrl"
          label="Homepage URL"
          value={homepageUrl}
          onChange={e => setHomepageUrl(validateUrl(e.target.value))}
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="homepageTitle"
          label="Homepage Title"
          value={homepageTitle}
          onChange={e => setHomepageTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Pages */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="pagesUrl"
          label="Pages URL (Value cannot be 'pages')"
          value={pagesUrl}
          onChange={onPagesUrlChange}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
          error={pagesUrlError}
          helperText={pagesUrlError ? "Value cannot be 'pages'" : ""}
        />
        <TextField
          className={classes.maxWidth}
          name="pagesTitle"
          label="Pages Title"
          value={pagesTitle}
          onChange={e => setPagesTitle(e.target.value)}
          required
        />
      </Grid>

      {/* MyAccount */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="myAccountUrl"
          label="My Account URL"
          value={myAccountUrl}
          onChange={e => setMyAccountUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="myAccountTitle"
          label="My Account Title"
          value={myAccountTitle}
          onChange={e => setMyAccountTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Packages */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="packagesUrl"
          label="Packages URL"
          value={packagesUrl}
          onChange={e => setPackagesUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="packagesTitle"
          label="Packages Title"
          value={packagesTitle}
          onChange={e => setPackagesTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Hotels */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="hotelsUrl"
          label="Hotels URL"
          value={hotelsUrl}
          onChange={e => setHotelsUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="hotelsTitle"
          label="Hotels Title"
          value={hotelsTitle}
          onChange={e => setHotelsTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Condos */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="condosUrl"
          label="Condos URL"
          value={condosUrl}
          onChange={e => setCondosUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="condosTitle"
          label="Condos Title"
          value={condosTitle}
          onChange={e => setCondosTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Events */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="eventsUrl"
          label="Events URL"
          value={eventsUrl}
          onChange={e => setEventsUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="eventsTitle"
          label="Events Title"
          value={eventsTitle}
          onChange={e => setEventsTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Booking */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="bookingUrl"
          label="Booking URL"
          value={bookingUrl}
          onChange={e => setBookingUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="bookingTitle"
          label="Booking Title"
          value={bookingTitle}
          onChange={e => setBookingTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Checkout */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="checkoutUrl"
          label="Checkout URL"
          value={checkoutUrl}
          onChange={e => setCheckoutUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="checkoutTitle"
          label="Checkout Title"
          value={checkoutTitle}
          onChange={e => setCheckoutTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Confirmation */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="confirmationUrl"
          label="Confirmation URL"
          value={confirmationUrl}
          onChange={e => setConfirmationUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="confirmationTitle"
          label="Confirmation Title"
          value={confirmationTitle}
          onChange={e => setConfirmationTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Contact */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="contactUrl"
          label="Contact URL"
          value={contactUrl}
          onChange={e => setContactUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="contactTitle"
          label="Contact Title"
          value={contactTitle}
          onChange={e => setContactTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Order */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="orderUrl"
          label="Order URL"
          value={orderUrl}
          onChange={e => setOrderUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="orderTitle"
          label="Order Title"
          value={orderTitle}
          onChange={e => setOrderTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Meetings */}
      <Grid item xs={12} lg={6}>
        <TextField
          className={classes.maxWidth}
          name="meetingsUrl"
          label="Meetings URL"
          value={meetingsUrl}
          onChange={e => setMeetingsUrl(validateUrl(e.target.value))}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />
        <TextField
          className={classes.maxWidth}
          name="meetingsTitle"
          label="Meetings Title"
          value={meetingsTitle}
          onChange={e => setMeetingsTitle(e.target.value)}
          required
        />
      </Grid>

      {/* Disclaimer  */}
      <Grid item xs={12}>
        <Typography variant="h6">Disclaimers:</Typography>
        <Typography variant="body2">
          <ul>
            <li>
              If the URL of any Route is changed the change will not be active
              unless the config.json file of the project is updated!
            </li>
            <li>
              Setting the URL will not automatically activate the module. For
              that you need to go to the 'Modules' tab.
            </li>
          </ul>
        </Typography>
      </Grid>
    </>
  );
};

export default RoutesFields;
