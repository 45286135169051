import React, {useState} from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
}));

const UserForm = ({
  onSubmit,
  onSubmitPassword,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {},
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [username, setUsername] = useState(defaultValues.username || "");
  const [email, setEmail] = useState(defaultValues.email || "");
  const [enabled, setEnabled] = useState(!!defaultValues.enabled || true);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const formSubmit = e => {
    e.preventDefault();
    const values = {
      username,
      email,
      enabled: enabled ? 1 : 0,
    };

    onSubmit(values);
  };

  const passwordFormSubmit = e => {
    e.preventDefault();
    const values = {
      password,
    };

    onSubmitPassword(values);
  };

  return (
    <>
      <form className={classes.form} onSubmit={formSubmit}>
        <Grid container spacing={5}>
          {/* Username  */}
          <Grid item xs={12}>
            <TextField
              className={classes.maxWidth}
              required
              name="username"
              label="Username"
              fullWidth
              value={username}
              InputProps={{
                readOnly,
              }}
              onChange={e => setUsername(e.target.value)}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              value={email}
              InputProps={{
                readOnly,
              }}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>

          {/* Enabled */}
          <Grid item xs={12} lg={4}>
            <FormControlLabel
              className={classes.maxWidth}
              control={
                <Checkbox
                  onChange={() => setEnabled(!enabled)}
                  value="enabled"
                  disabled={readOnly}
                  checked={Boolean(enabled)}
                />
              }
              label="Enable user"
            />
          </Grid>

          {!readOnly && (
            <Grid item xs={12} className={classes.buttonsContainer}>
              <Button variant="outlined" onClick={() => onCancel()}>
                Cancel
              </Button>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}>
                  {submitButtonLabel}
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </form>

      {/* Password Form  */}
      {!readOnly && (
        <form className={classes.form} onSubmit={passwordFormSubmit}>
          <Grid container spacing={5}>
            {/* Password and confirmPassword */}
            <Grid item xs={12}>
              <Typography variant="h6" style={{marginTop: 50}}>
                Change Password
              </Typography>
            </Grid>
            <Grid container item xs={12} md={6} lg={8} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  InputProps={{
                    readOnly,
                  }}
                  onChange={e => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  InputProps={{
                    readOnly,
                  }}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body1">Password Requirements:</Typography>
              <Typography variant="body2" component="ul">
                <>
                  <li>Must contain at least 10 characters.</li>
                  <li>Must contain at least 1 UPPERCASE letter.</li>
                  <li>Must contain at least 1 LOWERCASE letter.</li>
                  <li>Must contain at least 1 NUMBER.</li>
                  <li>Must contain at least 1 SYMBOL.</li>
                </>
              </Typography>
            </Grid>

            {!readOnly && (
              <Grid item xs={12} className={classes.buttonsContainer}>
                <Button variant="outlined" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      isLoading ||
                      password === "" ||
                      password !== confirmPassword
                    }>
                    {submitButtonLabel}
                  </Button>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </>
  );
};

export default UserForm;
