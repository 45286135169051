import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createEventOrganizer} from "../../../redux/features/events/organizersSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../../UI/MainPaper";
import EventOrganizersForm from "./EventOrganizersForm";
import Breadcrumbs from "../../Navigation/Breadcrumbs";

const EventOrganizersNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.eventOrganizers);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/events": "Events",
    "/events/organizers": "Event Organizers",
    "/events/organizers/new": "New Event Organizer",
  };

  const onCancel = () => {
    history.push("/events/organizers");
  };
  const onSubmit = async values => {
    const eventOrganizerObj = {
      organizer_id: null,
      ...values,
    };

    const res = await dispatch(createEventOrganizer(eventOrganizerObj));
    // If event was created successfully redirect to events page and show user
    if (!res.error) {
      history.push({
        pathname: "/events/organizers",
        state: {
          showSuccess: true,
          snackbarContent: `Your Event Organizer '${eventOrganizerObj.name}' was successfully created!`,
          snackbarLink: `/events/organizers/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Event Organizer
          </Typography>
          <EventOrganizersForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Event Organizer"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventOrganizersNew;
