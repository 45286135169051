import React, {useState} from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ButtonsContainer from "./ButtonsContainer";
import RoutesFields from "./FieldsContainers/RoutesFields";
import NavigationFields from "./FieldsContainers/NavigationFields";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%", marginBottom: theme.spacing(2)},
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset",
  },
}));

const RoutesForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [routesValue, setRoutesValue] = useState("");

  const formSubmit = e => {
    e.preventDefault();

    const settingArray = [
      {
        ...settingsList.routes,
        value: routesValue,
      },
    ];

    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={formSubmit}>
      <Grid container spacing={5}>
        {/* Routes   */}
        <RoutesFields settingsList={settingsList} onChange={setRoutesValue} />
        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

const MainNavigationForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [navigationValue, setNavigationValue] = useState("");

  // Prepare values for route selection in Navigation fields
  const routes = settingsList.routes.value;
  const keys = Object.keys(routes);
  const routeOptionsDefault = keys.map(key => {
    return {
      key,
      ...routes[key],
    };
  });

  const formSubmit = e => {
    e.preventDefault();

    const settingArray = [
      {
        ...settingsList.navigation,
        value: navigationValue,
      },
    ];

    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={formSubmit}>
      <Grid container spacing={5}>
        {/* Main Navigation  */}
        <NavigationFields
          title="Main Navigation"
          settingsList={settingsList}
          onChange={setNavigationValue}
          routeOptions={routeOptionsDefault}
          enableDropdown
        />
        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

const FooterNavigationForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [footerNavigationValue, setFooterNavigationValue] = useState("");

  // Prepare values for route selection in Navigation fields
  const routes = settingsList.routes.value;
  const keys = Object.keys(routes);
  const routeOptionsDefault = keys.map(key => {
    return {
      key,
      ...routes[key],
    };
  });

  const formSubmit = e => {
    e.preventDefault();

    const settingArray = [
      {
        ...settingsList.foot_navigation,
        value: footerNavigationValue,
      },
    ];

    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={formSubmit}>
      <Grid container spacing={5}>
        {/* Footer Navigation  */}
        <NavigationFields
          title="Footer Navigation"
          settingsList={settingsList}
          navigationKey="foot_navigation"
          onChange={setFooterNavigationValue}
          routeOptions={routeOptionsDefault}
        />
        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

const NavigationForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Style
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}>
        <Tab
          label="Routes"
          id={`vertical-tab-${0}`}
          aria-controls={`vertical-tabpanel-${0}`}
        />
        <Tab
          label="Main Navigation"
          id={`vertical-tab-${1}`}
          aria-controls={`vertical-tabpanel-${1}`}
        />
        <Tab
          label="Footer Navigation"
          id={`vertical-tab-${2}`}
          aria-controls={`vertical-tabpanel-${2}`}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <RoutesForm
          onSubmit={onSubmit}
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
          settingsList={settingsList}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MainNavigationForm
          onSubmit={onSubmit}
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
          settingsList={settingsList}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FooterNavigationForm
          onSubmit={onSubmit}
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
          settingsList={settingsList}
        />
      </TabPanel>
    </div>
  );
};

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default NavigationForm;
