import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {dismissError} from "../../redux/features/errors/errorsSlice";

// Components
import Snackbar from "@material-ui/core/Snackbar";
import {Alert, AlertTitle} from "@material-ui/lab";

const ErrorAlert = () => {
  // Redux
  const dispatch = useDispatch();
  const errors = useSelector(state => {
    return state.errors;
  });
  const {errorMessage, errorCode} = errors;

  return (
    <Snackbar
      open={errorCode !== ""}
      onClose={() => dispatch(dismissError())}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}>
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => dispatch(dismissError())}
        severity="error">
        <AlertTitle>Error</AlertTitle>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
