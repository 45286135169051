import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createMeeting} from "../../redux/features/meetings/meetingsSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import MeetingsForm from "./MeetingsForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const MeetingsNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.meetings);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/meetings": "Meetings",
    "/meetings/new": "New Meeting",
  };

  const onCancel = () => {
    history.push("/meetings");
  };
  const onSubmit = async values => {
    const meetingObj = {
      meeting_id: null,
      ...values,
    };

    const res = await dispatch(createMeeting(meetingObj));
    // If meeting was created successfully redirect to meetings page and show user
    if (!res.error) {
      history.push({
        pathname: "/meetings",
        state: {
          showSuccess: true,
          snackbarContent: `Your Meeting '${meetingObj.title}' was successfully created!`,
          snackbarLink: `/meetings/${res.id}`,
        },
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Typography component="h1" variant="h5" gutterBottom>
              New Meeting
            </Typography>
            <MeetingsForm
              onSubmit={onSubmit}
              onCancel={onCancel}
              isLoading={isLoading}
              submitButtonLabel="Create Meeting"
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MeetingsNew;
