import React, {useState, useEffect} from "react";

//Utils
import {useForm, Controller} from "react-hook-form";
import moment from "moment";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import UploadFiles from "../FormFields/UploadFiles";
import GalleryComboBox from "../FormFields/GalleryComboBox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import RichTextEditor from "../FormFields/RichTextEditor";
import DeleteButton from "../Buttons/DeleteButton";
import {KeyboardTimePicker} from "@material-ui/pickers";
import PermalinkTextField from "../FormFields/PermalinkTextField";
import CheckboxWithState from "../FormFields/CheckboxWithState";
import MyvrComboBox from "../FormFields/MyvrComboBox";

// Icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const CondosForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    myvr_condo: null,
    commission: 0,
    status: "Draft",
    featured: 0,
    beachfront: 0,
    bathrooms: 1,
    bedrooms: 1,
    minStay: 2,
    accommodates: 1,
    photoObj: null,
    gallery: null,
    // Suitability
    elderly: 0,
    events: 0,
    groups: 0,
    handicap: 0,
    infants: 0,
    kids: 0,
    pets: 0,
    smoking: 0,
    //Additional
    checkInTime: moment(),
    checkOutTime: moment(),
    //   Dynamic inputs
    amenities: [""],
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  // Form
  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {
      myvr_condo,
      featured,
      beachfront,
      latitude,
      longitude,
      gallery,
      photoObj,
      commission,
      // Suitability
      elderly,
      events,
      groups,
      handicap,
      infants,
      kids,
      pets,
      smoking,
      // Additional
      checkInTime,
      checkOutTime,
      localAreaDescription,
      accessDescription,
      transitDescription,
      houseRules,
      ...restOfData
    } = data;

    const formattedValues = {
      myvr_id: myvr_condo.myvr_id.toString(),
      featured: featured ? 1 : 0,
      beachfront: beachfront ? 1 : 0,
      commission: 0,
      location: [Number(latitude), Number(longitude)],
      photo: photoObj ? photoObj.photoId : null,
      gallery_id: gallery.id,
      suitability: {
        elderly: elderly ? "yes" : "no",
        events: events ? "yes" : "no",
        groups: groups ? "yes" : "no",
        handicap: handicap ? "yes" : "no",
        infants: infants ? "yes" : "no",
        kids: kids ? "yes" : "no",
        pets: pets ? "yes" : "no",
        smoking: smoking ? "yes" : "no",
      },
      additional: {
        checkInTime: moment(checkInTime).format("HH:mm:ss"),
        checkOutTime: moment(checkOutTime).format("HH:mm:ss"),
        localAreaDescription,
        accessDescription,
        transitDescription,
        houseRules,
      },
      ...restOfData,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={4}>
        {/********************************************************************************************************/}
        {/******************************************** Condo Information *****************************************/}
        {/********************************************************************************************************/}
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <Typography variant="h6" component="label">
              Condo Information
            </Typography>
          </Box>
          {/* Condo Name */}
          <TextField
            inputRef={register}
            required
            name="name"
            label="Name"
            autoComplete="condo-name"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Code */}
        <Grid item xs={12} md={6}>
          <Controller
            name="myvr_condo"
            control={control}
            defaultValue={defaultValues.myvr_condo}
            render={props => (
              <MyvrComboBox
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Condo Email  */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="email"
            type="email"
            label="Email"
            required
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Permalink */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="permalink"
            defaultValue={defaultValues.permalink}
            render={props => (
              <PermalinkTextField
                value={props.value}
                required
                label="Condo Permalink"
                onChange={props.onChange}
                startAdornment={process.env.REACT_APP_DOMAIN + "/condos/"}
                fullWidth
                readOnly={readOnly}
              />
            )}
          />
        </Grid>

        {/* Condo Description */}
        <Controller
          name={`description`}
          control={control}
          defaultValue={defaultValues.description}
          render={props => (
            <Grid item xs={12}>
              <RichTextEditor
                label="Description"
                withPictureUploader
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                required
                height={500}
              />
            </Grid>
          )} // props contains: onChange, onBlur and value
        />

        {/* Condo Short Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="short_description"
            defaultValue={defaultValues.short_description}
            label="Short Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Type */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="type"
            label="Condo Type"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
            helperText="ex. Condo, Apartment, House."
          />
        </Grid>

        {/* Condo Tripadvisor */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="tripadvisor"
            label="Trip Advisor"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Bathrooms */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="bathrooms"
            label="Bathrooms"
            required
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Bedrooms */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="bedrooms"
            label="Bedrooms"
            required
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo MinNightStay */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="minStay"
            label="Minimum Night Stay"
            required
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Accommodates */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="accommodates"
            label="Accommodates"
            required
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Commission */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Commission"
            name="commission"
            disabled
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Condo Status */}
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Controller
              name={`status`}
              control={control}
              defaultValue={defaultValues.status}
              render={props => (
                <FormControl className={classes.maxWidth}>
                  <InputLabel shrink>Status</InputLabel>
                  <Select
                    value={props.value}
                    inputProps={{readOnly}}
                    onChange={props.onChange}>
                    <MenuItem value={"Draft"}>Draft</MenuItem>
                    <MenuItem value={"Published"}>Published</MenuItem>
                    <MenuItem value={"Private"}>Private</MenuItem>
                  </Select>
                </FormControl>
              )} // props contains: onChange, onBlur and value
            />
          </Grid>
        </Grid>

        {/* Condo Featured */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`featured`}
            control={control}
            defaultValue={defaultValues.featured}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Featured"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Condo Beachfront */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`beachfront`}
            control={control}
            defaultValue={defaultValues.beachfront}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Beachfront"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/****************************************************************************************************/}
        {/***************************************************** Condo Location *****************************************/}
        {/*************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Condo Location
            </Typography>
          </Box>
        </Grid>

        {/* Condo PlaceId */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="placeID"
            label="Place ID"
            fullWidth
            InputProps={{
              readOnly,
            }}
            helperText={
              <Link
                target="_blank"
                rel="noopener"
                color="secondary"
                href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder">
                Go to PlaceID Finder
              </Link>
            }
          />
        </Grid>

        {/* Condo Latitude */}
        <Grid item xs={6}>
          <TextField
            inputRef={register}
            required
            name="latitude"
            label="Latitude"
            fullWidth
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Longitude */}
        <Grid item xs={6}>
          <TextField
            inputRef={register}
            required
            name="longitude"
            label="Longitude"
            fullWidth
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Address */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            required
            name="street"
            label="Street Address"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            name="street_2"
            label="Street Address Secondary"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo City */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="city"
            label="City"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo State */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="state"
            label="State"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Zip */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="zip"
            label="Zip"
            fullWidth
            InputProps={{
              readOnly,
              type: "text",
            }}
          />
        </Grid>

        {/*  Country */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="country"
            label="Country"
            fullWidth
            InputProps={{
              readOnly,
              type: "text",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/*************************************************************************************************************/}
        {/************************************************** Condo Images *************************************************/}
        {/**************************************************************************************************/}

        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Condo Images
            </Typography>
          </Box>
        </Grid>

        {/* Condo Image  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Condo Photo"
                buttonLabel="Set Condo Photo"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Condo Gallery  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`gallery`}
            control={control}
            defaultValue={defaultValues.gallery}
            render={props => (
              <GalleryComboBox
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/****************************************************************************************************/}
        {/***************************************************** Condo suitability  *****************************************/}
        {/*************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Condo Suitability
            </Typography>
          </Box>
        </Grid>

        {/* Elderly */}

        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`elderly`}
            control={control}
            defaultValue={defaultValues.elderly}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Elderly"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Events*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`events`}
            control={control}
            defaultValue={defaultValues.events}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Events"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Groups*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`groups`}
            control={control}
            defaultValue={defaultValues.groups}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Groups"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Handicap*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`handicap`}
            control={control}
            defaultValue={defaultValues.handicap}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Handicap"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Infants*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`infants`}
            control={control}
            defaultValue={defaultValues.infants}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Infants"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Kids*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`kids`}
            control={control}
            defaultValue={defaultValues.kids}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Kids"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Pets*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`pets`}
            control={control}
            defaultValue={defaultValues.pets}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Pets"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Smoking*/}
        <Grid item xs={12} md={6} lg={3}>
          <Controller
            name={`smoking`}
            control={control}
            defaultValue={defaultValues.smoking}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Smoking"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/**********************************************************************************************************************************/}
        {/************************************************************ Condo Additional  ***************************************************/}
        {/**********************************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Additional Information
            </Typography>
          </Box>
        </Grid>

        {/* Check In Time */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`checkInTime`}
            control={control}
            defaultValue={defaultValues.checkInTime}
            render={props => (
              <KeyboardTimePicker
                label="Check In Time"
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Check Out Time */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`checkOutTime`}
            control={control}
            defaultValue={defaultValues.checkOutTime}
            render={props => (
              <KeyboardTimePicker
                label="Check Out Time"
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Access Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="localAreaDescription"
            label="Access Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Local Area Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="localAreaDescription"
            label="Local Area Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Transit Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="transitDescription"
            label="Transit Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* House Rules */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="houseRules"
            label="House Rules"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/**********************************************************************************************************************************/}
        {/************************************************************ Condo Amenities ***************************************************/}
        {/**********************************************************************************************************************************/}

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="label">
              Amenities
            </Typography>
          </Grid>

          <Controller
            name={`amenities`}
            control={control}
            defaultValue={defaultValues.amenities}
            render={props => (
              <AmenitiesFields
                value={props.value}
                readOnly={readOnly}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

function AmenitiesFields({onChange, value, readOnly = false}) {
  const [amenity, setAmenity] = useState(
    value && value.length > 0 ? value : [""]
  );

  const addAmenity = () => {
    setAmenity([...amenity, ""]);
  };
  const handleAmenityChange = (index, newValue) => {
    const updatedAmenityArray = [...amenity];
    updatedAmenityArray[index] = newValue;

    setAmenity(updatedAmenityArray);
  };
  const handleAmenityRemove = index => {
    const updatedBS = amenity.filter((value, idx) => idx !== index);
    setAmenity(updatedBS);
  };

  useEffect(() => {
    onChange(amenity);
  }, [onChange, amenity]);

  return (
    <>
      {amenity.map((amenityEl, i) => (
        <React.Fragment key={`amenity-${i}`}>
          <Grid item xs={10}>
            <TextField
              id="amenity"
              key={`amenity-${i}`}
              required
              fullWidth
              label={`Amenity  ${i + 1}`}
              name="amenity"
              autoComplete="amenity"
              value={amenityEl}
              InputProps={{
                readOnly,
              }}
              onChange={e => handleAmenityChange(i, e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            {amenity.length > 1 && !readOnly && (
              <Box mb={1} ml={2} display="inline-block" color="error.main">
                <DeleteButton
                  aria-label="delete"
                  onClick={() => handleAmenityRemove(i)}
                  size="large"
                  startIcon={<DeleteIcon />}>
                  Delete
                </DeleteButton>
              </Box>
            )}
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        {!readOnly && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => addAmenity()}>
            Add Amenity
          </Button>
        )}
      </Grid>
    </>
  );
}

export default CondosForm;
