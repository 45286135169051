import React from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import ButtonsContainer from "./ButtonsContainer";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
}));
const LinkRouter = props => <Link {...props} component={RouterLink} />;

const ModulesForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  const defaultValues = {
    packages: settingsList.modules.value.packages ? "Enabled" : "Disabled",
    springBreak: settingsList.modules.value.springBreak
      ? "Enabled"
      : "Disabled",
    hotels: settingsList.modules.value.hotels ? "Enabled" : "Disabled",
    condos: settingsList.modules.value.condos ? "Enabled" : "Disabled",
    events: settingsList.modules.value.events ? "Enabled" : "Disabled",
    instagram: settingsList.modules.value.instagram ? "Enabled" : "Disabled",
    webcams: settingsList.modules.value.webcams ? "Enabled" : "Disabled",
    meetings: settingsList.modules.value.meetings ? "Enabled" : "Disabled",
    newsletter: settingsList.modules.value.newsletter ? "Enabled" : "Disabled",
  };
  const {handleSubmit, control} = useForm({
    defaultValues,
  });

  const formSubmit = data => {
    const {
      packages,
      springBreak,
      hotels,
      condos,
      events,
      instagram,
      webcams,
      meetings,
      newsletter,
    } = data;

    const settingArray = [
      {
        ...settingsList.modules,
        value: JSON.stringify({
          packages: packages === "Enabled",
          springBreak: springBreak === "Enabled",
          hotels: hotels === "Enabled",
          condos: condos === "Enabled",
          events: events === "Enabled",
          instagram: instagram === "Enabled",
          webcams: webcams === "Enabled",
          meetings: meetings === "Enabled",
          newsletter: newsletter === "Enabled",
        }),
      },
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/*  Packages  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`packages`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Packages Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Condos  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`condos`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Condos Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Hotels  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`hotels`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Hotels Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Events  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`events`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Events Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Instagram  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`instagram`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Instagram Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Webcams  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`webcams`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Webcams Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Meetings  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`meetings`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Meetings Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  Newsletter  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`newsletter`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Newsletter Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*  SpringBreak  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`springBreak`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Spring Break Module</InputLabel>
                <Select value={props.value} onChange={props.onChange}>
                  <MenuItem value={"Enabled"}>Enabled</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Disclaimer  */}
        <Grid item xs={12}>
          <Typography variant="body1">Disclaimers:</Typography>
          <Typography variant="body2" component="ul">
            <li>
              Activating the 'Packages' and 'Condos' Modules will change the
              behavior of the Booking Form and Booking Pages.
            </li>
            <li>
              If you want to add Title, Description and Picture to the
              'Packages', 'Hotels', 'Condos' and 'Events' pages, they need to be
              set through the{" "}
              <LinkRouter to="/pages" color="secondary">
                Pages Dashboard.
              </LinkRouter>
            </li>
          </Typography>
        </Grid>

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default ModulesForm;
