import React, {useEffect} from "react";

//utils
import cronstrue from "cronstrue";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchEventSources} from "../../../redux/features/events/sourcesSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const EventSourcesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {eventSourcesList, isLoading, error} = useSelector(
    state => state.eventSources
  );

  useEffect(() => {
    dispatch(fetchEventSources());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/events/sources"
        tableTitle="All Sources"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {
            title: "Frequency",
            field: "frequency",
            render: rowData => {
              return <>{cronstrue.toString(rowData.frequency)}</>;
            },
          },
          {title: "Source", field: "src"},
          {title: "Event Status", field: "event_status"},
          {title: "Type", field: "import_type"},
        ]}
        addButtonLabel="Event Source"
        dataList={eventSourcesList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="events"
      />
    </>
  );
};

export default EventSourcesPage;
