import React, {useState, useEffect} from "react";

// Utils
import {useForm, Controller} from "react-hook-form";
import arrayMove from "array-move";
import moment from "moment";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import {sortableContainer, sortableElement} from "react-sortable-hoc";
import IntegerInput from "../../FormFields/IntegerInput";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonsContainer from "./ButtonsContainer";
import DragHandle from "../../UI/DragHandle";
import CheckboxWithState from "../../FormFields/CheckboxWithState";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
}));

const SortableItem = sortableElement(({selectedPersonObj, onChange}) => (
  <Grid container item>
    <Grid container item xs={1} justify="center" alignItems="center">
      <DragHandle />
    </Grid>
    <Grid container item xs={11} spacing={4}>
      <SelectedPersonFields
        selectedPersonObj={selectedPersonObj}
        onChange={newValues => onChange(newValues)}
      />
    </Grid>
    <Grid item xs={12} style={{marginTop: 50, marginBottom: 20}}>
      <Divider />
    </Grid>
  </Grid>
));

const SortableContainer = sortableContainer(({children}) => {
  return (
    <Grid container item xs={12}>
      {children}
    </Grid>
  );
});

const SelectedPersonFields = ({selectedPersonObj, onChange}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [label, setLabel] = useState(selectedPersonObj.label);
  const [name] = useState(selectedPersonObj.name);
  const [show, setShow] = useState(selectedPersonObj.show);
  const [max, setMax] = useState(selectedPersonObj.max);
  const [min, setMin] = useState(selectedPersonObj.min);
  const [selected, setSelected] = useState(selectedPersonObj.selected);

  useEffect(() => {
    onChange({
      label,
      name,
      show,
      max,
      min,
      selected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, name, show, max, min, selected]);

  return (
    <>
      <Grid container item xs={12} style={{paddingBottom: 0}}>
        <Typography component="p" variant="body1">
          {name.charAt(0).toUpperCase() + name.slice(1)}
        </Typography>
        <FormControlLabel
          className={classes.maxWidth}
          control={
            <Checkbox
              onChange={() => setShow(!show)}
              value="show"
              checked={show}
            />
          }
          label="Show Field"
        />
      </Grid>

      {/* Label */}
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          className={classes.maxWidth}
          label="Label"
          value={label}
          onChange={e => setLabel(e.target.value)}
          required
          disabled={!show}
        />
      </Grid>
      {/* Min People*/}
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          className={classes.maxWidth}
          label="Minimum No. Of People"
          required
          value={min}
          disabled={!show}
          InputProps={{
            inputComponent: IntegerInput,
          }}
          onChange={e => setMin(e.target.value * 1)}
        />
      </Grid>
      {/* Max People*/}
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          className={classes.maxWidth}
          label="Max No. Of People"
          required
          value={max}
          disabled={!show}
          InputProps={{
            inputComponent: IntegerInput,
          }}
          onChange={e => setMax(e.target.value * 1)}
        />
      </Grid>
      {/* Default selected People*/}
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          className={classes.maxWidth}
          label="Default Selected People"
          required
          value={selected}
          disabled={!show}
          InputProps={{
            inputComponent: IntegerInput,
          }}
          onChange={e => setSelected(e.target.value * 1)}
        />
      </Grid>
    </>
  );
};

const PeopleFields = ({value, onChange}) => {
  // Local State
  const [selectedPeople, setSelectedPeople] = useState(
    Array.isArray(value) && value.length > 0 ? value : []
  );

  const onSortEnd = ({oldIndex, newIndex}) => {
    setSelectedPeople(arrayMove(selectedPeople, oldIndex, newIndex));
  };

  const handlePersonChange = (name, newValues) => {
    const indexToReplace = selectedPeople.findIndex(obj => obj.name === name);
    let newArray = [...selectedPeople];
    newArray[indexToReplace] = newValues;
    setSelectedPeople(newArray);
  };

  useEffect(() => {
    onChange(selectedPeople);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeople]);

  return (
    <>
      <Grid item xs={12}>
        <Typography component="p" variant="h5">
          Selected People
        </Typography>
        <Typography component="p" variant="body2">
          (Drag to change order they appear in the form)
        </Typography>
      </Grid>
      <SortableContainer onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
        {selectedPeople.map((selectedPersonObj, index) => (
          <SortableItem
            index={index}
            key={selectedPersonObj.name}
            selectedPersonObj={selectedPersonObj}
            onChange={newValues =>
              handlePersonChange(selectedPersonObj.name, newValues)
            }
          />
        ))}
      </SortableContainer>
    </>
  );
};

const DatesFields = ({value, onChange}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [minDateEnabled, setMinDateEnabled] = useState(
    value.minDate !== "tomorrow"
  );
  const [minDate, setMinDate] = useState(value.minDate);
  const [maxDateEnabled, setMaxDateEnabled] = useState(value.maxDate);
  const [maxDate, setMaxDate] = useState(value.maxDate);

  useEffect(() => {
    onChange({
      minDateEnabled,
      minDate,
      maxDateEnabled,
      maxDate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDateEnabled, minDate, maxDateEnabled, maxDate]);

  return (
    <>
      <Grid item xs={12} style={{paddingBottom: 0}}>
        <Typography component="p" variant="h5">
          Dates
        </Typography>
      </Grid>

      {/* Min Date */}
      <Grid container item xs={12} md={6}>
        <Typography component="p" variant="body1">
          Minimum Date
        </Typography>
        <CheckboxWithState
          className={classes.maxWidth}
          value={minDateEnabled}
          onChange={setMinDateEnabled}
          label="Enable Minimum Date"
        />

        <KeyboardDatePicker
          className={classes.maxWidth}
          label="Minimum Date"
          format="MM/dd/yyyy"
          value={minDate}
          onChange={date => setMinDate(date)}
          disabled={!minDateEnabled}
          KeyboardButtonProps={{
            "aria-label": "change minimum date",
          }}
        />
      </Grid>

      {/* Max Date */}
      <Grid container item xs={12} md={6}>
        <Typography component="p" variant="body1">
          Maximum Date
        </Typography>

        <CheckboxWithState
          className={classes.maxWidth}
          value={maxDateEnabled}
          onChange={setMaxDateEnabled}
          label="Enable Maximum Date"
        />

        <KeyboardDatePicker
          className={classes.maxWidth}
          label="Maximum Date"
          format="MM/dd/yyyy"
          value={maxDate}
          onChange={date => setMaxDate(date)}
          minDate={moment(minDate).add(2, "days")}
          disabled={!maxDateEnabled}
          KeyboardButtonProps={{
            "aria-label": "change maximum date",
          }}
        />
      </Grid>
    </>
  );
};

const BookingSettingsForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Form Setup
  const defaultValues = {
    eventsPackageOffsetStart: settingsList.events_package_offset.value.start,
    eventsPackageOffsetEnd: settingsList.events_package_offset.value.end,
    selectedPeople: settingsList.bookingForm.value.selectedPeople,
    minNightStay: settingsList.bookingForm.value.minNightStay,
    dates: {
      minDateEnabled: settingsList.bookingForm.value.minDate !== "tomorrow",
      minDate:
        settingsList.bookingForm.value.minDate === "tomorrow"
          ? moment().add(1, "days")
          : moment(settingsList.bookingForm.value.minDate, "YYYY-MM-DD"),
      maxDateEnabled: settingsList.bookingForm.value.maxDate !== null,
      maxDate:
        settingsList.bookingForm.value.maxDate === null
          ? moment().add(2, "days")
          : moment(settingsList.bookingForm.value.maxDate, "YYYY-MM-DD"),
    },
  };

  const {handleSubmit, control, register} = useForm({
    defaultValues,
  });

  const formSubmit = data => {
    const {
      dates: {minDateEnabled, minDate, maxDateEnabled, maxDate},
      selectedPeople,
      eventsPackageOffsetStart,
      eventsPackageOffsetEnd,
    } = data;
    const settingArray = [
      {
        ...settingsList.bookingForm,
        value: JSON.stringify({
          ...settingsList.bookingForm.value,
          minDate: minDateEnabled
            ? moment(minDate).format("YYYY-MM-DD")
            : "tomorrow",
          maxDate: maxDateEnabled ? moment(maxDate).format("YYYY-MM-DD") : null,
          selectedPeople,
        }),
      },
      {
        ...settingsList.events_package_offset,
        value: JSON.stringify({
          start: eventsPackageOffsetStart,
          end: eventsPackageOffsetEnd,
        }),
      },
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Min Night Stay*/}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            label="Minimum Night Stay"
            required
            name="minNightStay"
            defaultValue={defaultValues.minNightStay}
            InputProps={{
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Events Package Offset Start */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            label="Events Packages Offset Start"
            name="eventsPackageOffsetStart"
            required
            defaultValue={defaultValues.eventsPackageOffsetStart}
            InputProps={{
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Events Package Offset End */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            label="Events Packages Offset End"
            required
            name="eventsPackageOffsetEnd"
            defaultValue={defaultValues.eventsPackageOffsetEnd}
            InputProps={{
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Min and Max dates  */}
        <Controller
          name={`dates`}
          control={control}
          defaultValue={defaultValues.dates}
          render={props => (
            <DatesFields value={props.value} onChange={props.onChange} />
          )}
        />

        {/* Selected People  */}
        <Controller
          name={`selectedPeople`}
          control={control}
          defaultValue={defaultValues.selectedPeople}
          render={props => (
            <PeopleFields value={props.value} onChange={props.onChange} />
          )}
        />

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default BookingSettingsForm;
