import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchHotels} from "../../redux/features/hotels/hotelsSlice";

// Components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import {VariableSizeList} from "react-window";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType={List}
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const MultipleHotelsComboBox = ({
  id,
  fieldLabel,
  onChange,
  defaultValue,
  readOnly,
}) => {
  // Local state
  const [value, setValue] = useState(defaultValue || []);
  // Redux
  const dispatch = useDispatch();
  const {hotelsList, isLoading, error} = useSelector(state => state.hotels);

  useEffect(() => {
    // Load Hotels
    dispatch(fetchHotels());
  }, [dispatch]);

  useEffect(() => {
    if (value) {
      onChange(value);
    } else {
      onChange(null);
    }
  }, [onChange, value]);

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        style={{display: "flex", alignItems: "flex-end", height: "100%"}}
        component="div">
        There was an error loading the Hotel Module.
      </Typography>
    );
  }

  return (
    <>
      {!isLoading && hotelsList.length > 0 ? (
        <Autocomplete
          id={id}
          options={hotelsList}
          autoHighlight
          multiple
          disableCloseOnSelect
          loading={isLoading}
          getOptionLabel={hotel => hotel.name}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          disabled={readOnly}
          value={value}
          ListboxComponent={ListboxComponent}
          renderTags={(value, getTagProps) =>
            value.map((hotel, index) => (
              <Chip label={hotel.name} {...getTagProps({index})} />
            ))
          }
          renderOption={(hotel, {selected}) => {
            const labelId = `transfer-list-item-${hotel.id}-label`;
            return (
              <>
                <ListItemIcon>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={hotel.name}
                  secondary={`${hotel.city}, ${hotel.state}`}
                />
              </>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={fieldLabel}
              fullWidth
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={48} animation="wave" />
      )}
    </>
  );
};

export default MultipleHotelsComboBox;
