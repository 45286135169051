import React, {useEffect} from "react";

// Utils
import {useForm, Controller} from "react-hook-form";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPaymentMethods} from "../../../redux/features/sales/paymentMethodsSlice";

// Routing
import {Link as RouterLink} from "react-router-dom";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormatCustom from "../../FormFields/NumberFormatCustom";
import FullContainerProgress from "../../UI/FullContainerProgress";
import Box from "@material-ui/core/Box";
import PaymentMethodsComboBox from "../../FormFields/PaymentMethodsComboBox";
import Link from "@material-ui/core/Link";

// Icons
import EditIcon from "@material-ui/icons/Edit";

//Styling
const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3),
    position: "relative",
    minHeight: "100px",
    height: "100%",
  },
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  dialogContent: {overflow: "visible", paddingBottom: theme.spacing(5)},
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const ChargeCreateDialog = ({
  currentPageOrder,
  open,
  onClose,
  onSubmit,
  readOnly = false,
}) => {
  //Styles
  const classes = useStyles();
  // Hooks
  const {handleSubmit, register, control} = useForm();

  const {customer_id, customer_name, id} = currentPageOrder;

  // Redux
  const dispatch = useDispatch();
  const {isLoading: isLoadingCharges} = useSelector(state => state.charges);

  useEffect(() => {
    if (customer_id) dispatch(fetchPaymentMethods(customer_id));
  }, [customer_id, dispatch]);

  const formSubmit = data => {
    const {amount, paymentMethod} = data;
    const formattedData = {
      order_id: id,
      amount: Number(amount),
      card_id: paymentMethod.id,
    };
    onSubmit(formattedData);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="alert-charge-view"
      aria-describedby="alert-charge-view"
      scroll="body">
      {isLoadingCharges ? (
        <>
          <DialogContent>
            <Box width={700} height={500}>
              <FullContainerProgress />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-charge-view">
            Create Charge for order {id}
          </DialogTitle>

          <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                {isLoadingCharges ? (
                  <Grid item xs={12}>
                    <Box width={500} height={200}>
                      <FullContainerProgress />
                    </Box>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="p">
                        Customer Name: {customer_name}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body1" component="p">
                        <LinkRouter to={`/sales/customers/${customer_id}`}>
                          <EditIcon fontSize="small" /> Edit Customer Address /
                          Payment Methods
                        </LinkRouter>
                      </Typography>
                    </Grid>

                    {/* Amount */}
                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        className={classes.maxWidth}
                        required
                        label="Amount"
                        name="amount"
                        defaultValue={0}
                        InputProps={{
                          readOnly,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name={`paymentMethod`}
                        control={control}
                        render={props => (
                          <PaymentMethodsComboBox
                            customer_id={customer_id}
                            defaultValue={props.value}
                            onChange={props.onChange}
                            readOnly={readOnly}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>

            {!readOnly && (
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isLoadingCharges}>
                  Create Charge
                </Button>
              </DialogActions>
            )}
          </form>
        </>
      )}
    </Dialog>
  );
};

export default ChargeCreateDialog;
