import React, { useState } from "react";

// Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteButton from "../Buttons/DeleteButton";

const DeleteDialog = ({
  dialogTitle = "Are you sure you want to delete this?",
  open,
  onClose,
  nameToCheck = "delete",
  onSubmit,
}) => {
  const [name, setName] = useState("");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To confirm that you want to delete type the word "delete".
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Type 'delete'"
          type="text"
          fullWidth
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <DeleteButton
          onClick={() => {
            onSubmit();
            onClose();
          }}
          disabled={name !== nameToCheck}>
          Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
