import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchPackageCard,
  editPackageCard,
  deletePackageCard,
} from "../../../redux/features/packages/cardSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../../UI/MainPaper";
import PackageCardsForm from "./PackageCardsForm";
import FullContainerProgress from "../../UI/FullContainerProgress";
import DeleteButton from "../../Buttons/DeleteButton";
import DeleteDialog from "../../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../../Navigation/BreadcrumbsWithEdit";

const PackageCardsEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPagePackageCard, isLoading, error} = useSelector(
    state => state.packageCards
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.packages;

  const formattedValues = {
    title: currentPagePackageCard.title,
    price: currentPagePackageCard.price,
    link: currentPagePackageCard.link,
    short_description: currentPagePackageCard.short_description,
    start: moment(currentPagePackageCard.start),
    end: moment(currentPagePackageCard.end),
    order_index: currentPagePackageCard.order_index,
    featured: currentPagePackageCard.featured,

    // This is used to display the selected picture in the edit form
    photoObj: {
      photoId: currentPagePackageCard.photoId,
      photoName: currentPagePackageCard.photoName,
    },
  };

  let breadcrumbNameMap = {
    "/packages": "Packages",
    "/packages/cards": "Package Cards",
  };
  breadcrumbNameMap[`/packages/cards/${id}`] = currentPagePackageCard.title;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchPackageCard(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const packageCardObj = {
      id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editPackageCard(packageCardObj));
    if (!res.error) {
      history.push({
        pathname: "/packages/cards",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${packageCardObj.title} were saved successfully!`,
          snackbarLink: `/packages/cards/${packageCardObj.id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deletePackageCard(id));
    if (!res.error) {
      history.push({
        pathname: "/packages/cards",
        state: {
          showSuccess: true,
          snackbarContent: `The Package Card was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if addon doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPagePackageCard.name}
                </Typography>
                <PackageCardsForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Package Card
            </DeleteButton>
          </Grid>
        )}
      </Grid>

      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Package Card?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default PackageCardsEdit;
