import React from "react";

// Utils
import {useForm, Controller} from "react-hook-form";

// Redux
import {useSelector} from "react-redux";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FullContainerProgress from "../../UI/FullContainerProgress";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import UploadFiles from "../../FormFields/UploadFiles";
import RichTextEditor from "../../FormFields/RichTextEditor";

//Styling
const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
}));

const RoomViewDialog = ({open, onClose, onSubmit, readOnly = false}) => {
  const {handleSubmit, control} = useForm();

  //Styles
  const classes = useStyles();
  // Redux
  const {currentPageRoom, isLoading, error} = useSelector(state => state.rooms);

  const formSubmit = data => {
    const values = {
      room_id: currentPageRoom.id,
      room: currentPageRoom.name,
      occupancy: currentPageRoom.occupancy,
      hotelCode: currentPageRoom.hotelCode,
      short_description: data.short_description,
      description: data.description || "<br/>",
      photo_id: data.photoObj.photoId,
    };
    onSubmit(values);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      aria-labelledby="alert-room-view"
      aria-describedby="alert-room-view"
      scroll="body">
      {isLoading ? (
        <>
          <DialogContent>
            <Box width={700} height={500}>
              <FullContainerProgress />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-room-view">
            Edit "{currentPageRoom.name}"
          </DialogTitle>
          <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
            <DialogContent style={{overflow: "visible"}}>
              <Grid container spacing={4}>
                {error && (
                  <Grid item xs={12}>
                    <MuiAlert className={classes.alert} severity="error">
                      There was an error. Please try again.
                    </MuiAlert>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body1" component="p">
                    Hotel Code: {currentPageRoom.hotelCode}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" component="p">
                    Occupancy: {currentPageRoom.occupancy}
                  </Typography>
                </Grid>

                {/* Room Description */}
                <Controller
                  name={`description`}
                  control={control}
                  defaultValue={currentPageRoom.description}
                  render={props => (
                    <Grid item xs={12}>
                      <RichTextEditor
                        label="Description"
                        className={classes.maxWidth}
                        value={props.value}
                        onChange={props.onChange}
                        readOnly={readOnly}
                        required
                        height={400}
                      />
                    </Grid>
                  )} // props contains: onChange, onBlur and value
                />

                {/* Room Short Description */}
                <Controller
                  name={`short_description`}
                  control={control}
                  defaultValue={currentPageRoom.short_description}
                  render={props => (
                    <Grid item xs={12}>
                      <TextField
                        className={classes.maxWidth}
                        required
                        name="short_description"
                        label="Short Description"
                        multiline
                        fullWidth
                        readOnly={readOnly}
                        value={props.value}
                        onChange={props.onChange}
                      />
                    </Grid>
                  )} // props contains: onChange, onBlur and value
                />

                {/* Room Image  */}
                <Controller
                  name={`photoObj`}
                  control={control}
                  defaultValue={
                    {
                      photoId: currentPageRoom.photo_id,
                      photoName: currentPageRoom.photoName,
                    } || null
                  }
                  render={props => (
                    <Grid item xs={12} lg={6}>
                      <UploadFiles
                        required
                        label="Featured Image"
                        buttonLabel="Set Featured Image"
                        singleFile
                        onSave={photosArray => props.onChange(photosArray[0])}
                        readOnly={readOnly}
                        defaultValue={props.value}
                      />
                    </Grid>
                  )} // props contains: onChange, onBlur and value
                />
              </Grid>
            </DialogContent>

            {!readOnly && (
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Save Changes
                </Button>
              </DialogActions>
            )}
          </form>
        </>
      )}
    </Dialog>
  );
};

export default RoomViewDialog;
