import React, {useState, useEffect} from "react";

// Utils
import {useForm, Controller} from "react-hook-form";
import arrayMove from "array-move";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

// Components
import {sortableContainer, sortableElement} from "react-sortable-hoc";
import UploadFiles from "../../FormFields/UploadFiles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import RichTextEditor from "../../FormFields/RichTextEditor";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonsContainer from "./ButtonsContainer";
import DragHandle from "../../UI/DragHandle";

// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
  deleteButton: {
    color: red[500],
  },
}));

const SortableItem = sortableElement(
  ({footLogoObj, onChange, onDelete, withDivider = true}) => {
    const classes = useStyles();

    return (
      <Grid container item>
        <Grid container item xs={1} justify="center" alignItems="center">
          <DragHandle />
        </Grid>
        <Grid container item xs={10} spacing={4} alignItems="center">
          <FootLogoFields
            footLogoObj={footLogoObj}
            onChange={newValues => onChange(newValues)}
          />
        </Grid>
        <Grid container item xs={1} justify="center" alignItems="center">
          <Tooltip title="Delete Logo" placement="top">
            <IconButton
              aria-label="delete"
              onClick={onDelete}
              className={classes.deleteButton}>
              <DeleteIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
        {withDivider && (
          <Grid item xs={12} style={{marginTop: 50, marginBottom: 20}}>
            <Divider />
          </Grid>
        )}
      </Grid>
    );
  }
);

const SortableContainer = sortableContainer(({children}) => {
  return (
    <Grid container item xs={12}>
      {children}
    </Grid>
  );
});

const FootLogoFields = ({footLogoObj, onChange}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [href, setHref] = useState(footLogoObj.href);
  const [src, setSrc] = useState(footLogoObj.src);

  useEffect(() => {
    onChange({
      href,
      src,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href, src]);

  return (
    <>
      {/* Image */}
      <Grid item xs={12} md={4}>
        <UploadFiles
          required
          label="Image"
          buttonLabel="Set Logo Image"
          singleFile
          onSave={photosArray => setSrc(photosArray[0].photoName)}
          defaultValue={src ? {photoName: src} : null}
          verticalLayout
        />
      </Grid>

      {/* Link */}
      <Grid item xs={12} md={8}>
        <TextField
          className={classes.maxWidth}
          label="Link"
          type="url"
          value={href}
          onChange={e => setHref(e.target.value)}
          required
        />
      </Grid>
    </>
  );
};

const FooterLogos = ({value, onChange}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [foot_logos, setFootLogos] = useState(
    Array.isArray(value) && value.length > 0 ? value : []
  );

  const onSortEnd = ({oldIndex, newIndex}) => {
    setFootLogos(arrayMove(foot_logos, oldIndex, newIndex));
  };

  const handleFootLogoChange = (index, newValues) => {
    let newArray = [...foot_logos];
    newArray[index] = newValues;
    setFootLogos(newArray);
  };

  const onDeleteFootLogo = index => {
    const filteredArray = foot_logos.filter((value, idx) => idx !== index);
    setFootLogos(filteredArray);
  };

  const newFootLogo = () => {
    const newArray = [...foot_logos, {href: "", src: null}];
    setFootLogos(newArray);
  };

  useEffect(() => {
    if (Array.isArray(foot_logos) && foot_logos.length > 0) {
      onChange(foot_logos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foot_logos]);

  return (
    <>
      {/* Footer Logos */}
      <Grid item xs={12}>
        <Typography component="p" variant="h5">
          Footer Logos
        </Typography>
        <Typography component="p" variant="body2">
          (Drag to change order they appear in the footer)
        </Typography>
      </Grid>
      <SortableContainer onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
        {foot_logos.map((footLogoObj, index) => {
          return (
            <SortableItem
              index={index}
              key={`${footLogoObj.src}-${index}`}
              footLogoObj={footLogoObj}
              onChange={newValues => handleFootLogoChange(index, newValues)}
              onDelete={() => onDeleteFootLogo(index)}
              withDivider={index < foot_logos.length - 1}
            />
          );
        })}
      </SortableContainer>
      <Grid item xs={12}>
        <Button
          className={classes.maxWidth}
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          onClick={newFootLogo}>
          Footer Logo
        </Button>
      </Grid>
    </>
  );
};

const ContentForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Form Setup
  const defaultValues = {
    iconPhotoObj: settingsList.icon.value
      ? {photoName: settingsList.icon.value}
      : null,
    logoPhotoObj: settingsList.logo.value
      ? {photoName: settingsList.logo.value}
      : null,
    coverImagePhotoObj: settingsList.cover_image.value
      ? {photoName: settingsList.cover_image.value}
      : null,
    coverVideoObj: settingsList.cover_video.value
      ? {name: settingsList.cover_video.value}
      : null,
    titleLine1: settingsList.home_header.value.title.line1 || "",
    titleLine2: settingsList.home_header.value.title.line2 || "",
    bookingFormLine1: settingsList.home_header.value.bookingForm.line1 || "",
    bookingFormLine2: settingsList.home_header.value.bookingForm.line2 || "",
    need_help_message: settingsList.need_help_message.value || "",
    schedule_call_link: settingsList.schedule_call_link.value || "",
    schedule_call_with: settingsList.schedule_call_with.value || "",
    customer_service_hours: settingsList.customer_service_hours.value || "",
    newsletter_title: settingsList.newsletter_title.value || "",
    newsletter_body: settingsList.newsletter_body.value || "",
    foot_logos: settingsList.foot_logos.value,
  };

  const {handleSubmit, control, register} = useForm({
    defaultValues,
  });

  const formSubmit = data => {
    const {
      iconPhotoObj,
      logoPhotoObj,
      coverImagePhotoObj,
      coverVideoObj,
      titleLine1,
      titleLine2,
      bookingFormLine1,
      bookingFormLine2,
      need_help_message,
      schedule_call_link,
      schedule_call_with,
      customer_service_hours,
      newsletter_title,
      newsletter_body,
      foot_logos,
    } = data;

    const settingArray = [
      {
        ...settingsList.icon,
        value: iconPhotoObj?.photoName ? iconPhotoObj.photoName : null,
      },
      {
        ...settingsList.logo,
        value: logoPhotoObj?.photoName ? logoPhotoObj.photoName : null,
      },
      {
        ...settingsList.cover_image,
        value: coverImagePhotoObj?.photoName
          ? coverImagePhotoObj.photoName
          : null,
      },
      {
        ...settingsList.cover_video,
        value: coverVideoObj?.name ? coverVideoObj.name : null,
      },
      {
        ...settingsList.home_header,
        value: JSON.stringify({
          title: {line1: titleLine1, line2: titleLine2},
          bookingForm: {line1: bookingFormLine1, line2: bookingFormLine2},
        }),
      },
      {
        ...settingsList.newsletter_title,
        value: newsletter_title,
      },
      {
        ...settingsList.newsletter_body,
        value: newsletter_body,
      },
      {
        ...settingsList.need_help_message,
        value: need_help_message,
      },
      {
        ...settingsList.schedule_call_link,
        value: schedule_call_link,
      },
      {
        ...settingsList.schedule_call_with,
        value: schedule_call_with,
      },
      {
        ...settingsList.customer_service_hours,
        value: customer_service_hours,
      },
      {
        ...settingsList.foot_logos,
        value: JSON.stringify(foot_logos),
      },
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Favicon */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`iconPhotoObj`}
            control={control}
            defaultValue={defaultValues.iconPhotoObj}
            render={props => (
              <UploadFiles
                label="Favicon"
                buttonLabel="Set Favicon"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Logo */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`logoPhotoObj`}
            control={control}
            defaultValue={defaultValues.logoPhotoObj}
            render={props => (
              <UploadFiles
                label="Logo"
                buttonLabel="Set Logo"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Cover Image */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`coverImagePhotoObj`}
            control={control}
            defaultValue={defaultValues.coverImagePhotoObj}
            render={props => (
              <UploadFiles
                required
                label="Cover Image"
                buttonLabel="Set Cover Image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                defaultValue={props.value}
                itemsWidth={400}
                itemsHeight={200}
              />
            )}
          />
        </Grid>

        {/* Cover Video */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`coverVideoObj`}
            control={control}
            defaultValue={defaultValues.coverVideoObj}
            render={props => (
              <UploadFiles
                label="Cover Video"
                buttonLabel="Set Cover Video"
                singleFile
                video
                enableImages={false}
                nameKey="name"
                idKey="id"
                itemsWidth={400}
                itemsHeight={200}
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Home Header */}
        <Grid item xs={12} style={{paddingTop: 40, paddingBottom: 0}}>
          <Typography component="p" variant="h6">
            Home Header
          </Typography>
        </Grid>
        {/* Line 1 */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="titleLine1"
            label="Title Line 1"
          />
        </Grid>

        {/* Line 2 */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="titleLine2"
            label="Title Line 2"
          />
        </Grid>
        {/* Booking Form  */}
        {/* Line 1 */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="bookingFormLine1"
            label="Booking Form Line 1"
          />
        </Grid>

        {/* Line 2 */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="bookingFormLine2"
            label="Booking Form Line 2"
            multiline
          />
        </Grid>

        {/* Newsletter */}
        {/* Title */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="newsletter_title"
            label="Newsletter Title"
          />
        </Grid>
        {/* Title */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="newsletter_body"
            label="Newsletter Body"
          />
        </Grid>

        {/* Need Help Message */}
        <Grid item xs={12}>
          <Controller
            name={`need_help_message`}
            control={control}
            defaultValue={defaultValues.need_help_message}
            render={props => (
              <Grid item xs={12}>
                <RichTextEditor
                  label="Need Help Message"
                  value={props.value}
                  onChange={props.onChange}
                  height={150}
                />
              </Grid>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Schedule Call With */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="schedule_call_with"
            label="Schedule a Call With..."
            multiline
          />
        </Grid>

        {/* Schedule Call Link */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="schedule_call_link"
            label="Schedule Call Link"
            type="url"
            multiline
          />
        </Grid>

        {/* Customer Service Hours*/}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="customer_service_hours"
            label="Customer Service Hours"
            multiline
          />
        </Grid>

        <Controller
          name={`foot_logos`}
          control={control}
          defaultValue={defaultValues.foot_logos}
          render={props => (
            <FooterLogos value={props.value} onChange={props.onChange} />
          )}
        />

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default ContentForm;
