import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchCustomer,
  editCustomer,
} from "../../../redux/features/sales/customersSlice";
import {
  fetchAddresses,
  createAddress,
} from "../../../redux/features/sales/addressesSlice";
import {
  fetchPaymentMethods,
  createPaymentMethod,
} from "../../../redux/features/sales/paymentMethodsSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import AddressesTableComponent from "./Addresses/AddressesTableComponent";
import PaymentMethodsTableComponent from "./PaymentMethods/PaymentMethodsTableComponent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "../../UI/MainPaper";
import Snackbar from "@material-ui/core/Snackbar";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import CustomersForm from "./CustomersForm";
import FullContainerProgress from "../../UI/FullContainerProgress";
import BreadcrumbsWithEdit from "../../Navigation/BreadcrumbsWithEdit";
import CreateAddressDialog from "./Addresses/CreateAddressDialog";
import CreatePaymentMethodDialog from "./PaymentMethods/CreatePaymentMethodDialog";
import {Elements} from "react-stripe-elements";

// Icons
import AddIcon from "@material-ui/icons/Add";

const CustomersEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openPaymentMethodDialog, setOpenPaymentMethodDialog] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageCustomer, isLoading, error} = useSelector(
    state => state.customers
  );
  // User Permission from redux
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write} = userPermission.sales;

  const formattedValues = {
    first_name: currentPageCustomer.first_name,
    last_name: currentPageCustomer.last_name,
    email_address: currentPageCustomer.email_address,
    phone_number: currentPageCustomer.phone_number,
    created_date: currentPageCustomer.created_date,
    stripe_customer_id: currentPageCustomer.stripe_customer_id,
  };

  let breadcrumbNameMap = {
    "/sales": "Sales",
    "/sales/customers": "Customers",
  };
  breadcrumbNameMap[
    `/sales/customers/${id}`
  ] = `${currentPageCustomer.first_name} ${currentPageCustomer.last_name}`;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchCustomer(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const onSubmit = async values => {
    const customerObj = {
      customer_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editCustomer(customerObj));
    if (!res.error) {
      history.push({
        pathname: "/sales/customers",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${customerObj.first_name} ${customerObj.last_name} were saved successfully!`,
          snackbarLink: `/sales/customers/${customerObj.customer_id}`,
        },
      });
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onCreateAddressSubmit = async values => {
    const res = await dispatch(createAddress(id, values));

    // If Refund was created successfully close dialog and show user
    if (!res.error) {
      setOpenAddressDialog(false);
      setOpenSnackbar(true);
      setSnackbarContent(`The Address was successfully created!`);
      dispatch(fetchAddresses(id));
    }
  };
  const onCreatePaymentMethodSubmit = async values => {
    const res = await dispatch(createPaymentMethod(id, values));

    // If Payment Method was created successfully close dialog and show user
    if (!res.error) {
      setOpenPaymentMethodDialog(false);
      setOpenSnackbar(true);
      setSnackbarContent(`The Payment Method was successfully created!`);
      dispatch(fetchPaymentMethods(id));
    }
  };

  // Redirect if customer doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {`${currentPageCustomer.first_name} ${currentPageCustomer.last_name} `}
                </Typography>
                <CustomersForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>

        {/* Addresses  */}
        {!isLoading && (
          <Grid container item xs={12} justify="flex-end">
            <Box clone mt={3}>
              <Button
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddressDialog(true)}>
                Address
              </Button>
            </Box>
          </Grid>
        )}
        <AddressesTableComponent customerId={id} />

        {/* PaymentMethods */}
        {!isLoading && (
          <Grid container item xs={12} justify="flex-end">
            <Box clone mt={3}>
              <Button
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={() => setOpenPaymentMethodDialog(true)}>
                Payment Method
              </Button>
            </Box>
          </Grid>
        )}
        <PaymentMethodsTableComponent customerId={id} />
      </Grid>

      {/* Dialogs */}
      <CreateAddressDialog
        customerId={id}
        open={openAddressDialog}
        onClose={() => setOpenAddressDialog(false)}
        onSubmit={onCreateAddressSubmit}
      />
      <Elements>
        <CreatePaymentMethodDialog
          open={openPaymentMethodDialog}
          onClose={() => setOpenPaymentMethodDialog(false)}
          onSubmit={onCreatePaymentMethodSubmit}
        />
      </Elements>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default CustomersEdit;
