import React from "react";

//Styles
import {makeStyles} from "@material-ui/core/styles";

// Components
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import {VariableSizeList} from "react-window";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteButton from "../Buttons/DeleteButton";

// Icons
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const useStyles = makeStyles(theme => ({
  testClass: {
    "& .MuiInputBase-root": {
      height: "50px",
    },
  },
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  iconImg: {
    marginRight: "10px",
    maxHeight: 30,
    filter: theme.palette.type === "light" ? "invert(20%)" : "invert(80%)",
  },
}));

const IconComboBox = ({
  iconsList,
  loading,
  onChange,
  value,
  readOnly,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      {...rest}
      options={iconsList}
      autoHighlight
      clearOnEscape
      loading={loading}
      getOptionLabel={icon => icon.icon_name}
      renderGroup={renderGroup}
      className={classes.testClass}
      groupBy={icon => icon.icon_group}
      value={iconsList.filter(icon => icon.icon_id === value)[0]}
      onChange={onChange}
      disabled={readOnly}
      disableListWrap
      ListboxComponent={ListboxComponent}
      renderOption={icon => (
        <>
          <img
            src={`${process.env.REACT_APP_STATIC_PATH}/${icon.path}`}
            width="30px"
            alt={icon.icon_name}
            className={classes.iconImg}
          />
          {icon.icon_name}
        </>
      )}
      renderInput={params => {
        const value = iconsList.find(
          icon => icon.icon_name === params.inputProps.value
        );
        const path = value ? value.path : "";
        return (
          <TextField
            {...params}
            label="Choose an Icon"
            fullWidth
            required
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {path ? (
                    <img
                      src={`${process.env.REACT_APP_STATIC_PATH}/${path}`}
                      height="25px"
                      alt={"icon"}
                      className={classes.iconImg}
                    />
                  ) : (
                    <InfoIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};

const IconTextInputs = ({
  id,
  index,
  stateArray,
  handleChange,
  handleRemove,
  iconsList,
  iconsLoading,
  iconsError,
  readOnly,
}) => {
  const iconId = `${id}-icon-${index}`;
  const textId = `${id}-text-${index}`;

  return (
    <Box mb={5} id={id}>
      <Grid container item xs={12} key={`bs-${index}`}>
        <Grid item xs={12}>
          <Typography variant="body1" component="label">
            {`Item #${index + 1}`}
          </Typography>

          {stateArray.length > 1 && !readOnly && (
            <Box mb={1} ml={2} display="inline-block" color="error.main">
              <DeleteButton
                aria-label="delete"
                onClick={() => handleRemove(index)}
                size="small"
                startIcon={<DeleteIcon />}>
                Delete Item
              </DeleteButton>
            </Box>
          )}

          {iconsError ? (
            <Typography variant="body1" color="error" component="div">
              There was an error loading the Icons Module.
            </Typography>
          ) : (
            <>
              {iconsLoading ? (
                <Skeleton
                  variant="rect"
                  width={"100%"}
                  height={48}
                  animation="wave"
                />
              ) : (
                <Box mb={2}>
                  <IconComboBox
                    name={iconId}
                    id={iconId}
                    label="Icon"
                    iconsList={iconsList}
                    loading={iconsLoading}
                    value={stateArray[index].icon.id}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleChange("icon", index, {
                          id: newValue.icon_id,
                          path: newValue.path,
                        });
                      }
                    }}
                    readOnly={readOnly}
                  />
                </Box>
              )}
            </>
          )}

          <TextField
            required
            name={textId}
            id={textId}
            label="Text"
            fullWidth
            value={stateArray[index].text}
            inputProps={{
              "data-index": index,
              "data-type": "text",
            }}
            InputProps={{
              readOnly,
            }}
            onChange={e => handleChange("text", index, e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IconTextInputs;
