import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createCustomer} from "../../../redux/features/sales/customersSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../../UI/MainPaper";
import CustomersForm from "./CustomersForm";
import Breadcrumbs from "../../Navigation/Breadcrumbs";

const CustomersNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.customers);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/sales": "Sales",
    "/sales/customers": "Customers",
    "/sales/customers/new": "New Customer",
  };

  const onCancel = () => {
    history.push("/sales/customers");
  };
  const onSubmit = async values => {
    const customerObj = {
      ...values,
    };

    const res = await dispatch(createCustomer(customerObj));
    // If package was created successfully redirect to sales page and show user
    if (!res.error) {
      history.push({
        pathname: "/sales/customers",
        state: {
          showSuccess: true,
          snackbarContent: `Your Customer '${customerObj.first_name} ${customerObj.last_name}' was successfully created!`,
          snackbarLink: `/sales/customers/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Customer
          </Typography>
          <CustomersForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Customer"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomersNew;
