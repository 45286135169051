import React from "react";

// Components
import NumberFormat from "react-number-format";

const IntegerInput = props => {
  const {inputRef, onChange, ...other} = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        let formattedValue = values.value || 0;
        onChange({
          target: {value: formattedValue},
        });
      }}
      decimalScale={0}
      allowNegative={false}
    />
  );
};

export default IntegerInput;
