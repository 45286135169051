import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchCustomPackages} from "../../../redux/features/packages/customPackagesSlice";
import {fetchSettings} from "../../../redux/features/settings/settingsSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const CustomPackagesPage = () => {
  // Local State
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [copiedCode, setCopiedCode] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {customPackagesList, isLoading, error} = useSelector(
    state => state.customPackages
  );
  const {
    settingsList: {routes},
    isLoading: isLoadingSettings,
    error: settingsError,
  } = useSelector(state => state.settings);

  // Routing
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCustomPackages());
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/packages/customs"
        tableTitle="All Custom Packages"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {
            title: "Code Key",
            field: "code_key",
          },
          {
            title: "Check In",
            field: "check_in",
            type: "date",
          },
          {
            title: "Check Out",
            field: "check_out",
            type: "date",
          },
          {
            title: "Enabled",
            field: "enabled",
            type: "boolean",
          },
          {
            title: "Status",
            field: "package_status",
          },
        ]}
        customActions={[
          {
            icon: FileCopyIcon,
            tooltip: "Copy Checkout URL",
            onClick: (event, rowData) => {
              // Copy checkout url to clipboard
              const {code_key} = rowData;
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_DOMAIN}${routes.value.checkout.url}?cp=${code_key}`
              );
              setCopiedCode(code_key);
              setOpenSnackbar(true);
            },
          },
          {
            icon: VisibilityIcon,
            tooltip: "View/Edit",
            onClick: (event, rowData) => {
              // Go to Edit page
              history.push(`/packages/customs/${rowData.id}`);
            },
          },
        ]}
        addButtonLabel="Custom Package"
        dataList={customPackagesList}
        isLoading={isLoading && isLoadingSettings}
        error={error && settingsError}
        userPermissionKey="packages"
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <MuiAlert
          elevation={6}
          onClose={() => setOpenSnackbar(false)}
          severity="info">
          Copied checkout URL for Custom Package <strong>'{copiedCode}'</strong>{" "}
          to clipboard!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default CustomPackagesPage;
