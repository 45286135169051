import React from "react";

// Styling
import { makeStyles } from "@material-ui/core/styles";

// Components
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  progressContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FullContainerProgress = () => {
  // Styles
  const classes = useStyles();
  return (
    <div className={classes.progressContainer}>
      <CircularProgress />
    </div>
  );
};

export default FullContainerProgress;
