import React, {useState, useEffect} from "react";

// Hooks
import usePrevious from "../../hooks/usePrevious";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  uploadFiles,
  fetchFiles,
  deleteFile,
  editFile,
} from "../../redux/features/media/mediaSlice";

// Styling
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

// utils
import arrayMove from "array-move";

// Components
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  UploadFileTab,
  ImagesTab,
  VideosTab,
} from "../Media/MediaElements/TabPanels";

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tabPanel: {
    maxHeight: "100vh",
    overflow: "auto",
    "& .MuiBox-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  formButton: {
    marginTop: theme.spacing(3),
    fontSize: theme.typography.caption.fontSize,
  },

  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  dndMessage: {
    marginBottom: theme.spacing(1),
  },
  photoInput: {
    zIndex: "-2",
    position: "absolute",
    top: 0,
  },
  setImagesInput: {
    position: "relative",
    minWidth: "150px !important",
  },
  previewContainer: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 400 : 700],
  },
  previewsVertical: {
    textAlign: "center",
  },
}));

const SortableItem = SortableElement(
  ({value, cursor, classes, width = 100, height = 100}) => {
    return (
      <Box
        className={classes.previewContainer}
        display="inline-block"
        margin={1}>
        <div
          key={`p-${value.id}`}
          style={{
            userSelect: "none",
            cursor,
            backgroundImage: `url('${process.env.REACT_APP_IMG_PATH}/c/${
              width * 2
            }x${height * 2}/${value.name}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width,
            height,
          }}></div>
      </Box>
    );
  }
);

const SortableList = SortableContainer(
  ({items, readOnly, itemsWidth, itemsHeight, classes, verticalLayout}) => {
    const canSort = !readOnly && items.length > 1;

    return (
      <div
        id="previews"
        className={verticalLayout ? classes.previewsVertical : ""}>
        {items.map((value, index) => (
          <React.Fragment key={`sort-item-${index}`}>
            {value.name ? (
              <SortableItem
                key={`sort-item-${index}`}
                index={index}
                value={value}
                cursor={!canSort ? "auto" : "grab"}
                disabled={!canSort}
                width={itemsWidth}
                height={itemsHeight}
                classes={classes}
              />
            ) : (
              <Typography key={`item-no-value-${index}`}>No Value</Typography>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const UploadFiles = ({
  label = "Image",
  buttonLabel = "Set Image",
  required = false,
  singleFile = false,
  hidePreviews = false,
  onSave,
  defaultValue,
  readOnly = false,
  itemsWidth = 100,
  itemsHeight = 100,
  idKey = "photoId",
  nameKey = "photoName",
  mimetypeKey = "mimetype",
  video = false,
  enableImages = true,
  verticalLayout = false,
  disableClear = false,
}) => {
  // Format items at the start to use correct name and id keys
  const formatFileArray = array => {
    const updatedSelectedArray = array.map(el => ({
      name: el[nameKey],
      id: el[idKey],
    }));

    return updatedSelectedArray;
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const initialArray = defaultValue
    ? singleFile
      ? formatFileArray([defaultValue])
      : formatFileArray([...defaultValue])
    : [];
  const [
    selectingCurrentlyFilesArray,
    setSelectingCurrentlyFilesArray,
  ] = useState(initialArray);
  const [selectedFilesArray, setSelectedFilesArray] = useState(initialArray);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  // Styles
  const classes = useStyles();

  // hooks
  const prevSelectedFilesArray = usePrevious(selectedFilesArray);

  // Redux
  const dispatch = useDispatch();
  const {error: mediaError} = useSelector(state => state.media);

  useEffect(() => {
    const initialArray = defaultValue
      ? singleFile
        ? formatFileArray([defaultValue])
        : formatFileArray([...defaultValue])
      : [];
    setSelectedFilesArray(initialArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, singleFile]);

  // Save Function
  const formatAndSave = arrayToSave => {
    const formattedArray = arrayToSave.map(({id, mimetype, name}) => ({
      [idKey]: id,
      [nameKey]: name,
      [mimetypeKey]: mimetype,
    }));

    onSave(formattedArray);
  };

  // UI Functions
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onDialogOpen = () => {
    setSelectingCurrentlyFilesArray(selectedFilesArray);
    setOpenDialog(true);
  };

  const onDialogClose = () => {
    setSelectedFilesArray(prevSelectedFilesArray);
    setSelectingCurrentlyFilesArray(prevSelectedFilesArray);
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  // Drag and drop function for gallery order
  const onSortEnd = ({oldIndex, newIndex}) => {
    const newArray = arrayMove(selectedFilesArray, oldIndex, newIndex);
    setSelectedFilesArray(newArray);
    formatAndSave(newArray);
  };

  // Feature Functions
  const onDialogClickSave = () => {
    setOpenDialog(false);
    setSelectedFilesArray(selectingCurrentlyFilesArray);

    formatAndSave(selectingCurrentlyFilesArray);
  };

  const onFileSelect = fileObj => {
    const formattedObj = {
      id: fileObj.id,
      name: fileObj.name,
      mimetype: fileObj.mimetype,
    };

    if (singleFile) {
      setSelectingCurrentlyFilesArray([formattedObj]);
    } else {
      const newArray = (selectingCurrentlyFilesArray.filter(
        el => formattedObj.id === el.id
      ).length &&
        selectingCurrentlyFilesArray.filter(
          el => el.id !== formattedObj.id
        )) || [...selectingCurrentlyFilesArray, formattedObj];

      setSelectingCurrentlyFilesArray(newArray);
    }
  };

  const onUpload = async files => {
    let data = new FormData();

    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      data.append("files", element, element.name.replace(/\s/g, "-"));
    }

    await dispatch(uploadFiles(data));

    // Go To Select Images Tab
    setTabValue(1);
  };

  const onDelete = async photoId => {
    // Logic to show error or success
    const res = await dispatch(deleteFile(photoId));
    if (!res.error) {
      setSnackbarContent("The file was deleted successfully");
      dispatch(fetchFiles());

      // Filter the selected image that was deleted
      const arrFiltered = selectingCurrentlyFilesArray.filter(
        ({id}) => id !== photoId
      );
      setSelectingCurrentlyFilesArray(arrFiltered);
      setSelectedFilesArray(arrFiltered);
      formatAndSave(arrFiltered);
    } else {
      setSnackbarContent(res.errorMessage);
    }
    setOpenSnackbar(true);
  };

  const onEdit = async (fileId, newName) => {
    const fileObj = {
      id: fileId,
      newName,
    };
    // Logic to show error or success
    const res = await dispatch(editFile(fileObj));
    if (!res.error) {
      setSnackbarContent("The file was successfully updated");
    } else {
      setSnackbarContent(
        `There was an error modifying this File. Please try again.`
      );
    }
    dispatch(fetchFiles());
    setOpenSnackbar(true);
  };

  // Clear selected values
  const onClearValues = () => {
    const newArray = [];
    setSelectedFilesArray(newArray);
    formatAndSave(newArray);
  };

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection={verticalLayout ? "column" : "row"}
        alignItems={verticalLayout ? "center" : "baseline"}
        mb={2}>
        <FormControl required={required} className={classes.setImagesInput}>
          <InputLabel shrink htmlFor="media">
            {label}
          </InputLabel>
          {!readOnly && (
            <>
              <Button
                component="span"
                variant="contained"
                className={clsx(
                  classes.formButton,
                  verticalLayout ? classes.marginBottom : classes.marginRight
                )}
                onClick={onDialogOpen}
                disabled={readOnly}>
                {!singleFile && selectedFilesArray.length > 1
                  ? "Change Images"
                  : buttonLabel}
              </Button>
            </>
          )}
          <Input
            id="media"
            className={classes.photoInput}
            value={selectedFilesArray.length > 0 ? 1 : ""}
          />
        </FormControl>

        {selectedFilesArray.length > 0 &&
          !!selectedFilesArray[0].id &&
          !readOnly &&
          !disableClear && (
            <Button size="small" onClick={onClearValues}>
              Clear selected values
            </Button>
          )}
      </Box>

      {!hidePreviews && selectedFilesArray.length > 0 && (
        <>
          {video ? (
            <>
              {selectedFilesArray[0].name ? (
                <div>
                  <video
                    width={itemsWidth}
                    height={"auto"}
                    key={selectedFilesArray[0].name}
                    controls>
                    <source
                      src={`${process.env.REACT_APP_IMG_PATH}/o/${selectedFilesArray[0].name}`}
                      type={selectedFilesArray[0].mimetype}
                    />
                  </video>
                </div>
              ) : (
                <Typography>No Value</Typography>
              )}
            </>
          ) : (
            <>
              {selectedFilesArray.length > 1 && !readOnly && (
                <Typography variant="body2" className={classes.dndMessage}>
                  Drag and drop to set images order
                </Typography>
              )}
              {selectedFilesArray.length > 0 && !!selectedFilesArray[0].name ? (
                <SortableList
                  items={selectedFilesArray}
                  axis="xy"
                  onSortEnd={onSortEnd}
                  readOnly={readOnly}
                  itemsWidth={itemsWidth}
                  itemsHeight={itemsHeight}
                  verticalLayout={verticalLayout}
                  classes={classes}
                />
              ) : (
                <Typography>No Value</Typography>
              )}
            </>
          )}
        </>
      )}
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => onDialogClose()}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onDialogClose()}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Media Tool
            </Typography>
            <Button
              size="large"
              color="inherit"
              disabled={selectingCurrentlyFilesArray.length < 1}
              onClick={() => onDialogClickSave()}>
              Save
            </Button>
          </Toolbar>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="simple tabs example">
            <Tab
              label="Upload Files"
              id="tab-upload"
              aria-controls="tabpanel-upload"
            />
            <Tab
              label="Select Images"
              id="tab-select"
              aria-controls="tabpanel-select"
              disabled={!enableImages}
            />
            <Tab
              label="Select Videos"
              id="tab-select"
              aria-controls="tabpanel-select"
              disabled={!video}
            />
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabPanel} value={tabValue} index={0}>
          <UploadFileTab onUpload={onUpload} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabValue} index={1}>
          <ImagesTab
            selectedFilesArray={selectingCurrentlyFilesArray}
            onFileSelect={onFileSelect}
            onEdit={onEdit}
            onDelete={onDelete}
            isDialog
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabValue} index={2}>
          <VideosTab
            onFileSelect={onFileSelect}
            selectedFilesArray={selectingCurrentlyFilesArray}
            onEdit={onEdit}
            onDelete={onDelete}
            isDialog
          />
        </TabPanel>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={mediaError ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default UploadFiles;
