import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchUser,
  editUser,
  editUserPassword,
  //   deleteUser,
} from "../../redux/features/users/usersSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import UserFormEdit from "./UserFormEdit";
import FullContainerProgress from "../UI/FullContainerProgress";
// import DeleteButton from "../Buttons/DeleteButton";
// import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";

const UsersEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  // const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageUser, isLoading, error} = useSelector(state => state.users);
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {
    write,
    // delete: deletePermission
  } = userPermission.pages;

  const formattedValues = {
    username: currentPageUser.username,
    email: currentPageUser.email,
    password: currentPageUser.password,
    enabled: currentPageUser.enabled,
  };

  let breadcrumbNameMap = {
    "/users": "Users",
  };
  breadcrumbNameMap[`/users/${id}`] = currentPageUser.username;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchUser(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  const onSubmit = async values => {
    const userObj = {
      user_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editUser(userObj));
    if (!res.error) {
      history.push({
        pathname: "/users",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${userObj.username} were saved successfully!`,
          snackbarLink: `/users/${userObj.user_id}`,
        },
      });
    }
  };

  const onSubmitPassword = async values => {
    const userObj = {
      user_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editUserPassword(userObj));
    if (!res.error) {
      history.push({
        pathname: "/users",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${userObj.username} were saved successfully!`,
          snackbarLink: `/users/${userObj.user_id}`,
        },
      });
    }
  };

  //   const onDelete = async () => {
  //     // Logic to show error or success
  //     const res = await dispatch(deleteUser(id));
  //     if (!res.error) {
  //       history.push({
  //         pathname: "/users",
  //         state: {
  //           showSuccess: true,
  //           snackbarContent: `The User was successfully deleted!`,
  //         },
  //       });
  //     }
  //   };

  // Redirect if user doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageUser.name}
                </Typography>
                <UserFormEdit
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onSubmitPassword={onSubmitPassword}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {/* {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete User
            </DeleteButton>
          </Grid>
        )} */}
      </Grid>

      {/*   
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this User?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )} */}
    </>
  );
};

export default UsersEdit;
