import React from "react";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";

// Icons
import tableIcons from "../../Table/TableIcons";

const ProductsTableComponent = ({productsArray, isLoading}) => {
  // Need to spread array so it doesn't fail
  const formatData = productsArray => {
    return productsArray.map(o => ({
      ...o,
    }));
  };

  // Local State
  const formattedData = formatData(productsArray);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MaterialTable
          title="Products"
          isLoading={isLoading}
          icons={tableIcons}
          columns={[
            {
              title: "ID",
              field: "product_id",
              type: "numeric",
              width: 30,
            },
            {
              title: "Name",
              field: "name",
            },
            {
              title: "Price",
              field: "price",
              type: "currency",
              headerStyle: {
                textAlign: "right",
              },
            },
            {
              title: "Tax",
              field: "tax",
              type: "numeric",
              render: rowData => <>{rowData.tax}%</>,
            },
            {
              title: "Quantity",
              field: "quantity",
              type: "numeric",
            },
            {
              title: "Frequency",
              field: "type",
            },
            {
              title: "Applies To",
              field: "age_restriction",
            },
            {
              title: "Product Type",
              field: "product_type",
              render: rowData => (
                <>
                  {rowData.product_type.charAt(0).toUpperCase() +
                    rowData.product_type.slice(1)}
                </>
              ),
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: -1,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductsTableComponent;
