import React from "react";

// Styling
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import {red} from "@material-ui/core/colors";

// Component
import Button from "@material-ui/core/Button";

const errorTheme = createMuiTheme({palette: {primary: {main: red[500]}}});

// TODO: Style with theme variables https://material-ui.com/customization/default-theme/

const DeleteButton = ({onClick, children, variant = "contained", ...rest}) => {
  // Styles
  //   const classes = useStyles();
  return (
    <ThemeProvider theme={errorTheme}>
      <Button {...rest} onClick={onClick} color="primary" variant={variant}>
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default DeleteButton;
