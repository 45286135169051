import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchEvents} from "../../redux/features/events/eventsSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

// Icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import LinkIcon from "@material-ui/icons/Link";

const EventsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {eventsList, isLoading, error} = useSelector(state => state.events);

  // Routing
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/events"
      tableTitle="All Events"
      tableColumns={[
        {
          title: "ID",
          field: "id",
          type: "numeric",
          width: 30,
        },
        {title: "Title", field: "title"},
        {
          title: "Date/Time",
          field: "start",
          type: "datetime",
        },
        {title: "Status", field: "event_status"},
        {
          title: "Featured",
          field: "featured",
          type: "boolean",
        },
      ]}
      customActions={[
        {
          icon: LinkIcon,
          tooltip: "Visit Event",
          onClick: (event, rowData) => {
            // Go to event in the front end website in a new tab
            let a = document.createElement("a");
            a.target = "_blank";
            a.href = `${process.env.REACT_APP_DOMAIN}/${rowData.permalink}`;
            a.click();
          },
        },
        {
          icon: VisibilityIcon,
          tooltip: "View/Edit",
          onClick: (event, rowData) => {
            // Go to Edit page
            history.push(`/events/${rowData.id}`);
          },
        },
      ]}
      addButtonLabel="Event"
      dataList={eventsList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="events"
    />
  );
};

export default EventsPage;
