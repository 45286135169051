import React, {useState, useEffect} from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ButtonsContainer from "./ButtonsContainer";
import MailchimpListsComboBox from "../../FormFields/MailchimpListsComboBox";
import MailchimpSegmentsComboBox from "../../FormFields/MailchimpSegmentsComboBox";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
}));

const APIKeysForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Form Setup
  const defaultValues = {
    google_analytics_id: settingsList.google_analytics_id.value,
    maps_api_key: settingsList.maps_api_key.value,
    reCaptchaSiteKey: settingsList.reCaptchaSiteKey.value,
    stripe_publishable_key: settingsList.stripe_publishable_key.value,
    mailchimp_api_key: settingsList.mailchimp_api_key.value,
    mailchimp: {
      mailchimpListObj: {
        id: settingsList.mailchimp_list_id.value,
        name: settingsList.mailchimp_list_name.value,
      },
      mailchimpSegmentObj: {
        id: settingsList.mailchimp_segment_id.value,
        name: settingsList.mailchimp_segment_name.value,
      },
    },
  };
  const {handleSubmit, control, register} = useForm({
    defaultValues,
  });

  const formSubmit = data => {
    const {
      google_analytics_id,
      maps_api_key,
      reCaptchaSiteKey,
      stripe_publishable_key,
      mailchimp_api_key,
      mailchimp: {mailchimpListObj, mailchimpSegmentObj},
    } = data;

    const settingArray = [
      {...settingsList.google_analytics_id, value: google_analytics_id},
      {...settingsList.maps_api_key, value: maps_api_key},
      {...settingsList.reCaptchaSiteKey, value: reCaptchaSiteKey},
      {...settingsList.stripe_publishable_key, value: stripe_publishable_key},
      {...settingsList.mailchimp_api_key, value: mailchimp_api_key || null},
      {
        ...settingsList.mailchimp_list_id,
        value: String(mailchimpListObj.id) || null,
      },
      {
        ...settingsList.mailchimp_list_name,
        value: mailchimpListObj.name || null,
      },
      {
        ...settingsList.mailchimp_segment_id,
        value: String(mailchimpSegmentObj.id) || null,
      },
      {
        ...settingsList.mailchimp_segment_name,
        value: mailchimpSegmentObj.name || null,
      },
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Google Analytics Id*/}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="google_analytics_id"
            label="Google Analytics ID"
            required
          />
        </Grid>
        {/* Google Maps Key */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="maps_api_key"
            label="Google Maps API Key"
            required
          />
        </Grid>
        {/* reCaptcha */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="reCaptchaSiteKey"
            label="reCaptcha Site Key"
            required
          />
        </Grid>
        {/* Stripe Publishable Key */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="stripe_publishable_key"
            label="Stripe Publishable Key"
            required
          />
        </Grid>

        {/* MailChimp Key */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="mailchimp_api_key"
            label="MailChimp Key"
          />
        </Grid>

        {/* MailChimp List and Segment  */}
        <Grid container item xs={12} spacing={4}>
          <Controller
            name={`mailchimp`}
            control={control}
            defaultValue={defaultValues.mailchimp}
            render={props => (
              <MailChimpFields value={props.value} onChange={props.onChange} />
            )}
          />
        </Grid>

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

const MailChimpFields = ({value, onChange}) => {
  const [mailchimpListObj, setMailChimpListObj] = useState(
    value && value.mailchimpListObj ? value.mailchimpListObj : null
  );
  const [mailchimpSegmentObj, setMailchimpSegmentObj] = useState(
    value && value.mailchimpSegmentObj ? value.mailchimpSegmentObj : null
  );

  useEffect(() => {
    if (mailchimpListObj !== null && mailchimpSegmentObj !== null) {
      onChange({
        mailchimpListObj,
        mailchimpSegmentObj,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailchimpListObj, mailchimpSegmentObj]);

  return (
    <>
      {/* MailChimp List  */}
      <Grid item xs={12} lg={6}>
        <MailchimpListsComboBox
          defaultValue={mailchimpListObj}
          onChange={setMailChimpListObj}
        />
      </Grid>

      {/* MailChimp Segment  */}
      {mailchimpListObj !== null && mailchimpListObj.id && (
        <Grid item xs={12} lg={6}>
          <MailchimpSegmentsComboBox
            list_id={mailchimpListObj.id}
            defaultValue={mailchimpSegmentObj}
            onChange={setMailchimpSegmentObj}
          />
        </Grid>
      )}
    </>
  );
};

export default APIKeysForm;
