import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPackageCategories} from "../../redux/features/packages/categoriesSlice";

// Components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import {VariableSizeList} from "react-window";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 60;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 100 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const CategoriesComboBox = ({
  id,
  fieldLabel,
  multiple = false,
  loading,
  onChange,
  defaultValue,
  readOnly,
}) => {
  // Local state
  const [value, setValue] = useState(defaultValue || null);

  // Redux
  const dispatch = useDispatch();
  const {packageCategoriesList, isLoading, error} = useSelector(
    state => state.packageCategories
  );

  useEffect(() => {
    // Load Categories
    dispatch(fetchPackageCategories());
  }, [dispatch]);

  useEffect(() => {
    if (value) {
      onChange(value);
    } else {
      onChange(null);
    }
  }, [onChange, value]);

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        style={{display: "flex", alignItems: "flex-end", height: "100%"}}
        component="div">
        There was an error loading the Categories Module.
      </Typography>
    );
  }

  if (multiple)
    return (
      <>
        {!loading && packageCategoriesList.length > 0 ? (
          <Autocomplete
            id={id}
            options={packageCategoriesList}
            autoHighlight
            multiple
            disableCloseOnSelect
            loading={loading}
            getOptionLabel={category => category.name}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            disabled={readOnly}
            value={value}
            ListboxComponent={ListboxComponent}
            renderTags={(value, getTagProps) =>
              value.map((category, index) => (
                <Chip label={category.name} {...getTagProps({index})} />
              ))
            }
            renderOption={(category, {selected}) => {
              const labelId = `transfer-list-item-${category.id}-label`;
              return (
                <>
                  <ListItemIcon>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={category.name} />
                </>
              );
            }}
            renderInput={params => (
              <>
                <TextField
                  {...params}
                  label={fieldLabel}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </>
            )}
          />
        ) : (
          <Skeleton
            variant="rect"
            width={"100%"}
            height={48}
            animation="wave"
          />
        )}
      </>
    );
  else
    return (
      <>
        {!isLoading && packageCategoriesList.length > 0 ? (
          <Autocomplete
            id="category-select"
            options={packageCategoriesList}
            autoHighlight
            clearOnEscape
            loading={isLoading}
            getOptionLabel={category => category.name}
            value={
              value &&
              packageCategoriesList.find(category => category.id === value.id)
            }
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onInputChange={(e, value, reason) => {
              if (reason === "clear") {
                setValue(null);
              }
            }}
            disabled={readOnly}
            disableListWrap
            autoComplete
            ListboxComponent={ListboxComponent}
            renderOption={category => (
              <>
                <Typography component="div">
                  <Box>{category.name}</Box>
                  <Box fontWeight="fontWeightLight" fontSize="body2.fontSize">
                    {category.city}, {category.state}
                  </Box>
                </Typography>
              </>
            )}
            renderInput={params => (
              <>
                <TextField
                  {...params}
                  label="Choose a Categories"
                  fullWidth
                  required
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </>
            )}
          />
        ) : (
          <Skeleton
            variant="rect"
            width={"100%"}
            height={48}
            animation="wave"
          />
        )}
      </>
    );
};

export default CategoriesComboBox;
