import React from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    paddingTop: 412,
    marginLeft: -12,
  },
}));

const ButtonsContainer = ({onReset, isLoading, submitButtonLabel}) => {
  // Styles
  const classes = useStyles();
  return (
    <Grid item className={classes.buttonsContainer} xs={12}>
      <div className={classes.wrapper}>
        <Button color="secondary" onClick={onReset}>
          Reset Settings
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}>
          {submitButtonLabel}
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </Grid>
  );
};

export default ButtonsContainer;
