import React from "react";

// Utils
import moment from "moment-timezone";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";

// Icons
import tableIcons from "../../Table/TableIcons";

const RatesTableComponent = ({ratesArray, isLoading}) => {
  // Need to spread array so it doesn't fail
  const formatData = ratesArray => {
    return ratesArray.map(o => ({
      ...o,
    }));
  };

  // Local State
  const formattedData = formatData(ratesArray);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MaterialTable
          title="Rates by Date"
          isLoading={isLoading}
          icons={tableIcons}
          columns={[
            {
              title: "Date",
              field: "date",
              render: rowData => (
                <>{moment(rowData.date).tz("America/Chicago").format("ll")}</>
              ),
            },
            {
              title: "Price",
              field: "price",
              type: "currency",
              headerStyle: {
                textAlign: "right",
              },
            },
            {
              title: "Tax",
              field: "tax",
              type: "numeric",
              headerStyle: {
                textAlign: "right",
              },
              render: rowData => <>{rowData.tax}%</>,
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: -1,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RatesTableComponent;
