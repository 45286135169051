import React from "react";

// Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "./Breadcrumbs";

// Icons
import EditIcon from "@material-ui/icons/Edit";

function BreadcrumbsWithEdit({
  isLoading,
  breadcrumbNameMap,
  isEditMode,
  setEditMode,
  writePermission = false,
}) {
  return (
    <>
      {!isLoading && (
        <Grid container item xs={6} md={9} alignItems="center">
          <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
        </Grid>
      )}

      {!isLoading && !isEditMode && !!writePermission && (
        <Grid container item xs={6} md={3} justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<EditIcon />}
            onClick={() => setEditMode(true)}>
            Edit
          </Button>
        </Grid>
      )}
    </>
  );
}

export default BreadcrumbsWithEdit;
