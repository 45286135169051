import React, {useState, useEffect} from "react";

// Hooks
import usePrevious from "../../../../hooks/usePrevious";

// Utils
import arrayMove from "array-move";
import {randomString} from "../../../../helpers/randomizers";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

// Components
import {sortableContainer, sortableElement} from "react-sortable-hoc";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DragHandle from "../../../UI/DragHandle";

// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(theme => ({
  maxWidth: {width: "100%"},
  deleteButton: {
    color: red[500],
  },
  subItemContainer: {
    border: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const SortableItem = sortableElement(
  ({
    navigationItemObj,
    onChange,
    onDelete,
    enableDropdown = false,
    withDivider = true,
    disableDelete = false,
    routeOptions,
  }) => {
    const classes = useStyles();

    return (
      <Grid container item>
        <Grid container item xs={1} justify="center" alignItems="center">
          <DragHandle />
        </Grid>
        <Grid container item xs={10} spacing={3} alignItems="center">
          <NavigationItemFields
            navigationItemObj={navigationItemObj}
            onChange={newValues => onChange(newValues)}
            enableDropdown={enableDropdown}
            routeOptions={routeOptions}
          />
        </Grid>
        <Grid container item xs={1} justify="center" alignItems="center">
          {!disableDelete && (
            <Tooltip title="Delete Item" placement="top">
              <IconButton
                aria-label="delete"
                onClick={onDelete}
                className={classes.deleteButton}>
                <DeleteIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {withDivider && (
          <Grid item xs={12} style={{marginTop: 50, marginBottom: 20}}>
            <Divider />
          </Grid>
        )}
      </Grid>
    );
  }
);
const SortableSubItem = sortableElement(
  ({subItemObj, onChange, onDelete, disableDelete = false, routeOptions}) => {
    // Styles
    const classes = useStyles();
    return (
      <Grid container item className={classes.subItemContainer}>
        <Grid container item xs={1} justify="center" alignItems="center">
          <DragHandle />
        </Grid>
        <Grid container item xs={10} spacing={4} alignItems="center">
          <DropdownSubItemFields
            subItemObj={subItemObj}
            onChange={newValues => onChange(newValues)}
            routeOptions={routeOptions}
          />
        </Grid>
        <Grid container item xs={1} justify="center" alignItems="center">
          {!disableDelete && (
            <Tooltip title="Delete Logo" placement="top">
              <IconButton aria-label="delete" onClick={onDelete}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  }
);

const SortableContainer = sortableContainer(({children}) => {
  return (
    <Grid container item xs={12}>
      {children}
    </Grid>
  );
});

const NavigationItemFields = ({
  navigationItemObj,
  onChange,
  enableDropdown,
  routeOptions,
}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [label, setLabel] = useState(navigationItemObj.label);
  const [url, setUrl] = useState(navigationItemObj.url);
  const [urlFirstPart, setUrlFirstPart] = useState(
    navigationItemObj.type === "deep" || navigationItemObj.type === "shallow"
      ? navigationItemObj.url.split("/")[1]
      : navigationItemObj.type
  );
  const [urlSecondPart, setUrlSecondPart] = useState(
    navigationItemObj.type === "deep"
      ? navigationItemObj.url.split("/").slice(2).join("/")
      : ""
  );
  const [type, setType] = useState(navigationItemObj.type);
  const [subItems, setSubItems] = useState(navigationItemObj.subItems || []);
  const [key] = useState(navigationItemObj.key);

  // hooks
  const prevType = usePrevious(type);

  useEffect(() => {
    onChange({
      label,
      url,
      type,
      key,
      subItems,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, url, type, subItems]);

  useEffect(() => {
    if (type === "deep" || type === "shallow") {
      if (urlSecondPart !== "") {
        setType("deep");
      } else {
        setType("shallow");
      }
      setUrl(`/${urlFirstPart}${urlSecondPart ? "/" + urlSecondPart : ""}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSecondPart]);

  useEffect(() => {
    if (urlFirstPart === "external") {
      setType("external");
      // Empty Url when changing
      if (
        prevType === "deep" ||
        prevType === "shallow" ||
        prevType === "dropdown"
      ) {
        setUrl("");
        setSubItems([]);
      }
    } else if (urlFirstPart === "dropdown") {
      setType("dropdown");
      if (
        prevType === "deep" ||
        prevType === "shallow" ||
        prevType === "dropdown"
      ) {
        // Reset Sub Items
        setUrl("");
        setSubItems([
          {
            label: "",
            url: "",
            type: "external",
            key: randomString(),
          },
        ]);
      }
    } else {
      if (urlSecondPart !== "") {
        setType("deep");
      } else {
        setType("shallow");
      }
      setUrl(`/${urlFirstPart}${urlSecondPart ? "/" + urlSecondPart : ""}`);
      setSubItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFirstPart]);

  const onSortEnd = ({oldIndex, newIndex}) => {
    setSubItems(arrayMove(subItems, oldIndex, newIndex));
  };

  const handleSubItemChange = (index, newValues) => {
    let newArray = [...subItems];
    newArray[index] = newValues;
    setSubItems(newArray);
  };

  const onDeleteSubItem = index => {
    const filteredArray = subItems.filter((value, idx) => idx !== index);
    setSubItems(filteredArray);
  };

  const newDropdownSubItem = () => {
    const newArray = [
      ...subItems,
      {
        label: "",
        url: "",
        type: "external",
        key: randomString(),
      },
    ];
    setSubItems(newArray);
  };

  return (
    <>
      {/* Label */}
      <Grid item xs={10}>
        <TextField
          className={classes.maxWidth}
          label="Label"
          value={label}
          onChange={e => setLabel(e.target.value)}
          required
        />
      </Grid>

      {/* Url Select */}
      <Grid item xs={10} lg={5}>
        <FormControl className={classes.maxWidth}>
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            Route/Type
          </InputLabel>
          <Select
            value={urlFirstPart}
            onChange={e => setUrlFirstPart(e.target.value)}>
            {routeOptions.map(option => (
              <MenuItem key={option.url} value={option.url.split("/")[1]}>
                {option.url}
              </MenuItem>
            ))}
            <MenuItem key="external" value="external">
              External Link
            </MenuItem>
            {enableDropdown && (
              <MenuItem key="dropdown" value="dropdown">
                Dropdown
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>

      {/* Url */}
      {type === "shallow" || type === "deep" ? (
        <Grid item xs={10} lg={5}>
          <TextField
            className={classes.maxWidth}
            label="Deep Link URL (Optional)"
            value={urlSecondPart}
            onChange={e => setUrlSecondPart(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">/</InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : type === "external" ? (
        <Grid item xs={10} lg={5}>
          <TextField
            className={classes.maxWidth}
            label="External URL"
            type="url"
            value={url}
            required
            onChange={e => setUrl(e.target.value)}
          />
        </Grid>
      ) : type === "dropdown" ? (
        <Grid item xs={10} lg={5}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            color="secondary"
            onClick={newDropdownSubItem}>
            Add Sub Link
          </Button>
        </Grid>
      ) : null}

      {type === "dropdown" && (
        <SortableContainer onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
          {subItems.map((subItemObj, index) => {
            return (
              <SortableSubItem
                index={index}
                key={`subitem-${subItemObj.key}`}
                subItemObj={subItemObj}
                onChange={newValues => handleSubItemChange(index, newValues)}
                onDelete={() => onDeleteSubItem(index)}
                disableDelete={subItems.length <= 1}
                routeOptions={routeOptions}
              />
            );
          })}
        </SortableContainer>
      )}
    </>
  );
};

const DropdownSubItemFields = ({subItemObj, onChange, routeOptions}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [label, setLabel] = useState(subItemObj.label);
  const [url, setUrl] = useState(subItemObj.url);
  const [urlFirstPart, setUrlFirstPart] = useState(
    subItemObj.type === "deep" || subItemObj.type === "shallow"
      ? subItemObj.url.split("/")[1]
      : subItemObj.type
  );
  const [urlSecondPart, setUrlSecondPart] = useState(
    subItemObj.type === "deep"
      ? subItemObj.url.split("/").slice(2).join("/")
      : ""
  );
  const [type, setType] = useState(subItemObj.type);
  const [key] = useState(subItemObj.key);

  // hooks
  const prevType = usePrevious(type);

  useEffect(() => {
    onChange({
      label,
      url,
      type,
      key,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, url, type]);

  useEffect(() => {
    if (type === "deep" || type === "shallow") {
      if (urlSecondPart !== "") {
        setType("deep");
      } else {
        setType("shallow");
      }
      setUrl(`/${urlFirstPart}${urlSecondPart ? "/" + urlSecondPart : ""}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSecondPart]);

  useEffect(() => {
    if (urlFirstPart === "external") {
      setType("external");
      // Empty Url when changing
      if (prevType === "deep" || prevType === "shallow") {
        setUrl("");
      }
    } else {
      if (urlSecondPart !== "") {
        setType("deep");
      } else {
        setType("shallow");
      }
      setUrl(`/${urlFirstPart}${urlSecondPart ? "/" + urlSecondPart : ""}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFirstPart]);

  return (
    <>
      {/* Label */}
      <Grid item xs={12}>
        <TextField
          className={classes.maxWidth}
          label="Label"
          value={label}
          onChange={e => setLabel(e.target.value)}
          required
        />
      </Grid>

      {/* Url Select */}
      <Grid item xs={12} lg={6}>
        <FormControl className={classes.maxWidth}>
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            Route/Type
          </InputLabel>
          <Select
            value={urlFirstPart}
            onChange={e => setUrlFirstPart(e.target.value)}>
            {routeOptions.map(option => (
              <MenuItem key={option.url} value={option.url.split("/")[1]}>
                {option.url}
              </MenuItem>
            ))}
            <MenuItem key="external" value="external">
              External Link
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Url */}
      {type === "shallow" || type === "deep" ? (
        <Grid item xs={12} lg={6}>
          <TextField
            className={classes.maxWidth}
            label="Deep Link URL (Optional)"
            value={urlSecondPart}
            onChange={e => setUrlSecondPart(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">/</InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : type === "external" ? (
        <Grid item xs={12} lg={6}>
          <TextField
            className={classes.maxWidth}
            label="External URL"
            type="url"
            value={url}
            required
            onChange={e => setUrl(e.target.value)}
          />
        </Grid>
      ) : null}
    </>
  );
};

const NavigationFields = ({
  title = "Navigation",
  enableDropdown = false,
  navigationKey = "navigation",
  routeOptions,
  settingsList,
  onChange,
}) => {
  //Styles
  const classes = useStyles();

  // Local state
  const [navigation, setNavigation] = useState(
    settingsList[navigationKey].value.map(el => ({
      ...el,
      key: randomString(),
    }))
  );

  const onSortEnd = ({oldIndex, newIndex}) => {
    setNavigation(arrayMove(navigation, oldIndex, newIndex));
  };

  const handleNavigationItemChange = (index, newValues) => {
    let newArray = [...navigation];
    newArray[index] = newValues;
    setNavigation(newArray);
  };

  const onDeleteNavigationItem = index => {
    const filteredArray = navigation.filter((value, idx) => idx !== index);
    setNavigation(filteredArray);
  };

  const newNavigationItem = () => {
    const newArray = [
      ...navigation,
      {
        label: "",
        url: "",
        type: "external",
        key: randomString(),
        subItems: [],
      },
    ];
    setNavigation(newArray);
  };

  useEffect(() => {
    const navigationValue = JSON.stringify(navigation);
    onChange(navigationValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  return (
    <>
      <Grid item xs={12}>
        <Typography component="p" variant="h5">
          {title}
        </Typography>
        <Typography component="p" variant="body2">
          (Drag to change order they appear in the Menu)
        </Typography>
      </Grid>
      <SortableContainer onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
        {navigation.map((navigationItemObj, index) => {
          return (
            <SortableItem
              index={index}
              key={`item-${navigationItemObj.key}`}
              navigationItemObj={navigationItemObj}
              onChange={newValues =>
                handleNavigationItemChange(index, newValues)
              }
              onDelete={() => onDeleteNavigationItem(index)}
              withDivider={index < navigation.length - 1}
              disableDelete={navigation.length <= 1}
              routeOptions={routeOptions}
              enableDropdown={enableDropdown}
            />
          );
        })}
      </SortableContainer>
      <Grid item xs={12}>
        <Button
          className={classes.maxWidth}
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          onClick={newNavigationItem}>
          Add Link
        </Button>
      </Grid>
    </>
  );
};

export default NavigationFields;
