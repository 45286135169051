import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchEventVenues} from "../../../redux/features/events/venuesSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const EventVenuesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {eventVenuesList, isLoading, error} = useSelector(
    state => state.eventVenues
  );

  useEffect(() => {
    dispatch(fetchEventVenues());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/events/venues"
        tableTitle="All Venues"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {title: "City", field: "city"},
          {title: "State", field: "state"},
        ]}
        addButtonLabel="Event Venue"
        dataList={eventVenuesList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="events"
      />
    </>
  );
};

export default EventVenuesPage;
