import React from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TimezonesComboBox from "../../FormFields/TimezonesComboBox";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import ButtonsContainer from "./ButtonsContainer";

// Icons
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
  iconImg: {
    maxHeight: 30,
    filter: theme.palette.type === "light" ? "invert(10%)" : "invert(100%)",
  },
}));

const GeneralSettingsForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({
    defaultValues: {
      title: settingsList.title.value,
      description: settingsList.description.value,
      name: settingsList.company_info.value.name,
      email: settingsList.company_info.value.email,
      phoneNumber: settingsList.company_info.value.phoneNumber,
      default_locale: settingsList.default_locale.value,
      weather_city: settingsList.weather_city.value,
      phone: settingsList.phone.value,
      active_timezone: settingsList.active_timezone.value,
      // Address
      street1: settingsList.company_info.value.address.street1,
      street2: settingsList.company_info.value.address.street2,
      city: settingsList.company_info.value.address.city,
      state: settingsList.company_info.value.address.state,
      country: settingsList.company_info.value.address.country,
      zip: settingsList.company_info.value.address.zip,

      facebook: settingsList.company_info.value.socialMedia.facebook
        .trim()
        .split("/")[3],
      twitter: settingsList.company_info.value.socialMedia.twitter
        .trim()
        .split("/")[3],
      instagram: settingsList.company_info.value.socialMedia.instagram
        .trim()
        .split("/")[3],
      youtube: settingsList.company_info.value.socialMedia.youtube
        .trim()
        .split("/")[4],
      snapchat: settingsList.company_info.value.socialMedia.snapchat
        .trim()
        .split("/")[4],
      vimeo: settingsList.company_info.value.socialMedia.vimeo
        .trim()
        .split("/")[3],

      application_name: settingsList.application_name.value,

      apple_itunes_app: settingsList.apple_itunes_app.value,
      ios: settingsList.company_info.value.app.ios,
      android: settingsList.company_info.value.app.android,

      android_app: settingsList.android_app.value,
      theme_color: settingsList.theme_color.value,
      tile_color: settingsList.tile_color.value,
    },
  });

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const formSubmit = data => {
    const {
      title,
      description,
      name,
      email,
      phoneNumber,
      default_locale,
      weather_city,
      phone,
      active_timezone,

      // Address,
      street1,
      street2,
      city,
      state,
      country,
      zip,

      // Social Media
      facebook,
      twitter,
      instagram,
      youtube,
      snapchat,
      vimeo,

      // App Values
      application_name,
      apple_itunes_app,
      ios,
      android,
      android_app,

      // Colors
      theme_color,
      tile_color,
    } = data;

    const settingArray = [
      {...settingsList.title, value: title},
      {...settingsList.description, value: description},
      {...settingsList.phone, value: phone},
      {...settingsList.weather_city, value: weather_city},
      {...settingsList.default_locale, value: default_locale},
      {...settingsList.active_timezone, value: active_timezone},
      {
        ...settingsList.company_info,
        value: JSON.stringify({
          ...settingsList.company_info.value,
          name,
          email,
          phoneNumber: formatPhoneNumber(phoneNumber),
          address: {
            street1,
            street2,
            city,
            state,
            country,
            zip,
          },
          socialMedia: {
            twitter: twitter ? "https://twitter.com/" + twitter : null,
            facebook: facebook ? "https://www.facebook.com/" + facebook : null,
            instagram: instagram
              ? "https://www.instagram.com/" + instagram
              : null,
            youtube: youtube ? "https://www.youtube.com/user/" + youtube : null,
            vimeo: vimeo ? "https://vimeo.com/" + vimeo : null,
            snapchat: snapchat
              ? "https://www.snapchat.com/add/" + snapchat
              : null,
          },
          app: {
            ios,
            android,
          },
        }),
      },
      {...settingsList.facebook, value: facebook || null},
      {...settingsList.instagram, value: instagram || null},
      {...settingsList.twitter, value: twitter || null},
      {...settingsList.application_name, value: application_name || ""},
      {
        ...settingsList.apple_itunes_app,
        value: apple_itunes_app.toString() || "",
      },
      {...settingsList.android_app, value: android_app || ""},
      {...settingsList.theme_color, value: theme_color || ""},
      {...settingsList.tile_color, value: tile_color || ""},
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Website Title */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="title"
            label="Website Title"
            required
          />
        </Grid>
        {/* Website Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="description"
            label="Website Description"
            multiline
            required
          />
        </Grid>
        {/* Company Info */}
        <Grid item xs={12} style={{paddingTop: 40, paddingBottom: 0}}>
          <Typography component="p" variant="h6">
            Company Info
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="name"
            label="Name"
            required
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="email"
            label="Email"
            type="email"
            required
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            inputRef={register}
            required
            className={classes.maxWidth}
            label="Company Phone"
            name="phoneNumber"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            inputRef={register}
            required
            className={classes.maxWidth}
            label="Marketing Phone"
            name="phone"
          />
        </Grid>
        {/*  Address */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            required
            name="street1"
            label="Street Address"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            name="street2"
            label="Street Address Secondary"
            fullWidth
          />
        </Grid>
        {/*  City */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="city"
            label="City"
            multiline
            fullWidth
          />
        </Grid>
        {/*  State */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="state"
            label="State"
            fullWidth
          />
        </Grid>
        {/*  Zip */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="zip"
            label="Zip"
            fullWidth
          />
        </Grid>
        {/*  Country */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            required
            name="country"
            label="Country"
            fullWidth
          />
        </Grid>
        {/*Weather  City */}
        <Grid item xs={12} md={4}>
          <TextField
            required
            inputRef={register}
            name="weather_city"
            label="Weather City"
            fullWidth
          />
        </Grid>
        {/* Default Locale */}
        <Grid item xs={12} md={4}>
          <TextField
            required
            inputRef={register}
            name="default_locale"
            label="Default Locale"
            fullWidth
          />
        </Grid>
        {/* Active Timezone */}
        <Grid item xs={12} md={4}>
          <Controller
            name="active_timezone"
            control={control}
            // defaultValue={settingsList.header_script.value}
            render={props => (
              <TimezonesComboBox
                required
                label="Active Timezone"
                value={props.value}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/*************************************** Social Media Accounts *********************************************/}
        <Grid item xs={12} style={{paddingTop: 40, paddingBottom: 0}}>
          <Typography component="p" variant="h6">
            Social Media Accounts
          </Typography>
        </Grid>
        {/* Facebook */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            name="facebook"
            label="Facebook"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Twitter */}
        <Grid item xs={12} md={6}>
          <TextField
            name="twitter"
            label="Twitter"
            inputRef={register}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TwitterIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Instagram */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            name="instagram"
            label="Instagram"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InstagramIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* YouTube */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            name="youtube"
            label="YouTube"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <YouTubeIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Vimeo */}
        <Grid item xs={12} md={6}>
          <TextField
            name="vimeo"
            label="Vimeo"
            inputRef={register}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={`${process.env.REACT_APP_STATIC_PATH}/svg/brands/vimeo-v.svg`}
                    width="20px"
                    className={classes.iconImg}
                    alt="vimeo-logo"
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Snapchat */}
        <Grid item xs={12} md={6}>
          <TextField
            name="snapchat"
            inputRef={register}
            label="Snapchat"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={`${process.env.REACT_APP_STATIC_PATH}/svg/brands/snapchat-ghost.svg`}
                    width="20px"
                    className={classes.iconImg}
                    alt="snapchat-logo"
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/*************************************** Apps *********************************************/}
        <Grid item xs={12} style={{paddingTop: 40, paddingBottom: 0}}>
          <Typography component="p" variant="h6">
            App Values
          </Typography>
        </Grid>
        {/* iOS */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="application_name"
            label="Application Name"
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="apple_itunes_app"
            label="iTunes App ID"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AppleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="ios"
            label="iTunes Link"
            fullWidth
            multiline
            type="url"
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AppleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Android */}
        <Grid item xs={12}>
          <TextField
            name="android_app"
            inputRef={register}
            label="Android App ID"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AndroidIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="android"
            label="Google Play Store Link"
            type="url"
            fullWidth
            multiline
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AndroidIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl className={classes.maxWidth}>
            <label htmlFor="theme-color">Theme Color *</label>
            <input
              ref={register}
              id="theme-color"
              aria-describedby="background-color-picker"
              type="color"
              name="theme_color"
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.maxWidth}>
            <label htmlFor="tile-color">Tile Color *</label>
            <input
              ref={register}
              id="tile-color"
              aria-describedby="background-color-picker"
              type="color"
              name="tile_color"
              required
            />
          </FormControl>
        </Grid>

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default GeneralSettingsForm;
