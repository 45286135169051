import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchCoupons} from "../../redux/features/coupons/couponsSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const CouponsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {couponsList, isLoading, error} = useSelector(state => state.coupons);

  useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/coupons"
      tableTitle="All Coupons"
      tableColumns={[
        {
          title: "ID",
          field: "id",
          type: "numeric",
          width: 30,
        },
        {title: "Name", field: "name"},
        {title: "Code", field: "coupon_code"},
        {
          title: "Discount",
          field: "discount_amount",
          render: rowData => {
            switch (rowData.type) {
              case "currency":
                return <>–${rowData.discount_amount}</>;
              case "percentage":
                return <> {rowData.discount_amount}%</>;
              default:
                return <></>;
            }
          },
        },
        {
          title: "Enabled",
          field: "enabled",
          type: "boolean",
        },
      ]}
      addButtonLabel="Coupon"
      dataList={couponsList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="sales"
    />
  );
};

export default CouponsPage;
