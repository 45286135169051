import {createSlice} from "@reduxjs/toolkit";

import axios from "axios";
import jwtDecode from "jwt-decode";
import {loadState} from "../../../localStorage";

const url = `${process.env.REACT_APP_API_BASE_URL}/v1/auth`;

const fetchError = (state, {payload}) => {
  //  Only update if there is not already an error
  if (state.errorCode !== "") return;
  else if (state.errorCode !== 403) {
    // log Out if there is 403 error
    state.invalidLogin = false;
    state.isLoggedIn = false;
    state.user = "";
    state.user_id = "";
    state.email = "";
    state.userPermission = {};
    state.token = "";
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    invalidLogin: false,
    isLoggedIn: false,
    user: "",
    user_id: "",
    email: "",
    userPermission: {},
    token: loadState("auth.token") || "",
  },
  reducers: {
    setUser(state, {payload}) {
      state.isLoggedIn = payload.isLoggedIn;
      state.invalidLogin = payload.invalidLogin;
      state.user = payload.user;
      state.user_id = payload.user_id;
      state.email = payload.email;
      state.userPermission = payload.userPermission;
      state.token = payload.token;
    },
    authFailure(state, {payload}) {
      state.invalidLogin = false;
      state.isLoggedIn = false;
      state.user = "";
      state.user_id = "";
      state.email = "";
      state.userPermission = {};
      state.token = "";
    },
  },
  extraReducers: {
    /* eslint-disable no-useless-computed-key*/
    // Errors

    //   Addons
    ["addons/getAddonFailure"]: fetchError,
    ["addons/getAddonsFailure"]: fetchError,
    ["addons/getCustomAddonsFailure"]: fetchError,

    // Coupons
    ["coupons/getCouponFailure"]: fetchError,
    ["coupons/getCouponsFailure"]: fetchError,

    // Events
    ["events/getEventFailure"]: fetchError,
    ["events/getEventsFailure"]: fetchError,
    // EventCategories
    ["eventCategories/getEventCategoryFailure"]: fetchError,
    ["eventCategories/getEventCategoriesFailure"]: fetchError,
    // EventOrganizers
    ["eventOrganizers/getEventOrganizerFailure"]: fetchError,
    ["eventOrganizers/getEventOrganizersFailure"]: fetchError,
    // EventSource
    ["eventSources/getEventSourceFailure"]: fetchError,
    ["eventSources/getEventSourcesFailure"]: fetchError,
    // EventVenue
    ["eventVenues/getEventVenueFailure"]: fetchError,
    ["eventVenues/getEventVenuesFailure"]: fetchError,

    // Hotel
    ["hotels/getHotelFailure"]: fetchError,
    ["hotels/getHotelsFailure"]: fetchError,
    // Room
    ["rooms/getRoomFailure"]: fetchError,
    ["rooms/getRoomsFailure"]: fetchError,

    // Icon
    ["icons/getIconFailure"]: fetchError,
    ["icons/getIconsFailure"]: fetchError,

    // Gallery
    ["galleries/getGalleryFailure"]: fetchError,
    ["galleries/getGalleriesFailure"]: fetchError,
    // Media
    ["media/getFileFailure"]: fetchError,
    ["media/getFilesFailure"]: fetchError,

    // PackageCard
    ["packageCards/getPackageCardFailure"]: fetchError,
    ["packageCards/getPackageCardsFailure"]: fetchError,
    // PackageCategory
    ["packageCategories/getPackageCategoryFailure"]: fetchError,
    ["packageCategories/getPackageCategoriesFailure"]: fetchError,
    // CustomPackage
    ["customPackages/getCustomPackageFailure"]: fetchError,
    ["customPackages/getCustomPackagesFailure"]: fetchError,
    // PackageRegular
    ["packageRegulars/getPackageRegularFailure"]: fetchError,
    ["packageRegulars/getPackageRegularsFailure"]: fetchError,

    // Page
    ["pages/getPageFailure"]: fetchError,
    ["pages/getPagesFailure"]: fetchError,

    // Address
    ["addresses/getAddressFailure"]: fetchError,
    ["addresses/getAddressesFailure"]: fetchError,
    // Charge
    ["charges/getChargeFailure"]: fetchError,
    ["charges/getChargesFailure"]: fetchError,
    // Customer
    ["customers/getCustomerFailure"]: fetchError,
    ["customers/getCustomersFailure"]: fetchError,
    // Order
    ["orders/getOrderFailure"]: fetchError,
    ["orders/getOrdersFailure"]: fetchError,
    // PaymentMethod
    ["paymentMethods/getPaymentMethodFailure"]: fetchError,
    ["paymentMethods/getPaymentMethodsFailure"]: fetchError,
    // Rate
    ["rates/getRateFailure"]: fetchError,
    ["rates/getRatesFailure"]: fetchError,
    // Refund
    ["refunds/getRefundFailure"]: fetchError,
    ["refunds/getRefundsFailure"]: fetchError,
    /* eslint-enable no-useless-computed-key */
  },
});

export const {setUser, authFailure} = authSlice.actions;

// Define thunk outside of the slice
// This is the action that would be called in the component
export const logIn = ({email, password}) => {
  return async dispatch => {
    try {
      const res = await axios.post(`${url}/login`, {email, password});

      // Invalid Credentials
      if (res.data.response === "AUTHENTICATION_FAILED") {
        dispatch(
          authFailure({
            errorCode: 200,
            errorMessage: "Invalid Email/Password combination.",
          })
        );
      } else if (res.data.response.token) {
        const token = res.data.response.token;
        const decodedToken = jwtDecode(token);
        dispatch(
          setUser({
            token,
            user: decodedToken.user,
            user_id: decodedToken.user_id,
            email: decodedToken.email,
            userPermission: decodedToken.userPermission,
            isLoggedIn: true,
            invalidLogin: false,
          })
        );
      } else {
        throw new Error(res);
      }
    } catch (err) {
      dispatch(
        authFailure({
          errorCode: err.response?.status || 500,
          errorMessage: err.toString(),
        })
      );
    }
  };
};

export const logOut = token => {
  // Take the token and make a request to api to see if it's still valid
  return async dispatch => {
    const config = {
      headers: {Authorization: `Bearer ${token}`},
    };

    try {
      const res = await axios.post(`${url}/logout`, {}, config);
      if (res.data.response.success) {
        dispatch(
          setUser({
            token: "",
            user: "",
            user_id: "",
            email: "",
            userPermission: {},
            invalidLogin: false,
            isLoggedIn: false,
          })
        );
      } else {
        throw new Error("User not verified");
      }
    } catch (error) {
      dispatch(
        setUser({
          token: "",
          user: "",
          user_id: "",
          email: "",
          userPermission: {},
          invalidLogin: false,
          isLoggedIn: false,
        })
      );
    }
  };
};

// This is the action  that deletes the token if there is an error
export const verifyUser = token => {
  // Take the token and make a request to api to see if it's still valid
  return async dispatch => {
    const config = {
      headers: {Authorization: `Bearer ${token}`},
    };

    try {
      const res = await axios.post(
        `${url}/user-verification-to-see-if-user-is-verified-banana`,
        {},
        config
      );
      const decodedToken = jwtDecode(token);
      if (res.data.response.success) {
        if (process.env.NODE_ENV === "development") console.log(decodedToken);
        dispatch(
          setUser({
            token,
            user: decodedToken.user,
            user_id: decodedToken.user_id,
            email: decodedToken.email,
            userPermission: decodedToken.userPermission,
            invalidLogin: false,
            isLoggedIn: true,
          })
        );
      } else {
        throw new Error(res);
      }
    } catch (err) {
      if (err.response?.data) {
        // This means that the user just logged out
        if (err.response.data.content.message !== "TOKEN_MALFORMED") {
          dispatch(
            authFailure({
              errorCode: err.response?.status,
              errorMessage:
                err.response.data.content.message === "SESSION_EXPIRED"
                  ? "Session Expired"
                  : err.response.data.content.message,
            })
          );
          if (process.env.NODE_ENV === "development") {
            console.log("LOGGING USER OUT!!!!");
            console.log(err.response);
          }
        }
      } else if (process.env.NODE_ENV !== "development") {
        dispatch(
          authFailure({
            errorCode: err.response?.status,
            errorMessage: "Unknown Error",
          })
        );
      }
    }
  };
};

export default authSlice.reducer;
