import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchMeeting,
  editMeeting,
  deleteMeeting,
} from "../../redux/features/meetings/meetingsSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import MeetingsForm from "./MeetingsForm";
import FullContainerProgress from "../UI/FullContainerProgress";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";

const MeetingsEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageMeeting, isLoading, error} = useSelector(
    state => state.meetings
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.sales;

  const formattedValues = {
    // Meeting Info
    title: currentPageMeeting.title,
    permalink: currentPageMeeting.permalink,
    emails: currentPageMeeting.emails,
    phone_number: currentPageMeeting.phone_number,
    hotel_id: currentPageMeeting.hotel_id,
    package_id: currentPageMeeting.package_id,
    pkg: {
      id: currentPageMeeting.package_id,
    },
    hotel: {id: currentPageMeeting.hotel_id},
    team: currentPageMeeting.team,
    amenities: currentPageMeeting.amenities,
    meeting_types: currentPageMeeting.meeting_types,
    body: currentPageMeeting.body,
    short_description: currentPageMeeting.short_description,
    featured: currentPageMeeting.featured,
    //   Images
    // This is used to display the selected picture in the edit form
    photoObj: {
      photoId: currentPageMeeting.picture_id,
      photoName: currentPageMeeting.photoName,
    },
    gallery_id: currentPageMeeting.gallery_id,
    gallery: {id: currentPageMeeting.gallery_id},
    videoObj: {
      photoId: currentPageMeeting.video_id,
      photoName: currentPageMeeting.videoName,
    },
    meetingByPhotoObj: {
      photoId: currentPageMeeting.meeting_by,
      photoName: currentPageMeeting.meetingPhotoName,
    },
  };

  let breadcrumbNameMap = {
    "/meetings": "Meetings",
  };
  breadcrumbNameMap[`/meetings/${id}`] = currentPageMeeting.title;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchMeeting(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const meetingObj = {
      meeting_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editMeeting(meetingObj));
    if (!res.error) {
      history.push({
        pathname: "/meetings",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${meetingObj.title} were saved successfully!`,
          snackbarLink: `/meetings/${meetingObj.meeting_id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteMeeting(id));
    if (!res.error) {
      history.push({
        pathname: "/meetings",
        state: {
          showSuccess: true,
          snackbarContent: `The Meeting was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if meeting doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageMeeting.name}
                </Typography>
                <MeetingsForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Meeting
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Meeting?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default MeetingsEdit;
