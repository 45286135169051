import React, {useState} from "react";

// Redux
import {useSelector} from "react-redux";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {injectStripe} from "react-stripe-elements";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FullContainerProgress from "../../../UI/FullContainerProgress";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import AddressComboBox from "../../../FormFields/CustomerAddressComboBox";
import StripeElementWrapper from "../../../FormFields/Stripe/StripeElementWrapper";

//Styling
const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  dialogContent: {overflow: "visible", paddingBottom: theme.spacing(5)},
}));

const CreatePaymentMethodDialog = ({stripe, open, onClose, onSubmit}) => {
  //Styles
  const classes = useStyles();
  // Redux
  const {
    isLoading: isLoadingPaymentMethods,
    error: paymentMethodError,
  } = useSelector(state => state.paymentMethods);
  const {currentPageCustomer} = useSelector(state => state.customers);
  const {customer_id, first_name, last_name} = currentPageCustomer;

  // Local State
  const [address, setAddress] = useState(null);
  const [stripeValidationError, setStripeValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "There was an error. Please try again."
  );

  const formSubmit = async () => {
    const {line_1, line_2, city, state, zip, country} = address;
    const clientData = {
      name: first_name + " " + last_name,
      address_line1: line_1,
      address_line2: line_2,
      address_city: city,
      address_state: state,
      address_zip: zip,
      address_country: country,
    };

    const stripeRes = await stripe.createToken(clientData);
    let stripe_token = "";
    let stripeValidationSuccess = false;
    if (stripeRes.error) {
      stripeValidationSuccess = false;
    } else if (stripeRes.token) {
      stripe_token = stripeRes.token.id;

      stripeValidationSuccess = true;
    }

    // Make the submit if stripe was validated
    if (stripeValidationSuccess) {
      onSubmit({stripe_token});
    } else {
      // if not show the user on the alert
      const {message} = stripeRes.error;
      setStripeValidationError(true);
      setErrorMessage(message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-payment-method-view"
      aria-describedby="alert-payment-method-view"
      scroll="body">
      {isLoadingPaymentMethods ? (
        <>
          <DialogContent>
            <Box width={700} height={500}>
              <FullContainerProgress />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-payment-method-view">
            Create Payment Method
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <form className={classes.form}>
              <Grid container spacing={4}>
                {(paymentMethodError || stripeValidationError) && (
                  <Grid item xs={12}>
                    <MuiAlert className={classes.alert} severity="error">
                      {errorMessage}
                    </MuiAlert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="body1" component="p">
                    Customer ID: {customer_id}
                  </Typography>
                </Grid>

                {/* Customer Card info */}
                <Grid item xs={12}>
                  <StripeElementWrapper
                    label="Card Number"
                    component={CardNumberElement}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StripeElementWrapper
                    label="Expires"
                    component={CardExpiryElement}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StripeElementWrapper
                    label="CVC"
                    component={CardCVCElement}
                  />
                </Grid>

                {/* Customer Address linked to payment method */}
                <Grid item xs={12}>
                  <AddressComboBox
                    customerId={customer_id}
                    onChange={value => setAddress(value)}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={address === null}
              onClick={() => {
                formSubmit();
              }}>
              Create Payment Method
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default injectStripe(CreatePaymentMethodDialog);
