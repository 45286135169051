import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/customers";

const addressesInitialState = {
  customerId: null,
  addressesList: [],
  currentPageAddress: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const addresses = createSlice({
  name: "addresses",
  initialState: addressesInitialState,
  reducers: {
    setCustomerId(state, {payload}) {
      state.customerId = payload;
    },
    getAddressStart: startLoading,
    getAddressesStart: startLoading,
    createAddressStart: startLoading,
    editAddressStart: startLoading,
    deleteAddressStart: startLoading,
    getAddressSuccess(state, {payload}) {
      state.currentPageAddress = payload;
      state.isLoading = false;
      state.error = null;
    },
    getAddressesSuccess(state, {payload}) {
      state.addressesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createAddressSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editAddressSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteAddressSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getAddressFailure: loadingFailed,
    getAddressesFailure: loadingFailed,
    createAddressFailure: loadingFailed,
    editAddressFailure: loadingFailed,
    deleteAddressFailure: loadingFailed,
  },
});

export const {
  setCustomerId,
  getAddressStart,
  getAddressesStart,
  createAddressStart,
  editAddressStart,
  deleteAddressStart,
  getAddressSuccess,
  getAddressesSuccess,
  createAddressSuccess,
  editAddressSuccess,
  deleteAddressSuccess,
  getAddressFailure,
  getAddressesFailure,
  createAddressFailure,
  editAddressFailure,
  deleteAddressFailure,
} = addresses.actions;

export default addresses.reducer;

export const fetchAddresses = customerId => async (dispatch, getState) => {
  try {
    dispatch(getAddressesStart());
    const res = await axios.get(`${url}/${customerId}/addresses`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setCustomerId(customerId));
    dispatch(getAddressesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getAddressesFailure(errorObj));
  }
};

export const fetchAddress = (customerId, addressId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(getAddressStart());
    const res = await axios.get(`${url}/${customerId}/addresses/${addressId}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setCustomerId(customerId));
    dispatch(getAddressSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getAddressFailure(errorObj));
  }
};

export const createAddress = (customerId, addressObj) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createAddressStart());
    const res = await axios.post(`${url}/${customerId}/addresses`, addressObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createAddressSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createAddressFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editAddress = (customerId, addressObj) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(editAddressStart());
    const res = await axios.put(`${url}/${customerId}/addresses`, addressObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editAddressSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editAddressFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteAddress = (customerId, addressId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(deleteAddressStart());
    const res = await axios.delete(`${url}/${customerId}/addresses`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {address_id: addressId},
    });
    dispatch(deleteAddressSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteAddressFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
