import React from "react";

// Routing
import {useHistory} from "react-router-dom";

// Utils
import moment from "moment-timezone";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";

// Icons
import tableIcons from "../../Table/TableIcons";
import VisibilityIcon from "@material-ui/icons/Visibility";

const TransactionsTableComponent = ({transactionArray, isLoading}) => {
  // Routing
  const history = useHistory();

  // Need to spread array so it doesn't fail
  const formatData = transactionArray => {
    return transactionArray.map(o => ({
      ...o,
    }));
  };

  // Local State
  const formattedData = formatData(transactionArray);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MaterialTable
          title="Transactions"
          isLoading={isLoading}
          icons={tableIcons}
          columns={[
            {
              title: "ID",
              field: "transaction_id",
              type: "numeric",
              width: 30,
            },
            {
              title: "Transaction Date",
              field: "created",
              render: rowData => (
                <>
                  {moment
                    .unix(rowData.created)
                    .tz("America/Chicago")
                    .format("lll")}
                </>
              ),
            },
            {
              title: "Amount",
              field: "amount",
              type: "currency",
              headerStyle: {
                textAlign: "right",
              },
            },
            {
              title: "Amount Refunded",
              field: "amount_refunded",
              type: "currency",
              headerStyle: {
                textAlign: "right",
              },
            },
            {
              title: "Fully Refunded",
              field: "refunded",
              type: "boolean",
            },
            {
              title: "Status",
              field: "status",
              render: rowData => (
                <>
                  {rowData.status.charAt(0).toUpperCase() +
                    rowData.status.slice(1)}
                </>
              ),
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: VisibilityIcon,
              tooltip: "View",
              onClick: (event, rowData) => {
                // Go to Edit page
                history.replace(`/sales/charges/${rowData.transaction_id}`);
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default TransactionsTableComponent;
