import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchRooms,
  fetchRoom,
  editRoom,
  deleteRoom,
  syncRooms,
} from "../../../redux/features/hotels/roomsSlice";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import RoomViewDialog from "./RoomViewDialog";
import DeleteDialog from "../../UI/DeleteDialog";

// Icons
import tableIcons from "../../Table/TableIcons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import SyncIcon from "@material-ui/icons/Sync";

const RoomsTableComponent = ({hotelId, writePermission, deletePermission}) => {
  // Local State
  const [formattedData, setFormattedData] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {roomsList, isLoading} = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(fetchRooms(hotelId));
  }, [dispatch, hotelId]);

  useEffect(() => {
    // Need to spread array so it doesn't fail
    setFormattedData(formatData(roomsList));
  }, [roomsList]);

  // Need to spread array so it doesn't fail
  const formatData = roomsList => {
    return roomsList.map(o => ({
      ...o,
    }));
  };

  const handleAlertClose = () => {
    setOpenSnackbar(false);
  };

  const onSyncRooms = async () => {
    const res = await dispatch(syncRooms(hotelId));

    // If Rooms were sync show the message and fetch
    if (!res.error) {
      setOpenSnackbar(true);
      setSnackbarContent(`Rooms have been synced!`);
      dispatch(fetchRooms(hotelId));
    }
  };

  const onDeleteSubmit = async () => {
    const res = await dispatch(deleteRoom(hotelId, selectedRoomId));

    // If Room was deleted successfully close dialog and show user
    if (!res.error) {
      setDeleteDialogOpen(false);
      setOpenSnackbar(true);
      setSnackbarContent(`The Room was successfully deleted!`);
      dispatch(fetchRooms(hotelId));
    }
  };

  const onEditSubmit = async values => {
    const res = await dispatch(editRoom(hotelId, values));

    // If Room was edited successfully close dialog and show user
    if (!res.error) {
      setDialogOpen(false);
      setOpenSnackbar(true);
      setSnackbarContent(`The Room was successfully edited!`);
      dispatch(fetchRooms(hotelId));
    }
  };

  return (
    //  {/* Room Information */}
    <>
      <Grid container item xs={12} spacing={2}>
        {!!writePermission && (
          <Grid container item xs={12} justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              startIcon={<SyncIcon />}
              onClick={onSyncRooms}>
              Sync Rooms
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <MaterialTable
            title="Room List"
            isLoading={isLoading}
            icons={tableIcons}
            columns={[
              {
                title: "ID",
                field: "id",
                type: "numeric",
                width: 30,
              },
              {
                title: "Name",
                field: "name",
              },

              {
                title: "Occupancy",
                field: "occupancy",
                type: "numeric",
              },
            ]}
            actions={[
              {
                icon: VisibilityIcon,
                tooltip: !!writePermission ? "View/Edit" : "View",
                onClick: (event, rowData) => {
                  setSelectedRoomId(rowData.id);
                  dispatch(fetchRoom(hotelId, rowData.id));
                  setDialogOpen(true);
                },
              },

              !!deletePermission
                ? {
                    icon: DeleteIcon,
                    tooltip: "Delete",
                    onClick: (event, rowData) => {
                      setSelectedRoomId(rowData.id);
                      setDeleteDialogOpen(true);
                    },
                  }
                : null,
            ]}
            data={formattedData}
            options={{
              search: true,
              actionsColumnIndex: -1,
            }}
          />
        </Grid>
      </Grid>
      {selectedRoomId && (
        <RoomViewDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          hotelId={hotelId}
          roomId={selectedRoomId}
          onSubmit={onEditSubmit}
          // If user has write permission then is not readonly
          readOnly={!!!writePermission}
        />
      )}
      {selectedRoomId && !!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this room?"
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onSubmit={onDeleteSubmit}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAlertClose}
          severity={"success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default RoomsTableComponent;
