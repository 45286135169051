import React from "react";
// utils
import moment from "moment";
import preval from "preval.macro";

// Components
import packageJson from "../../../package.json";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

const Copyright = () => {
  const getDateString = () => {
    const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
    const formattedDate = lastUpdateMoment.format("DD.MM.YYYY HH:mm:ss");

    return formattedDate;
  };
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://vigtek.co/">
          Vigtek
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        Version: {packageJson.version}
        {" ("}
        {getDateString()}
        {")"}
      </Typography>
      {process.env.NODE_ENV === "development" && (
        <Typography variant="subtitle2" color="textSecondary" align="center">
          You are connected to {process.env.REACT_APP_API_BASE_URL}
        </Typography>
      )}
    </>
  );
};

export default Copyright;
