import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/meetings";

const meetingsInitialState = {
  meetingsList: [],
  currentPageMeeting: {},
  isLoading: false,
  error: null,
  myvrMap: [],
  isLoadingMyVRMap: false,
  errorMyVRMap: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}
function startLoadingMyVRMap(state) {
  state.isLoadingMyVRMap = true;
}
function loadingFailedMyVRMap(state, {payload}) {
  state.isLoadingMyVRMap = false;
  state.errorMyVRMap = payload;
}

const meetings = createSlice({
  name: "meetings",
  initialState: meetingsInitialState,
  reducers: {
    getMeetingStart: startLoading,
    getMeetingsStart: startLoading,
    getMyVRMapStart: startLoadingMyVRMap,
    createMeetingStart: startLoading,
    editMeetingStart: startLoading,
    deleteMeetingStart: startLoading,
    getMeetingSuccess(state, {payload}) {
      state.currentPageMeeting = payload;
      state.isLoading = false;
      state.error = null;
    },
    getMeetingsSuccess(state, {payload}) {
      state.meetingsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    getMyVRMapSuccess(state, {payload}) {
      state.myvrMap = payload;
      state.isLoadingMyVRMap = false;
      state.errorMyVRMap = null;
    },
    createMeetingSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editMeetingSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteMeetingSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getMeetingFailure: loadingFailed,
    getMeetingsFailure: loadingFailed,
    getMyVRMapFailure: loadingFailedMyVRMap,
    createMeetingFailure: loadingFailed,
    editMeetingFailure: loadingFailed,
    deleteMeetingFailure: loadingFailed,
  },
});

export const {
  getMeetingStart,
  getMeetingsStart,
  createMeetingStart,
  editMeetingStart,
  deleteMeetingStart,
  getMeetingSuccess,
  getMeetingsSuccess,
  createMeetingSuccess,
  editMeetingSuccess,
  deleteMeetingSuccess,
  getMeetingFailure,
  getMeetingsFailure,
  createMeetingFailure,
  editMeetingFailure,
  deleteMeetingFailure,
  getMyVRMapStart,
  getMyVRMapSuccess,
  getMyVRMapFailure,
} = meetings.actions;

export default meetings.reducer;

export const fetchMeetings = () => async (dispatch, getState) => {
  try {
    dispatch(getMeetingsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getMeetingsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getMeetingsFailure(errorObj));
  }
};

export const fetchMyVRMap = () => async (dispatch, getState) => {
  try {
    dispatch(getMyVRMapStart());
    const res = await axios.get(`${url}/myvr-map`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getMyVRMapSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getMyVRMapFailure(errorObj));
  }
};

export const fetchMeeting = id => async (dispatch, getState) => {
  try {
    dispatch(getMeetingStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getMeetingSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getMeetingFailure(errorObj));
  }
};

export const createMeeting = meetingObj => async (dispatch, getState) => {
  try {
    dispatch(createMeetingStart());
    const res = await axios.post(url, meetingObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createMeetingSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createMeetingFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editMeeting = meetingObj => async (dispatch, getState) => {
  try {
    dispatch(editMeetingStart());
    const res = await axios.put(url, meetingObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editMeetingSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editMeetingFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteMeeting = meetingId => async (dispatch, getState) => {
  try {
    dispatch(deleteMeetingStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: meetingId},
    });
    dispatch(deleteMeetingSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteMeetingFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
