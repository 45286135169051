import React, {useEffect, useState} from "react";

// Redux
import {useSelector} from "react-redux";

// Routing
import {NavLink} from "react-router-dom";

// Styles
import {makeStyles} from "@material-ui/core/styles";

// Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import HotelIcon from "@material-ui/icons/Hotel";
import EventIcon from "@material-ui/icons/Event";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TableChartIcon from "@material-ui/icons/TableChart";
import GroupIcon from "@material-ui/icons/Group";
import HouseIcon from "@material-ui/icons/House";
import MailIcon from "@material-ui/icons/Mail";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(8),
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  customScroll: {
    overflow: "hidden",
    overflowY: "auto",
    height: "100%",
    maxHeight: "80vh",
    padding: "0",
    "&::-webkit-scrollbar-thumb": {
      minHeight: "200px",
      border: "6px solid transparent",
      background:
        theme.palette.type === "dark"
          ? theme.palette.grey[700]
          : theme.palette.grey[300],
      borderRadius: "10px",
      backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar": {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: "20px",
    },
  },
}));

const MainListItems = ({open, onSubMenuOpen}) => {
  // Styles
  const classes = useStyles();

  //Local State
  const [packageMenuOpen, setPackageMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [ratesMenuOpen, setRateMenuOpen] = useState(false);
  const [salesMenuOpen, setSalesMenuOpen] = useState(false);
  const [mediaMenuOpen, setMediaMenuOpen] = useState(false);

  // User Permission from redux
  const {userPermission} = useSelector(state => {
    return state.auth;
  });

  const handlePackageClick = () => {
    setPackageMenuOpen(!packageMenuOpen);
    onSubMenuOpen();
  };
  const handleEventClick = () => {
    setEventMenuOpen(!eventMenuOpen);
    onSubMenuOpen();
  };
  const handleRateClick = () => {
    setRateMenuOpen(!ratesMenuOpen);
    onSubMenuOpen();
  };
  const handleSalesClick = () => {
    setSalesMenuOpen(!salesMenuOpen);
    onSubMenuOpen();
  };
  const handleMediaClick = () => {
    setMediaMenuOpen(!mediaMenuOpen);
    onSubMenuOpen();
  };

  useEffect(() => {
    // Close menus when drawer is closed
    if (!open) {
      setPackageMenuOpen(false);
      setEventMenuOpen(false);
      setSalesMenuOpen(false);
      setRateMenuOpen(false);
      setMediaMenuOpen(false);
    }
  }, [open]);

  return (
    <List component="nav" className={classes.customScroll}>
      {!!userPermission.dashboard.read && (
        <Tooltip
          title="Dashboard"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/">
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Tooltip>
      )}

      {/* Packages */}
      {!!userPermission.packages.read && (
        <>
          {open ? (
            <ListItem button onClick={handlePackageClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <BeachAccessIcon />
              </ListItemIcon>
              <ListItemText primary="Packages" />
              {packageMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            <Tooltip title="Packages" placement="right" arrow>
              <ListItem button onClick={handlePackageClick}>
                <ListItemIcon className={classes.listItemIcon}>
                  <BeachAccessIcon />
                </ListItemIcon>
                <ListItemText primary="Packages" />
                {packageMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>
          )}

          <Collapse in={packageMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Regular Package */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/packages/regular">
                <ListItemText primary="Regular" />
              </ListItem>
              {/* Custom Packages */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/packages/customs">
                <ListItemText primary="Custom" />
              </ListItem>

              {/* Package Card */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/packages/cards">
                <ListItemText primary="Cards" />
              </ListItem>
              {/* Package Categories */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/packages/categories">
                <ListItemText primary="Categories" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      {/* Hotels */}
      {!!userPermission.hotels.read && (
        <Tooltip
          title="Hotels"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/hotels">
            <ListItemIcon className={classes.listItemIcon}>
              <HotelIcon />
            </ListItemIcon>
            <ListItemText primary="Hotels" />
          </ListItem>
        </Tooltip>
      )}

      {/* Condos */}
      {!!userPermission.condos.read && (
        <Tooltip
          title="Condos"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/condos">
            <ListItemIcon className={classes.listItemIcon}>
              <HouseIcon />
            </ListItemIcon>
            <ListItemText primary="Condos" />
          </ListItem>
        </Tooltip>
      )}

      {/* Addons */}
      {!!userPermission.addons.read && (
        <Tooltip
          title="Addons"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/addons">
            <ListItemIcon className={classes.listItemIcon}>
              <RestaurantIcon />
            </ListItemIcon>
            <ListItemText primary="Addons" />
          </ListItem>
        </Tooltip>
      )}

      {/* Sales */}
      {!!userPermission.sales.read && (
        <>
          {open ? (
            <ListItem button onClick={handleSalesClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Sales" />
              {salesMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            <Tooltip title="Sales" placement="right" arrow>
              <ListItem button onClick={handleSalesClick}>
                <ListItemIcon className={classes.listItemIcon}>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Sales" />
                {salesMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>
          )}
          <Collapse in={salesMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Orders */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/sales/orders">
                <ListItemText primary="Orders" />
              </ListItem>
              {/* Charges */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/sales/charges">
                <ListItemText primary="Charges" />
              </ListItem>
              {/* Customers */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/sales/customers">
                <ListItemText primary="Customers" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      {/* Events */}
      {!!userPermission.events.read && (
        <>
          {open ? (
            <ListItem button onClick={handleEventClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Events" />
              {eventMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            <Tooltip title="Events" placement="right" arrow>
              <ListItem button onClick={handleEventClick}>
                <ListItemIcon className={classes.listItemIcon}>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="Events" />
                {eventMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>
          )}

          <Collapse in={eventMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Events */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/events">
                <ListItemText primary="All Events" />
              </ListItem>
              {/* Meetings */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/meetings">
                <ListItemText primary="Meetings" />
              </ListItem>
              {/* Event Categories */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/events/categories">
                <ListItemText primary="Categories" />
              </ListItem>
              {/* Event Organizers */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/events/organizers">
                <ListItemText primary="Organizers" />
              </ListItem>
              {/* Event Venues */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/events/venues">
                <ListItemText primary="Venues" />
              </ListItem>
              {/* Event Venues */}
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/events/sources">
                <ListItemText primary="Sources" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      {/* Pages */}
      {!!userPermission.pages.read && (
        <Tooltip
          title="Pages"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/pages">
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText primary="Pages" />
          </ListItem>
        </Tooltip>
      )}

      {/* Media */}
      {!!userPermission.media.read && (
        <>
          {open ? (
            <ListItem button onClick={handleMediaClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Media" />
              {mediaMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            <Tooltip title="Media" placement="right" arrow>
              <ListItem button onClick={handleMediaClick}>
                <ListItemIcon className={classes.listItemIcon}>
                  <PhotoLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Media" />
                {mediaMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>
          )}
          <Collapse in={mediaMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/media/media-page">
                <ListItemText primary="Media Page" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/media/galleries">
                <ListItemText primary="Galleries" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      {/* Coupons */}
      {!!userPermission.sales.read && (
        <Tooltip
          title="Coupons"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/coupons">
            <ListItemIcon className={classes.listItemIcon}>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary="Coupons" />
          </ListItem>
        </Tooltip>
      )}

      {/* Rates */}
      {!!userPermission.sales.read && (
        <>
          {open ? (
            <ListItem button onClick={handleRateClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <TableChartIcon />
              </ListItemIcon>
              <ListItemText primary="Rates" />
              {ratesMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            <Tooltip title="Rates" placement="right" arrow>
              <ListItem button onClick={handleRateClick}>
                <ListItemIcon className={classes.listItemIcon}>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Rates" />
                {ratesMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>
          )}
          <Collapse in={ratesMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/rates">
                <ListItemText primary="Change Rates" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                className={classes.nested}
                to="/rateshopper">
                <ListItemText primary="Rateshopper" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      {/* Users */}
      {!!userPermission.users.read && (
        <Tooltip
          title="Users"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/users">
            <ListItemIcon className={classes.listItemIcon}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </Tooltip>
      )}

      {/* Contacts */}
      {!!userPermission.sales.read && (
        <Tooltip
          title="Contacts"
          placement="right"
          arrow
          disableFocusListener={open}
          disableHoverListener={open}
          disableTouchListener={open}>
          <ListItem button component={NavLink} to="/contacts">
            <ListItemIcon className={classes.listItemIcon}>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItem>
        </Tooltip>
      )}
    </List>
  );
};

export default MainListItems;
