import React, {useState} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  uploadFiles,
  fetchFiles,
  deleteFile,
  editFile,
} from "../../redux/features/media/mediaSlice";

// Components
import Paper from "../UI/MainPaper";
import Breadcrumbs from "../Navigation/Breadcrumbs";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {UploadFileTab, ImagesTab, VideosTab} from "./MediaElements/TabPanels";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Paper>{children}</Paper>}
    </Typography>
  );
}

const MediaPage = () => {
  // Local State
  const [tabValue, setTabValue] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [
    selectingCurrentlyFilesArray,
    setSelectingCurrentlyFilesArray,
  ] = useState([]);

  // Redux
  const dispatch = useDispatch();

  // Redux
  const {error: mediaError} = useSelector(state => state.media);

  let breadcrumbNameMap = {
    "/media": "Media",
    "/media/media-page": "Media Page",
  };

  // UI Functions
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  //   File Functions
  const onUpload = async files => {
    let data = new FormData();

    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      data.append("files", element, element.name.replace(/\s/g, "-"));
    }

    await dispatch(uploadFiles(data));

    // Go To Select Images Tab
    setTabValue(1);
  };

  const onDelete = async photoId => {
    // Logic to show error or success
    const res = await dispatch(deleteFile(photoId));
    if (!res.error) {
      setSnackbarContent("The file was deleted successfully");
      setSelectingCurrentlyFilesArray([]);
      dispatch(fetchFiles());
    } else {
      setSnackbarContent(res.errorMessage);
    }
    setOpenSnackbar(true);
  };

  const onEdit = async (fileId, newName) => {
    const fileObj = {
      id: fileId,
      newName,
    };
    // Logic to show error or success
    const res = await dispatch(editFile(fileObj));
    if (!res.error) {
      setSnackbarContent("The file was successfully updated");
    } else {
      setSnackbarContent(
        `There was an error modifying this File. Please try again.`
      );
    }
    dispatch(fetchFiles());
    setOpenSnackbar(true);
  };

  const onFileSelect = fileObj => {
    const formattedObj = {
      id: fileObj.id,
      name: fileObj.name,
      mimetype: fileObj.mimetype,
    };

    setSelectingCurrentlyFilesArray([formattedObj]);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
        </Grid>
        <Grid item xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="simple tabs example">
              <Tab
                label="Upload Files"
                id="tab-upload"
                aria-controls="tabpanel-upload"
              />
              <Tab
                label="Images"
                id="tab-select"
                aria-controls="tabpanel-select"
              />
              <Tab
                label="Videos"
                id="tab-select"
                aria-controls="tabpanel-select"
              />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <UploadFileTab onUpload={onUpload} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ImagesTab
              onFileSelect={onFileSelect}
              selectedFilesArray={selectingCurrentlyFilesArray}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <VideosTab
              onFileSelect={onFileSelect}
              selectedFilesArray={selectingCurrentlyFilesArray}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={mediaError ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default MediaPage;
