import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchIcons} from "../../redux/features/icons/iconsSlice";

//Styles
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import ListSubheader from "@material-ui/core/ListSubheader";
import {VariableSizeList} from "react-window";

//Icons
import InfoIcon from "@material-ui/icons/Info";

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const useStyles = makeStyles(theme => ({
  testClass: {
    "& .MuiInputBase-root": {
      height: "50px",
    },
  },
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  iconImg: {
    marginRight: "10px",
    maxHeight: 30,
    filter: theme.palette.type === "light" ? "invert(20%)" : "invert(80%)",
  },
}));

const IconComboBox = ({onChange, defaultValue, readOnly}) => {
  /// Styles
  const classes = useStyles();

  // Local state
  const [value, setValue] = useState(defaultValue || null);

  // Redux
  const dispatch = useDispatch();
  const {iconsList, isLoading, error} = useSelector(state => state.icons);

  useEffect(() => {
    // Load Icons only if it's not being fetched outside
    dispatch(fetchIcons());
  }, [dispatch]);

  useEffect(() => {
    if (value) {
      onChange(value);
    } else {
      onChange(null);
    }
  }, [onChange, value]);

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        style={{
          display: "flex",
          alignItems: "flex-end",
          height: "100%",
        }}
        component="div">
        There was an error loading the Icon Module.
      </Typography>
    );
  }

  return (
    <>
      {!isLoading ? (
        <Autocomplete
          options={iconsList}
          autoHighlight
          clearOnEscape
          loading={isLoading}
          getOptionLabel={icon => icon.icon_name}
          renderGroup={renderGroup}
          className={classes.testClass}
          groupBy={icon => icon.icon_group}
          value={
            value && iconsList.find(icon => icon.icon_id === value.icon_id)
          }
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          disabled={readOnly}
          disableListWrap
          ListboxComponent={ListboxComponent}
          renderOption={icon => (
            <>
              <img
                src={`${process.env.REACT_APP_STATIC_PATH}/${icon.path}`}
                width="30px"
                alt={icon.icon_name}
                className={classes.iconImg}
              />
              {icon.icon_name}
            </>
          )}
          renderInput={params => {
            const value = iconsList.find(
              icon => icon.icon_name === params.inputProps.value
            );
            const path = value ? value.path : "";
            return (
              <TextField
                {...params}
                label="Choose an Icon"
                fullWidth
                required
                inputProps={{
                  ...params.inputProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {path ? (
                        <img
                          src={`${process.env.REACT_APP_STATIC_PATH}/${path}`}
                          height="25px"
                          alt={"icon"}
                          className={classes.iconImg}
                        />
                      ) : (
                        <InfoIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={48} animation="wave" />
      )}
    </>
  );
};

export default IconComboBox;
