import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createGallery} from "../../redux/features/media/galleriesSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import GalleriesForm from "./GalleriesForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const GalleriesNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.media);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/media": "Media",
    "/media/galleries": "Galleries",
    "/media/galleries/new": "New Gallery",
  };

  const onCancel = () => {
    history.push("/media/galleries");
  };
  const onSubmit = async values => {
    const galleryObj = {
      id: null,
      ...values,
    };

    const res = await dispatch(createGallery(galleryObj));
    // If gallery was created successfully redirect to media page and show user
    if (!res.error) {
      history.push({
        pathname: "/media/galleries",
        state: {
          showSuccess: true,
          snackbarContent: `Your Gallery '${galleryObj.name}' was successfully created!`,
          snackbarLink: `/media/galleries/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Gallery
          </Typography>
          <GalleriesForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Gallery"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GalleriesNew;
