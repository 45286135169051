import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPackageRegulars} from "../../redux/features/packages/regularSlice";

// Components
import {VariableSizeList} from "react-window";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 58;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 58;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType={List}
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const PackageComboBox = ({
  onChange,
  label = "Select a Package",
  defaultValue,
  readOnly,
  required = false,
}) => {
  // Local state
  const [value, setValue] = useState(defaultValue || null);

  // Redux
  const dispatch = useDispatch();
  const {packageRegularsList, isLoading, error} = useSelector(
    state => state.packages
  );

  useEffect(() => {
    dispatch(fetchPackageRegulars());
  }, [dispatch]);

  useEffect(() => {
    if (value) {
      onChange(value);
    } else {
      onChange(null);
    }
  }, [onChange, value]);

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        style={{display: "flex", alignItems: "flex-end", height: "100%"}}
        component="div">
        There was an error loading the Package Module.
      </Typography>
    );
  }

  return (
    <>
      {!isLoading ? (
        <Autocomplete
          id="package-select"
          options={packageRegularsList}
          autoHighlight
          clearOnEscape
          loading={isLoading}
          getOptionLabel={packageEl => packageEl.name}
          value={
            value &&
            packageRegularsList.find(packageEl => packageEl.id === value.id)
          }
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          disabled={readOnly}
          disableListWrap
          ListboxComponent={ListboxComponent}
          renderOption={packageEl => (
            <ListItemText
              id={`package-${packageEl.id}`}
              primary={packageEl.name}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              required={required}
              fullWidth
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={48} animation="wave" />
      )}
    </>
  );
};

export default PackageComboBox;
