import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createCoupon} from "../../redux/features/coupons/couponsSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import CouponsForm from "./CouponsForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const CouponsNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.coupons);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/coupons": "Coupons",
    "/coupons/new": "New Coupon",
  };

  const onCancel = () => {
    history.push("/coupons");
  };
  const onSubmit = async values => {
    const couponObj = {
      coupon_id: null,
      ...values,
    };

    const res = await dispatch(createCoupon(couponObj));
    // If coupon was created successfully redirect to coupons page and show user
    if (!res.error) {
      history.push({
        pathname: "/coupons",
        state: {
          showSuccess: true,
          snackbarContent: `Your Coupon '${couponObj.name}' was successfully created!`,
          snackbarLink: `/coupons/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Coupon
          </Typography>
          <CouponsForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Coupon"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CouponsNew;
