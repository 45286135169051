import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/packages/regular";

const packageRegularsInitialState = {
  packageRegularsList: [],
  currentPagePackageRegular: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const packageRegulars = createSlice({
  name: "packageRegulars",
  initialState: packageRegularsInitialState,
  reducers: {
    getPackageRegularStart: startLoading,
    getPackageRegularsStart: startLoading,
    createPackageRegularStart: startLoading,
    editPackageRegularStart: startLoading,
    deletePackageRegularStart: startLoading,
    getPackageRegularSuccess(state, {payload}) {
      state.currentPagePackageRegular = payload;
      state.isLoading = false;
      state.error = null;
    },
    getPackageRegularsSuccess(state, {payload}) {
      state.packageRegularsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createPackageRegularSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editPackageRegularSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deletePackageRegularSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getPackageRegularFailure: loadingFailed,
    getPackageRegularsFailure: loadingFailed,
    createPackageRegularFailure: loadingFailed,
    editPackageRegularFailure: loadingFailed,
    deletePackageRegularFailure: loadingFailed,
  },
});

export const {
  getPackageRegularStart,
  getPackageRegularsStart,
  createPackageRegularStart,
  editPackageRegularStart,
  deletePackageRegularStart,
  getPackageRegularSuccess,
  getPackageRegularsSuccess,
  createPackageRegularSuccess,
  editPackageRegularSuccess,
  deletePackageRegularSuccess,
  getPackageRegularFailure,
  getPackageRegularsFailure,
  createPackageRegularFailure,
  editPackageRegularFailure,
  deletePackageRegularFailure,
} = packageRegulars.actions;

export default packageRegulars.reducer;

export const fetchPackageRegulars = () => async (dispatch, getState) => {
  try {
    dispatch(getPackageRegularsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPackageRegularsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPackageRegularsFailure(errorObj));
  }
};

export const fetchPackageRegular = id => async (dispatch, getState) => {
  try {
    dispatch(getPackageRegularStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPackageRegularSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPackageRegularFailure(errorObj));
  }
};

export const createPackageRegular = packageObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createPackageRegularStart());
    const res = await axios.post(url, packageObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createPackageRegularSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createPackageRegularFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editPackageRegular = packageObj => async (dispatch, getState) => {
  try {
    dispatch(editPackageRegularStart());
    const res = await axios.put(url, packageObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editPackageRegularSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editPackageRegularFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deletePackageRegular = packageId => async (dispatch, getState) => {
  try {
    dispatch(deletePackageRegularStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: packageId},
    });
    dispatch(deletePackageRegularSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deletePackageRegularFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
