import React, {useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchContacts} from "../../redux/features/contacts/contactsSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const ContactsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {contactsList, isLoading, error} = useSelector(state => state.contacts);

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/contacts"
      tableTitle="All Contacts"
      tableColumns={[
        {
          title: "ID",
          field: "contact_id",
          type: "numeric",
          width: 30,
        },
        {title: "First Name", field: "firstName"},
        {title: "Last Name", field: "lastName"},
        {title: "Email", field: "email"},
        {title: "Subject", field: "subject"},
        {
          title: "Type",
          field: "type",
          render: rowData => <>{rowData.type.toUpperCase()}</>,
        },
        {
          title: "Date",
          field: "date_time",
          render: rowData => (
            <>{moment(rowData.date_time).tz("America/Chicago").format("lll")}</>
          ),
        },
        {
          title: "Resolved",
          field: "resolved",
          type: "boolean",
        },
      ]}
      addButtonLabel="Contact"
      dataList={contactsList}
      hideAddButton
      isLoading={isLoading}
      error={error}
      userPermissionKey="sales"
      idKey="contact_id"
    />
  );
};

export default ContactsPage;
