import React from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {useForm} from "react-hook-form";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const EventCategoriesForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    slug: "",
    color: "#000000",
    background: "#009abd",
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, register} = useForm({defaultValues});

  const formSubmit = data => {
    const formattedValues = {
      ...data,
      parent_id: null,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="name"
            label="Category name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Event Category Slug */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="slug"
            label="Slug"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Event Category Text Color */}
        <Grid item xs={6}>
          <FormControl className={classes.maxWidth}>
            <label htmlFor="color">Text Color *</label>
            <input
              id="color"
              ref={register}
              name="color"
              aria-describedby="text-color-picker"
              type="color"
              required
              disabled={readOnly}
            />
          </FormControl>
        </Grid>

        {/* Event Category Background Color */}
        <Grid item xs={6}>
          <FormControl className={classes.maxWidth}>
            <label htmlFor="background">Background Color *</label>
            <input
              ref={register}
              id="background"
              name="background"
              aria-describedby="background-color-picker"
              type="color"
              required
              disabled={readOnly}
            />
          </FormControl>
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default EventCategoriesForm;
