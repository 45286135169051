import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Icons
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  maxWidth: {
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    width: "100%",
  },
}));

const RowSet = ({
  id,
  defaultValue,
  onChange,
  onDelete,
  readOnly,
  disableDelete = false,
}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.maxWidth} spacing={2}>
      {defaultValue.start && (
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          className={classes.maxWidth}>
          <Typography variant="h6" component="p">
            {`${moment(defaultValue.start).format("LL")} - ${moment(
              defaultValue.end
            ).format("LL")}`}
          </Typography>

          {!readOnly && !disableDelete && (
            <IconButton aria-label="delete" value={id} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      )}

      {/* start: Joi.date().utc().format(['YYYY-MM-DD']).required(), */}
      <Grid item xs={12} md={6}>
        <KeyboardDatePicker
          label="Start Date"
          format="MM/dd/yyyy"
          value={moment(defaultValue.start)}
          name={`${id}-d-start`}
          onChange={date => {
            onChange(date, id, "start");
          }}
          readOnly={readOnly}
          KeyboardButtonProps={{
            "aria-label": "change start date",
          }}
        />
      </Grid>

      {/* end: Joi.date().utc().format(['YYYY-MM-DD']).required(), */}
      <Grid item xs={12} md={6}>
        <KeyboardDatePicker
          // className={classes.maxWidth}
          label="End Date"
          format="MM/dd/yyyy"
          value={moment(defaultValue.end).add(0, "days")}
          name={`${id}-d-end`}
          onChange={date => {
            onChange(date, id, "end");
          }}
          readOnly={readOnly}
          KeyboardButtonProps={{
            "aria-label": "change end date",
          }}
        />
      </Grid>
    </Grid>
  );
};

const DatePairBox = ({value, onChange, readOnly}) => {
  // Styles
  const classes = useStyles();
  // Local State
  const [datepair, setDatePair] = useState(
    Array.isArray(value) && value.length > 0
      ? value.map(dateObj => ({start: dateObj.start, end: dateObj.end}))
      : []
  );

  useEffect(() => {
    onChange(datepair);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepair]);

  const updateDatePair = (date, position, index) => {
    // Normalize Inputs
    let temp = [...datepair];
    let momentDate = moment(date);

    if (index === "start") {
      // Set the start date
      temp[position]["start"] = momentDate.format("YYYY-MM-DD");
      if (momentDate > moment(temp[position]["end"])) {
        // If the start date is more thant he current end date, modified the end date to one day after start date
        temp[position]["end"] = momentDate.add(1, "days").format("YYYY-MM-DD");
      }
    } else if (index === "end") {
      // Only modify if the end date is more than the start date
      if (moment(temp[position]["start"]) <= momentDate) {
        temp[position]["end"] = momentDate.format("YYYY-MM-DD");
      }
    }
    setDatePair(temp);
  };

  const removeDatePair = e => {
    if (datepair.length > 1) {
      let temp = [...datepair];
      let value = e.currentTarget.value;
      temp.splice(value, 1);
      setDatePair(temp);
      onChange(temp);
    }
  };

  const newRowSet = () => {
    let temp = [...datepair];

    temp.push({
      start: moment().add(1, "days").format("YYYY-MM-DD"),
      end: moment().add(2, "days").format("YYYY-MM-DD"),
    });
    setDatePair(temp);
  };

  return (
    <>
      {datepair && datepair.length > 0
        ? datepair.map((element, key) => {
            return (
              <Grid container item xs={12} key={key}>
                {key !== 0 && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}

                <RowSet
                  id={key}
                  defaultValue={element}
                  onChange={updateDatePair}
                  onDelete={removeDatePair}
                  readOnly={readOnly}
                  disableDelete={datepair.length < 2}
                />
              </Grid>
            );
          })
        : ""}

      {!readOnly && (
        <Grid item xs={12}>
          <Button
            className={classes.maxWidth}
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={newRowSet}>
            {""}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default DatePairBox;
