import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createHotel} from "../../redux/features/hotels/hotelsSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import HotelsForm from "./HotelsForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const HotelsNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.hotels);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/hotels": "Hotels",
    "/hotels/new": "New Hotel",
  };

  const onCancel = () => {
    history.push("/hotels");
  };

  const onSubmit = async values => {
    const hotelObj = {
      id: null,
      ...values,
    };

    const res = await dispatch(createHotel(hotelObj));
    // If page was created successfully redirect to hotels page and show user
    if (!res.error) {
      history.push({
        pathname: "/hotels",
        state: {
          showSuccess: true,
          snackbarContent: `Your Hotel '${hotelObj.name}' was successfully created!`,
          snackbarLink: `/hotels/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Hotel
          </Typography>
          <HotelsForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Hotel"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HotelsNew;
