import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchUsers} from "../../redux/features/users/usersSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const UsersPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {usersList, isLoading, error} = useSelector(state => state.users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/users"
      tableTitle="All Users"
      tableColumns={[
        {
          title: "ID",
          field: "user_id",
          type: "numeric",
          width: 30,
        },
        {title: "Username", field: "username"},
        {title: "Email", field: "email"},
        {title: "Enabled", field: "enabled", type: "boolean"},
      ]}
      addButtonLabel="User"
      idKey="user_id"
      dataList={usersList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="users"
    />
  );
};

export default UsersPage;
