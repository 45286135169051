import React from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Component
import {sortableHandle} from "react-sortable-hoc";
//Icon
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

export const useStyles = makeStyles(theme => ({
  dragIcon: {
    "&:hover": {
      cursor: "grab",
    },
    "&:active": {
      cursor: "grabbing",
    },
  },
}));

const DragHandle = sortableHandle(() => {
  const classes = useStyles();
  return <DragIndicatorIcon fontSize="large" className={classes.dragIcon} />;
});

export default DragHandle;
