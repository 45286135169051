import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/addons";

const addonsInitialState = {
  addonsList: [],
  customAddonsList: [],
  currentPageAddon: {},
  isLoading: false,
  error: null,
  customIsLoading: false,
  customError: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function startCustomLoading(state) {
  state.customIsLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}
function loadingCustomFailed(state, {payload}) {
  state.customIsLoading = false;
  state.customError = payload;
}

const addons = createSlice({
  name: "addons",
  initialState: addonsInitialState,
  reducers: {
    getAddonStart: startLoading,
    getAddonsStart: startLoading,
    getCustomAddonsStart: startCustomLoading,
    createAddonStart: startLoading,
    editAddonStart: startLoading,
    deleteAddonStart: startLoading,
    getAddonSuccess(state, {payload}) {
      state.currentPageAddon = payload;
      state.isLoading = false;
      state.error = null;
    },
    getAddonsSuccess(state, {payload}) {
      state.addonsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    getCustomAddonsSuccess(state, {payload}) {
      state.customAddonsList = payload;
      state.customIsLoading = false;
      state.customError = null;
    },
    createAddonSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editAddonSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteAddonSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getAddonFailure: loadingFailed,
    getAddonsFailure: loadingFailed,
    getCustomAddonsFailure: loadingCustomFailed,
    createAddonFailure: loadingFailed,
    editAddonFailure: loadingFailed,
    deleteAddonFailure: loadingFailed,
  },
});

export const {
  getAddonStart,
  getAddonsStart,
  getCustomAddonsStart,
  createAddonStart,
  editAddonStart,
  deleteAddonStart,
  getAddonSuccess,
  getAddonsSuccess,
  getCustomAddonsSuccess,
  createAddonSuccess,
  editAddonSuccess,
  deleteAddonSuccess,
  getAddonFailure,
  getAddonsFailure,
  getCustomAddonsFailure,
  createAddonFailure,
  editAddonFailure,
  deleteAddonFailure,
} = addons.actions;

export default addons.reducer;

export const fetchAddons = type => async (dispatch, getState) => {
  try {
    dispatch(getAddonsStart());
    const res = await axios.get(`${url}${type ? `?type=${type}` : ""}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getAddonsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getAddonsFailure(errorObj));
  }
};

export const fetchAddon = id => async (dispatch, getState) => {
  try {
    dispatch(getAddonStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getAddonSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getAddonFailure(errorObj));
  }
};

export const createAddon = addonObj => async (dispatch, getState) => {
  try {
    dispatch(createAddonStart());
    const res = await axios.post(url, addonObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createAddonSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createAddonFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editAddon = addonObj => async (dispatch, getState) => {
  try {
    dispatch(editAddonStart());
    const res = await axios.put(url, addonObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editAddonSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editAddonFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteAddon = addonId => async (dispatch, getState) => {
  try {
    dispatch(deleteAddonStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: addonId},
    });
    dispatch(deleteAddonSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteAddonFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

// Custom Addons
export const fetchCustomAddons = () => async (dispatch, getState) => {
  try {
    dispatch(getCustomAddonsStart());
    const res = await axios.get(`${url}/?type=custom`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCustomAddonsSuccess(res.data.response));
    return res.data.response;
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getCustomAddonsFailure(errorObj));
  }
};
