import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/hotels";

const hotelsInitialState = {
  hotelsList: [],
  currentPageHotel: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const hotels = createSlice({
  name: "hotels",
  initialState: hotelsInitialState,
  reducers: {
    getHotelStart: startLoading,
    getHotelsStart: startLoading,
    createHotelStart: startLoading,
    editHotelStart: startLoading,
    deleteHotelStart: startLoading,
    getHotelSuccess(state, {payload}) {
      state.currentPageHotel = payload;
      state.isLoading = false;
      state.error = null;
    },
    getHotelsSuccess(state, {payload}) {
      state.hotelsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createHotelSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editHotelSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteHotelSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getHotelFailure: loadingFailed,
    getHotelsFailure: loadingFailed,
    createHotelFailure: loadingFailed,
    editHotelFailure: loadingFailed,
    deleteHotelFailure: loadingFailed,
  },
});

export const {
  getHotelStart,
  getHotelsStart,
  createHotelStart,
  editHotelStart,
  deleteHotelStart,
  getHotelSuccess,
  getHotelsSuccess,
  createHotelSuccess,
  editHotelSuccess,
  deleteHotelSuccess,
  getHotelFailure,
  getHotelsFailure,
  createHotelFailure,
  editHotelFailure,
  deleteHotelFailure,
} = hotels.actions;

export default hotels.reducer;

export const fetchHotels = () => async (dispatch, getState) => {
  try {
    dispatch(getHotelsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getHotelsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getHotelsFailure(errorObj));
  }
};

export const fetchHotel = id => async (dispatch, getState) => {
  try {
    dispatch(getHotelStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getHotelSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getHotelFailure(errorObj));
  }
};

export const createHotel = hotelObj => async (dispatch, getState) => {
  try {
    dispatch(createHotelStart());
    const res = await axios.post(url, hotelObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createHotelSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createHotelFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editHotel = hotelObj => async (dispatch, getState) => {
  try {
    dispatch(editHotelStart());
    const res = await axios.put(url, hotelObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editHotelSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editHotelFailure(errorObj));
    return {
      error: true,
      ...errorObj,
    };
  }
};

export const deleteHotel = hotelId => async (dispatch, getState) => {
  try {
    dispatch(deleteHotelStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: hotelId},
    });
    dispatch(deleteHotelSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteHotelFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
