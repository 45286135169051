import React from "react";

// utils
import {useForm, Controller} from "react-hook-form";
import moment from "moment";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFiles from "../FormFields/UploadFiles";
import IconComboBox from "../FormFields/IconComboBox";
import NumberFormatCustom from "../FormFields/NumberFormatCustom";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
}));

const AddonForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    description: "",
    startDate: moment(),
    endDate: moment().add(1, "days"),
    type: "Daily/Person",
    status: "Published",
    use_in: "regular",
    age_id: 5,
    price: 1,
    taxRate: 1,
    photoObj: null,
    icon: null,
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {icon, start, end, photoObj, ...restOfData} = data;
    const formattedValues = {
      ...restOfData,
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),

      iconId: icon ? icon.icon_id : null,
      photoId: photoObj ? photoObj.photoId : null,
    };

    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Addon Name  */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            name="name"
            label="Name"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Addon Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            name="description"
            label="Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Addon Start Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="start"
            defaultValue={defaultValues.start}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Start Date"
                format="MM/dd/yyyy"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change start date",
                }}
              />
            )}
          />
        </Grid>

        {/* Addon End Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="end"
            defaultValue={defaultValues.end}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="End Date"
                format="MM/dd/yyyy"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change end date",
                }}
              />
            )}
          />
        </Grid>

        {/* Type of People */}

        <Grid item xs={12} lg={6}>
          <Controller
            name={`type`}
            control={control}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Daily/Person"}>Daily/Person</MenuItem>
                  <MenuItem value={"One-Time/Person"}>One-Time/Person</MenuItem>
                  <MenuItem value={"Daily/In-Room"}>Daily/In-Room</MenuItem>
                  <MenuItem value={"One-Time/In-Room"}>
                    One-Time/In-Room
                  </MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* People Going */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`age_id`}
            control={control}
            defaultValue={defaultValues.age_id}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Applies To</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={1}>Babies</MenuItem>
                  <MenuItem value={2}>Children</MenuItem>
                  <MenuItem value={3}>Adults</MenuItem>
                  <MenuItem value={4}>Seniors</MenuItem>
                  <MenuItem value={5}>All</MenuItem>
                  <MenuItem value={6}>All/No Babies</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Addon Price */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Price"
            name="price"
            defaultValue={defaultValues.price}
            inputProps={{
              thousandSeparator: true,
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Tax Rate */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Tax Rate"
            name="tax_rate"
            defaultValue={defaultValues.tax_rate}
            inputProps={{
              isAllowed: values => {
                const {formattedValue, floatValue} = values;
                return formattedValue === "" || floatValue <= 100;
              },
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Addon Image  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Featured Image"
                buttonLabel="Set Featured Image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Addon Icon  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name="icon"
            control={control}
            defaultValue={defaultValues.icon}
            render={props => (
              <IconComboBox
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Addon Status  */}
        <Grid item xs={12}>
          <Controller
            name={`status`}
            control={control}
            defaultValue={defaultValues.status}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Published"}>Published</MenuItem>
                  <MenuItem value={"Private"}>Private</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* In use  */}
        <Grid item xs={12}>
          <Controller
            name={`use_in`}
            control={control}
            defaultValue={defaultValues.use_in}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Type of Addon</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"regular"}>Regular</MenuItem>
                  <MenuItem value={"custom"}>Custom</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default AddonForm;
