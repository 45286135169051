import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchEvent,
  editEvent,
  deleteEvent,
} from "../../redux/features/events/eventsSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import EventsForm from "./EventsForm";
import FullContainerProgress from "../UI/FullContainerProgress";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";

const EventsEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageEvent, isLoading, error} = useSelector(
    state => state.events
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.events;

  const formattedValues = {
    title: currentPageEvent.title,
    permalink: currentPageEvent.permalink,
    description: currentPageEvent.description,
    short_description: currentPageEvent.short_description,
    start: moment(currentPageEvent.start).format(),
    end: moment(currentPageEvent.end).format(),
    venue: {id: currentPageEvent.venue_id},
    organizer: {id: currentPageEvent.organizer_id},
    package_id: currentPageEvent.package_id,
    pkg: {id: currentPageEvent.package_id},
    event_status: currentPageEvent.event_status,
    ticketmaster_id: currentPageEvent.ticketmaster_id,
    featured: currentPageEvent.featured ? true : false,

    // This is used to display the selected picture in the edit form
    photoObj: {
      photoId: currentPageEvent.photoId,
      photoName: currentPageEvent.photoName,
    },
  };

  let breadcrumbNameMap = {
    "/events": "Events",
  };
  breadcrumbNameMap[`/events/${id}`] = currentPageEvent.title;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchEvent(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const eventObj = {
      id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editEvent(eventObj));
    if (!res.error) {
      history.push({
        pathname: "/events",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${eventObj.name} were saved successfully!`,
          snackbarLink: `/events/${eventObj.id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteEvent(id));
    if (!res.error) {
      history.push({
        pathname: "/events",
        state: {
          showSuccess: true,
          snackbarContent: `The Event was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if event doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageEvent.name}
                </Typography>
                <EventsForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Event
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Event?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default EventsEdit;
