import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchMeetings} from "../../redux/features/meetings/meetingsSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const MeetingsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {meetingsList, isLoading, error} = useSelector(state => state.meetings);

  useEffect(() => {
    dispatch(fetchMeetings());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/meetings"
      tableTitle="All Meetings"
      tableColumns={[
        {
          title: "ID",
          field: "meeting_id",
          type: "numeric",
          width: 30,
        },
        {title: "Title", field: "title"},
        {title: "Permalink", field: "permalink"},
        {
          title: "Featured",
          field: "featured",
          type: "boolean",
        },
      ]}
      addButtonLabel="Meeting"
      dataList={meetingsList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="events"
      idKey="meeting_id"
    />
  );
};

export default MeetingsPage;
