import React, {useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchCharges} from "../../../redux/features/sales/chargesSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";

//Icons
import VisibilityIcon from "@material-ui/icons/Visibility";

const ChargesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {chargesList, isLoading, error} = useSelector(state => state.charges);

  // Routing
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCharges());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/sales/charges/"
      tableTitle="All Charges"
      hideAddButton
      tableColumns={[
        {
          title: "ID",
          field: "id",
          type: "numeric",
          width: 30,
        },
        {
          title: "Order",
          field: "order_id",
          type: "numeric",
          width: 30,
        },
        {
          title: "Customer Name",
          field: "customer_name",
        },
        {
          title: "Charge Date",
          field: "create",
          render: rowData => (
            <>{moment(rowData.create).tz("America/Chicago").format("lll")}</>
          ),
        },
        {
          title: "Stripe ID",
          field: "stripe_charge_id",
          render: rowData => (
            <Tooltip title="Visit Stripe" arrow>
              <Link
                color="inherit"
                href={`https://dashboard.stripe.com/payments/${rowData.stripe_charge_id}`}
                target="_blank"
                rel="noopener noreferrer">
                {rowData.stripe_charge_id}
              </Link>
            </Tooltip>
          ),
        },
        {
          title: "Amount",
          field: "amount",
          type: "currency",
          headerStyle: {
            textAlign: "right",
          },
        },
        {
          title: "Amount Refunded",
          field: "amount_refunded",
          type: "currency",
          headerStyle: {
            textAlign: "right",
          },
        },
        {
          title: "Status",
          field: "status",
          render: rowData => (
            <>
              {rowData.status &&
                rowData.status.charAt(0).toUpperCase() +
                  rowData.status.slice(1)}
            </>
          ),
        },
      ]}
      customActions={[
        {
          icon: VisibilityIcon,
          tooltip: "View",
          onClick: (event, rowData) => {
            // Go to Edit page
            history.push(`/sales/charges/${rowData.id}`);
          },
        },
      ]}
      dataList={chargesList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="sales"
    />
  );
};

export default ChargesPage;
