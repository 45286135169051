import React, {useState} from "react";

// Redux
import {useSelector} from "react-redux";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FullContainerProgress from "../../../UI/FullContainerProgress";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormatCustom from "../../../FormFields/NumberFormatCustom";

//Styling
const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
}));

const CreateRefundDialog = ({
  chargeId,
  orderId,
  open,
  maxAmount,
  onClose,
  onSubmit,
}) => {
  //Styles
  const classes = useStyles();
  // Redux
  const {isLoading, error} = useSelector(state => state.refunds);

  // Local State
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState("requested_by_customer");

  const formSubmit = () => {
    const values = {
      amount,
      reason,
    };
    onSubmit(values);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      aria-labelledby="alert-refund-view"
      aria-describedby="alert-refund-view"
      scroll="body">
      {isLoading ? (
        <>
          <DialogContent>
            <Box width={700} height={500}>
              <FullContainerProgress />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-refund-view">Create Refund</DialogTitle>
          <DialogContent style={{overflow: "visible"}}>
            <form className={classes.form}>
              <Grid container spacing={4}>
                {error && (
                  <Grid item xs={12}>
                    <MuiAlert className={classes.alert} severity="error">
                      There was an error. Please try again.
                    </MuiAlert>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body1" component="p">
                    Order ID: {orderId}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1" component="p">
                    Charge ID: {chargeId}
                  </Typography>
                </Grid>

                {/* Refund amount */}
                <Grid item xs={12} lg={6}>
                  <TextField
                    className={classes.maxWidth}
                    required
                    label="Amount"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    inputProps={{
                      thousandSeparator: true,
                      isAllowed: values => {
                        const {floatValue} = values;
                        return floatValue >= 0 && floatValue === maxAmount;
                      },
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={() => setAmount(maxAmount)}
                            size="small"
                            color="secondary">
                            Refund Full Amount
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Reason */}
                <Grid item xs={12} lg={6}>
                  <FormControl className={classes.maxWidth}>
                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                      Reason
                    </InputLabel>
                    <Select
                      value={reason}
                      onChange={e => setReason(e.target.value)}>
                      <MenuItem value={"requested_by_customer"}>
                        Requested By Customer
                      </MenuItem>
                      <MenuItem value={"fraudulent"}>Fraudulent</MenuItem>
                      <MenuItem value={"duplicate"}>Duplicate</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={amount === 0}
              onClick={() => {
                formSubmit();
              }}>
              Create Refund
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateRefundDialog;
