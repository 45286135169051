import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPackageCards} from "../../../redux/features/packages/cardSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const PackageCardsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {packageCardsList, isLoading, error} = useSelector(
    state => state.packageCards
  );

  useEffect(() => {
    dispatch(fetchPackageCards());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/packages/cards"
        tableTitle="All Packages"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Title", field: "title"},
          {
            title: "Link",
            field: "link",
            cellStyle: {maxWidth: "208px", overflowX: "hidden"},
          },
          {title: "Start Date", field: "start", type: "date"},
          {title: "End Date", field: "end", type: "date"},
          {title: "Featured", field: "featured", type: "boolean"},
        ]}
        addButtonLabel="Package Card"
        dataList={packageCardsList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="packages"
      />
    </>
  );
};

export default PackageCardsPage;
