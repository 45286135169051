import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPackageRegulars} from "../../../redux/features/packages/regularSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const PackageRegularPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {packageRegularsList, isLoading, error} = useSelector(
    state => state.packages
  );

  useEffect(() => {
    dispatch(fetchPackageRegulars());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/packages/regular"
      tableTitle="Regular Packages"
      tableColumns={[
        {title: "Name", field: "name"},
        {
          title: "Permalink",
          field: "permalink",
          cellStyle: {maxWidth: "208px", overflowX: "hidden"},
        },
        {title: "Featured", field: "featured", type: "boolean"},
        {title: "Is Fixed", field: "is_fixed", type: "boolean"},
        {title: "Status", field: "package_status"},
      ]}
      addButtonLabel="Package"
      dataList={packageRegularsList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="packages"
    />
  );
};

export default PackageRegularPage;
