import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/icons";

const iconsInitialState = {
  iconsList: [],
  currentPageIcon: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const icons = createSlice({
  name: "icons",
  initialState: iconsInitialState,
  reducers: {
    getIconStart: startLoading,
    getIconsStart: startLoading,
    getIconSuccess(state, {payload}) {
      state.currentPageIcon = payload;
      state.isLoading = false;
      state.error = null;
    },
    getIconsSuccess(state, {payload}) {
      state.iconsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    getIconFailure: loadingFailed,
    getIconsFailure: loadingFailed,
  },
});

export const {
  getIconStart,
  getIconsStart,
  getIconSuccess,
  getIconsSuccess,
  getIconFailure,
  getIconsFailure,
} = icons.actions;

export default icons.reducer;

export const fetchIcons = () => async (dispatch, getState) => {
  try {
    dispatch(getIconsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getIconsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getIconsFailure(errorObj));
  }
};

export const fetchIcon = id => async (dispatch, getState) => {
  try {
    dispatch(getIconStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getIconSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getIconFailure(errorObj));
  }
};
