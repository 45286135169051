import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPackageCategories} from "../../../redux/features/packages/categoriesSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const PackageCategoriesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {packageCategoriesList, isLoading, error} = useSelector(
    state => state.packageCategories
  );

  useEffect(() => {
    dispatch(fetchPackageCategories());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/packages/categories"
        tableTitle="All Categories"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {title: "Slug", field: "slug"},
        ]}
        addButtonLabel="Package Category"
        dataList={packageCategoriesList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="packages"
      />
    </>
  );
};

export default PackageCategoriesPage;
