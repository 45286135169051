import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchCondo,
  editCondo,
  deleteCondo,
} from "../../redux/features/condos/condosSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import CondosForm from "./CondosForm";
import FullContainerProgress from "../UI/FullContainerProgress";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";

const CondosEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageCondo, isLoading, error} = useSelector(
    state => state.condos
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.sales;

  const formattedValues = {
    // Condo Info
    name: currentPageCondo.name,
    myvr_condo: {
      myvr_id: currentPageCondo.myvr_id,
      name: "",
    },
    permalink: currentPageCondo.permalink,
    email: currentPageCondo.email,
    description: currentPageCondo.description,
    short_description: currentPageCondo.short_description,
    type: currentPageCondo.type,
    tripadvisor: currentPageCondo.tripadvisor,
    commission: currentPageCondo.commission || 0,
    status: currentPageCondo.status || "Draft",
    featured: currentPageCondo.featured,
    beachfront: currentPageCondo.beachfront,
    bathrooms: currentPageCondo.bathrooms,
    bedrooms: currentPageCondo.bedrooms,
    minStay: currentPageCondo.minStay,
    accommodates: currentPageCondo.accommodates,
    //   Location
    placeID: currentPageCondo.placeID,
    location: currentPageCondo.location,
    latitude: currentPageCondo.location ? currentPageCondo.location[0] : 0,
    longitude: currentPageCondo.location ? currentPageCondo.location[1] : 0,
    street: currentPageCondo.street,
    street_2: currentPageCondo.street_2,
    city: currentPageCondo.city,
    state: currentPageCondo.state,
    zip: currentPageCondo.zip,
    country: currentPageCondo.country,
    //   Images
    photoObj: {
      photoId: currentPageCondo.photo,
      photoName: currentPageCondo.photoName,
    },
    gallery_id: currentPageCondo.gallery_id,
    gallery: {id: currentPageCondo.gallery_id},
    // Suitability
    suitability: currentPageCondo.suitability,
    elderly: currentPageCondo.suitability
      ? currentPageCondo.suitability.elderly === "yes"
        ? 1
        : 0
      : 0,
    events: currentPageCondo.suitability
      ? currentPageCondo.suitability.events === "yes"
        ? 1
        : 0
      : 0,
    groups: currentPageCondo.suitability
      ? currentPageCondo.suitability.groups === "yes"
        ? 1
        : 0
      : 0,
    handicap: currentPageCondo.suitability
      ? currentPageCondo.suitability.handicap === "yes"
        ? 1
        : 0
      : 0,
    infants: currentPageCondo.suitability
      ? currentPageCondo.suitability.infants === "yes"
        ? 1
        : 0
      : 0,
    kids: currentPageCondo.suitability
      ? currentPageCondo.suitability.kids === "yes"
        ? 1
        : 0
      : 0,
    pets: currentPageCondo.suitability
      ? currentPageCondo.suitability.pets === "yes"
        ? 1
        : 0
      : 0,
    smoking: currentPageCondo.suitability
      ? currentPageCondo.suitability.smoking === "yes"
        ? 1
        : 0
      : 0,
    // Aditional
    additional: currentPageCondo.additional,
    checkInTime: currentPageCondo.additional
      ? moment(currentPageCondo.additional.checkInTime, "hh:mm:ss")
      : moment(),
    checkOutTime: currentPageCondo.additional
      ? moment(currentPageCondo.additional.checkOutTime, "hh:mm:ss")
      : moment(),
    localAreaDescription: currentPageCondo.additional
      ? currentPageCondo.additional.localAreaDescription
      : "",
    accessDescription: currentPageCondo.additional
      ? currentPageCondo.additional.accessDescription
      : "",
    transitDescription: currentPageCondo.additional
      ? currentPageCondo.additional.transitDescription
      : "",
    houseRules: currentPageCondo.additional
      ? currentPageCondo.additional.houseRules
      : "",

    //   Dynamic inputs
    amenities: currentPageCondo.amenities,
  };

  let breadcrumbNameMap = {
    "/condos": "Condos",
  };
  breadcrumbNameMap[`/condos/${id}`] = currentPageCondo.name;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchCondo(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const condoObj = {
      condos_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editCondo(condoObj));
    if (!res.error) {
      history.push({
        pathname: "/condos",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${condoObj.name} were saved successfully!`,
          snackbarLink: `/condos/${condoObj.condos_id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteCondo(id));
    if (!res.error) {
      history.push({
        pathname: "/condos",
        state: {
          showSuccess: true,
          snackbarContent: `The Condo was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if condo doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageCondo.name}
                </Typography>
                <CondosForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Condo
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Condo?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default CondosEdit;
