import React, {useState} from "react";

//utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchRatesForMonth,
  editRatesByMonthForHotelRoom,
} from "../../redux/features/sales/ratesSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Paper from "../UI/MainPaper";
import {DatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NumberFormatCustom from "../FormFields/NumberFormatCustom";
import FullContainerProgress from "../UI/FullContainerProgress";
import HotelComboBox from "../FormFields/HotelComboBox";
import RoomComboBox from "../FormFields/RoomComboBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  maxWidth: {width: "100%"},
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  resize: {
    fontSize: theme.typography.caption.fontSize,
    marginBottom: theme.spacing(2),
  },
  select: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: "100%",
  },
}));

const SetValueDialog = ({
  dialogTitle,
  open,
  onClose,
  onSubmit,
  referenceValue,
  selectedDate,
}) => {
  //Styles
  const classes = useStyles();
  // Local State
  const [value, setValue] = useState(0);
  const [day_type, setDayType] = useState("day_price");
  const daysInMonth = moment(selectedDate).daysInMonth();

  const handleSubmit = () => {
    // Get number of dates in month

    // Divide the value by the correct amount depending on day_type
    let finalValue = value;

    switch (day_type) {
      case "day_price":
        finalValue = value;
        break;
      case "week_price":
        finalValue = value / 7;
        break;
      case "month_price":
        finalValue = value / daysInMonth;
        break;
      default:
        finalValue = value;
        break;
    }

    onSubmit(referenceValue, finalValue, day_type);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <FormControl className={classes.select}>
          <InputLabel shrink>Rate Type</InputLabel>
          <Select value={day_type} onChange={e => setDayType(e.target.value)}>
            <MenuItem value={"day_price"}>Regular Day Rate</MenuItem>
            <MenuItem value={"week_price"}>Custom Week Rate</MenuItem>
            <MenuItem value={"month_price"}>Custom Month Rate</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className={classes.resize}
          value={value}
          size="small"
          label="Set Rate"
          fullWidth
          helperText={
            day_type === "week_price"
              ? "This value will be divided by 7 and assigned to the respective dates custom rate"
              : day_type === "month_price"
              ? `This value will be divided by ${daysInMonth} and assigned to the respective dates custom rate`
              : null
          }
          onChange={e => setValue(Number(e.target.value))}
          inputProps={{
            thousandSeparator: true,
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          disabled={false}>
          Set Values
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RatesTableCalendar = ({
  initialRates,
  isLoading,
  onSubmit,
  selectedDate,
  tableTitle,
  onMonthChange,
}) => {
  //Styles
  const classes = useStyles();

  // Local State
  const [ratesArray, setRatesArray] = useState(initialRates);
  const [showCustomRates, setShowCustomRates] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogObject, setDialogObject] = useState({
    title: "",
    onSubmit: null,
    referenceValue: null,
  });

  // Render functions
  const weeksArraysForYearMonth = (date, rates) => {
    let weeksArray = [];
    let weekDaysArray = [];

    const year = moment(date).format("YYYY");
    const month = moment(date).format("MM");

    // Get last day of month
    const lastDayOfMonth = Number(moment(date).endOf("month").format("D"));

    //Loop through month
    for (let d = 1; d <= lastDayOfMonth; d++) {
      /// YYYY-MM-DD

      const currentDayFormatted = `${year}-${month}-${d < 10 ? "0" : ""}${d}`;

      const emptyDayRateObj = {
        day: null,
        weekday: null,
        date: null,
        day_price: null,
        week_price: null,
        month_price: null,
        week: null,
      };

      let currentDayRateObj = {...emptyDayRateObj};

      /// Get the rate obj from the rates array
      const rateObj = rates.find(el => el.date === currentDayFormatted);

      // Get day of the week of current d
      let currentWeekDay = Number(
        moment(currentDayFormatted, "YYYY-MM-DD").weekday()
      );

      //update currentDayRateObj
      currentDayRateObj["day"] = d;
      currentDayRateObj["date"] = rateObj?.date;
      currentDayRateObj["day_price"] = rateObj?.day_price;
      currentDayRateObj["week_price"] = rateObj?.week_price;
      currentDayRateObj["month_price"] = rateObj?.month_price;
      currentDayRateObj["weekday"] = currentWeekDay;
      currentDayRateObj["week"] = weeksArray.length;

      // If first day of month push empty days before it
      if (d === 1) {
        for (let i = 0; i < currentWeekDay; i++) {
          weekDaysArray.push(emptyDayRateObj);
        }
      }

      // if current d is first day of week empty weekdayArray and push
      if (currentWeekDay === 0) {
        weekDaysArray = [];
        weekDaysArray.push(currentDayRateObj);
      } else if (currentWeekDay === 6) {
        // if current d is last day of week push to weekday array and push array to weekArrays
        weekDaysArray.push(currentDayRateObj);
        weeksArray.push(weekDaysArray);
      } else {
        // otherwise just push to weekDaysArray
        weekDaysArray.push(currentDayRateObj);
      }

      // If last day of month push empty days after it
      if (d === lastDayOfMonth) {
        for (let i = currentWeekDay + 1; i < 7; i++) {
          weekDaysArray.push(emptyDayRateObj);
        }
        weeksArray.push(weekDaysArray);
      }
    }
    return weeksArray;
  };

  // Array formatted to be rendered
  const weekArrays = weeksArraysForYearMonth(selectedDate, ratesArray);
  const weekArraysFlat = weekArrays.flat();

  // On change functions
  const handlePriceChange = (date, price, day_type) => {
    let ratesTemp = ratesArray.map(el => {
      if (el.date === date) {
        return {
          date,
          day_price: day_type === "day_price" ? price : el.day_price,
          week_price: day_type === "week_price" ? price : el.week_price,
          month_price: day_type === "month_price" ? price : el.month_price,
        };
      } else {
        return el;
      }
    });

    setRatesArray(ratesTemp);
  };
  const onChangeAll = (ref, price, day_type) => {
    let newArray = ratesArray.map(el => {
      // Set new price
      return {
        ...el,
        day_price: day_type === "day_price" ? price : el.day_price,
        week_price: day_type === "week_price" ? price : el.week_price,
        month_price: day_type === "month_price" ? price : el.month_price,
      };
    });
    setRatesArray(newArray);
  };

  const onChangeWeekday = (weekday, price, day_type) => {
    let newArray = ratesArray.map(el => {
      // If the current iteration el is on the weekday selected update price, if not return el
      const foundMatch = weekArraysFlat.find(dateEl => el.date === dateEl.date);
      if (foundMatch && foundMatch.weekday === weekday) {
        return {
          ...el,
          day_price: day_type === "day_price" ? price : el.day_price,
          week_price: day_type === "week_price" ? price : el.week_price,
          month_price: day_type === "month_price" ? price : el.month_price,
        };
      } else {
        return el;
      }
    });
    setRatesArray(newArray);
  };

  const onChangeWholeWeek = (week, price, day_type) => {
    let newArray = ratesArray.map(el => {
      // If the current iteration el is on the week selected update price, if not return el
      const foundMatch = weekArraysFlat.find(dateEl => el.date === dateEl.date);
      if (foundMatch && foundMatch.week === week) {
        return {
          ...el,
          day_price: day_type === "day_price" ? price : el.day_price,
          week_price: day_type === "week_price" ? price : el.week_price,
          month_price: day_type === "month_price" ? price : el.month_price,
        };
      } else {
        return el;
      }
    });
    setRatesArray(newArray);
  };

  const handleClickSetValue = (referenceValue, key) => {
    switch (key) {
      case "WEEK":
        setDialogObject({
          title: `Set the Rate For Week ${referenceValue + 1}`,
          onSubmit: onChangeWholeWeek,
          referenceValue,
        });
        break;
      case "WEEKDAY":
        setDialogObject({
          title: `Set rate for all ${moment()
            .weekday(referenceValue)
            .format("dddd")}s`,
          onSubmit: onChangeWeekday,
          referenceValue,
        });
        break;
      case "ALL":
        setDialogObject({
          title: `Set the Rate For All Month`,
          onSubmit: onChangeAll,
          referenceValue,
        });
        break;
      default:
        break;
    }
    setOpenDialog(true);
  };

  if (ratesArray.length < 1) {
    return (
      <Grid item xs={12}>
        <Paper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography variant="h6" gutterBottom align="center">
            No rates Available. Please select other values.
          </Typography>
        </Paper>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={1}>
                  <Button
                    onClick={() => onMonthChange("previous")}
                    startIcon={<ArrowBackIosIcon />}>
                    Previous
                  </Button>
                </TableCell>
                <TableCell align="center" colSpan={6}>
                  <Typography variant="h4" gutterBottom>
                    {tableTitle.monthTitle}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {tableTitle.roomTitle}
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  <Button
                    onClick={() => onMonthChange("next")}
                    endIcon={<ArrowForwardIosIcon />}>
                    Next
                  </Button>
                </TableCell>
              </TableRow>
              {/* Week Day names */}
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setShowCustomRates(!showCustomRates)}
                        value="show"
                        checked={showCustomRates}
                      />
                    }
                    label="Show Custom Rates"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Tooltip title={`Set rate on all`} placement="top">
                    <IconButton
                      onClick={() => handleClickSetValue(null, "ALL")}>
                      <TrendingDownIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {[0, 1, 2, 3, 4, 5, 6].map(n => (
                  <TableCell key={n}>
                    <Tooltip
                      title={`Set rate on ${moment()
                        .weekday(n)
                        .format("dddd")}s`}
                      placement="top">
                      <IconButton
                        onClick={() => handleClickSetValue(n, "WEEKDAY")}>
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>{""}</TableCell>
                {[0, 1, 2, 3, 4, 5, 6].map(n => (
                  <TableCell key={n}>
                    <Typography variant="caption">
                      {moment().weekday(n).format("dd")}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!isLoading && ratesArray.length > 0 ? (
              <TableBody>
                {weeksArraysForYearMonth(selectedDate, ratesArray).map(
                  (week, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Tooltip
                          title={`Set rate on whole week`}
                          placement="top">
                          <IconButton
                            onClick={() => handleClickSetValue(i, "WEEK")}>
                            <ArrowForwardIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      {week.map(
                        (
                          {day, date, day_price, week_price, month_price},
                          j
                        ) => (
                          <TableCell key={`${i}-${j}`}>
                            <Typography variant="h6" gutterBottom>
                              {day}
                            </Typography>
                            {day_price !== null && (
                              <TextField
                                label={showCustomRates ? "Day Price" : null}
                                className={classes.resize}
                                value={day_price}
                                variant="outlined"
                                size="small"
                                onChange={e =>
                                  handlePriceChange(
                                    date,
                                    Number(e.target.value),
                                    "day_price"
                                  )
                                }
                                inputProps={{
                                  thousandSeparator: true,
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                InputLabelProps={{shrink: true}}
                              />
                            )}
                            {week_price !== null && showCustomRates && (
                              <TextField
                                label="Week Price"
                                className={classes.resize}
                                value={week_price}
                                variant="outlined"
                                size="small"
                                onChange={e =>
                                  handlePriceChange(
                                    date,
                                    Number(e.target.value),
                                    "week_price"
                                  )
                                }
                                inputProps={{
                                  thousandSeparator: true,
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                InputLabelProps={{shrink: true}}
                              />
                            )}
                            {month_price !== null && showCustomRates && (
                              <TextField
                                label="Month Price"
                                className={classes.resize}
                                value={month_price}
                                variant="outlined"
                                size="small"
                                onChange={e =>
                                  handlePriceChange(
                                    date,
                                    Number(e.target.value),
                                    "month_price"
                                  )
                                }
                                inputProps={{
                                  thousandSeparator: true,
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                InputLabelProps={{shrink: true}}
                              />
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" gutterBottom>
                      Loading...
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={() => onSubmit(ratesArray)}>
          Save Values
        </Button>
      </Grid>
      <SetValueDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        dialogTitle={dialogObject.title}
        onSubmit={dialogObject.onSubmit}
        referenceValue={dialogObject.referenceValue}
        selectedDate={selectedDate}
      />
    </>
  );
};

const RatesPage = () => {
  //Styles
  const classes = useStyles();

  // Local State
  const [fetchFlag, setFetchFlag] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [tableSelectedDate, setTableSelectedDate] = useState(selectedDate);
  const [hotel, setHotel] = useState(null);
  const [room, setRoom] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [tableTitle, setTableTitle] = useState({monthTitle: "", roomTitle: ""});

  // Redux
  const dispatch = useDispatch();
  const {ratesList, isLoading, error} = useSelector(state => state.rates);

  const onFetchRates = (withDate = selectedDate) => {
    setFetchFlag(true);
    setTableSelectedDate(moment(withDate));

    const date = moment(withDate).format("YYYY-MM");
    setTableTitle({
      monthTitle: `${moment(withDate).format("MMMM")} -
                    ${moment(withDate).format("YYYY")}`,
      roomTitle: `
      ${hotel ? hotel.name : null} -  ${room ? room.name : null}`,
    });
    dispatch(fetchRatesForMonth(date, hotel.id, room.id));
  };

  const onSubmit = async ratesArray => {
    // Need to convert array with objects like:
    // {
    //   date: "2020-08-01",
    //   day_price: 111,
    //   month_price: 222,
    //   week_price: 333
    // }
    //
    // into three objects like:
    //   {
    //     date: "2020-08-01",
    //     price: 111,
    //     custom_rates: 0,
    //     day_type: 'day'
    //   },
    //   {
    //     date: "2020-08-01",
    //     price: 222,
    //     custom_rates: 1,
    //     day_type: 'week'
    //   },
    //   {
    //     date: "2020-08-01",
    //     price: 333,
    //     custom_rates: 1,
    //     day_type: 'month'
    //   },

    const formattedRatesArray = ratesArray.flatMap(r => {
      const {date, day_price, week_price, month_price} = r;
      return [
        {date, price: day_price, day_type: "day", custom_rates: 0},
        {date, price: week_price, day_type: "week", custom_rates: 1},
        {
          date,
          price: month_price,
          day_type: "month",
          custom_rates: 1,
        },
      ];
    });

    const formattedRatesObj = {
      hotel_id: hotel.id,
      room_id: room.id,
      rates: formattedRatesArray,
    };

    // Logic to show error or success
    const res = await dispatch(editRatesByMonthForHotelRoom(formattedRatesObj));
    if (!res.error) {
      setSnackbarContent("Your changes to the Rates were saved successfully!");
      setOpenSnackbar(true);
      onFetchRates();
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onMonthChange = (nextOrPrevious = "next") => {
    if (hotel && room && selectedDate) {
      let newDate = moment(selectedDate);
      // setTableSelectedDate(newDate);

      if (nextOrPrevious === "next") {
        newDate = newDate.add(1, "month");
      } else {
        newDate = newDate.subtract(1, "month");
      }
      setSelectedDate(newDate);

      onFetchRates(newDate);
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const handleChangeSelectedDate = date => {
    setSelectedDate(date);

    if (hotel && room && selectedDate) {
      onFetchRates(date);
    }
  };

  return (
    <Grid container spacing={3}>
      {/* Hotel and Room  */}
      <Grid container item xs={12} lg={10} spacing={2}>
        <Grid container item xs={12} spacing={2}>
          {/* Hotel  */}
          <Grid item xs={12} md={6}>
            <HotelComboBox
              defaultValue={hotel}
              onChange={setHotel}
              readOnly={isLoading}
              required
            />
          </Grid>

          {/* Hotel Room */}
          {hotel && (
            <Grid item xs={12} md={6}>
              <RoomComboBox
                defaultValue={room}
                hotel_id={hotel ? hotel.id : null}
                onChange={setRoom}
                readOnly={isLoading}
              />
            </Grid>
          )}
        </Grid>

        {/* Select Month */}
        <Grid item xs={12}>
          <DatePicker
            className={classes.maxWidth}
            views={["year", "month"]}
            label="Select Month"
            openTo="month"
            minDate={moment().startOf("year")}
            value={selectedDate}
            onChange={handleChangeSelectedDate}
            disabled={isLoading || !room}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={2} style={{paddingRight: 0}}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<GetAppIcon />}
          disabled={!(hotel && room && selectedDate && !isLoading)}
          onClick={() => onFetchRates()}>
          Preview Rates
        </Button>
      </Grid>

      {!fetchFlag ? (
        <Grid item xs={12}>
          <Paper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant="h6" gutterBottom align="center">
              Please select some values and fetch rates.
            </Typography>
          </Paper>
        </Grid>
      ) : !isLoading ? (
        <RatesTableCalendar
          selectedDate={tableSelectedDate}
          tableTitle={tableTitle}
          initialRates={ratesList}
          onSubmit={onSubmit}
          onMonthChange={onMonthChange}
          isLoading={isLoading}
        />
      ) : (
        <Grid item xs={12}>
          <Paper>
            <FullContainerProgress />
          </Paper>
        </Grid>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};

export default RatesPage;
