import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchPage,
  editPage,
  deletePage,
} from "../../redux/features/pages/pagesSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import PageForm from "./PageForm";
import FullContainerProgress from "../UI/FullContainerProgress";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";

const PagesEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPagePage, isLoading, error} = useSelector(state => state.pages);
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.pages;

  const formattedValues = {
    title: currentPagePage.title,
    permalink: currentPagePage.permalink,
    description: currentPagePage.description,
    body: currentPagePage.body,
    page_status: currentPagePage.page_status,
    type: currentPagePage.type,

    linked_object: currentPagePage.linked_object,

    hotel_id: currentPagePage.hotel_id,
    hotel: {id: currentPagePage.hotel_id},

    package_id: currentPagePage.package_id,
    pkg: {id: currentPagePage.package_id},

    condo_id: currentPagePage.condo_id,
    condo: {condos_id: currentPagePage.condo_id},

    // This is used to display the selected picture in the edit form
    photoObj: {
      photoId: currentPagePage.picture_id,
      photoName: currentPagePage.photoName,
    },
  };

  let breadcrumbNameMap = {
    "/pages": "Pages",
  };
  breadcrumbNameMap[`/pages/${id}`] = currentPagePage.title;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchPage(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const pageObj = {
      id,
      ...values,
    };

    // Logic to show error or success
    const res = await dispatch(editPage(pageObj));
    if (!res.error) {
      history.push({
        pathname: "/pages",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to page '${pageObj.title}' were saved successfully!`,
          snackbarLink: `/pages/${pageObj.id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deletePage(id));
    if (!res.error) {
      history.push({
        pathname: "/pages",
        state: {
          showSuccess: true,
          snackbarContent: `The Page was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if page doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPagePage.title}
                </Typography>
                <PageForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Page
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Page?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default PagesEdit;
