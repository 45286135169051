import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/orders";

const ordersInitialState = {
  ordersList: [],
  currentPageOrder: {},
  isLoading: false,
  error: null,

  /// Send Email
  isLoadingEmail: false,
  errorEmail: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}
function startLoadingEmail(state) {
  state.isLoadingEmail = true;
}
function loadingFailedEmail(state, {payload}) {
  state.isLoadingEmail = false;
  state.errorEmail = payload;
}

const orders = createSlice({
  name: "orders",
  initialState: ordersInitialState,
  reducers: {
    getOrderStart: startLoading,
    getOrdersStart: startLoading,
    createOrderStart: startLoading,
    editOrderStart: startLoading,
    deleteOrderStart: startLoading,
    getOrderSuccess(state, {payload}) {
      state.currentPageOrder = payload;
      state.isLoading = false;
      state.error = null;
    },
    getOrdersSuccess(state, {payload}) {
      state.ordersList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createOrderSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editOrderSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteOrderSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getOrderFailure: loadingFailed,
    getOrdersFailure: loadingFailed,
    createOrderFailure: loadingFailed,
    editOrderFailure: loadingFailed,
    deleteOrderFailure: loadingFailed,

    // Email
    sendEmailStart: startLoadingEmail,
    sendEmailSuccess(state) {
      state.isLoadingEmail = false;
      state.errorEmail = null;
    },
    sendEmailFailure: loadingFailedEmail,
  },
});

export const {
  getOrderStart,
  getOrdersStart,
  createOrderStart,
  editOrderStart,
  deleteOrderStart,
  getOrderSuccess,
  getOrdersSuccess,
  createOrderSuccess,
  editOrderSuccess,
  deleteOrderSuccess,
  getOrderFailure,
  getOrdersFailure,
  createOrderFailure,
  editOrderFailure,
  deleteOrderFailure,

  // Email
  sendEmailStart,
  sendEmailSuccess,
  sendEmailFailure,
} = orders.actions;

export default orders.reducer;

export const fetchOrders = () => async (dispatch, getState) => {
  try {
    dispatch(getOrdersStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getOrdersSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getOrdersFailure(errorObj));
  }
};

export const fetchOrder = id => async (dispatch, getState) => {
  try {
    dispatch(getOrderStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getOrderSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getOrderFailure(errorObj));
  }
};

export const createOrder = orderObj => async (dispatch, getState) => {
  try {
    dispatch(createOrderStart());
    const res = await axios.post(url, orderObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createOrderSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createOrderFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editOrder = (orderId, orderObj) => async (dispatch, getState) => {
  try {
    dispatch(editOrderStart());
    const res = await axios.put(`${url}/${orderId}`, orderObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editOrderSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editOrderFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteOrder = orderId => async (dispatch, getState) => {
  try {
    dispatch(deleteOrderStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: orderId},
    });
    dispatch(deleteOrderSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteOrderFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

// Send Email
export const sendEmail = (orderId, emailObj) => async (dispatch, getState) => {
  try {
    dispatch(sendEmailStart());
    const res = await axios.post(`${url}/${orderId}/email`, emailObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(sendEmailSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(sendEmailFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
