import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchRooms} from "../../redux/features/hotels/roomsSlice";

// Components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import {VariableSizeList} from "react-window";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 70;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const RoomComboBox = ({hotel_id, defaultValue, onChange, readOnly}) => {
  // Local state
  const [value, setValue] = useState(defaultValue || null);

  // Redux
  const dispatch = useDispatch();
  const {roomsList, isLoading, error} = useSelector(state => state.rooms);

  useEffect(() => {
    if (hotel_id && Number.isInteger(hotel_id)) {
      // Load rooms for that hotel
      dispatch(fetchRooms(hotel_id));
    }
  }, [dispatch, hotel_id]);

  useEffect(() => {
    if (value) {
      onChange(value);
    } else {
      onChange(null);
    }
  }, [onChange, value]);

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        style={{
          display: "flex",
          alignItems: "flex-end",
          height: "100%",
        }}
        component="div">
        There was an error loading the Room Module.
      </Typography>
    );
  }

  return (
    <>
      {!isLoading ? (
        <>
          {roomsList.length > 0 ? (
            <Autocomplete
              id="room-select"
              options={roomsList}
              autoHighlight
              clearOnEscape
              loading={isLoading}
              getOptionLabel={roomEl => roomEl.name}
              value={value && roomsList.find(roomEl => roomEl.id === value.id)}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              disabled={readOnly}
              disableListWrap
              ListboxComponent={ListboxComponent}
              renderOption={roomEl => <>{roomEl.name}</>}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Choose a Room"
                  fullWidth
                  required
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          ) : (
            <Typography
              variant="body1"
              color="error"
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "100%",
              }}
              component="div">
              There are no rooms available for that Hotel. Please select another
              one.
            </Typography>
          )}
        </>
      ) : (
        <Skeleton variant="rect" width={"100%"} height={48} animation="wave" />
      )}
    </>
  );
};

export default RoomComboBox;
