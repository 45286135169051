import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/media/";

const mediaInitialState = {
  mediaList: [],
  imageList: [],
  videoList: [],
  currentPageMedia: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}

function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const media = createSlice({
  name: "media",
  initialState: mediaInitialState,
  reducers: {
    getFileStart: startLoading,
    getFilesStart: startLoading,
    uploadFilesStart: startLoading,
    editFileStart: startLoading,
    deleteFileStart: startLoading,
    getFileSuccess(state, {payload}) {
      state.currentPageMedia = payload;
      state.isLoading = false;
      state.error = null;
    },
    geFilesSuccess(state, {payload}) {
      if (payload.type === "all") {
        state.mediaList = payload.list;
      } else if (payload.type === "image") {
        state.imageList = payload.list;
      } else if (payload.type === "video") {
        state.videoList = payload.list;
      }
      state.isLoading = false;
      state.error = null;
    },
    uploadFilesSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editFileSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteFileSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getFileFailure: loadingFailed,
    geFilesFailure: loadingFailed,
    uploadFilesFailure: loadingFailed,
    editFileFailure: loadingFailed,
    deleteFileFailure: loadingFailed,
  },
});

export const {
  getFileStart,
  getFilesStart,
  uploadFilesStart,
  editFileStart,
  deleteFileStart,
  getFileSuccess,
  geFilesSuccess,
  uploadFilesSuccess,
  editFileSuccess,
  deleteFileSuccess,
  getFileFailure,
  geFilesFailure,
  uploadFilesFailure,
  editFileFailure,
  deleteFileFailure,
} = media.actions;

export default media.reducer;

export const fetchFiles = (type = "all") => async (dispatch, getState) => {
  try {
    dispatch(getFilesStart());
    const res = await axios.get(`${url}?type=${type}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(geFilesSuccess({type, list: res.data.response}));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(geFilesFailure(errorObj));
  }
};

export const fetchFile = id => async (dispatch, getState) => {
  try {
    dispatch(getFileStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getFileSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getFileFailure(errorObj));
  }
};

export const uploadFiles = files => async (dispatch, getState) => {
  try {
    dispatch(uploadFilesStart());
    const res = await axios.post(url, files, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(uploadFilesSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(uploadFilesFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editFile = fileObj => async (dispatch, getState) => {
  try {
    dispatch(editFileStart());
    const res = await axios.put(url, fileObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editFileSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editFileFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteFile = fileId => async (dispatch, getState) => {
  try {
    dispatch(deleteFileStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: fileId},
    });
    if (res.data.error) {
      throw res.data;
    }
    dispatch(deleteFileSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    const errorMessage =
      err.content === "ER_ROW_IS_REFERENCED"
        ? "File couldn't be deleted because is being used."
        : "File couldn't be deleted. Please try again later.";
    dispatch(
      deleteFileFailure({
        errorCode: err.status,
        errorMessage,
      })
    );
    return {error: true, errorMessage};
  }
};
