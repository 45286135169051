import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchCustomPackage,
  editCustomPackage,
  deleteCustomPackage,
} from "../../../redux/features/packages/customPackagesSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../../UI/MainPaper";
import CustomPackagesForm from "./CustomPackagesForm";
import FullContainerProgress from "../../UI/FullContainerProgress";
import DeleteButton from "../../Buttons/DeleteButton";
import DeleteDialog from "../../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../../Navigation/BreadcrumbsWithEdit";

const CustomPackagesEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageCustomPackage, isLoading, error} = useSelector(
    state => state.customPackages
  );
  const {user, userPermission} = useSelector(state => state.auth);
  const {write, delete: deletePermission} = userPermission.packages;

  const formattedValues = {
    name: currentPageCustomPackage.name,
    package_status: currentPageCustomPackage.package_status,
    hotel_id: currentPageCustomPackage.hotel_id,
    hotel: currentPageCustomPackage.hotel,
    room_id: currentPageCustomPackage.room_id,
    room: currentPageCustomPackage.room,
    code_key: currentPageCustomPackage.code_key,
    coupon_id: currentPageCustomPackage.coupon_id,
    coupon: currentPageCustomPackage.coupon,
    adults: currentPageCustomPackage.adults,
    children: currentPageCustomPackage.children,
    babies: currentPageCustomPackage.babies,
    seniors: currentPageCustomPackage.seniors,
    enabled: currentPageCustomPackage.enabled ? true : false,
    custom_rate: currentPageCustomPackage.custom_rate ? true : false,
    check_in: moment(currentPageCustomPackage.check_in),
    check_out: moment(currentPageCustomPackage.check_out),
    addons: currentPageCustomPackage.addons
      ? currentPageCustomPackage.addons.filter(
          addon => addon.use_in === "regular"
        )
      : [],
    customAddons: currentPageCustomPackage.addons
      ? currentPageCustomPackage.addons.filter(
          addon => addon.use_in === "custom"
        )
      : [],
    products: currentPageCustomPackage.products,

    hotelRoom: {
      hotel: currentPageCustomPackage.hotel,
      room: currentPageCustomPackage.room,
    },
    addonsProducts: {
      addons: currentPageCustomPackage.addons
        ? currentPageCustomPackage.addons.filter(
            addon => addon.use_in === "regular"
          )
        : [],
      products: currentPageCustomPackage.products,
    },
  };

  let breadcrumbNameMap = {
    "/packages": "Packages",
    "/packages/customs": "Custom Packages",
  };
  breadcrumbNameMap[`/packages/customs/${id}`] = currentPageCustomPackage.name;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchCustomPackage(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const customPackageObj = {
      custom_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editCustomPackage(customPackageObj));
    if (!res.error) {
      history.push({
        pathname: "/packages/customs",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${customPackageObj.name} were saved successfully!`,
          snackbarLink: `/packages/customs/${customPackageObj.custom_id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteCustomPackage(id));
    if (!res.error) {
      history.push({
        pathname: "/packages/customs",
        state: {
          showSuccess: true,
          snackbarContent: `The Custom Package was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if addon doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageCustomPackage.name}
                </Typography>
                <CustomPackagesForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Custom Package
            </DeleteButton>
          </Grid>
        )}
      </Grid>

      {!!deletePermission && (
        <DeleteDialog
          dialogTitle={`Are you sure you want to delete this Custom Package? There is no going back after this ${
            user ? `${user}!` : "!"
          }`}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default CustomPackagesEdit;
