import React, {useState, useEffect} from "react";

// Styles
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

// Routing
import {withRouter} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const Home = ({location}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paperContainer, classes.fixedHeight);

  // Local State
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      setUnauthorized(location.state.unauthorized);
    }
  }, [location]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Typography component="h1" variant="h3" gutterBottom>
            {` This is Home :)`}
          </Typography>
        </Paper>
      </Grid>
      <Snackbar
        open={unauthorized}
        autoHideDuration={6000}
        onClose={() => setUnauthorized(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setUnauthorized(false)}
          severity="warning">
          You don't have the necessary permissions to access that page!
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};

export default withRouter(Home);
