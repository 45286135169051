import React, {useState, useEffect} from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchIcons} from "../../redux/features/icons/iconsSlice";
import {fetchSettings} from "../../redux/features/settings/settingsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormatCustom from "../FormFields/NumberFormatCustom";
import InputAdornment from "@material-ui/core/InputAdornment";
import UploadFiles from "../FormFields/UploadFiles";
import GalleryComboBox from "../FormFields/GalleryComboBox";
import AmenitiesComboBox from "../FormFields/AmenitiesComboBox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconTextInputs from "../FormFields/IconTextInputs";
import Box from "@material-ui/core/Box";
import RichTextEditor from "../FormFields/RichTextEditor";
import PermalinkTextField from "../FormFields/PermalinkTextField";
import DeleteButton from "../Buttons/DeleteButton";
import CheckboxWithState from "../FormFields/CheckboxWithState";

// Icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const HotelsForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    hotelCode: "",
    permalink: "",
    email: [""],
    description: "",
    short_description: "",
    policies: "",
    tripadvisor: "",
    tax: 0,
    commission: 0,
    hotel_status: "Published",
    stars: 1,
    featured: 0,
    beachfront: 0,
    //   Location
    placeid: "",
    latitude: 0,
    longitude: 0,
    address: "",
    city: "",
    state: "",
    zip: "",
    //   Images
    // This is used to display the selected picture in the edit form
    photoObj: null,
    brandLogoObj: null,
    gallery: null,
    //   Dynamic inputs
    badges_and_services: [],
    top_amenities: [],
    amenities: [],
    meeting_amenities: [],
  },
  readOnly = false,
}) => {
  //Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({defaultValues});

  // Redux
  const dispatch = useDispatch();

  const {
    settingsList,
    isLoading: settingsLoading,
    error: settingsError,
  } = useSelector(state => state.settings);
  const {amenities_options} = settingsList;
  const {iconsList, isLoading: iconsLoading, error: iconsError} = useSelector(
    state => state.icons
  );

  const formSubmit = data => {
    const formattedAmenities = amenities_options.value.flatMap((el, i) => {
      if (data.amenities[i].list.length > 0) {
        return {
          title: el.title,
          list: data.amenities[i].list,
        };
      } else {
        return [];
      }
    });

    const {
      photoObj,
      brandLogoObj,
      gallery,
      latitude,
      longitude,
      beachfront,
      featured,
      ...restOfData
    } = data;

    let formattedData = {
      ...restOfData,
      beachfront: beachfront ? 1 : 0,
      featured: featured ? 1 : 0,
      amenities: formattedAmenities,
      photo: photoObj ? photoObj.photoId : null,
      brand_logo: brandLogoObj ? brandLogoObj.photoId : null,
      gallery_id: gallery.id,
      location: [Number(latitude), Number(longitude)],
    };
    onSubmit(formattedData);
  };

  useEffect(() => {
    // Load Icons and settings
    dispatch(fetchIcons());
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={3}>
        {/**********************************************************************************************************************************/}
        {/************************************************************ Hotel Information ***************************************************/}
        {/**********************************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <Typography variant="h6" component="label">
              Hotel Information
            </Typography>
          </Box>
          {/* Hotel Name */}
          <TextField
            inputRef={register}
            required
            name="name"
            label="Name"
            autoComplete="hotel-name"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Code */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            required
            name="hotelCode"
            label="Hotel Code"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Stars */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`stars`}
            control={control}
            defaultValue={defaultValues.stars}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Stars</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Hotel Permalink */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="permalink"
            defaultValue={defaultValues.permalink}
            render={props => (
              <PermalinkTextField
                value={props.value}
                required
                label="Hotel Permalink"
                onChange={props.onChange}
                startAdornment={process.env.REACT_APP_DOMAIN + "/hotels/"}
                fullWidth
                readOnly={readOnly}
              />
            )}
          />
        </Grid>

        {/* Hotel Emails */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="label">
              Emails
            </Typography>
          </Grid>

          <Controller
            name={`email`}
            control={control}
            defaultValue={defaultValues.email}
            render={props => (
              <EmailsFields
                value={props.value}
                readOnly={readOnly}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Hotel Description */}
        <Controller
          name={`description`}
          control={control}
          defaultValue={defaultValues.description}
          render={props => (
            <Grid item xs={12}>
              <RichTextEditor
                label="Description"
                withPictureUploader
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                required
                height={400}
              />
            </Grid>
          )} // props contains: onChange, onBlur and value
        />

        {/* Hotel Short Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="short_description"
            defaultValue={defaultValues.short_description}
            label="Short Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Policies */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="policies"
            defaultValue={defaultValues.policies}
            label="Policies"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Tripadvisor */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="tripadvisor"
            defaultValue={defaultValues.tripadvisor}
            label="Trip Advisor"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Tax Rate */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Tax Rate"
            name="tax"
            defaultValue={defaultValues.tax}
            inputProps={{
              isAllowed: values => {
                const {formattedValue, floatValue} = values;
                return formattedValue === "" || floatValue <= 100;
              },
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Hotel Commission */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            required
            label="Commission"
            name="commission"
            disabled
            defaultValue={defaultValues.commission}
            fullWidth
            inputProps={{
              isAllowed: values => {
                const {formattedValue, floatValue} = values;
                return formattedValue === "" || floatValue <= 100;
              },
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Hotel Status */}
        <Grid item xs={12}>
          <Controller
            name={`hotel_status`}
            control={control}
            defaultValue={defaultValues.hotel_status}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Published"}>Published</MenuItem>
                  <MenuItem value={"Private"}>Private</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Hotel Featured */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`featured`}
            control={control}
            defaultValue={defaultValues.featured}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Featured"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Hotel Beachfront */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`beachfront`}
            control={control}
            defaultValue={defaultValues.beachfront}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Beachfront"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/**********************************************************************************************************************************/}
        {/************************************************************ Hotel Location ******************************************************/}
        {/**********************************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Hotel Location
            </Typography>
          </Box>
        </Grid>

        {/* Hotel PlaceId */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="placeid"
            label="Place ID"
            fullWidth
            InputProps={{
              readOnly,
            }}
            helperText={
              <Link
                target="_blank"
                rel="noopener"
                color="secondary"
                href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder">
                Go to PlaceID Finder
              </Link>
            }
          />
        </Grid>

        {/* Hotel Latitude */}
        <Grid item xs={6}>
          <TextField
            inputRef={register}
            required
            name="latitude"
            label="Latitude"
            fullWidth
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Longitude */}
        <Grid item xs={6}>
          <TextField
            inputRef={register}
            required
            name="longitude"
            label="Longitude"
            fullWidth
            type="number"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Address */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="address"
            label="Address"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel City */}
        <Grid item xs={12} lg={4}>
          <TextField
            inputRef={register}
            required
            name="city"
            label="City"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel State */}
        <Grid item xs={12} lg={4}>
          <TextField
            inputRef={register}
            required
            name="state"
            label="State"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Zip */}
        <Grid item xs={12} lg={4}>
          <TextField
            inputRef={register}
            required
            name="zip"
            label="Zip"
            multiline
            fullWidth
            InputProps={{
              readOnly,
              type: "text",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/**********************************************************************************************************************************/}
        {/************************************************************ Hotel Images *************************************************/}
        {/**********************************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Hotel Images
            </Typography>
          </Box>
        </Grid>
        {/* Hotel Image  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Hotel Photo"
                buttonLabel="Set Hotel Photo"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>
        {/* Hotel Brand Image  */}
        <Grid item xs={12} lg={6}>
          <Grid item xs={12}>
            <Controller
              name={`brandLogoObj`}
              control={control}
              defaultValue={defaultValues.brandLogoObj}
              render={props => (
                <UploadFiles
                  label="Hotel Logo"
                  buttonLabel="Set Hotel Logo"
                  singleFile
                  onSave={photosArray => {
                    props.onChange(photosArray[0]);
                  }}
                  readOnly={readOnly}
                  defaultValue={props.value}
                />
              )}
            />
          </Grid>
        </Grid>
        {/* Hotel Gallery  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`gallery`}
            control={control}
            defaultValue={defaultValues.gallery}
            render={props => (
              <GalleryComboBox
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>
        {/**********************************************************************************************************************************/}
        {/************************************************************ Badges and Services *************************************************/}
        {/**********************************************************************************************************************************/}
        {/* Badges and services */}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Badges And Services
            </Typography>
          </Box>
        </Grid>
        <Controller
          name={`badges_and_services`}
          control={control}
          defaultValue={false}
          render={props => (
            <BadgesAndServices
              onChange={props.onChange}
              defaultValue={defaultValues.badges_and_services}
              iconsList={iconsList}
              iconsLoading={iconsLoading}
              iconsError={iconsError}
              readOnly={readOnly}
            />
          )} // props contains: onChange, onBlur and value
        />
        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>
        {/**********************************************************************************************************************************/}
        {/************************************************************ Hotel Top Amenities ***************************************************/}
        {/**********************************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8} mb={2}>
            <Typography variant="h6" component="label">
              Top Amenities
            </Typography>
          </Box>
        </Grid>
        <Controller
          name={`top_amenities`}
          control={control}
          defaultValue={false}
          render={props => (
            <TopAmenities
              onChange={props.onChange}
              defaultValue={defaultValues.top_amenities}
              iconsList={iconsList}
              iconsLoading={iconsLoading}
              iconsError={iconsError}
              readOnly={readOnly}
            />
          )} // props contains: onChange, onBlur and value
        />
        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>
        {/**********************************************************************************************************************************/}
        {/************************************************************ Hotel Amenities ***************************************************/}
        {/**********************************************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8} mb={2}>
            <Typography variant="h6" component="label">
              Amenities
            </Typography>
          </Box>
        </Grid>

        {amenities_options && amenities_options.value.length > 0 && (
          <>
            {amenities_options.value.map(({title, list}, i) => (
              <Controller
                key={i}
                name={`amenities[${i}].list`}
                control={control}
                defaultValue={false}
                render={props => (
                  <Grid item xs={12} key={i}>
                    <AmenitiesComboBox
                      fieldLabel={title}
                      amenitiesList={list}
                      defaultValue={
                        defaultValues.amenities.find(obj => obj.title === title)
                          ? defaultValues.amenities.find(
                              obj => obj.title === title
                            ).list
                          : []
                      }
                      onChange={e => props.onChange(e)}
                      readOnly={readOnly}
                      loading={settingsLoading}
                      error={settingsError}
                    />
                  </Grid>
                )} // props contains: onChange, onBlur and value
              />
            ))}
          </>
        )}

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

/**********************************************************************************************************************************/
/************************************************************ Custom Fields ***************************************************/
/**********************************************************************************************************************************/

function EmailsFields({onChange, value, readOnly = false}) {
  const [email, setEmail] = useState(value && value.length > 0 ? value : [""]);

  const addEmail = () => {
    setEmail([...email, ""]);
  };
  const handleEmailChange = (index, newValue) => {
    const updatedEmailArray = [...email];
    updatedEmailArray[index] = newValue;

    setEmail(updatedEmailArray);
  };
  const handleEmailRemove = index => {
    const updatedBS = email.filter((value, idx) => idx !== index);
    setEmail(updatedBS);
  };

  useEffect(() => {
    onChange(email);
  }, [onChange, email]);

  return (
    <>
      {email.map((emailEl, i) => (
        <React.Fragment key={`email-${i}`}>
          <Grid item xs={10}>
            <TextField
              id="email"
              key={`email-${i}`}
              required
              type="email"
              fullWidth
              label={`Email Address ${i + 1}`}
              name="email"
              autoComplete="email"
              value={emailEl}
              InputProps={{
                readOnly,
              }}
              onChange={e => handleEmailChange(i, e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            {email.length > 1 && !readOnly && (
              <Box mb={1} ml={2} display="inline-block" color="error.main">
                <DeleteButton
                  aria-label="delete"
                  onClick={() => handleEmailRemove(i)}
                  size="large"
                  startIcon={<DeleteIcon />}>
                  Delete
                </DeleteButton>
              </Box>
            )}
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        {!readOnly && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => addEmail()}>
            Add Email
          </Button>
        )}
      </Grid>
    </>
  );
}

function BadgesAndServices({
  onChange,
  defaultValue = [],
  readOnly = false,
  iconsList,
  iconsLoading,
  iconsError,
}) {
  // Dynamic input initial structure
  const blankBadgesAndServices = {
    icon: {id: null, path: ""},
    text: "",
  };
  const [badges_and_services, setBadgesAndServices] = useState(
    defaultValue.length > 0 ? defaultValue : [{...blankBadgesAndServices}]
  );

  useEffect(() => {
    onChange(badges_and_services);
  }, [onChange, badges_and_services]);

  const addBS = () => {
    setBadgesAndServices([...badges_and_services, {...blankBadgesAndServices}]);
  };
  const handleBadgesAndServicesChange = (type, index, newValue) => {
    const updatedBS = [...badges_and_services];
    updatedBS[index] = {
      ...updatedBS[index],
      [type]: type === "icon" ? {...newValue} : newValue,
    };

    setBadgesAndServices(updatedBS);
  };
  const handleBadgesAndServicesRemove = index => {
    const updatedBS = badges_and_services.filter((value, idx) => idx !== index);
    setBadgesAndServices(updatedBS);
  };

  return (
    <Grid item xs={12}>
      {badges_and_services.map((bsElement, i) => (
        <IconTextInputs
          id="badges-services"
          key={`bs-${i}`}
          index={i}
          readOnly={readOnly}
          stateArray={badges_and_services}
          handleChange={handleBadgesAndServicesChange}
          handleRemove={handleBadgesAndServicesRemove}
          iconsList={iconsList}
          iconsLoading={iconsLoading}
          iconsError={iconsError}
        />
      ))}
      {!readOnly && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => addBS()}>
          Add Item
        </Button>
      )}
    </Grid>
  );
}

function TopAmenities({
  onChange,
  defaultValue = [],
  readOnly = false,
  iconsList,
  iconsLoading,
  iconsError,
}) {
  // Dynamic input initial structure
  const blankTopAmenities = {icon: {id: null, path: ""}, text: ""};
  const [top_amenities, setTopAmenities] = useState(
    defaultValue.length > 0 ? defaultValue : [{...blankTopAmenities}]
  );

  useEffect(() => {
    onChange(top_amenities);
  }, [onChange, top_amenities]);

  const addTopAmenity = () => {
    setTopAmenities([...top_amenities, {...blankTopAmenities}]);
  };
  const handleTopAmenitiesChange = (type, index, newValue) => {
    const updatedAmenities = [...top_amenities];
    updatedAmenities[index] = {
      ...updatedAmenities[index],
      [type]: type === "icon" ? {...newValue} : newValue,
    };
    setTopAmenities(updatedAmenities);
  };
  const handleTopAmenitiesRemove = index => {
    const updatedAmenities = top_amenities.filter(
      (value, idx) => idx !== index
    );
    setTopAmenities(updatedAmenities);
  };

  return (
    <Grid item xs={12}>
      {top_amenities.map((amenityEl, i) => (
        <IconTextInputs
          id={`top-amenities-${i}`}
          key={`ta-${i}`}
          readOnly={readOnly}
          index={i}
          stateArray={top_amenities}
          handleChange={handleTopAmenitiesChange}
          handleRemove={handleTopAmenitiesRemove}
          iconsList={iconsList}
          iconsLoading={iconsLoading}
          iconsError={iconsError}
        />
      ))}

      {!readOnly && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => addTopAmenity()}>
          Add Amenity
        </Button>
      )}
    </Grid>
  );
}

export default HotelsForm;
