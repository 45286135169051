import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchAddon,
  editAddon,
  deleteAddon,
} from "../../redux/features/addons/addonsSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import AddonForm from "./AddonForm";
import FullContainerProgress from "../UI/FullContainerProgress";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";

const AddonsEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageAddon, isLoading, error} = useSelector(
    state => state.addons
  );
  // User Permission from redux
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.addons;

  const formattedValues = {
    name: currentPageAddon.name,
    description: currentPageAddon.description,
    start: moment(currentPageAddon.start),
    end: moment(currentPageAddon.end),
    type: currentPageAddon.type,
    age_id: currentPageAddon.age_id,
    price: currentPageAddon.price,
    tax_rate: currentPageAddon.tax_rate,
    iconId: currentPageAddon.icon_id,
    icon: {icon_id: currentPageAddon.icon_id, path: currentPageAddon.icon},
    status: currentPageAddon.status,
    use_in: currentPageAddon.use_in,

    // This is used to display the selected picture in the edit form
    photoObj: {
      photoId: currentPageAddon.photoId,
      photoName: currentPageAddon.photoName,
    },
  };

  let breadcrumbNameMap = {
    "/addons": "Addons",
  };
  breadcrumbNameMap[`/addons/${id}`] = currentPageAddon.name;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchAddon(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);
  useEffect(() => {
    setEditMode(write);
  }, [write]);
  const onSubmit = async values => {
    const addonObj = {
      id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editAddon(addonObj));
    if (!res.error) {
      history.push({
        pathname: "/addons",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${addonObj.name} were saved successfully!`,
          snackbarLink: `/addons/${addonObj.id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteAddon(id));
    if (!res.error) {
      history.push({
        pathname: "/addons",
        state: {
          showSuccess: true,
          snackbarContent: `The Addon was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if addon doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading && !currentPageAddon.name ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageAddon.name}
                </Typography>
                <AddonForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Addon
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Addon?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default AddonsEdit;
