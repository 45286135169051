import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchHotel,
  editHotel,
  deleteHotel,
} from "../../redux/features/hotels/hotelsSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import HotelsForm from "./HotelsForm";
import FullContainerProgress from "../UI/FullContainerProgress";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteDialog from "../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../Navigation/BreadcrumbsWithEdit";
import RoomsTableComponent from "./Rooms/RoomsTableComponent";

const HotelsEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageHotel, isLoading, error} = useSelector(
    state => state.hotels
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.hotels;

  const formattedValues = {
    name: currentPageHotel.name,
    hotelCode: currentPageHotel.hotelCode,
    permalink: currentPageHotel.permalink,
    email: currentPageHotel.email,
    description: currentPageHotel.description,
    short_description: currentPageHotel.short_description,
    policies: currentPageHotel.policies,
    tripadvisor: currentPageHotel.tripadvisor,
    tax: currentPageHotel.tax,
    commission: currentPageHotel.commission,
    hotel_status: currentPageHotel.hotel_status,
    stars: currentPageHotel.stars,
    featured: currentPageHotel.featured,
    beachfront: currentPageHotel.beachfront,
    //   Location
    placeid: currentPageHotel.placeid,
    location: currentPageHotel.location,
    latitude: currentPageHotel.location ? currentPageHotel.location[0] : 0,
    longitude: currentPageHotel.location ? currentPageHotel.location[1] : 0,
    address: currentPageHotel.address,
    city: currentPageHotel.city,
    state: currentPageHotel.state,
    zip: currentPageHotel.zip,
    //   Images
    // This is used to display the selected picture in the edit form
    photoObj: {
      photoId: currentPageHotel.photo,
      photoName: currentPageHotel.photoName,
    },
    brandLogoObj: {
      photoId: currentPageHotel.brand_logo,
      photoName: currentPageHotel.brandPhotoName,
    },
    brand_logo: currentPageHotel.brand_logo,
    gallery_id: currentPageHotel.gallery_id,
    gallery: {id: currentPageHotel.gallery_id},
    //   Dynamic inputs
    badges_and_services: currentPageHotel.badges_and_services,
    top_amenities: currentPageHotel.top_amenities,
    amenities: currentPageHotel.amenities,
    meetings_amenities: currentPageHotel.meetings_amenities,
  };

  let breadcrumbNameMap = {
    "/hotels": "Hotels",
  };
  breadcrumbNameMap[`/hotels/${id}`] = currentPageHotel.name;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchHotel(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const hotelObj = {
      id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editHotel(hotelObj));
    if (!res.error) {
      history.push({
        pathname: "/hotels",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${hotelObj.name} were saved successfully!`,
          snackbarLink: `/hotels/${hotelObj.id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteHotel(id));
    if (!res.error) {
      history.push({
        pathname: "/hotels",
        state: {
          showSuccess: true,
          snackbarContent: `The Hotel was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if hotel doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageHotel.name}
                </Typography>
                <HotelsForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>

        {/* Rooms Table */}
        {isLoading ? null : (
          <RoomsTableComponent
            hotelId={id}
            writePermission={write}
            deletePermission={deletePermission}
          />
        )}

        {/* Delete Button  */}
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Hotel
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Hotel?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default HotelsEdit;
