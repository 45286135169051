import React, {useState} from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MuiAlert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import NumberFormatCustom from "../NumberFormatCustom";
import IntegerInput from "../IntegerInput";

// Icons
import tableIcons from "../../Table/TableIcons";

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  resize: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

// Need to spread array so it doesn't fail
const formatData = data => {
  return data.map(o => ({
    ...o,
  }));
};

const ProductsTableEdit = ({originalData, onSubmit}) => {
  //Styles
  const classes = useStyles();

  // Local State
  const [data, setData] = useState(formatData(originalData));
  const [alert, setAlert] = useState({
    open: false,
    content: "",
  });

  const validateData = newData => {
    const {name, price, quantity} = newData;

    let validateObj = {
      valid: true,
      content: "",
    };

    if (name && name !== "") {
      if (price > 0) {
        if (quantity > 0) {
        } else {
          validateObj = {
            valid: false,
            content: "Quantity needs to be larger than 0!",
          };
        }
      } else {
        validateObj = {
          valid: false,
          content: "Price needs to be larger than 0!",
        };
      }
    } else {
      validateObj = {
        valid: false,
        content: "Name cannot be empty!",
      };
    }

    return validateObj;
  };

  return (
    <Grid container item spacing={2}>
      {alert.open && (
        <Grid item xs={12}>
          <MuiAlert
            className={classes.alert}
            onClose={() =>
              setAlert({
                open: false,
                content: "",
              })
            }
            severity="error">
            {alert.content}
          </MuiAlert>
        </Grid>
      )}
      <Grid item xs={12}>
        <MaterialTable
          title="Products"
          icons={tableIcons}
          columns={[
            {
              title: "Name",
              field: "name",
              initialEditValue: "Product",
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              ),
            },
            {
              title: "Price",
              field: "price",
              type: "currency",
              headerStyle: {
                textAlign: "right",
              },
              initialEditValue: 1,
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  inputProps={{
                    thousandSeparator: true,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              ),
            },
            {
              title: "Tax",
              field: "tax",
              type: "numeric",
              render: rowData => <>{rowData.tax}%</>,
              initialEditValue: 1,
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  inputProps={{
                    isAllowed: values => {
                      const {formattedValue, floatValue} = values;
                      return formattedValue === "" || floatValue <= 100;
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                    inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            {
              title: "Quantity",
              field: "quantity",
              type: "numeric",
              initialEditValue: 1,
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  InputProps={{
                    inputComponent: IntegerInput,
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              ),
            },
            {
              title: "Frequency",
              field: "type",
              initialEditValue: "Daily/Person",
              editComponent: props => (
                <FormControl>
                  <Select
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    classes={{
                      select: classes.resize,
                    }}>
                    <MenuItem value={"Daily/Person"}>Daily/Person</MenuItem>
                    <MenuItem value={"One-Time/Person"}>
                      One-Time/Person
                    </MenuItem>
                    <MenuItem value={"Daily/In-Room"}>Daily/In-Room</MenuItem>
                    <MenuItem value={"One-Time/In-Room"}>
                      One-Time/In-Room
                    </MenuItem>
                  </Select>
                </FormControl>
              ),
            },
            {
              title: "Applies To",
              field: "age_restriction",
              initialEditValue: "All",
              editComponent: props => (
                <FormControl>
                  <Select
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    classes={{
                      select: classes.resize,
                    }}>
                    <MenuItem value={"Babies"}>Babies</MenuItem>
                    <MenuItem value={"Children"}>Children</MenuItem>
                    <MenuItem value={"Adults"}>Adults</MenuItem>
                    <MenuItem value={"Seniors"}>Seniors</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"All/No Babies"}>All/No Babies</MenuItem>
                  </Select>
                </FormControl>
              ),
            },
            {
              title: "Product Type",
              field: "product_type",
              render: rowData => (
                <>
                  {rowData.product_type.charAt(0).toUpperCase() +
                    rowData.product_type.slice(1)}
                </>
              ),
              initialEditValue: "addon",
              editComponent: props => (
                <FormControl>
                  <Select
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    classes={{
                      select: classes.resize,
                    }}>
                    <MenuItem value={"addon"}>Addon</MenuItem>
                    <MenuItem value={"upgrade"}>Upgrade</MenuItem>
                  </Select>
                </FormControl>
              ),
            },
          ]}
          data={data}
          options={{
            search: false,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: -1,
          }}
          editable={{
            onRowAddCancelled: rowData =>
              setAlert({
                open: false,
                content: "",
              }),
            onRowUpdateCancelled: rowData =>
              setAlert({
                open: false,
                content: "",
              }),
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const validateObj = validateData(newData);
                  if (validateObj.valid) {
                    setData([...data, newData]);
                    setAlert({
                      open: false,
                      content: "",
                    });

                    resolve();
                  } else {
                    setAlert({
                      open: true,
                      content: validateObj.content,
                    });
                    reject();
                  }
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const validateObj = validateData(newData);
                  if (validateObj.valid) {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);

                    setAlert({
                      open: false,
                      content: "",
                    });

                    resolve();
                  } else {
                    setAlert({
                      open: true,
                      content: validateObj.content,
                    });
                    reject();
                  }
                }, 1000);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);

                  resolve();
                }, 1000);
              }),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mb={3} clone>
          <Button
            style={{width: "100%"}}
            color="primary"
            variant="contained"
            size="large"
            onClick={() => onSubmit(data)}>
            Submit Changes
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductsTableEdit;
