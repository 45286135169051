import React, {useState, useEffect} from "react";

// utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchOrder,
  editOrder,
  sendEmail,
} from "../../../redux/features/sales/ordersSlice";
import {createCharge} from "../../../redux/features/sales/chargesSlice";

// Routing
import {
  useParams,
  useHistory,
  Redirect,
  Link as RouterLink,
} from "react-router-dom";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {green, red, yellow} from "@material-ui/core/colors";

// Components
import TransactionsTableComponent from "./TransactionsTableComponent";
import RatesTableComponent from "./RatesTableComponent";
import ProductsTableComponent from "./ProductsTableComponent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "../../UI/MainPaper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FullContainerProgress from "../../UI/FullContainerProgress";
import Breadcrumbs from "../../Navigation/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ProductsTableEdit from "../../FormFields/TableInputs/ProductsTableEdit";
import RatesTableEdit from "../../FormFields/TableInputs/RatesTableEdit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomerComboBox from "../../FormFields/CustomerComboBox";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import LaunchIcon from "@material-ui/icons/Launch";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AddIcon from "@material-ui/icons/Add";
import ChargeCreateDialog from "./ChargeCreateDialog";

const useStyles = makeStyles(theme => ({
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
  paperContainer: {
    padding: theme.spacing(3),
    position: "relative",
    minHeight: "100px",
    height: "100%",
  },
  chipsContainer: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  greenAvatar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redAvatar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  yellowAvatar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

// Panels
const StatusChips = ({orderObj, onStatusSubmit}) => {
  // Styles
  const classes = useStyles();

  const {order_status, type} = orderObj;

  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [status, setStatus] = useState(order_status);

  // Status variables
  //succeeded, pending, or failed.
  const statusVars =
    order_status === "COMPLETE"
      ? {
          icon: <DoneIcon />,
          className: classes.greenAvatar,
        }
      : order_status === "CANCELED"
      ? {
          icon: <ErrorIcon />,
          className: classes.redAvatar,
        }
      : {
          icon: <HelpIcon className={classes.yellowAvatar} />,
          className: classes.yellowAvatar,
        };

  return (
    <>
      <Grid item xs={12} className={classes.chipsContainer}>
        {order_status && (
          <Chip
            icon={statusVars.icon}
            className={statusVars.className}
            label={order_status.toUpperCase()}
          />
        )}
        {type && <Chip color="secondary" label={type.toUpperCase()} />}
        {!isEditMode && (
          <Button
            size="small"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setEditMode(true)}>
            Edit Status
          </Button>
        )}
      </Grid>
      {isEditMode && (
        <>
          <Grid item xs={12}>
            <FormControl>
              <Select value={status} onChange={e => setStatus(e.target.value)}>
                <MenuItem value={"COMPLETE"}>COMPLETE</MenuItem>
                <MenuItem value={"CANCELED"}>CANCELED</MenuItem>
                <MenuItem value={"PENDING"}>PENDING</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12}>
            <Button onClick={() => setEditMode(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onStatusSubmit(status)}>
              Submit
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

const DatePanel = ({datesArray}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={1}>
          {datesArray.map(({label, date}, i) => (
            <Grid item xs={12} key={`date-${i}`}>
              <Typography component="h2" variant="body1">
                {label}
              </Typography>
              <Typography variant="h5">
                {moment(date).tz("America/Chicago").format("lll")}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

const SendEmailPanel = ({onSubmit, isLoading = false}) => {
  // Local State

  const [sendGuest, setSendGuest] = useState(true);
  const [sendHotel, setSendHotel] = useState(true);
  const [sendAdmin, setSendAdmin] = useState(true);

  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={12} md={3}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h3" variant="body1">
              Send Emails
            </Typography>
            <FormControlLabel
              className={classes.maxWidth}
              control={
                <Checkbox
                  onChange={() => setSendGuest(!sendGuest)}
                  value="sendGuest"
                  // disabled={readOnly}
                  checked={sendGuest}
                />
              }
              label="Send to Guest"
            />
            <FormControlLabel
              className={classes.maxWidth}
              control={
                <Checkbox
                  onChange={() => setSendHotel(!sendHotel)}
                  value="sendHotel"
                  // disabled={readOnly}
                  checked={sendHotel}
                />
              }
              label="Send to Hotel"
            />
            <FormControlLabel
              className={classes.maxWidth}
              control={
                <Checkbox
                  onChange={() => setSendAdmin(!sendAdmin)}
                  value="sendAdmin"
                  // disabled={readOnly}
                  checked={sendAdmin}
                />
              }
              label="Send to Admin"
            />
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() =>
                  onSubmit({
                    sendGuest,
                    sendHotel,
                    sendAdmin,
                  })
                }
                disabled={isLoading}>
                Send Emails
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const TotalAmountsPanel = ({amounts, type}) => {
  // Styles
  const classes = useStyles();
  return (
    <Grid item xs={12} md={2}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h3" variant="body1">
              Total
            </Typography>
            <Typography variant="h5" gutterBottom>
              ${" "}
              {type === "package"
                ? amounts.total.toFixed(2)
                : amounts.totalDue.toFixed(2)}
            </Typography>
            <Typography component="h3" variant="body1">
              Total Paid
            </Typography>
            <Typography variant="h5" gutterBottom>
              $ {amounts.totalPaid.toFixed(2)}
            </Typography>
            {amounts.totalRefunds > 0 && (
              <>
                <Typography component="h3" variant="body1">
                  Total Refunded
                </Typography>
                <Typography variant="h5" gutterBottom>
                  $ {amounts.totalRefunds.toFixed(2)}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const CustomerPanel = ({currentPageOrder, onCustomerSubmit}) => {
  // Styles
  const classes = useStyles();

  const {customer_name, customer_id} = currentPageOrder;

  // Local state
  const [isEditMode, setEditMode] = useState(false);
  const [customer, setCustomer] = useState({customer_name, customer_id});

  return (
    <Grid item xs={12} md={3}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          {!isEditMode ? (
            <>
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center">
                <Typography component="h3" variant="body1">
                  Customer
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => setEditMode(true)}>
                  Edit
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  {customer_name}
                </Typography>
                <Typography variant="body1">
                  <LinkRouter
                    to={`/sales/customers/${customer_id}`}
                    key={`/sales/customers/${customer_id}`}
                    className={classes.textWithIcon}>
                    <LaunchIcon />
                    Go to Customer page
                  </LinkRouter>
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <CustomerComboBox
                  defaultValue={customer}
                  onChange={setCustomer}
                />
              </Grid>
              <Grid container item xs={12} justify="space-between">
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={customer ? !customer.customer_id : true}
                  onClick={() => onCustomerSubmit(customer.customer_id)}>
                  Submit
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const PackageInfoPanel = ({packageObj, peopleArr, withCoupon = false}) => {
  // Styles
  const classes = useStyles();

  const {
    package_name,
    hotel_name,
    hotel_address,
    room_name,
    room_qty,
    night_stay,
    originals,
  } = packageObj;

  return (
    <Grid item xs={12} md={withCoupon ? 8 : 12}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography component="h2" variant="h5" gutterBottom>
              Package
            </Typography>
            <Typography gutterBottom>{package_name}</Typography>
            <Typography>People: </Typography>
            <List dense disablePadding>
              {peopleArr.flatMap(({identifier, qty}) => {
                if (qty < 1) return [];
                return (
                  <ListItem>
                    <ListItemText primary={`${qty} ${identifier}`} />
                  </ListItem>
                );
              })}
            </List>
            <Typography gutterBottom>{night_stay} night(s)</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography component="h3" variant="h5" gutterBottom>
              Hotel
            </Typography>
            <Typography gutterBottom>{hotel_name}</Typography>
            <Typography variant="caption" gutterBottom>
              {hotel_address}
            </Typography>
            <Typography variant="body1">
              <LinkRouter
                to={`/hotels/${originals.hotel_id}`}
                key={`/hotels/${originals.hotel_id}`}
                className={classes.textWithIcon}>
                <LaunchIcon />
                Go to Hotel page
              </LinkRouter>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography component="h3" variant="h5" gutterBottom>
              Room(s)
            </Typography>
            <Typography>
              {room_qty} x {room_name}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const CondoInfoPanel = ({condoObj, peopleArr, withCoupon = false}) => {
  // Styles
  const classes = useStyles();

  const {condo_address, condo_name, night_stay} = condoObj;

  return (
    <Grid item xs={12} md={withCoupon ? 4 : 8}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" gutterBottom>
              Condo Info
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{condo_name}</Typography>
            <Typography variant="subtitle2">
              <LocationOnIcon fontSize="small" />
              {condo_address}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>{night_stay} night(s)</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>People: </Typography>
            <List dense disablePadding>
              {peopleArr.flatMap(({identifier, qty}) => {
                if (qty < 1) return [];
                return (
                  <ListItem>
                    <ListItemText primary={`${qty} ${identifier}`} />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const CouponPanel = ({coupon}) => {
  // Styles
  const classes = useStyles();

  const {
    coupon_id,
    name,
    coupon_code,
    description,
    type,
    discount_amount,
  } = coupon;
  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" gutterBottom>
              Coupon
            </Typography>
            <Typography gutterBottom>{name.toUpperCase()}</Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
              mb={1}>
              <Typography>Code</Typography>
              <Typography>{coupon_code}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
              mb={1}>
              <Typography>Discount</Typography>
              <Typography color="error">
                {type === "currency" ? "–$ " : ""}
                {discount_amount.toFixed(2)}
                {type === "percentage" ? "% OFF" : ""}
              </Typography>
            </Box>

            {description && (
              <Typography variant="caption" gutterBottom>
                {description}
              </Typography>
            )}

            <Typography variant="body1">
              <LinkRouter
                to={`/coupons/${coupon_id}`}
                key={`/coupons/${coupon_id}`}
                className={classes.textWithIcon}>
                <LaunchIcon />
                Go to Coupon page
              </LinkRouter>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const AmountPanel = ({amountArray, panelLabel, md = 4}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={12} md={md}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h3" variant="body1">
              {panelLabel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {amountArray.map(({amount, label}) => {
              if (amount === 0) return null;
              return (
                <Box
                  key={label}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                  mb={2}>
                  <Typography variant="caption">{label}</Typography>
                  <Typography
                    variant="body1"
                    color={amount > 0 ? "textPrimary" : "error"}>
                    {amount >= 0 ? "" : "–"}$ {Math.abs(amount).toFixed(2)}
                  </Typography>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const OrdersView = () => {
  // Styles
  const classes = useStyles();

  // Local State
  const [isEditProductsMode, setEditProductsMode] = useState(false);
  const [isEditRatesMode, setEditRatesMode] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarEmail, setOpenSnackbarEmail] = useState(false);
  const [openChargeDialog, setOpenChargeDialog] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [snackbarContentEmail, setSnackbarContentEmail] = useState("");

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {
    currentPageOrder,
    isLoading,
    error,
    isLoadingEmail,
    errorEmail,
  } = useSelector(state => state.orders);

  const orderInfo = {
    check_in: currentPageOrder.check_in,
    check_out: currentPageOrder.check_out,
    package_name: currentPageOrder.package?.package_name,
    room_id: currentPageOrder.package?.originals.room_id,
    hotel_id: currentPageOrder.package?.originals.hotel_id,
    room_qty: currentPageOrder.package?.room_qty,
    ratesOverwrite: true,
  };

  let breadcrumbNameMap = {
    "/sales": "Sales",
    "/sales/orders": "Orders",
  };
  breadcrumbNameMap[`/sales/orders/${id}`] = id;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchOrder(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const handleCloseEmail = () => {
    setOpenSnackbarEmail(false);
    setSnackbarContentEmail("");
  };

  const onProductsSubmit = async values => {
    const orderObj = {
      products: values.map(
        ({
          name,
          price,
          tax,
          type,
          quantity,
          age_restriction,
          product_type,
        }) => ({
          name,
          price,
          tax,
          type,
          quantity,
          age_restriction,
          product_type,
        })
      ),
    };

    // Logic to show error or success
    const res = await dispatch(editOrder(id, orderObj));
    if (!res.error) {
      dispatch(fetchOrder(id));
      setSnackbarContent(
        "Your changes to the Order Products were saved successfully!"
      );
      setOpenSnackbar(true);
      setEditProductsMode(false);
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onStatusSubmit = async status => {
    const orderObj = {
      status,
    };

    // Logic to show error or success
    const res = await dispatch(editOrder(id, orderObj));
    if (!res.error) {
      dispatch(fetchOrder(id));
      setSnackbarContent(
        "Your changes to the Order Status were saved successfully!"
      );
      setOpenSnackbar(true);
      setEditRatesMode(false);
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onSendEmailSubmit = async emailObj => {
    // Logic to show error or success
    const res = await dispatch(sendEmail(id, emailObj));
    if (!res.error) {
      setSnackbarContentEmail("Your email(s) were sent successfully!");
      setOpenSnackbarEmail(true);
      setEditRatesMode(false);
    } else {
      setSnackbarContentEmail(
        "There was an error sending the emails. Please try again."
      );
      setOpenSnackbarEmail(true);
    }
  };
  const onCustomerSubmit = async customer_id => {
    const orderObj = {
      customer_id,
    };

    // Logic to show error or success
    const res = await dispatch(editOrder(id, orderObj));
    if (!res.error) {
      dispatch(fetchOrder(id));
      setSnackbarContent(
        "Your changes to the Order Customer were saved successfully!"
      );
      setOpenSnackbar(true);
      setEditRatesMode(false);
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onRatesSubmit = async values => {
    const orderObj = {
      info: {...values},
    };

    // Logic to show error or success
    const res = await dispatch(editOrder(id, orderObj));
    if (!res.error) {
      dispatch(fetchOrder(id));
      setSnackbarContent(
        "Your changes to the Order Rates were saved successfully!"
      );
      setOpenSnackbar(true);
      setEditRatesMode(false);
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onChargeSubmit = async values => {
    console.log(values);
    // Logic to show error or success
    const res = await dispatch(createCharge(values));
    if (!res.error) {
      dispatch(fetchOrder(id));
      setSnackbarContent("Your charge was done successfully!");
      setOpenSnackbar(true);
      setEditProductsMode(false);
      setOpenChargeDialog(false);
    }
  };

  // Redirect if order doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={6} alignItems="center">
          <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
        </Grid>

        {isLoading || !Object.entries(currentPageOrder).length ? (
          <Grid item xs={12}>
            <Paper className={classes.paperContainer}>
              <FullContainerProgress />
            </Paper>
          </Grid>
        ) : (
          <>
            {/* Chips  */}
            <StatusChips
              orderObj={currentPageOrder}
              onStatusSubmit={onStatusSubmit}
            />

            {/* Date Placed  */}
            <DatePanel
              date={currentPageOrder.date_order_placed}
              label="Date Placed"
              datesArray={[
                {
                  date: currentPageOrder.date_order_placed,
                  label: "Date Placed",
                },
                {
                  date: currentPageOrder.check_in,
                  label: "Check In",
                },
                {
                  date: currentPageOrder.check_out,
                  label: "Check Out",
                },
              ]}
            />

            {/* Total Amounts  */}
            <TotalAmountsPanel
              amounts={currentPageOrder.amounts}
              type={currentPageOrder.type}
            />

            {/* Customer  */}
            <CustomerPanel
              currentPageOrder={currentPageOrder}
              onCustomerSubmit={onCustomerSubmit}
            />

            {/* Send Email Panel   */}
            {currentPageOrder.type === "package" && (
              <SendEmailPanel
                onSubmit={onSendEmailSubmit}
                isLoading={isLoadingEmail}
              />
            )}

            {/* Amounts  */}
            {currentPageOrder.type === "package" ? (
              <>
                {/* General Amounts  */}
                <AmountPanel
                  panelLabel="General Amounts"
                  amountArray={[
                    {
                      label: "Subtotal",
                      amount: currentPageOrder.amounts.subtotal,
                    },
                    {label: "Tax", amount: currentPageOrder.amounts.tax},
                    {
                      label: "Discount",
                      amount: currentPageOrder.amounts.discount_amount * -1,
                    },
                    {label: "Total", amount: currentPageOrder.amounts.total},
                    {
                      label: "Total Paid",
                      amount: currentPageOrder.amounts.totalPaid,
                    },
                    {
                      label: "Total Refunded",
                      amount: currentPageOrder.amounts.totalRefunds,
                    },
                  ]}
                />

                {/* Rates Amounts  */}
                <AmountPanel
                  panelLabel="Rates Amounts"
                  amountArray={[
                    {
                      label: "Rates Subtotal",
                      amount: currentPageOrder.amounts.rates_subtotal,
                    },
                    {
                      label: "Rates Subtotal Discount",
                      amount:
                        currentPageOrder.amounts.discount_rates_subtotal * -1,
                    },
                    {
                      label: "Rates Tax",
                      amount: currentPageOrder.amounts.rates_tax,
                    },
                    {
                      label: "Rates Tax Discount",
                      amount: currentPageOrder.amounts.discount_rates_tax * -1,
                    },
                  ]}
                />

                {/* Addon Amounts  */}
                <AmountPanel
                  panelLabel="Addons Amounts"
                  amountArray={[
                    {
                      label: "Addons Subtotal",
                      amount: currentPageOrder.amounts.addons_subtotal,
                    },
                    {
                      label: "Addons Subtotal Discount",
                      amount:
                        currentPageOrder.amounts.discount_addons_subtotal * -1,
                    },
                    {
                      label: "Addons Tax",
                      amount: currentPageOrder.amounts.addons_tax,
                    },
                    {
                      label: "Addons Tax Discount",
                      amount: currentPageOrder.amounts.discount_addons_tax * -1,
                    },
                  ]}
                />
              </>
            ) : currentPageOrder.type === "condo" ? (
              //  General Amounts
              <AmountPanel
                panelLabel="Amounts"
                md={4}
                amountArray={[
                  {
                    label: "Total Rent",
                    amount: currentPageOrder.amounts.totalRent,
                  },
                  {label: "Tax", amount: currentPageOrder.amounts.totalTaxes},
                  {
                    label: "Discount",
                    amount: currentPageOrder.amounts.totalDiscount * -1,
                  },
                  {
                    label: "Total Due",
                    amount: currentPageOrder.amounts.totalDue,
                  },
                  {
                    label: "Min Total Due",
                    amount: currentPageOrder.amounts.minTotalDue,
                  },
                  {
                    label: "Total Paid",
                    amount: currentPageOrder.amounts.totalPaid,
                  },
                  {
                    label: "Total Refundable Fees",
                    amount: currentPageOrder.amounts.totalRefundableFees,
                  },
                  {
                    label: "Total Non Refundable Fees",
                    amount: currentPageOrder.amounts.totalNonrefundableFees,
                  },
                  {
                    label: "Total Refunded",
                    amount: currentPageOrder.amounts.totalRefunds,
                  },
                ]}
              />
            ) : null}

            {/* Rates Button */}
            {currentPageOrder.type === "package" ? (
              <Grid container item xs={12} justify="flex-end">
                <Box clone mt={3}>
                  {isEditRatesMode ? (
                    <Button
                      size="large"
                      startIcon={<CloseIcon />}
                      onClick={() => setEditRatesMode(false)}>
                      Cancel Edit
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      size="large"
                      startIcon={<EditIcon />}
                      onClick={() => setEditRatesMode(true)}>
                      Edit Rates
                    </Button>
                  )}
                </Box>
              </Grid>
            ) : null}

            {/* Info */}
            {currentPageOrder.type === "package" &&
              currentPageOrder.package &&
              !isEditRatesMode && (
                <PackageInfoPanel
                  packageObj={currentPageOrder.package}
                  peopleArr={currentPageOrder.people}
                  withCoupon={currentPageOrder.coupon}
                />
              )}

            {currentPageOrder.type === "condo" &&
              currentPageOrder.condo &&
              !isEditRatesMode && (
                <CondoInfoPanel
                  condoObj={currentPageOrder.condo}
                  withCoupon={currentPageOrder.coupon}
                  peopleArr={currentPageOrder.people}
                />
              )}

            {/* Coupon  */}
            {currentPageOrder.coupon && !isEditRatesMode && (
              <CouponPanel coupon={currentPageOrder.coupon} />
            )}

            {/* Rates  */}
            {currentPageOrder.type === "package" ? (
              <>
                {isEditRatesMode ? (
                  <RatesTableEdit
                    orderInfo={orderInfo}
                    onSubmit={onRatesSubmit}
                  />
                ) : (
                  <RatesTableComponent
                    isLoading={isLoading}
                    ratesArray={currentPageOrder.rates}
                  />
                )}
              </>
            ) : currentPageOrder.type === "condo" ? null : null}

            {/* Products  */}
            {currentPageOrder.type === "package" && (
              <>
                <Grid container item xs={12} justify="flex-end">
                  <Box clone mt={3}>
                    {isEditProductsMode ? (
                      <Button
                        size="large"
                        startIcon={<CloseIcon />}
                        onClick={() => setEditProductsMode(false)}>
                        Cancel Edit
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        size="large"
                        startIcon={<EditIcon />}
                        onClick={() => setEditProductsMode(true)}>
                        Edit Products
                      </Button>
                    )}
                  </Box>
                </Grid>
                {isEditProductsMode ? (
                  <ProductsTableEdit
                    originalData={currentPageOrder.products}
                    onSubmit={onProductsSubmit}
                  />
                ) : (
                  <ProductsTableComponent
                    isLoading={isLoading}
                    productsArray={currentPageOrder.products}
                  />
                )}
              </>
            )}

            {/* Transactions  */}
            <Grid container item xs={12} justify="flex-end">
              <Box clone mt={3}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenChargeDialog(true)}>
                  Add Charge
                </Button>
              </Box>
            </Grid>
            <TransactionsTableComponent
              isLoading={isLoading}
              transactionArray={currentPageOrder.transactions}
            />
          </>
        )}
      </Grid>

      <ChargeCreateDialog
        open={openChargeDialog}
        onClose={() => setOpenChargeDialog(false)}
        onSubmit={onChargeSubmit}
        readOnly={false}
        currentPageOrder={currentPageOrder}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openSnackbarEmail}
        autoHideDuration={6000}
        onClose={handleCloseEmail}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseEmail}
          severity={errorEmail ? "error" : "success"}>
          {snackbarContentEmail}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default OrdersView;
