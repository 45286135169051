import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/contacts";

const contactsInitialState = {
  contactsList: [],
  currentPageContact: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const contacts = createSlice({
  name: "contacts",
  initialState: contactsInitialState,
  reducers: {
    getContactStart: startLoading,
    getContactsStart: startLoading,
    editContactStart: startLoading,
    getContactSuccess(state, {payload}) {
      state.currentPageContact = payload;
      state.isLoading = false;
      state.error = null;
    },
    getContactsSuccess(state, {payload}) {
      state.contactsList = payload;
      state.isLoading = false;
      state.error = null;
    },

    editContactSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },

    getContactFailure: loadingFailed,
    getContactsFailure: loadingFailed,
    editContactFailure: loadingFailed,
  },
});

export const {
  getContactStart,
  getContactsStart,
  editContactStart,
  getContactSuccess,
  getContactsSuccess,
  editContactSuccess,
  getContactFailure,
  getContactsFailure,
  editContactFailure,
} = contacts.actions;

export default contacts.reducer;

export const fetchContacts = () => async (dispatch, getState) => {
  try {
    dispatch(getContactsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getContactsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getContactsFailure(errorObj));
  }
};

export const fetchContact = id => async (dispatch, getState) => {
  try {
    dispatch(getContactStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getContactSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getContactFailure(errorObj));
  }
};

export const editContact = contactObj => async (dispatch, getState) => {
  try {
    dispatch(editContactStart());
    const res = await axios.put(`${url}/resolved`, contactObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editContactSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editContactFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
