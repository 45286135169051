import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchSettings,
  editSettings,
} from "../../redux/features/settings/settingsSlice";

// Components
import FullContainerProgress from "../UI/FullContainerProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/MainPaper";
import Snackbar from "@material-ui/core/Snackbar";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//Forms
import ContentForm from "./Forms/ContentForm";
import GeneralSettingsForm from "./Forms/GeneralSettingsForm";
import SEOForm from "./Forms/SEOForm";
import APIKeysForm from "./Forms/APIKeysForm";
import RedirectsForm from "./Forms/RedirectsForm";
import EndpointsForm from "./Forms/EndpointsForm";
import ModulesForm from "./Forms/ModulesForm";
import BookingSettingsForm from "./Forms/BookingSettingsForm";
import NavigationForm from "./Forms/NavigationForm";
import CacheForm from "./Forms/CacheForm";
import UpdatesForm from "./Forms/UpdatesForm";
import CustomCodeForm from "./Forms/CustomCodeForm";
import WidgetsForm from "./Forms/WidgetsForm";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Paper>{children}</Paper>}
    </Typography>
  );
}

const SettingsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {settingsList, isLoading, error} = useSelector(state => state.settings);

  // Local State
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // Fetch Settings
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  // Submit function
  const onSubmit = async settingArray => {
    // Logic to show error or success
    const res = await dispatch(editSettings(settingArray));
    if (!res.error) {
      setSnackbarContent("Your changes were successfully updated!");
      setOpenSnackbar(true);
      dispatch(fetchSettings());
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const onReset = () => {
    dispatch(fetchSettings());
  };

  if (error && !Object.entries(settingsList).length) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h5">
            Settings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container item xs={12}>
              <Typography component="h1" variant="h5" gutterBottom>
                There was an error fetching the settings. Please try again
                later.
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h4">
            Settings
          </Typography>
        </Grid>
        {isLoading || !Object.entries(settingsList).length ? (
          <Grid item xs={12}>
            <Paper>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box height={"70vh"} style={{position: "relative"}}>
                    <FullContainerProgress />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          // General settings
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                <Tab label="General" />
                <Tab label="SEO" />
                <Tab label="Content/Media" />
                <Tab label="Navigation" />
                <Tab label="Modules" />
                <Tab label="Booking" />
                <Tab label="Endpoints" />
                <Tab label="API Keys" />
                <Tab label="Redirects" />
                <Tab label="Updates" />
                <Tab label="Cache" />
                <Tab label="Custom Code" />
                <Tab label="Widgets" />
                <Tab label="Checkout" />
                <Tab label="Appearance" />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <GeneralSettingsForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SEOForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <ContentForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <NavigationForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <ModulesForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <BookingSettingsForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              <EndpointsForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
              <APIKeysForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={8}>
              <RedirectsForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={9}>
              <UpdatesForm />
            </TabPanel>
            <TabPanel value={tabValue} index={10}>
              <CacheForm />
            </TabPanel>
            <TabPanel value={tabValue} index={11}>
              <CustomCodeForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={12}>
              <WidgetsForm
                settingsList={settingsList}
                onSubmit={onSubmit}
                onReset={onReset}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={13}>
              <p>Checkout Settings Coming Soon</p>
            </TabPanel>
            <TabPanel value={tabValue} index={14}>
              <p>Appearance Settings Coming Soon</p>
            </TabPanel>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default SettingsPage;
