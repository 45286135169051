import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/users";

const usersInitialState = {
  usersList: [],
  currentPageUser: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const users = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {
    getUserStart: startLoading,
    getUsersStart: startLoading,
    createUserStart: startLoading,
    editUserStart: startLoading,
    getUserSuccess(state, {payload}) {
      state.currentPageUser = payload;
      state.isLoading = false;
      state.error = null;
    },
    getUsersSuccess(state, {payload}) {
      state.usersList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createUserSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editUserSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getUserFailure: loadingFailed,
    getUsersFailure: loadingFailed,
    createUserFailure: loadingFailed,
    editUserFailure: loadingFailed,
  },
});

export const {
  getUserStart,
  getUsersStart,
  createUserStart,
  editUserStart,
  getUserSuccess,
  getUsersSuccess,
  createUserSuccess,
  editUserSuccess,
  getUserFailure,
  getUsersFailure,
  createUserFailure,
  editUserFailure,
} = users.actions;

export default users.reducer;

export const fetchUsers = () => async (dispatch, getState) => {
  try {
    dispatch(getUsersStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getUsersSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getUsersFailure(errorObj));
  }
};

export const fetchUser = id => async (dispatch, getState) => {
  try {
    dispatch(getUserStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getUserSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getUserFailure(errorObj));
  }
};

export const createUser = userObj => async (dispatch, getState) => {
  try {
    dispatch(createUserStart());
    const res = await axios.post(url, userObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createUserSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    // let errorObj = getErrorObjFormat(err);

    const errorCode = err.response?.status || 500;
    const errorMessage =
      err.response?.status === 400
        ? err.response?.data.content
          ? err.response.data.content.details[0].message
          : "There was an error creating this user. Please Try Again"
        : "";

    dispatch(
      createUserFailure({
        errorCode,
        errorMessage,
      })
    );
    return {error: true, errorCode, errorMessage};
  }
};

export const editUser = userObj => async (dispatch, getState) => {
  try {
    dispatch(editUserStart());
    await axios.put(url, userObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editUserSuccess());
    return {error: false};
  } catch (err) {
    // let errorObj = getErrorObjFormat(err);

    const errorCode = err.response?.status || 500;
    const errorMessage =
      err.response?.status === 400
        ? err.response?.data.content
          ? err.response.data.content.details[0].message
          : "There was an error updating this user. Please Try Again"
        : "";

    dispatch(
      editUserFailure({
        errorCode,
        errorMessage,
      })
    );

    return {error: true, errorCode, errorMessage};
  }
};

export const editUserPassword = userObj => async (dispatch, getState) => {
  try {
    dispatch(editUserStart());
    await axios.put(url + "/password", userObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editUserSuccess());
    return {error: false};
  } catch (err) {
    // let errorObj = getErrorObjFormat(err);

    const errorCode = err.response?.status || 500;
    const errorMessage =
      err.response?.status === 400
        ? err.response?.data.content
          ? err.response.data.content.details[0].message
          : "Password doesn't meet requirements"
        : "";

    dispatch(
      editUserFailure({
        errorCode,
        errorMessage,
      })
    );

    return {error: true, errorCode, errorMessage};
  }
};
