import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchAddresses} from "../../../../redux/features/sales/addressesSlice";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";

// Icons
import tableIcons from "../../../Table/TableIcons";

const AddressesTableComponent = ({customerId, addButton}) => {
  // Local State
  const [formattedData, setFormattedData] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const {addressesList, isLoading} = useSelector(state => state.addresses);

  useEffect(() => {
    dispatch(fetchAddresses(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    // Need to spread array so it doesn't fail
    setFormattedData(formatData(addressesList));
  }, [addressesList]);

  // Need to spread array so it doesn't fail
  const formatData = addressesList => {
    return addressesList.map(o => ({
      ...o,
    }));
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MaterialTable
          title="Customer Addresses"
          isLoading={isLoading}
          icons={tableIcons}
          columns={[
            {
              title: "ID",
              field: "address_id",
              type: "numeric",
              width: 30,
            },
            {
              title: "Line 1",
              field: "line_1",
            },
            {
              title: "Line 2",
              field: "line_2",
            },
            {
              title: "City",
              field: "city",
            },
            {
              title: "State",
              field: "state",
            },
            {
              title: "Zip Code",
              field: "zip",
            },
            {
              title: "Country",
              field: "country",
            },
            {
              title: "Other Details",
              field: "others_details",
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            pageSize: 2,
            pageSizeOptions: [2, 5, 10],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddressesTableComponent;
