import React, {useState, useEffect} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {fetchAddons} from "../../redux/features/addons/addonsSlice";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ProductsComboBox from "./ProductsComboBox";

const AddonsProductsComboBoxes = ({onChange, value, readOnly = false}) => {
  // Local State
  const [addons, setAddons] = useState(value.addons || []);
  const [products, setProducts] = useState(value.products || []);

  // Redux
  const dispatch = useDispatch();
  const {
    addonsList,
    isLoading: addonsLoading,
    error: addonsError,
  } = useSelector(state => state.addons);

  useEffect(() => {
    dispatch(fetchAddons("regular"));
  }, [dispatch]);

  useEffect(() => {
    onChange({
      addons,
      products,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons, products]);

  return (
    <>
      {!addonsError ? (
        <>
          {/* addons: Joi.array().items(Joi.number()).required(), */}
          <Grid item xs={12} md={6}>
            <ProductsComboBox
              id="addons-select"
              fieldLabel="Select Addons *"
              helperText="If left empty package won't be available."
              productsList={addonsList}
              disabledProducts={products}
              loading={addonsLoading}
              defaultValue={addons}
              onChange={setAddons}
              readOnly={readOnly}
            />
          </Grid>

          {/* products: Joi.array().items(Joi.number()).required(), */}
          <Grid item xs={12} md={6}>
            <ProductsComboBox
              id="products-select"
              fieldLabel="Select Products (Optional)"
              productsList={addonsList}
              disabledProducts={addons}
              loading={addonsLoading}
              defaultValue={products}
              onChange={setProducts}
              readOnly={readOnly}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="error"
            style={{
              display: "flex",
              alignItems: "flex-end",
              height: "100%",
            }}
            component="div">
            There was an error loading the Addon/Products Module. Please try
            again later. Or contact support.
          </Typography>
        </Grid>
      )}
    </>
  );
};
export default AddonsProductsComboBoxes;
