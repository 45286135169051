import React from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import UploadFiles from "../../FormFields/UploadFiles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ButtonsContainer from "./ButtonsContainer";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
}));

const SEOForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Styles
  const classes = useStyles();

  // Form Setup
  const defaultValues = {
    google_site_verification: settingsList.google_site_verification.value,
    meta_title: settingsList.meta_title.value,
    meta_description: settingsList.meta_description.value,
    og_title: settingsList.og_title.value,
    og_description: settingsList.og_description.value,
    ogPhotoObj: settingsList.og_image.value
      ? {photoName: settingsList.og_image.value}
      : null,
    twitter_title: settingsList.twitter_title.value,
    twitter_description: settingsList.twitter_description.value,
    twitterPhotoObj: settingsList.twitter_image.value
      ? {photoName: settingsList.twitter_image.value}
      : null,
  };
  const {handleSubmit, control, register} = useForm({
    defaultValues,
  });

  const formSubmit = data => {
    const {
      meta_title,
      google_site_verification,
      meta_description,
      og_title,
      og_description,
      ogPhotoObj,
      twitter_title,
      twitter_description,
      twitterPhotoObj,
    } = data;

    const settingArray = [
      {
        ...settingsList.google_site_verification,
        value: google_site_verification,
      },
      {...settingsList.meta_title, value: meta_title},
      {...settingsList.meta_description, value: meta_description},
      {...settingsList.og_title, value: og_title},
      {...settingsList.og_description, value: og_description},
      {...settingsList.og_image, value: ogPhotoObj.photoName},
      {...settingsList.twitter_title, value: twitter_title},
      {...settingsList.twitter_description, value: twitter_description},
      {...settingsList.twitter_image, value: twitterPhotoObj.photoName},
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Google Site Verification */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="google_site_verification"
            label="Google Site Verification"
          />
        </Grid>
        {/* Meta Title */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="meta_title"
            label="Meta Title"
            required
          />
        </Grid>
        {/* Meta Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="meta_description"
            label="Meta Description"
            multiline
            required
          />
        </Grid>
        {/* OG Title */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="og_title"
            label="og_title"
          />
        </Grid>
        {/* OG Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="og_description"
            label="og_description"
            multiline
          />
        </Grid>
        {/* OG Image */}
        <Grid item xs={12}>
          <Controller
            name={`ogPhotoObj`}
            control={control}
            defaultValue={defaultValues.ogPhotoObj}
            render={props => (
              <UploadFiles
                label="og_image"
                buttonLabel="Set og_image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Twitter Title */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="twitter_title"
            label="twitter_title"
          />
        </Grid>
        {/* Twitter Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="twitter_description"
            label="twitter_description"
            multiline
          />
        </Grid>

        {/* Twitter Image */}
        <Grid item xs={12}>
          <Controller
            name={`twitterPhotoObj`}
            control={control}
            defaultValue={defaultValues.twitterPhotoObj}
            render={props => (
              <UploadFiles
                label="twitter_image"
                buttonLabel="Set twitter_image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default SEOForm;
