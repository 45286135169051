import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {verifyUser} from "../redux/features/auth/authSlice";

// Styles
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

// Routing
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Components
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Dashboard from "./Layout/Dashboard";
import Login from "./Auth/Login";
import {CssBaseline} from "@material-ui/core";
import {StripeProvider} from "react-stripe-elements";
import ErrorAlert from "./UI/ErrorAlert";

const App = () => {
  // Redux state
  const {
    mode,
    palette: {primary, secondary},
  } = useSelector(state => state.ui);

  // Styling
  const theme = createMuiTheme({
    palette: {
      type: mode,
      primary,
      secondary,
    },
  });

  // Redux
  const dispatch = useDispatch();
  const auth = useSelector(state => {
    return state.auth;
  });
  const {isLoggedIn, token, userPermission} = auth;

  useEffect(() => {
    // Make the api call to see if user is logged in
    if (token !== "") dispatch(verifyUser(token));
  }, [dispatch, token]);

  const PrivateRoute = ({component: Component, ...rest}) => {
    const checkPermission = pathname => {
      let readPermission = false;

      const pathToCheck = pathname.split("/")[1];

      switch (pathToCheck) {
        case "404":
        case "403":
        case "user":
          readPermission = true;
          break;
        case "addons":
        case "condos":
        case "events":
        case "hotels":
        case "media":
        case "packages":
        case "pages":
        case "products":
        case "settings":
        case "users":
          readPermission = !!userPermission[pathToCheck].read;
          break;
        case "meetings":
          readPermission = !!userPermission.events.read;
          break;
        case "sales":
        case "rates":
        case "rateshopper":
        case "coupons":
        case "contacts":
          readPermission = !!userPermission.sales.read;
          break;
        case "":
          readPermission = !!userPermission.dashboard.read;
          break;
        // TODO: Add these permissions
        // case "tools":
        // case "reports":
        default:
          readPermission = null;
      }

      return readPermission;
    };

    return (
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? (
            <>
              {checkPermission(props.location.pathname) !== null ? (
                <>
                  {checkPermission(props.location.pathname) ? (
                    <Component {...props} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/403",
                      }}
                    />
                  )}
                </>
              ) : (
                <Redirect
                  to={{
                    pathname: "/404",
                  }}
                />
              )}
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {from: props.location},
              }}
            />
          )
        }
      />
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
        <ThemeProvider theme={theme}>
          <Router>
            <CssBaseline />
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <PrivateRoute path="/" component={Dashboard} />
            </Switch>
          </Router>
          <ErrorAlert />
        </ThemeProvider>
      </StripeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
