import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/settings";

const settingsInitialState = {
  settingsList: {},
  currentPageSetting: {},
  isLoading: false,
  error: null,

  /// Cache
  isLoadingCache: false,
  errorCache: null,

  /// Server Update
  isLoadingUpdate: false,
  errorUpdate: null,

  // mailchimp
  mailchimpLists: [],
  currentMailchimpListSegments: [],
  isLoadingMailchimp: false,
  errorMailchimp: null,
  isLoadingMailchimpSegment: false,
  errorMailchimpSegment: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}
function startLoadingUpdate(state) {
  state.isLoadingUpdate = true;
}
function loadingFailedUpdate(state, {payload}) {
  state.isLoadingUpdate = false;
  state.errorUpdate = payload;
}
function startLoadingCache(state) {
  state.isLoadingCache = true;
}
function loadingFailedCache(state, {payload}) {
  state.isLoadingCache = false;
  state.errorCache = payload;
}
function startLoadingMailchimp(state) {
  state.isLoadingMailchimp = true;
}
function loadingFailedMailchimp(state, {payload}) {
  state.isLoadingMailchimp = false;
  state.errorMailchimp = payload;
}
function startLoadingMailchimpSegment(state) {
  state.isLoadingMailchimpSegment = true;
}
function loadingFailedMailchimpSegment(state, {payload}) {
  state.isLoadingMailchimpSegment = false;
  state.errorMailchimpSegment = payload;
}

const settings = createSlice({
  name: "settings",
  initialState: settingsInitialState,
  reducers: {
    getSettingStart: startLoading,
    getSettingsStart: startLoading,
    createSettingStart: startLoading,
    editSettingsStart: startLoading,
    deleteSettingStart: startLoading,
    getSettingSuccess(state, {payload}) {
      state.currentPageSetting = payload;
      state.isLoading = false;
      state.error = null;
    },
    getSettingsSuccess(state, {payload}) {
      state.settingsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createSettingSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editSettingsSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteSettingSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getSettingFailure: loadingFailed,
    getSettingsFailure: loadingFailed,
    createSettingFailure: loadingFailed,
    editSettingsFailure: loadingFailed,
    deleteSettingFailure: loadingFailed,

    // Server Update
    serverUpdateStart: startLoadingUpdate,
    serverUpdateSuccess(state) {
      state.isLoadingUpdate = false;
      state.errorUpdate = null;
    },
    serverUpdateFailure: loadingFailedUpdate,

    // Cache
    clearCacheStart: startLoadingCache,
    clearCacheSuccess(state) {
      state.isLoadingCache = false;
      state.errorCache = null;
    },
    clearCacheFailure: loadingFailedCache,

    // Mailchimp
    getSegmentsStart: startLoadingMailchimpSegment,
    getMailchimpListsStart: startLoadingMailchimp,
    getSegmentsSuccess(state, {payload}) {
      state.currentMailchimpListSegments = payload;
      state.isLoadingMailchimpSegment = false;
      state.errorMailchimpSegment = null;
    },
    getMailchimpListsSuccess(state, {payload}) {
      state.mailchimpLists = payload;
      state.isLoadingMailchimp = false;
      state.errorMailchimp = null;
    },
    getSegmentsFailure: loadingFailedMailchimpSegment,
    getMailchimpListsFailure: loadingFailedMailchimp,
  },
});

export const {
  getSettingStart,
  getSettingsStart,
  createSettingStart,
  editSettingsStart,
  deleteSettingStart,
  getSettingSuccess,
  getSettingsSuccess,
  createSettingSuccess,
  editSettingsSuccess,
  deleteSettingSuccess,
  getSettingFailure,
  getSettingsFailure,
  createSettingFailure,
  editSettingsFailure,
  deleteSettingFailure,

  // Server Update
  serverUpdateStart,
  serverUpdateSuccess,
  serverUpdateFailure,

  // Cache
  clearCacheStart,
  clearCacheSuccess,
  clearCacheFailure,

  // Mailchimp
  getSegmentsStart,
  getMailchimpListsStart,
  getSegmentsSuccess,
  getMailchimpListsSuccess,
  getSegmentsFailure,
  getMailchimpListsFailure,
} = settings.actions;

export default settings.reducer;

export const fetchSettings = () => async (dispatch, getState) => {
  try {
    dispatch(getSettingsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getSettingsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getSettingsFailure(errorObj));
  }
};

export const fetchSetting = id => async (dispatch, getState) => {
  try {
    dispatch(getSettingStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getSettingSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getSettingFailure(errorObj));
  }
};

export const createSetting = settingObj => async (dispatch, getState) => {
  try {
    dispatch(createSettingStart());
    const res = await axios.post(url, settingObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createSettingSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createSettingFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editSettings = settingArray => async (dispatch, getState) => {
  const settingsObj = {
    settings: settingArray,
  };
  try {
    dispatch(editSettingsStart());
    const res = await axios.put(`${url}/multiple`, settingsObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editSettingsSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editSettingsFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteSetting = settingId => async (dispatch, getState) => {
  try {
    dispatch(deleteSettingStart());
    const res = await axios.delete(`${url}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: settingId},
    });
    dispatch(deleteSettingSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteSettingFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

// Mailchimp
export const fetchMailchimpLists = () => async (dispatch, getState) => {
  try {
    dispatch(getMailchimpListsStart());
    const res = await axios.get(`${url}/mailchimp`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getMailchimpListsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getMailchimpListsFailure(errorObj));
  }
};

export const fetchMailchimpSegments = listId => async (dispatch, getState) => {
  try {
    dispatch(getSegmentsStart());
    const res = await axios.get(`${url}/mailchimp/${listId}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getSegmentsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getSegmentsFailure(errorObj));
  }
};

// Cache
export const clearCache = () => async (dispatch, getState) => {
  try {
    dispatch(clearCacheStart());
    const res = await axios.delete(`${url}/cache`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(clearCacheSuccess());
    return {error: false, response: res.data.response};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(clearCacheFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

/// Server updates
export const serverUpdate = key => async (dispatch, getState) => {
  try {
    dispatch(serverUpdateStart());
    const res = await axios.put(
      `${url}/update/${key}`,
      {},
      {
        headers: {Authorization: `Bearer ${getState().auth.token}`},
      }
    );
    dispatch(serverUpdateSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(serverUpdateFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
