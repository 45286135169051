import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = `${process.env.REACT_APP_API_BASE_URL}/v1/redirects`;

const redirectsInitialState = {
  redirectsList: [],
  currentRedirectRedirect: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const redirects = createSlice({
  name: "redirects",
  initialState: redirectsInitialState,
  reducers: {
    getRedirectStart: startLoading,
    getRedirectsStart: startLoading,
    createRedirectStart: startLoading,
    editRedirectStart: startLoading,
    deleteRedirectStart: startLoading,
    getRedirectSuccess(state, {payload}) {
      state.currentRedirectRedirect = payload;
      state.isLoading = false;
      state.error = null;
    },
    getRedirectsSuccess(state, {payload}) {
      state.redirectsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createRedirectSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editRedirectSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteRedirectSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getRedirectFailure: loadingFailed,
    getRedirectsFailure: loadingFailed,
    createRedirectFailure: loadingFailed,
    editRedirectFailure: loadingFailed,
    deleteRedirectFailure: loadingFailed,
  },
});

export const {
  getRedirectStart,
  getRedirectsStart,
  createRedirectStart,
  editRedirectStart,
  deleteRedirectStart,
  getRedirectSuccess,
  getRedirectsSuccess,
  createRedirectSuccess,
  editRedirectSuccess,
  deleteRedirectSuccess,
  getRedirectFailure,
  getRedirectsFailure,
  createRedirectFailure,
  editRedirectFailure,
  deleteRedirectFailure,
} = redirects.actions;

export default redirects.reducer;

export const fetchRedirects = () => async (dispatch, getState) => {
  try {
    dispatch(getRedirectsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getRedirectsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getRedirectsFailure(errorObj));
  }
};

export const fetchRedirect = id => async (dispatch, getState) => {
  try {
    dispatch(getRedirectStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getRedirectSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getRedirectFailure(errorObj));
  }
};

export const createRedirect = redirectObj => async (dispatch, getState) => {
  try {
    dispatch(createRedirectStart());
    const res = await axios.post(url, redirectObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createRedirectSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createRedirectFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editRedirect = redirectObj => async (dispatch, getState) => {
  try {
    dispatch(editRedirectStart());
    const res = await axios.put(url, redirectObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editRedirectSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editRedirectFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteRedirect = redirectId => async (dispatch, getState) => {
  try {
    dispatch(deleteRedirectStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: redirectId},
    });
    dispatch(deleteRedirectSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteRedirectFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
