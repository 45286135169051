import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchAddons} from "../../redux/features/addons/addonsSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const AddonsPage = () => {
  // Local State to fetch by type
  const [showCustom, setShowCustom] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const {addonsList, isLoading, error} = useSelector(state => state.addons);

  useEffect(() => {
    dispatch(fetchAddons(showCustom ? "" : "regular"));
  }, [dispatch, showCustom]);

  return (
    <>
      <Grid container item xs={12} justify="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => setShowCustom(!showCustom)}
              value="showCustom"
              checked={showCustom}
            />
          }
          label="Show Custom Addons"
        />
      </Grid>

      <DisplayTablePage
        baseUrl="/addons"
        tableTitle="All Addons"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {title: "Type", field: "type"},
          {
            title: "Price",
            field: "price",
            type: "currency",
            headerStyle: {textAlign: "right"},
          },
          {
            title: "Identifier",
            field: "identifier",
          },
          {title: "Use", field: "use_in"},
          {title: "Status", field: "status"},
        ]}
        addButtonLabel="Addon"
        dataList={addonsList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="addons"
      />
    </>
  );
};

export default AddonsPage;
