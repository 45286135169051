import React from "react";

// Components
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Route, Link as RouterLink} from "react-router-dom";

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const BreadcrumbsWithRoutes = ({breadcrumbNameMap}) => {
  return (
    <Route>
      {({location}) => {
        const pathnames = location.pathname.split("/").filter(x => x);

        return (
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb">
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              return last ? (
                <Typography color="textPrimary" key={to}>
                  {breadcrumbNameMap[to]}
                </Typography>
              ) : (
                <LinkRouter color="inherit" to={to} key={to}>
                  {breadcrumbNameMap[to]}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
};

export default BreadcrumbsWithRoutes;
