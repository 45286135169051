import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchHotels} from "../../redux/features/hotels/hotelsSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const HotelsPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {hotelsList, isLoading, error} = useSelector(state => state.hotels);

  useEffect(() => {
    dispatch(fetchHotels());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/hotels"
      tableTitle="All Hotels"
      tableColumns={[
        {
          title: "ID",
          field: "id",
          type: "numeric",
          width: 30,
        },
        {title: "Name", field: "name"},
        {title: "City", field: "city"},
        {title: "State", field: "state"},
        {title: "No. of Rooms", field: "rooms", type: "numeric"},
        {title: "Hotel Status", field: "hotel_status"},
        {title: "Featured", field: "featured", type: "boolean"},
      ]}
      addButtonLabel="Hotel"
      dataList={hotelsList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="hotels"
    />
  );
};

export default HotelsPage;
