import React, {useEffect, useState} from "react";

//utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchRateshopper,
  syncRateshopper,
} from "../../redux/features/sales/ratesSlice";

// Styling
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

// Components
import Paper from "../UI/MainPaper";
import {DatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FullContainerProgress from "../UI/FullContainerProgress";
import HotelComboBox from "../FormFields/HotelComboBox";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import {MTableToolbar} from "material-table";

// Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import SyncIcon from "@material-ui/icons/Sync";
import tableIcons from "../Table/TableIcons";

const useStyles = makeStyles(theme => ({
  maxWidth: {width: "100%"},
  detailPanelContainer: {
    padding: theme.spacing(5),
  },
  calendarCell: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  unSelectableCell: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[600],
    color: theme.palette.grey[500],
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const RatesTableCalendar = ({
  initialRates,
  isLoading,
  onSubmit,
  selectedDate,
  calendarTitle,
}) => {
  //Styles
  const classes = useStyles();

  // Local State
  const [ratesArray] = useState(initialRates);
  const [selectedRates, setSelectedRates] = useState([]);
  // Check states
  const [allSelected, setAllSelected] = useState(false);
  const [weekDaysSelected, setWeekDaysSelected] = useState([]);
  const [weeksSelected, setWeeksSelected] = useState([]);

  const weeksArraysForYearMonth = (date, rates) => {
    let weeksArray = [];
    let weekDaysArray = [];

    const year = moment(date).format("YYYY");
    const month = moment(date).format("MM");

    // Get last day of month
    const lastDayOfMonth = Number(moment(date).endOf("month").format("D"));

    //Loop through month
    for (let d = 1; d <= lastDayOfMonth; d++) {
      /// YYYY-MM-DD

      const currentDayFormatted = `${year}-${month}-${d < 10 ? "0" : ""}${d}`;

      const emptyDayRateObj = {
        day: null,
        weekday: null,
        date: null,
        price: null,
        week: null,
        hotel_id: null,
        key: null,
        occupancy: null,
        room_id: null,
      };

      let currentDayRateObj = {...emptyDayRateObj};

      /// Get the rate obj from the rates array
      const rateObj = rates.find(el => el.date === currentDayFormatted);

      // Get day of the week of current d
      let currentWeekDay = Number(
        moment(currentDayFormatted, "YYYY-MM-DD").weekday()
      );

      //update currentDayRateObj
      currentDayRateObj["day"] = d;
      currentDayRateObj["date"] = rateObj?.date;
      currentDayRateObj["price"] = rateObj?.price;
      currentDayRateObj["key"] = rateObj?.key;
      currentDayRateObj["hotel_id"] = rateObj?.hotel_id;
      currentDayRateObj["room_id"] = rateObj?.room_id;
      currentDayRateObj["occupancy"] = rateObj?.occupancy;
      currentDayRateObj["weekday"] = currentWeekDay;
      currentDayRateObj["week"] = weeksArray.length;

      // If first day of month push empty days before it
      if (d === 1) {
        for (let i = 0; i < currentWeekDay; i++) {
          weekDaysArray.push(emptyDayRateObj);
        }
      }

      // if current d is first day of week empty weekdayArray and push
      if (currentWeekDay === 0) {
        weekDaysArray = [];
        weekDaysArray.push(currentDayRateObj);
      } else if (currentWeekDay === 6) {
        // if current d is last day of week push to weekday array and push array to weekArrays
        weekDaysArray.push(currentDayRateObj);
        weeksArray.push(weekDaysArray);
      } else {
        // otherwise just push to weekDaysArray
        weekDaysArray.push(currentDayRateObj);
      }

      // If last day of month push empty days after it
      if (d === lastDayOfMonth) {
        for (let i = currentWeekDay + 1; i < 7; i++) {
          weekDaysArray.push(emptyDayRateObj);
        }
        weeksArray.push(weekDaysArray);
      }
    }
    return weeksArray;
  };

  // Array formatted to be rendered
  const weekArrays = weeksArraysForYearMonth(selectedDate, ratesArray);
  const weekArraysFlat = weekArrays.flat();

  // Select Cell when clicked
  const onCellClick = date => {
    const rateToPush = ratesArray.find(rate => rate.date === date);

    const newArray = (selectedRates.filter(el => rateToPush.key === el.key)
      .length &&
      selectedRates.filter(el => el.key !== rateToPush.key)) || [
      ...selectedRates,
      rateToPush,
    ];

    setSelectedRates(newArray);
  };

  // Select Functions
  const onSelectAll = () => {
    let newArray = [...ratesArray];
    setSelectedRates(newArray);
  };
  const onSelectWholeWeek = week => {
    let newArray = ratesArray.flatMap(el => {
      // If the current iteration el is on the week selected update price, if not return el
      const foundMatch = weekArraysFlat.find(dateEl => el.date === dateEl.date);
      if (foundMatch && foundMatch.week === week) {
        return el;
      } else {
        return [];
      }
    });
    setSelectedRates(newArray);
  };
  const onSelectWeekday = weekday => {
    let newArray = ratesArray.flatMap(el => {
      // If the current iteration el is on the weekday selected update price, if not return el
      const foundMatch = weekArraysFlat.find(dateEl => el.date === dateEl.date);
      if (foundMatch && foundMatch.weekday === weekday) {
        return el;
      } else {
        return [];
      }
    });
    setSelectedRates([...selectedRates, ...newArray]);
  };

  const handleCheckAll = e => {
    const isChecked = e.target.checked;

    setAllSelected(isChecked);

    // Depending if its checked or not, set the value of selected rates to be all or empty
    if (isChecked) {
      onSelectAll();
      setWeekDaysSelected([0, 1, 2, 3, 4, 5, 6]);

      // Select all week
      let weeks = [];
      for (let index = 0; index < weekArrays.length; index++) {
        weeks.push(index);
      }
      setWeeksSelected(weeks);
    } else {
      setSelectedRates([]);
      setWeekDaysSelected([]);
      setWeeksSelected([]);
    }
  };
  const handleCheckWeekDay = (e, weekday) => {
    const isChecked = e.target.checked;

    // Depending if its checked or not, set the value of selected rates to be all or filter weekdays
    if (isChecked) {
      // add weekday to the selected weekdays array and select
      setWeekDaysSelected([...weekDaysSelected, weekday]);
      onSelectWeekday(weekday);
    } else {
      // remove weekday from the selected weekdays array and filter
      const newWeekdaysSelected = weekDaysSelected.filter(
        day => day !== weekday
      );
      setWeekDaysSelected(newWeekdaysSelected);

      let newArray = selectedRates.flatMap(el => {
        // If the current iteration el is on the weekday selected return empty
        const foundMatch = weekArraysFlat.find(
          dateEl => el.date === dateEl.date
        );
        if (foundMatch && foundMatch.weekday === weekday) {
          return [];
        } else {
          return el;
        }
      });
      setSelectedRates(newArray);
    }
  };

  const handleCheckWeek = (e, week) => {
    const isChecked = e.target.checked;

    // Depending if its checked or not, set the value of selected rates to be all or filter weeks
    if (isChecked) {
      // add week to the selected weeks array and select
      setWeeksSelected([...weeksSelected, week]);
      onSelectWholeWeek(week);
    } else {
      // remove week from the selected weeks array and filter
      const newWeeksSelected = weeksSelected.filter(w => w !== week);
      setWeeksSelected(newWeeksSelected);

      let newArray = selectedRates.flatMap(el => {
        // If the current iteration el is on the week selected return empty
        const foundMatch = weekArraysFlat.find(
          dateEl => el.date === dateEl.date
        );
        if (foundMatch && foundMatch.week === week) {
          return [];
        } else {
          return el;
        }
      });
      setSelectedRates(newArray);
    }
  };

  if (ratesArray.length < 1) {
    return (
      <Grid item xs={12}>
        <Paper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography variant="h6" gutterBottom align="center">
            No rates Available. Please select other values.
          </Typography>
        </Paper>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={8}>
                {calendarTitle}
              </TableCell>
            </TableRow>
            {/* Week Day names */}
            <TableRow>
              <TableCell>
                <Tooltip title={`Select all`} placement="top">
                  <Checkbox
                    color="primary"
                    checked={allSelected}
                    onChange={handleCheckAll}
                  />
                </Tooltip>
              </TableCell>
              {[0, 1, 2, 3, 4, 5, 6].map(n => (
                <TableCell key={n}>
                  <Tooltip
                    title={`Select all ${moment().weekday(n).format("dddd")}s`}
                    placement="top">
                    <Checkbox
                      color="primary"
                      checked={weekDaysSelected.includes(n)}
                      onChange={e => handleCheckWeekDay(e, n)}
                    />
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>{""}</TableCell>
              {[0, 1, 2, 3, 4, 5, 6].map(n => (
                <TableCell key={n}>
                  <Typography variant="caption">
                    {moment().weekday(n).format("dd")}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && ratesArray.length > 0 ? (
            <TableBody>
              {weeksArraysForYearMonth(selectedDate, ratesArray).map(
                (week, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Tooltip title={`Select whole week`} placement="top">
                        <Checkbox
                          color="primary"
                          checked={weeksSelected.includes(i)}
                          onChange={e => handleCheckWeek(e, i)}
                        />
                      </Tooltip>
                    </TableCell>

                    {week.map(({day, date, price, key}, j) => {
                      const emptyCell = price === null;
                      const isSelectableCell = price !== undefined;

                      const selected = !!selectedRates.filter(
                        el => el.key === key
                      ).length;

                      return (
                        <TableCell
                          key={`${i}-${j}`}
                          className={clsx(
                            selected && classes.selected,
                            !emptyCell
                              ? isSelectableCell
                                ? classes.calendarCell
                                : classes.unSelectableCell
                              : ""
                          )}>
                          {price !== null && (
                            <Box onClick={() => onCellClick(date)}>
                              <Typography variant="h6" gutterBottom>
                                {day}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {price !== undefined
                                  ? `$${price.toFixed(2)}`
                                  : "No rate"}
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" gutterBottom>
                    Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          disabled={selectedRates.length < 1}
          startIcon={<SyncIcon />}
          onClick={() => onSubmit(selectedRates)}>
          Sync Selected Rates
        </Button>
      </Grid>
    </>
  );
};

const RatesTableOfCalendars = ({
  hotelName,
  rates,
  isLoading,
  onSubmit,
  selectedDate,
}) => {
  //Styles
  const classes = useStyles();

  // Need to spread array so it doesn't fail
  const formatData = ratesList => {
    return ratesList.map(o => ({
      ...o,
    }));
  };

  // map all the rates in case of syncing the whole month
  const onSyncAllMonth = () => {
    const mapOfAllRates = rates.flatMap(roomObj => roomObj.rates);

    onSubmit(mapOfAllRates);
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MaterialTable
          title={`${hotelName} rates for ${moment(selectedDate).format(
            "MMMM YYYY"
          )}`}
          isLoading={isLoading}
          icons={tableIcons}
          columns={[
            {
              title: "Room Id",
              field: "room_id",
            },
            {
              title: "Room Name",
              field: "name",
            },
            {
              title: "Number of Rates",
              field: "rates",
              render: rowData => <>{rowData.rates.length}</>,
            },
          ]}
          detailPanel={[
            {
              tooltip: "Show Rates",
              render: rowData => {
                return (
                  <Grid
                    container
                    className={classes.detailPanelContainer}
                    spacing={1}>
                    <RatesTableCalendar
                      selectedDate={selectedDate}
                      calendarTitle={
                        <>
                          <Typography variant="h4" gutterBottom>
                            {selectedDate.format("MMMM YYYY")}
                          </Typography>
                          <Typography variant="h6" gutterBottom>
                            {rowData.name}
                          </Typography>
                        </>
                      }
                      initialRates={rowData.rates}
                      onSubmit={onSubmit}
                      isLoading={isLoading}
                    />
                  </Grid>
                );
              },
            },
          ]}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          data={formatData(rates)}
          options={{
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
          }}
          components={{
            Toolbar: props => (
              <>
                <MTableToolbar {...props} />

                <Button
                  variant="contained"
                  color="secondary"
                  // size="large"
                  fullWidth
                  startIcon={<SyncIcon />}
                  onClick={() => onSyncAllMonth()}>
                  Sync {hotelName} rates for {selectedDate.format("MMM, 2020")}
                </Button>
              </>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const RateshopperPage = () => {
  //Styles
  const classes = useStyles();

  // Local State
  const [fetchFlag, setFetchFlag] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [hotel, setHotel] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [tableSelectedDate, setTableSelectedDate] = useState(selectedDate);
  const [tableHotel, setTableHotel] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {rateshopper, isLoading, error} = useSelector(state => state.rates);
  const {hotelsList, isLoading: isLoadingHotels} = useSelector(
    state => state.hotels
  );

  const onFetchRates = () => {
    setFetchFlag(true);
    setTableSelectedDate(moment(selectedDate));
    setTableHotel(hotel);
    const date = moment(selectedDate).format("YYYY-MM");
    if (hotel.id !== -1) {
      dispatch(fetchRateshopper(date, [hotel]));
    } else {
      // Logic to show error or success
      dispatch(fetchRateshopper(date, hotelsList));
    }
  };

  const onSubmit = async ratesArray => {
    const formattedRatesObj = {
      rates: ratesArray,
    };

    // Logic to show error or success
    const res = await dispatch(syncRateshopper(formattedRatesObj));
    if (!res.error) {
      setSnackbarContent("Your changes to the Rates were saved successfully!");
      setOpenSnackbar(true);
      onFetchRates();
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const masterRater = async () => {
    const formattedRatesArray = rateshopper.flatMap(hotelObj => {
      const mapOfRatesPerRoom = hotelObj.rooms.flatMap(({rates}) => rates);

      return mapOfRatesPerRoom;
    });

    const formattedRatesObj = {
      rates: formattedRatesArray,
    };

    // Logic to show error or success
    const res = await dispatch(syncRateshopper(formattedRatesObj));

    if (!res.error) {
      setSnackbarContent(
        `All hotel rates for ${moment(selectedDate).format(
          "MMMM, YYYY"
        )} were synced successfully!`
      );
      setOpenSnackbar(true);
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  useEffect(() => {
    if (
      (tableHotel !== null && hotel !== null && tableHotel.id !== hotel.id) ||
      !moment(selectedDate).isSame(tableSelectedDate) ||
      hotel === null
    ) {
      setFetchFlag(false);
    }
  }, [selectedDate, hotel, tableSelectedDate, tableHotel]);

  return (
    <Grid container spacing={3}>
      {/* Hotel and Room  */}

      <Grid container item xs={12} lg={9} spacing={2}>
        {/* Hotel  */}
        <Grid item xs={12}>
          <HotelComboBox
            defaultValue={hotel}
            onChange={setHotel}
            readOnly={isLoading}
            required
            addSelectAll
          />
        </Grid>

        {/* Select Month */}
        <Grid item xs={12}>
          <DatePicker
            className={classes.maxWidth}
            views={["year", "month"]}
            label="Select Month"
            openTo="month"
            minDate={moment().startOf("year")}
            value={selectedDate}
            onChange={setSelectedDate}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={3} style={{paddingRight: 0}}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<GetAppIcon />}
          disabled={!(hotel && selectedDate && !isLoading && !isLoadingHotels)}
          onClick={() => onFetchRates()}>
          Preview Rates
        </Button>
      </Grid>

      {!fetchFlag ? (
        <Grid item xs={12}>
          <Paper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant="h6" gutterBottom align="center">
              Please select some values and fetch rates.
            </Typography>
          </Paper>
        </Grid>
      ) : !isLoading ? (
        <>
          {rateshopper.length > 0 ? (
            <>
              {rateshopper.length > 0 && (
                <Grid item xs={12}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      startIcon={<SyncIcon />}
                      disabled={!selectedDate || isLoadingHotels || isLoading}
                      onClick={() => masterRater()}>
                      Sync All Available Hotels Rates for{" "}
                      {moment(selectedDate).format("MMM, YYYY")}
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              )}

              {rateshopper.map(hotelRates => (
                <RatesTableOfCalendars
                  selectedDate={tableSelectedDate}
                  hotelName={hotelRates.name}
                  rates={hotelRates.rooms}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                />
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Paper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Typography variant="h6" gutterBottom align="center">
                  No rates Available at this moment.
                </Typography>
              </Paper>
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Paper>
            <FullContainerProgress />
          </Paper>
        </Grid>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};

export default RateshopperPage;
