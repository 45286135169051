import React, {useState, useEffect} from "react";

// utils
import moment from "moment";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchRatesForDates} from "../../../redux/features/sales/ratesSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import HotelComboBox from "../HotelComboBox";
import RoomComboBox from "../RoomComboBox";

// Icons
import tableIcons from "../../Table/TableIcons";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles(theme => ({
  maxWidth: {width: "100%"},
}));

// Need to spread array so it doesn't fail
const formatData = data => {
  return data.map(o => ({
    ...o,
  }));
};

const RatesTableEdit = ({orderInfo, onSubmit}) => {
  //Styles
  const classes = useStyles();

  // Local State
  const [fetchDisabled, setFetchDisabled] = useState(false);
  const [check_in, setCheckIn] = useState(
    orderInfo.check_in ? moment(orderInfo.check_in) : moment()
  );
  const [check_out, setCheckOut] = useState(
    orderInfo.check_out ? moment(orderInfo.check_out) : moment().add(1, "days")
  );
  const [hotel, setHotel] = useState({id: orderInfo.hotel_id, name: ""});
  const [room, setRoom] = useState({id: orderInfo.room_id, name: ""});
  const [package_name, setPackageName] = useState(orderInfo.package_name);

  // Redux
  const dispatch = useDispatch();
  const {ratesList, isLoading, error} = useSelector(state => state.rates);

  const data = formatData(ratesList.length > 0 ? ratesList[0].rates : []);

  const onFetchRates = () => {
    dispatch(
      fetchRatesForDates(
        moment(check_in).format("YYYY-MM-DD"),
        moment(check_out).format("YYYY-MM-DD"),
        hotel.id,
        room.id
      )
    );
  };

  useEffect(() => {
    onFetchRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = () => {
    const updatedOrderInfo = {
      ...orderInfo,
      package_name,
      room_id: room.id,
      hotel_id: hotel.id,
      check_in: moment(check_in).format("YYYY-MM-DD"),
      check_out: moment(check_out).format("YYYY-MM-DD"),
    };
    onSubmit(updatedOrderInfo);
  };

  return (
    <form className={classes.form}>
      <Grid item xs={12}>
        <Grid container item spacing={2}>
          <Grid container item xs={12} lg={10} spacing={2}>
            {/* Package Name  */}
            <Grid item xs={12}>
              <TextField
                className={classes.maxWidth}
                required
                name="package_name"
                label="Package Name"
                fullWidth
                value={package_name}
                onChange={e => setPackageName(e.target.value)}
              />
            </Grid>

            {/* Hotel and Room  */}
            <Grid container item xs={12} spacing={2}>
              {/* Hotel  */}
              <Grid item xs={12} md={6}>
                <HotelComboBox
                  defaultValue={hotel}
                  onChange={setHotel}
                  readOnly={false}
                  required
                />
              </Grid>

              {/* Hotel Room */}
              {hotel && (
                <Grid item xs={12} md={6}>
                  <RoomComboBox
                    defaultValue={room}
                    hotel_id={hotel.id}
                    onChange={setRoom}
                    readOnly={false}
                  />
                </Grid>
              )}
            </Grid>

            {/* Check In */}
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Check In"
                format="MM/dd/yyyy"
                value={check_in}
                onChange={date => {
                  setCheckIn(date);
                }}
                onError={(e, v) => {
                  setFetchDisabled(!!e);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change check in date",
                }}
              />
            </Grid>

            {/* Check Out */}
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                className={classes.maxWidth}
                disablePast
                minDate={moment(check_in).add(1, "days")}
                label="Check Out"
                format="MM/dd/yyyy"
                value={check_out}
                onChange={date => {
                  setCheckOut(date);
                }}
                onError={(e, v) => {
                  setFetchDisabled(!!e);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change check out date",
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} lg={2}>
            <Button
              style={{
                width: "100%",
                height: "100%",
              }}
              // variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              disabled={fetchDisabled}
              onClick={onFetchRates}>
              Fetch Rates
            </Button>
          </Grid>

          <Grid item xs={12}>
            <MaterialTable
              title="Rates by Date"
              isLoading={isLoading}
              icons={tableIcons}
              columns={[
                {
                  title: "Date",
                  field: "date",
                  render: rowData => (
                    <>
                      {moment(rowData.date).tz("America/Chicago").format("ll")}
                    </>
                  ),
                },
                {
                  title: "Price",
                  field: "price",
                  type: "currency",
                  headerStyle: {
                    textAlign: "right",
                  },
                },
                {
                  title: "Tax",
                  field: "tax",
                  type: "numeric",
                  render: rowData => <>{`${ratesList[0].tax.toFixed(2)}%`}</>,
                },
              ]}
              data={!error ? data : []}
              options={{
                search: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 20],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mb={3} clone>
              <Button
                style={{
                  width: "100%",
                }}
                color="primary"
                variant="contained"
                size="large"
                onClick={formSubmit}>
                Submit Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default RatesTableEdit;
