import React from "react";

// utils
import {useForm, Controller} from "react-hook-form";
import moment from "moment";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {DateTimePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFiles from "../FormFields/UploadFiles";
import VenueComboBox from "../FormFields/VenueComboBox";
import EventOrganizerComboBox from "../FormFields/EventOrganizerComboBox";
import PackageComboBox from "../FormFields/PackageComboBox";
import RichTextEditor from "../FormFields/RichTextEditor";
import PermalinkTextField from "../FormFields/PermalinkTextField";
import CheckboxWithState from "../FormFields/CheckboxWithState";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
}));

const EventsForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    title: "",
    description: "",
    short_description: "",
    startDate: moment(),
    endDate: moment().add(1, "days"),
    permalink: "",
    venue: null,
    organizer: null,
    pkg: null,
    photoObj: null,
    event_status: "Published",
    featured: false,
    ticketmaster_id: "",
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {
      start,
      end,
      photoObj,
      venue,
      pkg,
      organizer,
      featured,
      ...restOfData
    } = data;

    const formattedValues = {
      ...restOfData,
      start: moment(start).format(),
      end: moment(end).format(),
      photoId: photoObj ? photoObj.photoId : null,
      venue_id: (venue && venue.id) || null,
      organizer_id: (organizer && organizer.id) || null,
      package_id: (pkg && pkg.id) || null,
      featured: featured ? 1 : 0,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Event Title  */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            required
            name="title"
            label="Title"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Event Permalink  */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="permalink"
            defaultValue={defaultValues.permalink}
            render={props => (
              <PermalinkTextField
                value={props.value}
                required
                label="Permalink"
                onChange={props.onChange}
                startAdornment={process.env.REACT_APP_DOMAIN + "/events/"}
                fullWidth
                readOnly={readOnly}
              />
            )}
          />
        </Grid>

        {/* Event Description */}

        <Grid item xs={12}>
          <Controller
            name={`description`}
            control={control}
            defaultValue={defaultValues.description}
            render={props => (
              <RichTextEditor
                label="Event Description"
                withPictureUploader
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                height={500}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Event Short Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="short_description"
            label="Short Description"
            multiline
            required
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Event Start Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="start"
            defaultValue={defaultValues.start}
            render={props => (
              <DateTimePicker
                className={classes.maxWidth}
                label="Start Date"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change start date",
                }}
              />
            )}
          />
        </Grid>

        {/* Event End Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="end"
            defaultValue={defaultValues.end}
            render={props => (
              <DateTimePicker
                className={classes.maxWidth}
                label="End Date"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change end date",
                }}
              />
            )}
          />
        </Grid>

        {/* Event Ticket Master ID */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="ticketmaster_id"
            label="Ticket Master ID"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Event Venue  */}
        <Grid item xs={12} md={6}>
          <Controller
            name="venue"
            control={control}
            defaultValue={defaultValues.venue}
            render={props => (
              <VenueComboBox
                required
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Event Organizer  */}
        <Grid item xs={12} md={6}>
          <Controller
            name="organizer"
            control={control}
            defaultValue={defaultValues.organizer}
            render={props => (
              <EventOrganizerComboBox
                required
                label="Linked Package"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Event Package  */}
        <Grid item xs={12} md={6}>
          <Controller
            name="pkg"
            control={control}
            defaultValue={defaultValues.pkg}
            render={props => (
              <PackageComboBox
                label="Linked Package"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Event Status  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`event_status`}
            control={control}
            defaultValue={defaultValues.event_status}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Status
                </InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Published"}>Published</MenuItem>
                  <MenuItem value={"Private"}>Private</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Event Image  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Featured Image"
                buttonLabel="Set Featured Image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
                itemsWidth={400}
                itemsHeight={200}
              />
            )}
          />
        </Grid>

        {/* Event Featured */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`featured`}
            control={control}
            defaultValue={defaultValues.featured}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Featured"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default EventsForm;
