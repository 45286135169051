import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/rates";

const ratesInitialState = {
  ratesList: [],
  rateshopper: [],
  currentPageRate: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const rates = createSlice({
  name: "rates",
  initialState: ratesInitialState,
  reducers: {
    getRateStart: startLoading,
    getRatesStart: startLoading,
    createRateStart: startLoading,
    editRateStart: startLoading,
    deleteRateStart: startLoading,
    getRateshopperStart: startLoading,
    syncRateshopperStart: startLoading,
    getRateSuccess(state, {payload}) {
      state.currentPageRate = payload;
      state.isLoading = false;
      state.error = null;
    },
    getRatesSuccess(state, {payload}) {
      state.ratesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createRateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editRateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteRateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getRateshopperSuccess(state, {payload}) {
      state.rateshopper = payload.rateshopper;
      state.isLoading = false;
      state.error = null;
    },
    syncRateshopperSuccess(state, {payload}) {
      state.isLoading = false;
      state.error = null;
    },
    getRateFailure: loadingFailed,
    getRatesFailure: loadingFailed,
    createRateFailure: loadingFailed,
    editRateFailure: loadingFailed,
    deleteRateFailure: loadingFailed,
    getRateshopperFailure: loadingFailed,
    syncRateshopperFailure: loadingFailed,
  },
});

export const {
  getRateStart,
  getRatesStart,
  getRateshopperStart,
  syncRateshopperStart,
  createRateStart,
  editRateStart,
  deleteRateStart,
  getRateSuccess,
  getRatesSuccess,
  getRateshopperSuccess,
  syncRateshopperSuccess,
  createRateSuccess,
  editRateSuccess,
  deleteRateSuccess,
  getRateFailure,
  getRatesFailure,
  getRateshopperFailure,
  syncRateshopperFailure,
  createRateFailure,
  editRateFailure,
  deleteRateFailure,
} = rates.actions;

export default rates.reducer;

export const fetchRates = () => async (dispatch, getState) => {
  try {
    dispatch(getRatesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getRatesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRatesFailure(errorObj));
  }
};

export const fetchRate = id => async (dispatch, getState) => {
  try {
    dispatch(getRateStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getRateSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRateFailure(errorObj));
  }
};

export const fetchRatesForDates = (
  checkIn,
  checkOut,
  hotelId,
  roomId = null
) => async (dispatch, getState) => {
  try {
    dispatch(getRatesStart());
    const res = await axios.get(
      `${url}/${checkIn}/${checkOut}/${hotelId}/${roomId ? roomId : ""}`,
      {
        headers: {Authorization: `Bearer ${getState().auth.token}`},
      }
    );
    dispatch(getRatesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRatesFailure(errorObj));
  }
};

export const fetchRatesForMonth = (date, hotelId, roomId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(getRatesStart());
    const res = await axios.get(`${url}/month/${date}/${hotelId}/${roomId}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getRatesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRatesFailure(errorObj));
  }
};

export const createRate = rateObj => async (dispatch, getState) => {
  try {
    dispatch(createRateStart());
    const res = await axios.post(url, rateObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createRateSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createRateFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editRate = (rateId, rateObj) => async (dispatch, getState) => {
  try {
    dispatch(editRateStart());
    const res = await axios.put(`${url}/${rateId}`, rateObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editRateSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editRateFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
export const editRatesByMonthForHotelRoom = rateObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(editRateStart());
    const res = await axios.put(`${url}`, rateObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editRateSuccess());
    return {error: false, res};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editRateFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteRate = rateId => async (dispatch, getState) => {
  try {
    dispatch(deleteRateStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: rateId},
    });
    dispatch(deleteRateSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteRateFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const fetchRateshopper = (date, hotelList) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(getRateshopperStart());

    let promiseGET = [];

    hotelList.forEach(({id: hotelId}) => {
      promiseGET.push(
        new Promise(resolve =>
          resolve(
            axios.get(`${url}/live/${date}/${hotelId}`, {
              headers: {
                Authorization: `Bearer ${getState().auth.token}`,
              },
            })
          )
        )
      );
    });

    const allRes = await Promise.all(promiseGET);

    const mapOfHotelRatesFormatted = hotelList.flatMap(({id, name}, i) => {
      const res = allRes[i];
      if (
        res.data.response &&
        !res.data.error &&
        res.data.response.rooms.length > 0
      ) {
        return {id, name, rooms: res.data.response.rooms};
      } else {
        return [];
      }
    });

    dispatch(
      getRateshopperSuccess({
        rateshopper: mapOfHotelRatesFormatted,
      })
    );
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRateshopperFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const syncRateshopper = ratesObj => async (dispatch, getState) => {
  try {
    dispatch(syncRateshopperStart());
    const res = await axios.put(`${url}/live`, ratesObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(syncRateshopperSuccess());
    return {error: false, res};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(syncRateshopperFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
