export const getErrorObjFormat = err => {
  let errorMessage = "";
  let errorCode = err.response?.status || 500;
  if (
    err.response?.data?.content?.details &&
    err.response?.data?.content?.details.length > 0
  ) {
    errorMessage = err.response?.data?.content?.details[0].message;
  } else if (err.response?.data?.content?.message) {
    errorMessage = err.response?.data?.content?.message;
  } else {
    switch (errorCode) {
      case 429:
        errorMessage = "Slow Down there! You are making TOO MANY REQUESTS!";
        break;
      case 400:
        errorMessage =
          "There was a problem with your request. Try again later or contact support.";
        break;
      case 404:
        errorMessage = "We can't find what you are looking for!";
        break;
      case 403:
        errorMessage = "You have no authorization to do this action!";
        break;
      case 500:
        errorMessage = "Server Error. Try again later or contact support.";
        break;
      default:
        errorMessage = `We've encountered an unknown error. ${err.toString()}`;
    }
  }

  return {
    errorCode,
    errorMessage,
  };
};
