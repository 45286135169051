import React, {useState, useEffect} from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFiles from "../FormFields/UploadFiles";
import GalleryComboBox from "../FormFields/GalleryComboBox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import RichTextEditor from "../FormFields/RichTextEditor";
import PermalinkTextField from "../FormFields/PermalinkTextField";
import ArrayOfStrings from "../FormFields/ArrayOfStrings";
import IconButton from "@material-ui/core/IconButton";

// Icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import PackageComboBox from "../FormFields/PackageComboBox";
import HotelComboBox from "../FormFields/HotelComboBox";
import CheckboxWithState from "../FormFields/CheckboxWithState";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
  teamMemberContainer: {
    marginBottom: theme.spacing(4),
  },
}));

const MeetingsForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    title: "",
    photoObj: null,
    gallery: null,
    videoObj: null,
    emails: [""],
    meetingByPhotoObj: null,
    hotel_id: null,
    package_id: null,
    pkg: null,
    hotel: null,
    team: [],
    amenities: [""],
    meeting_types: [""],
    body: "",
    permalink: "",
    phone_number: "",
    short_description: "",
    featured: 0,
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  // Form
  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {
      gallery,
      pkg,
      hotel,
      photoObj,
      videoObj,
      meetingByPhotoObj,
      featured,
      ...restOfData
    } = data;

    const formattedValues = {
      hotel_id: hotel.id,
      package_id: pkg !== null && pkg.id ? pkg.id : null,
      gallery_id: gallery.id,
      video_id: videoObj !== null && videoObj.photoId ? videoObj.photoId : null,
      picture_id: photoObj.photoId,
      meeting_by: meetingByPhotoObj.photoId,
      featured: featured ? 1 : 0,
      ...restOfData,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={4}>
        {/********************************************************************************************************/}
        {/******************************************** Meeting Information *****************************************/}
        {/********************************************************************************************************/}
        <Grid item xs={12}>
          {/* Meeting Title */}
          <TextField
            inputRef={register}
            required
            name="title"
            label="Title"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Meeting Permalink */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="permalink"
            defaultValue={defaultValues.permalink}
            render={props => (
              <PermalinkTextField
                value={props.value}
                required
                label="Meeting Permalink"
                onChange={props.onChange}
                startAdornment={process.env.REACT_APP_DOMAIN + "/meetings/"}
                fullWidth
                readOnly={readOnly}
              />
            )}
          />
        </Grid>

        {/* Linked Hotel */}
        <Grid item xs={12} md={6}>
          <Controller
            name="hotel"
            control={control}
            defaultValue={defaultValues.hotel}
            render={props => (
              <HotelComboBox
                required
                label="Linked Hotel"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Linked Package */}
        <Grid item xs={12} md={6}>
          <Controller
            name="pkg"
            control={control}
            defaultValue={defaultValues.pkg}
            render={props => (
              <PackageComboBox
                label="Linked Package"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          {/* Meeting Phone Number */}
          <TextField
            inputRef={register}
            name="phone_number"
            label="Phone Number"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Condo Featured */}
        <Grid item xs={12}>
          <Controller
            name={`featured`}
            control={control}
            defaultValue={defaultValues.featured}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Featured"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Hotel Emails */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="label">
              Emails
            </Typography>
          </Grid>

          <Controller
            name={`emails`}
            control={control}
            defaultValue={defaultValues.emails}
            render={props => (
              <ArrayOfStrings
                label="Email"
                type="email"
                value={props.value}
                readOnly={readOnly}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/*************************************************************************************************************/}
        {/************************************************** Meeting Content *************************************************/}
        {/**************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Page Content
            </Typography>
          </Box>
        </Grid>

        {/* Meeting Short Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="short_description"
            label="Short Description"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Meeting Body */}
        <Controller
          name={`body`}
          control={control}
          defaultValue={defaultValues.body}
          render={props => (
            <Grid item xs={12}>
              <RichTextEditor
                label="Body"
                withPictureUploader
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                height={500}
              />
            </Grid>
          )} // props contains: onChange, onBlur and value
        />

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="label">
              Amenities
            </Typography>
          </Grid>

          <Controller
            name={`amenities`}
            control={control}
            defaultValue={defaultValues.amenities}
            render={props => (
              <ArrayOfStrings
                label="Amenity"
                value={props.value}
                readOnly={readOnly}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="label">
              Type of Events Options
            </Typography>
          </Grid>

          <Controller
            name={`meeting_types`}
            control={control}
            defaultValue={defaultValues.meeting_types}
            render={props => (
              <ArrayOfStrings
                label="Option"
                value={props.value}
                readOnly={readOnly}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {/*************************************************************************************************************/}
        {/************************************************** Meeting Images *************************************************/}
        {/**************************************************************************************************/}

        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Meeting Images/Videos
            </Typography>
          </Box>
        </Grid>

        {/* Meeting Image  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Cover Photo"
                buttonLabel="Set Meeting Photo"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Meeting By Image  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`meetingByPhotoObj`}
            control={control}
            defaultValue={defaultValues.meetingByPhotoObj}
            render={props => (
              <UploadFiles
                required
                label="Hotel Logo"
                buttonLabel="Set Hotel Logo"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {/* Meeting Video  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`videoObj`}
            control={control}
            defaultValue={defaultValues.videoObj}
            render={props => (
              <UploadFiles
                label="Main Video"
                buttonLabel="Set Meeting Video"
                singleFile
                video
                enableImages={false}
                onSave={fileArray => {
                  props.onChange(fileArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
                itemsWidth={400}
                itemsHeight={200}
              />
            )}
          />
        </Grid>

        {/* Meeting Gallery  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`gallery`}
            control={control}
            defaultValue={defaultValues.gallery}
            render={props => (
              <GalleryComboBox
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>
        {/*************************************************************************************************************/}
        {/************************************************** Meeting Team *************************************************/}
        {/**************************************************************************************************/}
        <Grid item xs={12}>
          <Box mt={8}>
            <Typography variant="h6" component="label">
              Team Information
            </Typography>
          </Box>
        </Grid>

        {/* Hotel Emails */}
        <Grid container item xs={12} spacing={2}>
          <Controller
            name={`team`}
            control={control}
            defaultValue={defaultValues.team}
            render={props => (
              <TeamFields
                value={props.value}
                readOnly={readOnly}
                onChange={props.onChange}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={8}>
            <Divider />
          </Box>
        </Grid>

        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

function TeamFields({onChange, value, readOnly = false}) {
  // Styles
  const classes = useStyles();

  const [team, setTeam] = useState(
    value && value.length > 0
      ? value
      : [
          {
            image_url: "",
            name: "",
            title: "",
          },
        ]
  );

  const addTeam = () => {
    setTeam([
      ...team,
      {
        image_url: "",
        name: "",
        title: "",
      },
    ]);
  };
  const handleTeamChange = (type, index, newValue) => {
    const updatedTeamArray = [...team];
    updatedTeamArray[index] = {
      ...updatedTeamArray[index],
      [type]: newValue,
    };
    setTeam(updatedTeamArray);
  };
  const handleTeamRemove = index => {
    const updatedTeamArray = team.filter((value, idx) => idx !== index);
    setTeam(updatedTeamArray);
  };

  useEffect(() => {
    onChange(team);
  }, [onChange, team]);

  return (
    <>
      {team.map((teamEl, i) => (
        <Grid
          container
          item
          xs={12}
          key={`team-${i}`}
          className={classes.teamMemberContainer}
          spacing={3}>
          {i !== 0 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            alignItems="center">
            <Typography variant="body1" component="label">
              {`Team Member ${i + 1}`}
            </Typography>
            {team.length > 1 && !readOnly && (
              <IconButton
                aria-label="delete"
                onClick={() => handleTeamRemove(i)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={`Member Name`}
              value={teamEl.name}
              InputProps={{
                readOnly,
              }}
              onChange={e => handleTeamChange("name", i, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Member Title"
              value={teamEl.title}
              InputProps={{
                readOnly,
              }}
              onChange={e => handleTeamChange("title", i, e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadFiles
              required
              label="Member Photo"
              buttonLabel="Set Member Photo"
              singleFile
              onSave={photosArray => {
                if (photosArray.length)
                  handleTeamChange("image_url", i, photosArray[0].photoName);
              }}
              readOnly={readOnly}
              defaultValue={
                teamEl.image_url && teamEl.image_url !== ""
                  ? {photoName: teamEl.image_url}
                  : null
              }
            />
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        {!readOnly && (
          <Button
            className={classes.maxWidth}
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => addTeam()}>
            Add Team Member
          </Button>
        )}
      </Grid>
    </>
  );
}

export default MeetingsForm;
