import React, {useState} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {serverUpdate} from "../../../redux/features/settings/settingsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const UpdatesForm = () => {
  // Styles
  const classes = useStyles();

  // Local State
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {isLoadingUpdate, errorUpdate} = useSelector(state => state.settings);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const onServerUpdate = async key => {
    let serverName = "server";
    switch (key) {
      case "store":
        serverName = "Store Site";
        break;
      case "api":
        serverName = "Store API";
        break;
      case "admin":
        serverName = "Admin Dashboard API";
        break;
      default:
        break;
    }
    // Logic to show error or success
    const res = await dispatch(serverUpdate(key));
    if (!res.error) {
      setSnackbarContent(`${serverName} Server Update in Progress!`);
    } else {
      setSnackbarContent(
        `There was an error updating the ${serverName}. Please try again later.`
      );
    }
    setOpenSnackbar(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Click the button to update and rebuild the respective server.
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Front End</Typography>
        </Grid>
        <Grid container item xs={12}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoadingUpdate}
              onClick={() => onServerUpdate("store")}>
              Update Store Site
            </Button>
            {isLoadingUpdate && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Back End</Typography>
        </Grid>
        <Grid container item xs={12} md={6}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoadingUpdate}
              onClick={() => onServerUpdate("api")}>
              Update Store API
            </Button>
            {isLoadingUpdate && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
        <Grid container item xs={12} md={6}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoadingUpdate}
              onClick={() => onServerUpdate("admin")}>
              Update Admin Dashboard API
            </Button>
            {isLoadingUpdate && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={errorUpdate ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};

export default UpdatesForm;
