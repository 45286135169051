import React, {useState, useEffect} from "react";

// Redux
import {useSelector} from "react-redux";

// Routing
import {useHistory, useLocation, Redirect} from "react-router-dom";

// Components
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Icons
import tableIcons from "../Table/TableIcons";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";

const DisplayTablePage = ({
  baseUrl,
  tableTitle,
  tableColumns,
  addButtonLabel,
  dataList,
  isLoading,
  error,
  hideAddButton = false,
  idKey = "id",
  userPermissionKey,
  customActions = [],
  customOptions = {
    search: true,
    actionsColumnIndex: -1,
    pageSize: 10,
    thirdSortClick: false,
  },
}) => {
  // Routing
  const history = useHistory();
  const location = useLocation();

  // User Permission from redux
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {read, write} = userPermission[userPermissionKey];

  // Local State
  const [showSuccess, setShowSuccess] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [snackbarLink, setSnackbarLink] = useState("");

  useEffect(() => {
    if (location.state) {
      setShowSuccess(location.state.showSuccess);
      setSnackbarContent(location.state.snackbarContent);
      setSnackbarLink(location.state.snackbarLink);
    }
    return () => {
      setShowSuccess(false);
      setSnackbarContent("");
      setSnackbarLink("");
    };
  }, [location.state]);

  const handleSnackbarClose = () => {
    setShowSuccess(false);
    setSnackbarContent("");
  };

  // Need to spread array so it doesn't fail
  const formattedData = dataList.map(o => ({
    ...o,
  }));

  // Check if user has permission to read. If not redirect to 403
  if (!read) {
    return (
      <Redirect
        to={{
          pathname: "/403",
        }}
      />
    );
  }

  return (
    <Grid container spacing={3}>
      <>
        {/* Show button only if user has write permission  */}
        {!hideAddButton && !!write && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => history.push(`${baseUrl}/new`)}>
              {addButtonLabel}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MaterialTable
            title={tableTitle}
            isLoading={isLoading}
            icons={tableIcons}
            actions={
              customActions.length > 0
                ? customActions
                : [
                    {
                      icon: VisibilityIcon,
                      tooltip: !!write ? "View/Edit" : "View",
                      onClick: (event, rowData) => {
                        // Go to Edit page
                        history.push(`${baseUrl}/${rowData[idKey]}`);
                      },
                    },
                  ]
            }
            // if there is no error show columns, if there is error show empty table
            columns={!error ? tableColumns : []}
            data={formattedData}
            options={customOptions}
          />
        </Grid>
        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="success"
            action={
              snackbarLink ? (
                <Button size="small" onClick={() => history.push(snackbarLink)}>
                  View
                </Button>
              ) : null
            }>
            {snackbarContent}
          </MuiAlert>
        </Snackbar>
      </>
    </Grid>
  );
};

export default DisplayTablePage;
