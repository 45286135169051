import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = `${process.env.REACT_APP_API_BASE_URL}/v1/pages`;

const pagesInitialState = {
  pagesList: [],
  currentPagePage: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const pages = createSlice({
  name: "pages",
  initialState: pagesInitialState,
  reducers: {
    getPageStart: startLoading,
    getPagesStart: startLoading,
    createPageStart: startLoading,
    editPageStart: startLoading,
    deletePageStart: startLoading,
    getPageSuccess(state, {payload}) {
      state.currentPagePage = payload;
      state.isLoading = false;
      state.error = null;
    },
    getPagesSuccess(state, {payload}) {
      state.pagesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createPageSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editPageSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deletePageSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getPageFailure: loadingFailed,
    getPagesFailure: loadingFailed,
    createPageFailure: loadingFailed,
    editPageFailure: loadingFailed,
    deletePageFailure: loadingFailed,
  },
});

export const {
  getPageStart,
  getPagesStart,
  createPageStart,
  editPageStart,
  deletePageStart,
  getPageSuccess,
  getPagesSuccess,
  createPageSuccess,
  editPageSuccess,
  deletePageSuccess,
  getPageFailure,
  getPagesFailure,
  createPageFailure,
  editPageFailure,
  deletePageFailure,
} = pages.actions;

export default pages.reducer;

export const fetchPages = () => async (dispatch, getState) => {
  try {
    dispatch(getPagesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPagesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPagesFailure(errorObj));
  }
};

export const fetchPage = id => async (dispatch, getState) => {
  try {
    dispatch(getPageStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPageSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPageFailure(errorObj));
  }
};

export const createPage = pageObj => async (dispatch, getState) => {
  try {
    dispatch(createPageStart());
    const res = await axios.post(url, pageObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createPageSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createPageFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editPage = pageObj => async (dispatch, getState) => {
  try {
    dispatch(editPageStart());
    const res = await axios.put(url, pageObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editPageSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editPageFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deletePage = pageId => async (dispatch, getState) => {
  try {
    dispatch(deletePageStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: pageId},
    });
    dispatch(deletePageSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deletePageFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
