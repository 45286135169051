import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchEventCategories} from "../../../redux/features/events/categoriesSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const EventCategoriesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {eventCategoriesList, isLoading, error} = useSelector(
    state => state.eventCategories
  );

  useEffect(() => {
    dispatch(fetchEventCategories());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/events/categories"
        tableTitle="All Categories"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {title: "Slug", field: "slug"},
        ]}
        addButtonLabel="Event Category"
        dataList={eventCategoriesList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="events"
      />
    </>
  );
};

export default EventCategoriesPage;
