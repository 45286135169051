import React, {useState, useEffect} from "react";
// utils
import {useForm, Controller} from "react-hook-form";
import moment from "moment";

// Styling
import {makeStyles} from "@material-ui/core/styles";
// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import HotelComboBox from "../../FormFields/HotelComboBox";
import RoomComboBox from "../../FormFields/RoomComboBox";
import IntegerInput from "../../FormFields/IntegerInput";
import Divider from "@material-ui/core/Divider";
import CouponComboBox from "../../FormFields/CouponComboBox";
import CustomProductsComboBox from "../../FormFields/CustomProductsComboBox";
import AddonsProductsComboBoxes from "../../FormFields/AddonsProductsComboBoxes";
import CheckboxWithState from "../../FormFields/CheckboxWithState";

// name|room|hotel|addons|customAddons|check_in|check_out

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const CustomPackagesForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    coupon: null,
    customAddons: [],
    hotelRoom: {room: null, hotel: null},
    addonsProducts: {
      addons: [],
      products: [],
    },
    adults: 2,
    children: 0,
    babies: 0,
    seniors: 0,
    check_in: moment(),
    check_out: moment().add(1, "days"),
    enabled: true,
    custom_rate: false,
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {
      hotelRoom: {hotel, room},
      coupon,
      addonsProducts: {addons, products},
      customAddons,
      check_in,
      check_out,
      enabled,
      ...restOfData
    } = data;

    const totalAddons = [
      ...addons.flatMap(addon => addon.id),
      ...customAddons.flatMap(customAddon => customAddon.id),
    ];
    const formattedValues = {
      ...restOfData,

      package_status: "Published",
      room_id: room.id,
      hotel_id: hotel.id,
      addons: totalAddons,
      products: products.map(product => product.id),
      coupon_id: coupon ? coupon.id : null,
      check_in: moment(check_in).format("YYYY-MM-DD"),
      check_out: moment(check_out).format("YYYY-MM-DD"),
      enabled: enabled ? 1 : 0,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      {/* Custom Package Name */}
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="name"
            label="Name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Custom Package Check In */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="check_in"
            defaultValue={defaultValues.check_in}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Check In"
                format="MM/dd/yyyy"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change check in date",
                }}
              />
            )}
          />
        </Grid>
        {/* Custom Package Check Out */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="check_out"
            defaultValue={defaultValues.check_out}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Check Out"
                format="MM/dd/yyyy"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change check out date",
                }}
              />
            )}
          />
        </Grid>

        {/* Adults */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="adults"
            label="Adults"
            required
            type="number"
            defaultValue={defaultValues.adults}
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Children */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="children"
            label="Children"
            required
            type="number"
            defaultValue={defaultValues.children}
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Babies */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="babies"
            label="Babies"
            required
            type="number"
            defaultValue={defaultValues.babies}
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Seniors */}
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="seniors"
            label="Seniors"
            required
            type="number"
            defaultValue={defaultValues.seniors}
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Hotel and room information */}

        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" component="label">
              Hotel Information
            </Typography>
          </Grid>
          <Controller
            name={`hotelRoom`}
            control={control}
            defaultValue={defaultValues.hotelRoom}
            render={props => (
              <HotelRoomComboBoxes
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" component="label">
            Select the Addons/Products and coupons
          </Typography>
        </Grid>

        {/* Addons /Products  */}
        {/* // TODO: Figure Out how to do multiple values on default values */}
        <Controller
          name={`addonsProducts`}
          control={control}
          defaultValue={defaultValues.addonsProducts}
          render={props => (
            <AddonsProductsComboBoxes
              value={props.value}
              onChange={props.onChange}
              readOnly={readOnly}
            />
          )}
        />

        {/* Custom Addons  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`customAddons`}
            control={control}
            defaultValue={defaultValues.customAddons}
            render={props => (
              <CustomProductsComboBox
                id="custom-addons-select"
                fieldLabel="Select Custom Addons *"
                readOnly={readOnly}
                defaultValues={props.value}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>

        {/* Coupons */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`coupon`}
            control={control}
            defaultValue={defaultValues.coupon}
            render={props => (
              <CouponComboBox
                readOnly={readOnly}
                defaultValue={props.value}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Custom Package Enabled */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`enabled`}
            control={control}
            defaultValue={defaultValues.enabled}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Enabled"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Custom Rate  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`custom_rate`}
            control={control}
            defaultValue={defaultValues.custom_rate}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Use Custom Rate"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const HotelRoomComboBoxes = ({value, onChange, readOnly}) => {
  const [hotel, setHotel] = useState(value.hotel || null);
  const [room, setRoom] = useState(value.room || null);

  useEffect(() => {
    if (hotel !== null && room !== null) {
      onChange({
        hotel,
        room,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel, room]);

  return (
    <Grid container item xs={12} spacing={5}>
      {/* Custom Package Hotel  */}
      <Grid item xs={12} md={6}>
        <HotelComboBox
          required
          label="Hotel"
          defaultValue={hotel}
          onChange={setHotel}
          readOnly={readOnly}
        />
      </Grid>

      {/* Custom Package Hotel Room */}
      <Grid container item xs={12} md={6} alignItems="flex-end">
        {hotel !== null && (
          <Grid item xs={12}>
            <RoomComboBox
              defaultValue={room}
              hotel_id={hotel.id}
              onChange={setRoom}
              readOnly={readOnly}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomPackagesForm;
