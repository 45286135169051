import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchGalleries} from "../../redux/features/media/galleriesSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const GalleriesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {galleriesList, isLoading, error} = useSelector(
    state => state.galleries
  );

  useEffect(() => {
    dispatch(fetchGalleries());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/media/galleries"
        tableTitle="All Galleries"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Gallery Name", field: "name"},
          {
            title: "No. of Images",
            field: "photoQty",
            type: "numeric",
            width: 200,
          },
        ]}
        addButtonLabel="Gallery"
        dataList={galleriesList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="media"
      />
    </>
  );
};

export default GalleriesPage;
