import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";
import { saveState } from "../localStorage";

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
});

store.subscribe(() => {
  saveState(store.getState().auth.token, "auth.token");
  saveState(store.getState().ui.mode, "ui.mode");
  saveState(store.getState().ui.palette, "ui.palette");
});
