import {createSlice} from "@reduxjs/toolkit";

const handleError = (state, {payload}) => {
  //  Only update if there is not already an error
  if (state.errorCode !== "") return;

  let message = "";
  if (!payload.errorMessage) {
    switch (payload.errorCode) {
      case 429:
        message = "Slow Down there! You are making TOO MANY REQUESTS!";
        break;
      case 403:
        message = "You don't have the necessary permissions to do this action!";
        break;
      case 404:
        message = "We can't find what you are looking for!";
        break;
      case 500:
        message = "Server Error. Try again later.";
        break;
      default:
        message = "We've encountered an unknown error. Please contact support.";
    }
  } else {
    message = payload.errorMessage;
  }

  state.errorCode = payload.errorCode;
  state.errorMessage = message;
};

const errorSlice = createSlice({
  name: "errors",
  initialState: {
    errorCode: "",
    errorMessage: "",
  },
  reducers: {
    setError(state, {payload}) {
      state.errorCode = payload.errorCode;
      state.errorMessage = payload.errorMessage;
    },
    dismissError(state, {payload}) {
      state.errorCode = "";
      state.errorMessage = "";
    },
  },
  extraReducers: {
    // TODO: Auth
    /* eslint-disable no-useless-computed-key*/
    ["auth/authFailure"]: handleError,

    //   Addons
    ["addons/getAddonFailure"]: handleError,
    ["addons/getAddonsFailure"]: handleError,
    ["addons/getCustomAddonsFailure"]: handleError,
    ["addons/createAddonFailure"]: handleError,
    ["addons/editAddonFailure"]: handleError,
    ["addons/deleteAddonFailure"]: handleError,

    // Coupons
    ["coupons/getCouponFailure"]: handleError,
    ["coupons/getCouponsFailure"]: handleError,
    ["coupons/createCouponFailure"]: handleError,
    ["coupons/editCouponFailure"]: handleError,
    ["coupons/deleteCouponFailure"]: handleError,

    // Events
    ["events/getEventFailure"]: handleError,
    ["events/getEventsFailure"]: handleError,
    ["events/createEventFailure"]: handleError,
    ["events/editEventFailure"]: handleError,
    ["events/deleteEventFailure"]: handleError,
    // EventCategories
    ["eventCategories/getEventCategoryFailure"]: handleError,
    ["eventCategories/getEventCategoriesFailure"]: handleError,
    ["eventCategories/createEventCategoryFailure"]: handleError,
    ["eventCategories/editEventCategoryFailure"]: handleError,
    ["eventCategories/deleteEventCategoryFailure"]: handleError,
    // EventOrganizers
    ["eventOrganizers/getEventOrganizerFailure"]: handleError,
    ["eventOrganizers/getEventOrganizersFailure"]: handleError,
    ["eventOrganizers/createEventOrganizerFailure"]: handleError,
    ["eventOrganizers/editEventOrganizerFailure"]: handleError,
    ["eventOrganizers/deleteEventOrganizerFailure"]: handleError,
    // EventSource
    ["eventSources/getEventSourceFailure"]: handleError,
    ["eventSources/getEventSourcesFailure"]: handleError,
    ["eventSources/createEventSourceFailure"]: handleError,
    ["eventSources/editEventSourceFailure"]: handleError,
    ["eventSources/deleteEventSourceFailure"]: handleError,
    // EventVenue
    ["eventVenues/getEventVenueFailure"]: handleError,
    ["eventVenues/getEventVenuesFailure"]: handleError,
    ["eventVenues/createEventVenueFailure"]: handleError,
    ["eventVenues/editEventVenueFailure"]: handleError,
    ["eventVenues/deleteEventVenueFailure"]: handleError,

    // Coupons
    ["meetings/getMeetingFailure"]: handleError,
    ["meetings/getMeetingsFailure"]: handleError,
    ["meetings/editMeetingFailure"]: handleError,

    // Hotel
    ["hotels/getHotelFailure"]: handleError,
    ["hotels/getHotelsFailure"]: handleError,
    ["hotels/createHotelFailure"]: handleError,
    ["hotels/editHotelFailure"]: handleError,
    ["hotels/deleteHotelFailure"]: handleError,
    // Room
    ["rooms/getRoomFailure"]: handleError,
    ["rooms/getRoomsFailure"]: handleError,
    ["rooms/createRoomFailure"]: handleError,
    ["rooms/editRoomFailure"]: handleError,
    ["rooms/deleteRoomFailure"]: handleError,

    // Condo
    ["condos/getCondoFailure"]: handleError,
    ["condos/getCondosFailure"]: handleError,
    ["condos/createCondoFailure"]: handleError,
    ["condos/editCondoFailure"]: handleError,
    ["condos/deleteCondoFailure"]: handleError,

    // Icon
    ["icons/getIconFailure"]: handleError,
    ["icons/getIconsFailure"]: handleError,

    // Gallery
    ["galleries/getGalleryFailure"]: handleError,
    ["galleries/getGalleriesFailure"]: handleError,
    ["galleries/createGalleryFailure"]: handleError,
    ["galleries/editGalleryFailure"]: handleError,
    ["galleries/deleteGalleryFailure"]: handleError,
    // Media
    ["media/getFileFailure"]: handleError,
    ["media/getFilesFailure"]: handleError,
    ["media/uploadFilesFailure"]: handleError,
    ["media/editFileFailure"]: handleError,
    ["media/deleteFileFailure"]: handleError,

    // PackageCard
    ["packageCards/getPackageCardFailure"]: handleError,
    ["packageCards/getPackageCardsFailure"]: handleError,
    ["packageCards/createPackageCardFailure"]: handleError,
    ["packageCards/editPackageCardFailure"]: handleError,
    ["packageCards/deletePackageCardFailure"]: handleError,
    // PackageCategory
    ["packageCategories/getPackageCategoryFailure"]: handleError,
    ["packageCategories/getPackageCategoriesFailure"]: handleError,
    ["packageCategories/createPackageCategoryFailure"]: handleError,
    ["packageCategories/editPackageCategoryFailure"]: handleError,
    ["packageCategories/deletePackageCategoryFailure"]: handleError,
    // CustomPackage
    ["customPackages/getCustomPackageFailure"]: handleError,
    ["customPackages/getCustomPackagesFailure"]: handleError,
    ["customPackages/createCustomPackageFailure"]: handleError,
    ["customPackages/editCustomPackageFailure"]: handleError,
    ["customPackages/deleteCustomPackageFailure"]: handleError,
    // PackageRegular
    ["packageRegulars/getPackageRegularFailure"]: handleError,
    ["packageRegulars/getPackageRegularsFailure"]: handleError,
    ["packageRegulars/createPackageRegularFailure"]: handleError,
    ["packageRegulars/editPackageRegularFailure"]: handleError,
    ["packageRegulars/deletePackageRegularFailure"]: handleError,

    // Page
    ["pages/getPageFailure"]: handleError,
    ["pages/getPagesFailure"]: handleError,
    ["pages/editPageFailure"]: handleError,
    ["pages/createPageFailure"]: handleError,
    ["pages/deletePageFailure"]: handleError,

    // Address
    ["addresses/getAddressFailure"]: handleError,
    ["addresses/getAddressesFailure"]: handleError,
    ["addresses/createAddressFailure"]: handleError,
    ["addresses/editAddressFailure"]: handleError,
    ["addresses/deleteAddressFailure"]: handleError,

    // Charge
    ["charges/getChargeFailure"]: handleError,
    ["charges/getChargesFailure"]: handleError,
    ["charges/createChargeFailure"]: handleError,
    ["charges/editChargeFailure"]: handleError,
    ["charges/deleteChargeFailure"]: handleError,

    // Customer
    ["customers/getCustomerFailure"]: handleError,
    ["customers/getCustomersFailure"]: handleError,
    ["customers/createCustomerFailure"]: handleError,
    ["customers/editCustomerFailure"]: handleError,
    ["customers/deleteCustomerFailure"]: handleError,

    // Order
    ["orders/getOrderFailure"]: handleError,
    ["orders/getOrdersFailure"]: handleError,
    ["orders/createOrderFailure"]: handleError,
    ["orders/editOrderFailure"]: handleError,
    ["orders/deleteOrderFailure"]: handleError,

    // PaymentMethod
    ["paymentMethods/getPaymentMethodFailure"]: handleError,
    ["paymentMethods/getPaymentMethodsFailure"]: handleError,
    ["paymentMethods/createPaymentMethodFailure"]: handleError,
    ["paymentMethods/editPaymentMethodFailure"]: handleError,
    ["paymentMethods/deletePaymentMethodFailure"]: handleError,

    // Rate
    ["rates/getRateFailure"]: handleError,
    ["rates/getRatesFailure"]: handleError,
    ["rates/createRateFailure"]: handleError,
    ["rates/editRateFailure"]: handleError,
    ["rates/deleteRateFailure"]: handleError,
    ["rates/getRateshopperFailure"]: handleError,
    ["rates/syncRateshopperFailure"]: handleError,

    // Refund
    ["refunds/getRefundFailure"]: handleError,
    ["refunds/getRefundsFailure"]: handleError,
    ["refunds/createRefundFailure"]: handleError,
    ["refunds/editRefundFailure"]: handleError,
    ["refunds/deleteRefundFailure"]: handleError,

    // User
    ["users/getUserFailure"]: handleError,
    ["users/getUsersFailure"]: handleError,
    ["users/createUserFailure"]: handleError,
    ["users/editUserFailure"]: handleError,
    ["users/deleteUserFailure"]: handleError,
    /* eslint-enable no-useless-computed-key */
  },
});

export const {setError, dismissError} = errorSlice.actions;

export default errorSlice.reducer;
