import React, {useState, useEffect} from "react";

//Utils
import {validateUrl} from "../../helpers/stringFormatters";

// Components
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const PermalinkTextField = ({
  onChange,
  value = "",
  required = false,
  name = "",
  label = "",
  startAdornment = process.env.REACT_APP_DOMAIN,
  readOnly = false,
  ...rest
}) => {
  const [permalink, setPermalink] = useState(value);
  useEffect(() => {
    onChange(permalink);
  }, [onChange, permalink]);
  return (
    <TextField
      {...rest}
      required={required}
      name={name}
      label={label}
      fullWidth
      value={permalink}
      onChange={e => setPermalink(validateUrl(e.target.value))}
      InputProps={{
        readOnly,
        startAdornment: (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
      }}
    />
  );
};

export default PermalinkTextField;
