import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/charges";

const chargesInitialState = {
  chargesList: [],
  currentPageCharge: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const charges = createSlice({
  name: "charges",
  initialState: chargesInitialState,
  reducers: {
    getChargeStart: startLoading,
    getChargesStart: startLoading,
    createChargeStart: startLoading,
    editChargeStart: startLoading,
    deleteChargeStart: startLoading,
    getChargeSuccess(state, {payload}) {
      state.currentPageCharge = payload;
      state.isLoading = false;
      state.error = null;
    },
    getChargesSuccess(state, {payload}) {
      state.chargesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createChargeSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editChargeSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteChargeSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getChargeFailure: loadingFailed,
    getChargesFailure: loadingFailed,
    createChargeFailure: loadingFailed,
    editChargeFailure: loadingFailed,
    deleteChargeFailure: loadingFailed,
  },
});

export const {
  getChargeStart,
  getChargesStart,
  createChargeStart,
  editChargeStart,
  deleteChargeStart,
  getChargeSuccess,
  getChargesSuccess,
  createChargeSuccess,
  editChargeSuccess,
  deleteChargeSuccess,
  getChargeFailure,
  getChargesFailure,
  createChargeFailure,
  editChargeFailure,
  deleteChargeFailure,
} = charges.actions;

export default charges.reducer;

export const fetchCharges = () => async (dispatch, getState) => {
  try {
    dispatch(getChargesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getChargesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getChargesFailure(errorObj));
  }
};

export const fetchCharge = id => async (dispatch, getState) => {
  try {
    dispatch(getChargeStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getChargeSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getChargeFailure(errorObj));
  }
};

export const createCharge = chargeObj => async (dispatch, getState) => {
  try {
    dispatch(createChargeStart());
    const res = await axios.post(url, chargeObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createChargeSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createChargeFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editCharge = chargeObj => async (dispatch, getState) => {
  try {
    dispatch(editChargeStart());
    const res = await axios.put(url, chargeObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editChargeSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editChargeFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteCharge = chargeId => async (dispatch, getState) => {
  try {
    dispatch(deleteChargeStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: chargeId},
    });
    dispatch(deleteChargeSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteChargeFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
