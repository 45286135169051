import React, {useState, useEffect} from "react";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DeleteButton from "../Buttons/DeleteButton";

// Icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const ArrayOfStrings = ({
  onChange,
  value,
  label = "Value ",
  type = "text",
  readOnly = false,
}) => {
  const [stringArray, setStringArray] = useState(
    value && value.length > 0 ? value : [""]
  );

  const addValue = () => {
    setStringArray([...stringArray, ""]);
  };
  const handleValueChange = (index, newValue) => {
    const updatedArray = [...stringArray];
    updatedArray[index] = newValue;

    setStringArray(updatedArray);
  };
  const handleValueRemove = index => {
    const updatedArray = stringArray.filter((value, idx) => idx !== index);
    setStringArray(updatedArray);
  };

  useEffect(() => {
    onChange(stringArray);
  }, [onChange, stringArray]);

  return (
    <>
      {stringArray.map((stringArrayEl, i) => (
        <React.Fragment key={`stringArray-${i}`}>
          <Grid item xs={10}>
            <TextField
              key={`stringArray-${i}`}
              required
              fullWidth
              type={type}
              label={`${label}  ${i + 1}`}
              value={stringArrayEl}
              InputProps={{
                readOnly,
              }}
              onChange={e => handleValueChange(i, e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            {stringArray.length > 1 && !readOnly && (
              <Box mb={1} ml={2} display="inline-block" color="error.main">
                <DeleteButton
                  aria-label="delete"
                  onClick={() => handleValueRemove(i)}
                  size="large"
                  startIcon={<DeleteIcon />}>
                  Delete
                </DeleteButton>
              </Box>
            )}
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        {!readOnly && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => addValue()}>
            Add {label}
          </Button>
        )}
      </Grid>
    </>
  );
};

export default ArrayOfStrings;
