import React from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../redux/features/auth/authSlice";

// Components
import Button from "@material-ui/core/Button";

const Logout = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => {
    return state.auth;
  });

  const handleClick = () => {
    dispatch(logOut(auth.token));
  };

  return (
    <Button color="primary" fullWidth onClick={() => handleClick()}>
      Logout
    </Button>
  );
};

export default Logout;
