import React from "react";

// utils
import moment from "moment";
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import {KeyboardDatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormatCustom from "../FormFields/NumberFormatCustom";
import IntegerInput from "../FormFields/IntegerInput";
import MultipleHotelsComboBox from "../FormFields/MultipleHotelsComboBox";
import MultipleCondosComboBox from "../FormFields/MultipleCondosComboBox";
import MultiplePackagesComboBox from "../FormFields/MultiplePackagesComboBox";
import CheckboxWithState from "../FormFields/CheckboxWithState";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
}));

const CouponsForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    description: "",
    coupon_code: "",
    type: "currency",
    discount_amount: 50,
    max_discount: 100,
    min_order_total: 50,
    min_night_stay: 3,
    min_people: 3,
    uses_left: 10,
    start_date: moment(),
    end_date: moment().add(1, "days"),
    booking_date_start: moment(),
    booking_date_end: moment().add(1, "days"),
    only_complete_payment: 0,
    multiple_uses_same_customer: 0,
    enabled: 1,
    packages: [],
    hotels: [],
    condos: [],
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register, watch} = useForm({defaultValues});

  const selectedType = watch("type");

  const formSubmit = data => {
    const {
      start_date,
      end_date,
      booking_date_start,
      booking_date_end,
      enabled,
      only_complete_payment,
      multiple_uses_same_customer,
      packages,
      hotels,
      condos,
      ...restOfData
    } = data;
    const formattedValues = {
      ...restOfData,
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      booking_date_start: moment(booking_date_start).format("YYYY-MM-DD"),
      booking_date_end: moment(booking_date_end).format("YYYY-MM-DD"),
      enabled: enabled ? 1 : 0,
      only_complete_payment: only_complete_payment ? 1 : 0,
      multiple_uses_same_customer: multiple_uses_same_customer ? 1 : 0,
      packages: packages.map(pkg => pkg.id),
      hotels: hotels.map(hotel => hotel.id),
      condos: condos.map(condo => condo.condos_id),
      rooms: [],
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Coupon Name  */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            name="name"
            label="Name"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Coupon Code */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="coupon_code"
            label="Coupon Code"
            required
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Coupon Description */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="description"
            label="Coupon Description"
            multiline
            required
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Coupon Start Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="start_date"
            defaultValue={defaultValues.start_date}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Coupon Start Date"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change start date",
                }}
              />
            )}
          />
        </Grid>

        {/* Coupon End Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="end_date"
            defaultValue={defaultValues.end_date}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Coupon End Date"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change end date",
                }}
              />
            )}
          />
        </Grid>

        {/* Coupon Booking Start Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="booking_date_start"
            defaultValue={defaultValues.booking_date_start}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Booking Start Date"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change start date",
                }}
              />
            )}
          />
        </Grid>
        {/* Coupon Booking End Date */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="booking_date_end"
            defaultValue={defaultValues.booking_date_end}
            render={props => (
              <KeyboardDatePicker
                className={classes.maxWidth}
                label="Booking End Date"
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                KeyboardButtonProps={{
                  "aria-label": "change end date",
                }}
              />
            )}
          />
        </Grid>

        {/* Coupon Discount Type */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`type`}
            control={control}
            defaultValue={defaultValues.type}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"currency"}>Currency</MenuItem>
                  <MenuItem value={"percentage"}>Percentage</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Coupon Discount Amount */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Discount Amount"
            name="discount_amount"
            defaultValue={defaultValues.discount_amount}
            inputProps={{
              thousandSeparator: true,
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  {selectedType === "currency" ? "$" : "%"}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Coupon Max Discount */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Max Discount"
            name="max_discount"
            defaultValue={defaultValues.max_discount}
            inputProps={{
              thousandSeparator: true,
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Coupon Min Order Total */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Minimum Order Total"
            name="min_order_total"
            defaultValue={defaultValues.min_order_total}
            inputProps={{
              thousandSeparator: true,
            }}
            InputProps={{
              readOnly,
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Coupon MinNightStay */}
        <Grid item xs={12} md={4}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="min_night_stay"
            defaultValue={defaultValues.min_night_stay}
            label="Minimum Night Stay"
            required
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Coupon Min People*/}
        <Grid item xs={12} md={4}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="min_people"
            defaultValue={defaultValues.min_people}
            label="Minimum No. Of People"
            required
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Coupon Uses */}
        <Grid item xs={12} md={4}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="uses_left"
            defaultValue={defaultValues.uses_left}
            label="No. of Uses"
            required
            InputProps={{
              readOnly,
              inputComponent: IntegerInput,
            }}
          />
        </Grid>

        {/* Packages  */}
        <Grid item xs={12}>
          <Controller
            name="packages"
            control={control}
            defaultValue={defaultValues.packages}
            render={props => (
              <MultiplePackagesComboBox
                id="package-select"
                fieldLabel="Select Packages (Optional)"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Hotels  */}
        <Grid item xs={12}>
          <Controller
            name="hotels"
            control={control}
            defaultValue={defaultValues.hotels}
            render={props => (
              <MultipleHotelsComboBox
                id="hotel-select"
                fieldLabel="Select Hotels (Optional)"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Condos  */}
        <Grid item xs={12}>
          <Controller
            name="condos"
            control={control}
            defaultValue={defaultValues.condos}
            render={props => (
              <MultipleCondosComboBox
                id="condo-select"
                fieldLabel="Select Condos (Optional)"
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Coupon Only Complete Payment */}
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name={`only_complete_payment`}
            control={control}
            defaultValue={defaultValues.only_complete_payment}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Use only on complete payment"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Coupon Multiple Uses Same Customer */}
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name={`multiple_uses_same_customer`}
            control={control}
            defaultValue={defaultValues.multiple_uses_same_customer}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Use multiple times for same customer"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Coupon Enabled */}
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            name={`enabled`}
            control={control}
            defaultValue={defaultValues.enabled}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Enabled"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CouponsForm;
