import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/events/categories";

const eventCategoriesInitialState = {
  eventCategoriesList: [],
  currentPageEventCategory: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const eventCategories = createSlice({
  name: "eventCategories",
  initialState: eventCategoriesInitialState,
  reducers: {
    getEventCategoryStart: startLoading,
    getEventCategoriesStart: startLoading,
    createEventCategoryStart: startLoading,
    editEventCategoryStart: startLoading,
    deleteEventCategoryStart: startLoading,
    getEventCategorySuccess(state, {payload}) {
      state.currentPageEventCategory = payload;
      state.isLoading = false;
      state.error = null;
    },
    getEventCategoriesSuccess(state, {payload}) {
      state.eventCategoriesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createEventCategorySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editEventCategorySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteEventCategorySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getEventCategoryFailure: loadingFailed,
    getEventCategoriesFailure: loadingFailed,
    createEventCategoryFailure: loadingFailed,
    editEventCategoryFailure: loadingFailed,
    deleteEventCategoryFailure: loadingFailed,
  },
});

export const {
  getEventCategoryStart,
  getEventCategoriesStart,
  createEventCategoryStart,
  editEventCategoryStart,
  deleteEventCategoryStart,
  getEventCategorySuccess,
  getEventCategoriesSuccess,
  createEventCategorySuccess,
  editEventCategorySuccess,
  deleteEventCategorySuccess,
  getEventCategoryFailure,
  getEventCategoriesFailure,
  createEventCategoryFailure,
  editEventCategoryFailure,
  deleteEventCategoryFailure,
} = eventCategories.actions;

export default eventCategories.reducer;

export const fetchEventCategories = () => async (dispatch, getState) => {
  try {
    dispatch(getEventCategoriesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventCategoriesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventCategoriesFailure(errorObj));
  }
};

export const fetchEventCategory = id => async (dispatch, getState) => {
  try {
    dispatch(getEventCategoryStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventCategorySuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventCategoryFailure(errorObj));
  }
};

export const createEventCategory = categoryObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createEventCategoryStart());
    const res = await axios.post(url, categoryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createEventCategorySuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createEventCategoryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editEventCategory = categoryObj => async (dispatch, getState) => {
  try {
    dispatch(editEventCategoryStart());
    const res = await axios.put(url, categoryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editEventCategorySuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editEventCategoryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteEventCategory = eventId => async (dispatch, getState) => {
  try {
    dispatch(deleteEventCategoryStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: eventId},
    });
    dispatch(deleteEventCategorySuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteEventCategoryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
