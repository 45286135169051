import React from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    position: "relative",
    minHeight: "300px",
  },
}));

const MainPaper = ({children, ...rest}) => {
  // Styles
  const classes = useStyles();
  return (
    <Paper className={classes.paper} {...rest}>
      {children}
    </Paper>
  );
};

export default MainPaper;
