import React, {useState, useEffect} from "react";

// Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function CheckboxWithState({
  onChange,
  value,
  label = "Check",
  readOnly = false,
  ...rest
}) {
  const [checked, setChecked] = useState(value === 1 ? true : false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    onChange(checked);
  }, [onChange, checked]);
  return (
    <FormControlLabel
      {...rest}
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          disabled={readOnly}
        />
      }
      label={label}
    />
  );
}
export default CheckboxWithState;
