import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchPackageCategory,
  editPackageCategory,
  deletePackageCategory,
} from "../../../redux/features/packages/categoriesSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../../UI/MainPaper";
import PackageCategoriesForm from "./PackageCategoriesForm";
import FullContainerProgress from "../../UI/FullContainerProgress";
import DeleteButton from "../../Buttons/DeleteButton";
import DeleteDialog from "../../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../../Navigation/BreadcrumbsWithEdit";

const PackageCategoriesEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPagePackageCategory, isLoading, error} = useSelector(
    state => state.packageCategories
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.packages;

  const formattedValues = {
    name: currentPagePackageCategory.name,
    slug: currentPagePackageCategory.slug,
    color: currentPagePackageCategory.color,
    background: currentPagePackageCategory.background,
    parent_id: currentPagePackageCategory.parent_id,
  };

  let breadcrumbNameMap = {
    "/packages": "Packages",
    "/packages/categories": "Package Categories",
  };
  breadcrumbNameMap[`/packages/categories/${id}`] =
    currentPagePackageCategory.name;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchPackageCategory(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const packageCategoryObj = {
      category_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editPackageCategory(packageCategoryObj));
    if (!res.error) {
      history.push({
        pathname: "/packages/categories",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${packageCategoryObj.name} were saved successfully!`,
          snackbarLink: `/packages/categories/${packageCategoryObj.category_id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deletePackageCategory(id));
    if (!res.error) {
      history.push({
        pathname: "/packages/categories",
        state: {
          showSuccess: true,
          snackbarContent: `The Package Category was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if category doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPagePackageCategory.name}
                </Typography>
                <PackageCategoriesForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Package Category
            </DeleteButton>
          </Grid>
        )}
      </Grid>

      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Package Category?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default PackageCategoriesEdit;
