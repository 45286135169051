import React from "react";

//Utils
import {useForm, Controller} from "react-hook-form";
import moment from "moment";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import HotelComboBox from "../../FormFields/HotelComboBox";
import AddonsProductsComboBoxes from "../../FormFields/AddonsProductsComboBoxes";
import CategoriesComboBox from "../../FormFields/CategoriesComboBox";
import DisclaimerBox from "../../FormFields/Disclaimer";
import DatePairBox from "../../FormFields/DateList";
import UploadFiles from "../../FormFields/UploadFiles";
import RichTextEditor from "../../FormFields/RichTextEditor";
import GalleryComboBox from "../../FormFields/GalleryComboBox";
import PermalinkTextField from "../../FormFields/PermalinkTextField";
import CheckboxWithState from "../../FormFields/CheckboxWithState";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const PackageRegularForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    permalink: "",
    tagline: "",
    description: "",
    short_description: "",
    photoObj: null,
    gallery: null,
    featured: 0,
    is_fixed: 0,
    custom_rate: 0,
    hosted_in: "",
    min_stay: 3,
    languages: "",
    disclaimer: [],
    package_status: "Published",
    order_index: 1000,
    hotels: [],
    addonsProducts: {
      addons: [],
      products: [],
    },
    categories: [],
    dates: [
      {
        start: moment().format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    ],
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {
      photoObj,
      gallery,
      featured,
      fixed,
      custom_rate,
      hotels,
      addonsProducts,
      categories,
      dates,
      ...restOfData
    } = data;
    const formattedValues = {
      photoId: photoObj ? photoObj.photoId : null,
      gallery_id: gallery.id,
      featured: featured ? 1 : 0,
      is_fixed: fixed ? 1 : 0,
      custom_rate,
      hotels: hotels.map(hotel => hotel.id),
      addons: addonsProducts.addons.map(addon => addon.id),
      products: addonsProducts.products.map(product => product.id),
      categories: categories.map(category => category.id),
      dates: dates.map(dates => ({start: dates.start, end: dates.end})),
      ...restOfData,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      {/* name: Joi.string().required(), */}
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="name"
            label="Package name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>
        {/* permalink: Joi.string().required(), */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="permalink"
            defaultValue={defaultValues.permalink}
            render={props => (
              <PermalinkTextField
                value={props.value}
                required
                label="Package Permalink"
                onChange={props.onChange}
                startAdornment={process.env.REACT_APP_DOMAIN + "/packages/"}
                fullWidth
                readOnly={readOnly}
              />
            )}
          />
        </Grid>

        {/* tagline: Joi.string().required(), */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="tagline"
            label="Tag Line"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>
        {/* description: Joi.string().required(), */}
        {/* Page body*/}
        <Controller
          name={`description`}
          control={control}
          defaultValue={defaultValues.description}
          render={props => (
            <Grid item xs={12}>
              <RichTextEditor
                label="Description"
                withPictureUploader
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
                required
                height={400}
              />
            </Grid>
          )} // props contains: onChange, onBlur and value
        />

        {/* short_description: Joi.string().required(), */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="short_description"
            defaultValue={defaultValues.short_description}
            label="Short Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* featured: Joi.number().integer().min(0).max(1).required(), */}
        <Grid item xs={12} md={4}>
          <Controller
            name={`featured`}
            control={control}
            defaultValue={defaultValues.featured}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Featured"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Custom Rate */}
        <Grid item xs={12} md={4}>
          <Controller
            name={`custom_rate`}
            control={control}
            defaultValue={defaultValues.custom_rate}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Use Custom Rate"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Is Fixed  */}
        <Grid item xs={12} md={4}>
          <Controller
            name={`fixed`}
            control={control}
            defaultValue={defaultValues.fixed}
            render={props => (
              <CheckboxWithState
                className={classes.maxWidth}
                value={props.value}
                disabled={readOnly}
                onChange={props.onChange}
                label="Is Fixed"
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* photo: Joi.number().integer().min(1).required(), */}
        {/* Package  Picture */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Package Photo"
                buttonLabel="Set Package Photo"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>
        {/* gallery_id: Joi.number().integer().min(1).required(), */}
        {/* Package Gallery  */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`gallery`}
            control={control}
            defaultValue={defaultValues.gallery}
            render={props => (
              <GalleryComboBox
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )}
          />
        </Grid>
        {/* hosted_in: Joi.string().required(), */}
        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="hosted_in"
            label="Hosted In"
            multiline
            required
            InputProps={{
              readOnly,
            }}
          />
        </Grid>
        {/* min_stay: Joi.number().integer().min(0).required(), */}
        <Grid item xs={12} lg={6}>
          <TextField
            className={classes.maxWidth}
            name="min_stay"
            label="Minimum Night Stay"
            required
            type="number"
            InputProps={{
              readOnly,
            }}
            inputRef={register}
          />
        </Grid>
        {/* languages: Joi.string().required(), */}
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            name="languages"
            label="Languages"
            required
            InputProps={{
              readOnly,
            }}
            inputRef={register}
          />
        </Grid>

        {/* package_status: Joi.string().valid('Draft', 'Published', 'Private').required(), */}
        <Grid item xs={12}>
          <Controller
            name={`package_status`}
            control={control}
            defaultValue={defaultValues.package_status || ""}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Published"}>Published</MenuItem>
                  <MenuItem value={"Private"}>Private</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/* order_index:  Joi.number().integer().min(0).default(1000), */}
        <Grid item xs={12} lg={6}>
          <TextField
            className={classes.maxWidth}
            name="order_index"
            label="Display Priority"
            required
            type="number"
            InputProps={{
              readOnly,
            }}
            inputRef={register}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Typography variant="h6" component="label" gutterBottom>
            Available Dates
          </Typography>
        </Grid>

        <Controller
          name={`dates`}
          control={control}
          defaultValue={defaultValues.dates}
          render={props => (
            <DatePairBox
              value={props.value}
              onChange={props.onChange}
              readOnly={readOnly}
            />
          )}
        />

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* DISCLAIMER COMPONENTS => disclaimer: Joi.array() */}
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Typography variant="h6" component="label" gutterBottom>
            Disclaimers
          </Typography>
        </Grid>

        <Controller
          name={`disclaimer`}
          control={control}
          defaultValue={defaultValues.disclaimer}
          render={props => (
            <DisclaimerBox
              value={props.value}
              onChange={props.onChange}
              readOnly={readOnly}
            />
          )}
        />

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* // TODO: Figure Out how to do multiple values on default values */}
        <Controller
          name={`addonsProducts`}
          control={control}
          defaultValue={defaultValues.addonsProducts}
          render={props => (
            <AddonsProductsComboBoxes
              value={props.value}
              onChange={props.onChange}
              readOnly={readOnly}
            />
          )}
        />

        {/* hotels: Joi.array().items(Joi.number()).required(), */}
        <Grid item xs={12} md={6}>
          <Controller
            name="hotels"
            control={control}
            defaultValue={defaultValues.hotels}
            render={props => (
              <HotelComboBox
                id="hotel-select"
                fieldLabel="Select Hotels (Optional)"
                helperText="If left empty package won't be available."
                multiple
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* categories: Joi.array().items(Joi.number()).required(), */}
        <Grid item xs={12} md={6}>
          <Controller
            name="categories"
            control={control}
            defaultValue={defaultValues.categories}
            render={props => (
              <CategoriesComboBox
                id="categories-select"
                fieldLabel="Select Categories (Optional)"
                multiple
                defaultValue={props.value}
                onChange={props.onChange}
                readOnly={readOnly}
              />
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PackageRegularForm;
