import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/customers";

const paymentMethodsInitialState = {
  customerId: null,
  paymentMethodsList: [],
  currentPagePaymentMethod: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const paymentMethods = createSlice({
  name: "paymentMethods",
  initialState: paymentMethodsInitialState,
  reducers: {
    setCustomerId(state, {payload}) {
      state.customerId = payload;
    },
    getPaymentMethodStart: startLoading,
    getPaymentMethodsStart: startLoading,
    createPaymentMethodStart: startLoading,
    editPaymentMethodStart: startLoading,
    deletePaymentMethodStart: startLoading,
    getPaymentMethodSuccess(state, {payload}) {
      state.currentPagePaymentMethod = payload;
      state.isLoading = false;
      state.error = null;
    },
    getPaymentMethodsSuccess(state, {payload}) {
      state.paymentMethodsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createPaymentMethodSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editPaymentMethodSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deletePaymentMethodSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getPaymentMethodFailure: loadingFailed,
    getPaymentMethodsFailure: loadingFailed,
    createPaymentMethodFailure: loadingFailed,
    editPaymentMethodFailure: loadingFailed,
    deletePaymentMethodFailure: loadingFailed,
  },
});

export const {
  setCustomerId,
  getPaymentMethodStart,
  getPaymentMethodsStart,
  createPaymentMethodStart,
  editPaymentMethodStart,
  deletePaymentMethodStart,
  getPaymentMethodSuccess,
  getPaymentMethodsSuccess,
  createPaymentMethodSuccess,
  editPaymentMethodSuccess,
  deletePaymentMethodSuccess,
  getPaymentMethodFailure,
  getPaymentMethodsFailure,
  createPaymentMethodFailure,
  editPaymentMethodFailure,
  deletePaymentMethodFailure,
} = paymentMethods.actions;

export default paymentMethods.reducer;

export const fetchPaymentMethods = customerId => async (dispatch, getState) => {
  try {
    dispatch(getPaymentMethodsStart());
    const res = await axios.get(`${url}/${customerId}/payment-methods`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setCustomerId(customerId));
    dispatch(getPaymentMethodsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPaymentMethodsFailure(errorObj));
  }
};

export const fetchPaymentMethod = (customerId, paymentMethodId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(getPaymentMethodStart());
    const res = await axios.get(
      `${url}/${customerId}/payment-methods/${paymentMethodId}`,
      {
        headers: {Authorization: `Bearer ${getState().auth.token}`},
      }
    );
    dispatch(setCustomerId(customerId));
    dispatch(getPaymentMethodSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPaymentMethodFailure(errorObj));
  }
};

export const createPaymentMethod = (customerId, paymentMethodObj) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createPaymentMethodStart());
    const res = await axios.post(
      `${url}/${customerId}/payment-methods`,
      paymentMethodObj,
      {
        headers: {Authorization: `Bearer ${getState().auth.token}`},
      }
    );
    dispatch(createPaymentMethodSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createPaymentMethodFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editPaymentMethod = (customerId, paymentMethodObj) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(editPaymentMethodStart());
    const res = await axios.put(
      `${url}/${customerId}/payment-methods`,
      paymentMethodObj,
      {
        headers: {Authorization: `Bearer ${getState().auth.token}`},
      }
    );
    dispatch(editPaymentMethodSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editPaymentMethodFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deletePaymentMethod = (customerId, paymentMethodId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(deletePaymentMethodStart());
    const res = await axios.delete(`${url}/${customerId}/payment-methods`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {paymentMethod_id: paymentMethodId},
    });
    dispatch(deletePaymentMethodSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deletePaymentMethodFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
