import React from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {useForm} from "react-hook-form";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const EventVenuesForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    email: "",
    phone: "",
    website: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    ticketmaster_id: "",
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {ticketmaster_id, ...restOfData} = data;
    const formattedValues = {
      ...restOfData,
      ticketmaster_id: ticketmaster_id ? ticketmaster_id : null,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* EventVenue Name */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="name"
            label="Venue name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* EventVenue Ticket Master ID */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="ticketmaster_id"
            label="Ticket Master ID"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Event Venue Phone */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            required
            label="Phone"
            name="phone"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Event Venue Email */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="email"
            type="email"
            required
            label="Email"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* EventVenue Website */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="website"
            label="Venue Website"
            type="url"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Address */}
        <Grid item xs={12}>
          <TextField
            required
            inputRef={register}
            name="street"
            label="Street Address"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel City */}
        <Grid item xs={12} lg={4}>
          <TextField
            inputRef={register}
            required
            name="city"
            label="City"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel State */}
        <Grid item xs={12} lg={4}>
          <TextField
            inputRef={register}
            required
            name="state"
            label="State"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Hotel Zip */}
        <Grid item xs={12} lg={4}>
          <TextField
            required
            name="zip"
            inputRef={register}
            label="Zip"
            fullWidth
            InputProps={{
              readOnly,
              type: "text",
            }}
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default EventVenuesForm;
