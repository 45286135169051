import React from "react";

// Styles
import {makeStyles} from "@material-ui/core/styles";

// Routing
import {Switch, Route, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// Pages Components
// import TestPage from "../TestComponents/TestPage";

import Home from "./../Home/Home";

import UserPage from "../User/UserPage";

import UsersPage from "../Users/UsersPage";
import UsersNew from "../Users/UsersNew";
import UsersEdit from "../Users/UsersEdit";

import CondosPage from "../Condos/CondosPage";
import CondosNew from "../Condos/CondosNew";
import CondosEdit from "../Condos/CondosEdit";

import HotelsPage from "../Hotels/HotelsPage";
import HotelsNew from "../Hotels/HotelsNew";
import HotelsEdit from "../Hotels/HotelsEdit";

import AddonsPage from "../Addons/AddonsPage";
import AddonsNew from "../Addons/AddonsNew";
import AddonsEdit from "../Addons/AddonsEdit";

import PagesPage from "../Pages/PagesPage";
import PagesNew from "../Pages/PagesNew";
import PagesEdit from "../Pages/PagesEdit";

import PackageRegularPage from "../Packages/Regular/PackageRegularPage";
import PackageRegularNew from "../Packages/Regular/PackageRegularNew";
import PackageRegularEdit from "../Packages/Regular/PackageRegularEdit";

import PackageCardsPage from "../Packages/Cards/PackageCardsPage";
import PackageCardsNew from "../Packages/Cards/PackageCardsNew";
import PackageCardsEdit from "../Packages/Cards/PackageCardsEdit";

import PackageCategoriesPage from "../Packages/Categories/PackageCategoriesPage";
import PackageCategoriesNew from "../Packages/Categories/PackageCategoriesNew";
import PackageCategoriesEdit from "../Packages/Categories/PackageCategoriesEdit";

import CustomPackagesPage from "../Packages/Custom/CustomPackagesPage";
import CustomPackagesNew from "../Packages/Custom/CustomPackagesNew";
import CustomPackagesEdit from "../Packages/Custom/CustomPackagesEdit";

import EventsPage from "../Events/EventsPage";
import EventsNew from "../Events/EventsNew";
import EventsEdit from "../Events/EventsEdit";

import EventCategoriesPage from "../Events/Categories/EventCategoriesPage";
import EventCategoriesNew from "../Events/Categories/EventCategoriesNew";
import EventCategoriesEdit from "../Events/Categories/EventCategoriesEdit";

import EventOrganizersPage from "../Events/Organizers/EventOrganizersPage";
import EventOrganizersNew from "../Events/Organizers/EventOrganizersNew";
import EventOrganizersEdit from "../Events/Organizers/EventOrganizersEdit";

import EventVenuesPage from "../Events/Venues/EventVenuesPage";
import EventVenuesNew from "../Events/Venues/EventVenuesNew";
import EventVenuesEdit from "../Events/Venues/EventVenuesEdit";

import EventSourcesPage from "../Events/Sources/EventSourcesPage";
import EventSourcesNew from "../Events/Sources/EventSourcesNew";
import EventSourcesEdit from "../Events/Sources/EventSourcesEdit";

import MediaPage from "../Media/MediaPage";

import GalleriesPage from "../Media/GalleriesPage";
import GalleriesNew from "../Media/GalleriesNew";
import GalleriesEdit from "../Media/GalleriesEdit";

import CouponsPage from "../Coupons/CouponsPage";
import CouponsNew from "../Coupons/CouponsNew";
import CouponsEdit from "../Coupons/CouponsEdit";

import ChargesPage from "../Sales/Charges/ChargesPage";
import ChargesView from "../Sales/Charges/ChargesView";

import OrdersPage from "../Sales/Orders/OrdersPage";
import OrdersView from "../Sales/Orders/OrdersView";

import MeetingsPage from "../Meetings/MeetingsPage";
import MeetingsNew from "../Meetings/MeetingsNew";
import MeetingsEdit from "../Meetings/MeetingsEdit";

import CustomersPage from "../Sales/Customers/CustomersPage";
import CustomersNew from "../Sales/Customers/CustomersNew";
import CustomersEdit from "../Sales/Customers/CustomersEdit";

import RatesPage from "../Rates/RatesPage";
import RateshopperPage from "../Rates/RateshopperPage";

import ContactsPage from "../Contacts/ContactsPage";
import ContactsView from "../Contacts/ContactsView";

import SettingsPage from "../Settings/SettingsPage";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(8),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "60vh",
  },
}));

const FourOhFour = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer}>
          <Typography component="h2" variant="h1" align="center">
            404
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            Oops this page doesn't exist!
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const FourOhThree = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer}>
          <Typography component="h2" variant="h1" align="center">
            403
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            You shall not pass!
          </Typography>
          <Typography variant="h6" align="center">
            You don't have the necessary permissions to access this page.
            Contact your system administrator to grant you access.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const DashboardRoutes = () => {
  return (
    <Switch>
      {/* Test */}
      {/* <Route exact path="/test">
        <TestPage />
      </Route> */}

      {/* Packages */}
      <Route exact path="/packages">
        <Redirect to="/packages/regular" />
      </Route>

      {/* Regular Packages */}
      <Route exact path="/packages/regular">
        <PackageRegularPage />
      </Route>
      <Route exact path="/packages/regular/new">
        <PackageRegularNew />
      </Route>
      <Route exact path="/packages/regular/:id">
        <PackageRegularEdit />
      </Route>

      {/* Custom Packages */}
      <Route exact path="/packages/customs">
        <CustomPackagesPage />
      </Route>
      <Route exact path="/packages/customs/new">
        <CustomPackagesNew />
      </Route>
      <Route exact path="/packages/customs/:id">
        <CustomPackagesEdit />
      </Route>

      {/* Package Cards */}
      <Route exact path="/packages/cards">
        <PackageCardsPage />
      </Route>
      <Route exact path="/packages/cards/new">
        <PackageCardsNew />
      </Route>
      <Route exact path="/packages/cards/:id">
        <PackageCardsEdit />
      </Route>

      {/* Package Categories  */}
      <Route exact path="/packages/categories">
        <PackageCategoriesPage />
      </Route>
      <Route exact path="/packages/categories/new">
        <PackageCategoriesNew />
      </Route>
      <Route exact path="/packages/categories/:id">
        <PackageCategoriesEdit />
      </Route>

      {/* Hotels  */}
      <Route exact path="/hotels">
        <HotelsPage />
      </Route>
      <Route exact path="/hotels/new">
        <HotelsNew />
      </Route>
      <Route exact path="/hotels/:id">
        <HotelsEdit />
      </Route>

      {/* Condos  */}
      <Route exact path="/condos">
        <CondosPage />
      </Route>
      <Route exact path="/condos/new">
        <CondosNew />
      </Route>
      <Route exact path="/condos/:id">
        <CondosEdit />
      </Route>

      {/* Addons  */}
      <Route exact path="/addons">
        <AddonsPage />
      </Route>
      <Route exact path="/addons/new">
        <AddonsNew />
      </Route>
      <Route exact path="/addons/:id">
        <AddonsEdit />
      </Route>

      {/* Events  */}
      {/* Categories */}
      <Route exact path="/events/categories">
        <EventCategoriesPage />
      </Route>
      <Route exact path="/events/categories/new">
        <EventCategoriesNew />
      </Route>
      <Route exact path="/events/categories/:id">
        <EventCategoriesEdit />
      </Route>

      {/* Organizers */}
      <Route exact path="/events/organizers">
        <EventOrganizersPage />
      </Route>
      <Route exact path="/events/organizers/new">
        <EventOrganizersNew />
      </Route>
      <Route exact path="/events/organizers/:id">
        <EventOrganizersEdit />
      </Route>
      {/* Venues */}
      <Route exact path="/events/venues">
        <EventVenuesPage />
      </Route>
      <Route exact path="/events/venues/new">
        <EventVenuesNew />
      </Route>
      <Route exact path="/events/venues/:id">
        <EventVenuesEdit />
      </Route>
      {/* Sources */}
      <Route exact path="/events/sources">
        <EventSourcesPage />
      </Route>
      <Route exact path="/events/sources/new">
        <EventSourcesNew />
      </Route>
      <Route exact path="/events/sources/:id">
        <EventSourcesEdit />
      </Route>
      {/* All Events */}
      <Route exact path="/events">
        <EventsPage />
      </Route>
      <Route exact path="/events/new">
        <EventsNew />
      </Route>
      <Route exact path="/events/:id">
        <EventsEdit />
      </Route>

      {/* Pages */}
      <Route exact path="/pages">
        <PagesPage />
      </Route>
      <Route exact path="/pages/new">
        <PagesNew />
      </Route>
      <Route exact path="/pages/:id">
        <PagesEdit />
      </Route>

      {/* Media */}
      <Route exact path="/media">
        <Redirect to="/media/media-page" />
      </Route>
      <Route exact path="/media/media-page">
        <MediaPage />
      </Route>
      {/* Galleries */}
      <Route exact path="/media/galleries">
        <GalleriesPage />
      </Route>
      <Route exact path="/media/galleries/new">
        <GalleriesNew />
      </Route>
      <Route exact path="/media/galleries/:id">
        <GalleriesEdit />
      </Route>

      {/* Coupons */}
      <Route exact path="/coupons">
        <CouponsPage />
      </Route>
      <Route exact path="/coupons/new">
        <CouponsNew />
      </Route>
      <Route exact path="/coupons/:id">
        <CouponsEdit />
      </Route>

      {/* Meetings */}
      <Route exact path="/meetings">
        <MeetingsPage />
      </Route>
      <Route exact path="/meetings/new">
        <MeetingsNew />
      </Route>
      <Route exact path="/meetings/:id">
        <MeetingsEdit />
      </Route>

      {/* Sales  */}
      <Route exact path="/sales">
        <Redirect to="/sales/orders" />
      </Route>

      {/* Orders */}
      <Route exact path="/sales/orders">
        <OrdersPage />
      </Route>
      <Route exact path="/sales/orders/:id">
        <OrdersView />
      </Route>

      {/* Charges */}
      <Route exact path="/sales/charges">
        <ChargesPage />
      </Route>
      <Route exact path="/sales/charges/:id">
        <ChargesView />
      </Route>

      {/* Customers */}
      <Route exact path="/sales/customers">
        <CustomersPage />
      </Route>
      <Route exact path="/sales/customers/new">
        <CustomersNew />
      </Route>
      <Route exact path="/sales/customers/:id">
        <CustomersEdit />
      </Route>

      {/* User  */}
      <Route exact path="/user">
        <UserPage />
      </Route>

      {/* Users  */}
      <Route exact path="/users">
        <UsersPage />
      </Route>
      <Route exact path="/users/new">
        <UsersNew />
      </Route>
      <Route exact path="/users/:id">
        <UsersEdit />
      </Route>

      {/* Rates */}
      <Route exact path="/rates">
        <RatesPage />
      </Route>
      <Route exact path="/rateshopper">
        <RateshopperPage />
      </Route>

      {/* Contacts */}
      <Route exact path="/contacts">
        <ContactsPage />
      </Route>
      <Route exact path="/contacts/:id">
        <ContactsView />
      </Route>

      {/* Settings */}
      <Route exact path="/settings">
        <SettingsPage />
      </Route>

      {/* Error Pages */}
      <Route exact path="/404">
        <FourOhFour />
      </Route>
      <Route exact path="/403">
        <FourOhThree />
      </Route>

      {/* Dashboard Home Page */}
      <Route exact path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default DashboardRoutes;
