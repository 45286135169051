import React, {useState, useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchCharge} from "../../../redux/features/sales/chargesSlice";
import {
  fetchRefunds,
  createRefund,
} from "../../../redux/features/sales/refundsSlice";

// Styling
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {green, red, yellow, purple} from "@material-ui/core/colors";

// Routing
import {useParams, useHistory, Link as RouterLink} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FullContainerProgress from "../../UI/FullContainerProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "../../Navigation/Breadcrumbs";
import RefundsTableComponent from "./Refunds/RefundsTableComponent";
import CreateRefundDialog from "./Refunds/CreateRefundDialog";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

// Icons
import ReplayIcon from "@material-ui/icons/Replay";
import PaymentIcon from "@material-ui/icons/Payment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import ErrorIcon from "@material-ui/icons/Error";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import LinkIcon from "@material-ui/icons/Link";
import DoneIcon from "@material-ui/icons/Done";
import FaceIcon from "@material-ui/icons/Face";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CodeIcon from "@material-ui/icons/Code";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3),
    position: "relative",
    minHeight: "100px",
    height: "100%",
  },
  chipsContainer: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  addressText: {
    display: "flex",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  riskAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  green: {
    color: green[600],
  },
  red: {
    color: red[500],
  },
  yellow: {
    color: yellow[600],
  },
  devMode: {
    color: theme.palette.getContrastText(purple[600]),
    backgroundColor: purple[600],
  },
  greenAvatar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redAvatar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  yellowAvatar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
  },
}));

// Panels
const StatusChips = ({chargeObj}) => {
  // Styles
  const classes = useStyles();

  const {paid, refunded, status, captured, livemode} = chargeObj;

  // Status variables
  //succeeded, pending, or failed.
  const statusVars =
    status === "succeeded"
      ? {
          icon: <DoneIcon />,
          className: classes.greenAvatar,
        }
      : status === "failed"
      ? {
          icon: <ErrorIcon />,
          className: classes.redAvatar,
        }
      : {
          icon: <HelpIcon className={classes.yellowAvatar} />,
          className: classes.yellowAvatar,
        };

  return (
    <Grid item xs={12} className={classes.chipsContainer}>
      {status && (
        <Chip
          icon={statusVars.icon}
          className={statusVars.className}
          label={status.toUpperCase()}
        />
      )}
      <Chip
        icon={paid ? <DoneIcon /> : <ErrorIcon />}
        className={paid ? classes.greenAvatar : classes.redAvatar}
        label={paid ? "PAID" : "NOT PAID"}
      />
      <Chip
        icon={captured ? <DoneIcon /> : <ErrorIcon />}
        className={captured ? classes.greenAvatar : classes.redAvatar}
        label={captured ? "CAPTURED" : "NOT CAPTURED"}
      />
      {refunded && <Chip color="secondary" label={"REFUNDED"} />}
      {!livemode && (
        <Chip
          className={classes.devMode}
          icon={<CodeIcon />}
          label={"DEV MODE"}
        />
      )}
    </Grid>
  );
};

const DatePanel = ({created}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              Date
            </Typography>
            <Typography component="p" variant="h5" gutterBottom>
              {moment.unix(created).tz("America/Chicago").format("lll")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const LinksPanel = ({customer_id, stripe_charge_id, order_id}) => {
  // Styles
  const classes = useStyles();

  const LinkRouter = props => <Link {...props} component={RouterLink} />;

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              <LinkRouter
                to={`/sales/orders/${order_id}`}
                key={`/sales/orders/${order_id}`}
                className={classes.textWithIcon}>
                <ReceiptIcon />
                Go to Order
              </LinkRouter>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <LinkRouter
                to={`/sales/customers/${customer_id}`}
                key={`/sales/customers/${customer_id}`}
                className={classes.textWithIcon}>
                <FaceIcon />
                Go to Customer
              </LinkRouter>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Link
                href={`https://dashboard.stripe.com/payments/${stripe_charge_id}`}
                target="_blank"
                rel="noopener"
                className={classes.textWithIcon}>
                <LinkIcon />
                View Charge on Stripe
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const AmountPanel = ({amount, amount_refunded}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              Amount
            </Typography>
            <Typography component="p" variant="h5" gutterBottom>
              $ {(amount / 100).toFixed(2)}
            </Typography>
            {amount_refunded ? (
              <>
                <Typography component="h2" variant="body1">
                  Refunded
                </Typography>
                <Typography component="p" variant="h5">
                  $ {(amount_refunded / 100).toFixed(2)}
                </Typography>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const ReceiptDetailsPanel = ({receipt_email, receipt_number, receipt_url}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="space-between">
            <Typography component="h2" variant="h5">
              Receipt Details
            </Typography>
            <Typography variant="body1">
              <Link
                href={receipt_url}
                target="_blank"
                rel="noopener"
                className={classes.textWithIcon}>
                <LinkIcon />
                View Receipt on Stripe
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="p" variant="body2">
              Receipt #: {receipt_number || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              component="p"
              variant="body2"
              className={classes.textWithIcon}>
              <EmailIcon />
              {receipt_email || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const OutcomePanel = ({outcome}) => {
  // Styles
  const classes = useStyles();

  const {
    network_status,
    reason,
    risk_level,
    risk_score,
    seller_message,
  } = outcome;

  const avatarColor =
    risk_score >= 66
      ? classes.redAvatar
      : risk_score >= 33
      ? classes.yellowAvatar
      : classes.greenAvatar;

  return (
    <Grid item xs={6}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography component="h2" variant="h5" gutterBottom>
              Outcome
            </Typography>
            <Typography component="p">{seller_message}</Typography>
            <Typography component="p" variant="body2">
              Status:{" "}
              {network_status
                .split("_")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ") || "N/A"}
            </Typography>
            {reason && (
              <Typography component="p" variant="body2">
                Reason: {reason || "N/A"}
              </Typography>
            )}
          </Grid>

          <Grid
            container
            item
            xs={12}
            lg={4}
            direction="column"
            justify="center"
            alignItems="center">
            <Typography component="p" variant="caption" gutterBottom>
              Risk
            </Typography>

            <Avatar className={clsx(classes.riskAvatar, avatarColor)}>
              {risk_score}
            </Avatar>
            <Typography component="p">
              {risk_level
                .split("_")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const BillingDetailsPanel = ({billingDetails}) => {
  // Styles
  const classes = useStyles();

  const {
    address: {city, country, line1, line2, postal_code, state},
    email,
    name,
    phone,
  } = billingDetails;

  return (
    <Grid item xs={6}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" gutterBottom>
              Billing Details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="p">{name}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="span" className={classes.addressText}>
              <LocationOnIcon />
              <Typography component="span" variant="body2">
                {line1}.
                {line2 && (
                  <>
                    <br />
                    {line2}.
                  </>
                )}
                <br />
                {city}, {state}.<br />
                {postal_code}
                <br />
                {country}.
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              component="p"
              variant="body2"
              className={classes.textWithIcon}>
              <EmailIcon />
              {email || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              component="p"
              variant="body2"
              className={classes.textWithIcon}>
              <PhoneIcon />
              {phone || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const PaymentMethodPanel = ({paymentMethod}) => {
  // Styles
  const classes = useStyles();

  const {
    brand,
    last4,
    exp_month,
    exp_year,
    funding,
    wallet,
    country,
    checks,
    fingerprint,
  } = paymentMethod.card;

  const CheckStatus = ({status}) => {
    switch (status) {
      case "pass":
        return (
          <Tooltip title="Pass" aria-label="pass">
            <CheckCircleIcon fontSize="small" className={classes.green} />
          </Tooltip>
        );
      case "fail":
        return (
          <Tooltip title="Fail" aria-label="fail">
            <ErrorIcon fontSize="small" className={classes.red} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip
            title={status.charAt(0).toUpperCase() + status.slice(1)}
            aria-label={status}>
            <HelpIcon fontSize="small" className={classes.yellow} />
          </Tooltip>
        );
    }
  };

  return (
    <Grid item xs={6}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" gutterBottom>
              Payment Method
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography component="p" className={classes.textWithIcon}>
              <PaymentIcon fontSize="large" />
              {brand.toUpperCase()}
            </Typography>
            <Typography component="p">
              {"•••• ".repeat(3)}
              {last4}
            </Typography>
            <Typography component="p">
              {exp_month}/{exp_year}
            </Typography>
            <Typography component="p">
              <Typography component="span" variant="body2">
                Funding:
              </Typography>{" "}
              {funding.toUpperCase()}
            </Typography>
            {wallet && (
              <Typography component="p">
                <Typography component="span" variant="body2">
                  Wallet Type:
                </Typography>{" "}
                {wallet.type
                  .split("_")
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </Typography>
            )}
            <Typography component="p">
              <Typography component="span" variant="body2">
                Country:
              </Typography>{" "}
              {country}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              className={classes.textWithIcon}>
              <CheckStatus status={checks.cvc_check || "unchecked"} />
              CVC
            </Typography>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              className={classes.textWithIcon}>
              <CheckStatus status={checks.address_line1_check || "unchecked"} />
              Address Line 1
            </Typography>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              className={classes.textWithIcon}>
              <CheckStatus
                status={checks.address_postal_code_check || "unchecked"}
              />
              Address Postal Code
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p">
              <Typography component="span" variant="body2">
                Fingerprint:
              </Typography>{" "}
              {fingerprint}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const AdditionalDataPanel = ({chargeObj}) => {
  // Styles
  const classes = useStyles();

  const additionalKeys = [
    "application",
    "application_fee",
    "application_fee_amount",
    "destination",
    "dispute",
    "on_behalf_of",
    "order",
    "failure_code",
    "failure_message",
    "invoice",
    "payment_intent",
    "receipt_number",
    "review",
    "shipping",
    "source_transfer",
    "statement_descriptor",
    "statement_descriptor_suffix",
    "transfer_data",
    "transfer_group",
    "metadata",
    "fraud_details",
  ];

  // Display additional data when value !== null or !== {}
  const additionalDataArray = additionalKeys.flatMap(additionalKey => {
    // if value is not null
    if (chargeObj[additionalKey]) {
      // if value is not an object return value
      if (chargeObj[additionalKey].constructor !== Object) {
        return {
          [additionalKey]: chargeObj[additionalKey],
        };
      } else if (Object.keys(chargeObj[additionalKey]).length !== 0) {
        //  if value is object check if not empty {}
        return {
          [additionalKey]: chargeObj[additionalKey],
        };
      } else return [];
    } else return [];
  });

  return (
    <Grid item xs={12}>
      <Paper className={classes.paperContainer}>
        <Typography component="h3" variant="h5" gutterBottom>
          Additional Data
        </Typography>
        {additionalDataArray.length > 0 ? (
          <pre>{JSON.stringify(additionalDataArray, null, 2)}</pre>
        ) : (
          <Typography component="p" gutterBottom>
            No Additional Data to show.
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

// Main Component
const ChargesView = () => {
  // Styles
  const classes = useStyles();

  // Local State
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [openRefundDialog, setOpenRefundDialog] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageCharge, isLoading, error} = useSelector(
    state => state.charges
  );

  let breadcrumbNameMap = {
    "/sales": "Sales",
    "/sales/charges": "Charges",
  };
  breadcrumbNameMap[`/sales/charges/${id}`] = currentPageCharge.charge_id;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchCharge(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const onCreateRefundSubmit = async values => {
    const res = await dispatch(createRefund(id, values));

    // If Refund was created successfully close dialog and show user
    if (!res.error) {
      setOpenRefundDialog(false);
      setOpenSnackbar(true);
      setSnackbarContent(`The Refund was successfully created!`);
      dispatch(fetchRefunds(id));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {!isLoading && (
          <Grid
            container
            item
            xs={currentPageCharge.refunded ? 12 : 6}
            alignItems="center">
            <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
          </Grid>
        )}

        {!isLoading && !currentPageCharge.refunded && (
          <Grid container item xs={6} justify="flex-end">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<ReplayIcon />}
              onClick={() => setOpenRefundDialog(true)}>
              Refund
            </Button>
          </Grid>
        )}

        {/* Main Container  */}
        {isLoading ? (
          <Grid item xs={12}>
            <Paper className={classes.paperContainer}>
              <FullContainerProgress />
            </Paper>
          </Grid>
        ) : (
          <>
            {/* Chips  */}
            <StatusChips chargeObj={currentPageCharge} />

            {/* Amount  */}
            {currentPageCharge.amount && (
              <AmountPanel
                amount={currentPageCharge.amount}
                amount_refunded={currentPageCharge.amount_refunded}
              />
            )}
            {/* Date Created  */}
            {currentPageCharge.created && (
              <DatePanel created={currentPageCharge.created} />
            )}
            {/* Links  */}
            {currentPageCharge.customer_id && (
              <LinksPanel
                customer_id={currentPageCharge.customer_id}
                stripe_charge_id={currentPageCharge.stripe_charge_id}
                order_id={currentPageCharge.order_id}
              />
            )}

            {/* Billing Details  */}
            {currentPageCharge.billing_details && (
              <BillingDetailsPanel
                billingDetails={currentPageCharge.billing_details}
              />
            )}
            {/* Payment Method  */}
            {currentPageCharge.payment_method_details && (
              <PaymentMethodPanel
                paymentMethod={currentPageCharge.payment_method_details}
              />
            )}
            {/* Receipt */}
            <ReceiptDetailsPanel
              receipt_email={currentPageCharge.receipt_email}
              receipt_number={currentPageCharge.receipt_number}
              receipt_url={currentPageCharge.receipt_url}
            />

            {/* Outcome  */}
            {currentPageCharge.outcome && (
              <OutcomePanel outcome={currentPageCharge.outcome} />
            )}

            {/* Additional Data  */}
            <AdditionalDataPanel chargeObj={currentPageCharge} />

            {/* Refunds  */}
            <RefundsTableComponent chargeId={id} />
          </>
        )}
      </Grid>

      <CreateRefundDialog
        chargeId={currentPageCharge.id}
        maxAmount={
          (currentPageCharge.amount - currentPageCharge.refunds_total) / 100
        }
        orderId={currentPageCharge.order_id}
        open={openRefundDialog}
        onClose={() => setOpenRefundDialog(false)}
        onSubmit={onCreateRefundSubmit}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ChargesView;
