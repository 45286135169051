import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createPackageCard} from "../../../redux/features/packages/cardSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../../UI/MainPaper";
import PackageCardsForm from "./PackageCardsForm";
import Breadcrumbs from "../../Navigation/Breadcrumbs";

const PackageCardsNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.packageCards);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/packages": "Packages",
    "/packages/cards": "Package Cards",
    "/packages/cards/new": "New Package Card",
  };

  const onCancel = () => {
    history.push("/packages/cards");
  };
  const onSubmit = async values => {
    const packageCardObj = {
      id: null,
      ...values,
    };

    const res = await dispatch(createPackageCard(packageCardObj));
    // If package was created successfully redirect to packages page and show user
    if (!res.error) {
      history.push({
        pathname: "/packages/cards",
        state: {
          showSuccess: true,
          snackbarContent: `Your Package Card '${packageCardObj.name}' was successfully created!`,
          snackbarLink: `/packages/cards/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New PackageCard
          </Typography>
          <PackageCardsForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Package Card"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PackageCardsNew;
