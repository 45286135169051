import React, {useState} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {clearCache} from "../../../redux/features/settings/settingsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},

  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CacheForm = () => {
  // Styles
  const classes = useStyles();

  // Local State
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  // Redux
  const dispatch = useDispatch();
  const {isLoadingCache, errorCache} = useSelector(state => state.settings);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const onClearCache = async () => {
    // Logic to show error or success
    const res = await dispatch(clearCache());
    if (!res.error) {
      setSnackbarContent("Cache was successfully cleared!");
    } else {
      setSnackbarContent(
        "There was an error clearing the cache. Please try again later."
      );
    }
    setOpenSnackbar(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Click the button to clear the cache in all servers. This will also
          clear the cache on the store site.
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoadingCache}
            onClick={onClearCache}>
            Clear Cache
          </Button>
          {isLoadingCache && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={errorCache ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};

export default CacheForm;
