import {combineReducers} from "redux";
import {getDefaultMiddleware} from "@reduxjs/toolkit";

// Reducers
import authReducer from "../features/auth/authSlice";
import uiReducer from "../features/ui/uiSlice";
import errorsReducer from "../features/errors/errorsSlice";
import settingsReducer from "../features/settings/settingsSlice";
import redirectsReducer from "../features/settings/redirectsSlice";
import usersReducer from "../features/users/usersSlice";
import condoReducer from "../features/condos/condosSlice";
import customPackagesReducer from "../features/packages/customPackagesSlice";
import packageReducer from "../features/packages/regularSlice";
import packageCardReducer from "../features/packages/cardSlice";
import packageCategoryReducer from "../features/packages/categoriesSlice";
import addonsReducer from "../features/addons/addonsSlice";
import eventsReducer from "../features/events/eventsSlice";
import eventCategoryReducer from "../features/events/categoriesSlice";
import eventOrganizerReducer from "../features/events/organizersSlice";
import eventVenueReducer from "../features/events/venuesSlice";
import eventSourceReducer from "../features/events/sourcesSlice";
import couponsReducer from "../features/coupons/couponsSlice";
import contactsReducer from "../features/contacts/contactsSlice";
import chargesReducer from "../features/sales/chargesSlice";
import ordersReducer from "../features/sales/ordersSlice";
import ratesReducer from "../features/sales/ratesSlice";
import refundsReducer from "../features/sales/refundsSlice";
import customersReducer from "../features/sales/customersSlice";
import addressesReducer from "../features/sales/addressesSlice";
import paymentMethodsReducer from "../features/sales/paymentMethodsSlice";
import pagesReducer from "../features/pages/pagesSlice";
import mediaReducer from "../features/media/mediaSlice";
import galleriesReducer from "../features/media/galleriesSlice";
import iconsReducer from "../features/icons/iconsSlice";
import hotelsReducer from "../features/hotels/hotelsSlice";
import roomsReducer from "../features/hotels/roomsSlice";
import meetingReducer from "../features/meetings/meetingsSlice";

export default combineReducers({
  ui: uiReducer,
  errors: errorsReducer,
  auth: authReducer,
  settings: settingsReducer,
  redirects: redirectsReducer,
  users: usersReducer,
  condos: condoReducer,
  customPackages: customPackagesReducer,
  packages: packageReducer,
  packageCards: packageCardReducer,
  packageCategories: packageCategoryReducer,
  hotels: hotelsReducer,
  rooms: roomsReducer,
  addons: addonsReducer,
  events: eventsReducer,
  eventCategories: eventCategoryReducer,
  eventOrganizers: eventOrganizerReducer,
  eventVenues: eventVenueReducer,
  eventSources: eventSourceReducer,
  coupons: couponsReducer,
  charges: chargesReducer,
  orders: ordersReducer,
  rates: ratesReducer,
  refunds: refundsReducer,
  customers: customersReducer,
  addresses: addressesReducer,
  paymentMethods: paymentMethodsReducer,
  pages: pagesReducer,
  media: mediaReducer,
  galleries: galleriesReducer,
  icons: iconsReducer,
  meetings: meetingReducer,
  contacts: contactsReducer,
  middleware: [...getDefaultMiddleware({serializableCheck: false})],
});
