import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPages} from "../../redux/features/pages/pagesSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const PagesPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {pagesList, isLoading, error} = useSelector(state => state.pages);

  useEffect(() => {
    dispatch(fetchPages());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/pages"
      tableTitle="All Pages"
      tableColumns={[
        {
          title: "ID",
          field: "id",
          type: "numeric",
          width: 30,
        },
        {title: "Title", field: "title"},
        {title: "Permalink", field: "permalink"},
        {
          title: "Type",
          field: "type",
          defaultSort: "desc",
          render: rowData => <>{rowData.type.toUpperCase()}</>,
        },
        {title: "Page Status", field: "page_status"},
      ]}
      addButtonLabel="Page"
      dataList={pagesList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="pages"
    />
  );
};

export default PagesPage;
