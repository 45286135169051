import React, {useState, useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchContact,
  editContact,
} from "../../redux/features/contacts/contactsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {green, red, yellow, purple} from "@material-ui/core/colors";

// Routing
import {useParams, useHistory} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FullContainerProgress from "../UI/FullContainerProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "../Navigation/Breadcrumbs";
import Chip from "@material-ui/core/Chip";

// Icons
import ReceiptIcon from "@material-ui/icons/Receipt";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3),
    position: "relative",
    minHeight: "100px",
    height: "100%",
  },
  chipsContainer: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  addressText: {
    display: "flex",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  riskAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  green: {
    color: green[600],
  },
  red: {
    color: red[500],
  },
  yellow: {
    color: yellow[600],
  },
  devMode: {
    color: theme.palette.getContrastText(purple[600]),
    backgroundColor: purple[600],
  },
  greenAvatar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  redAvatar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  yellowAvatar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
  },
  additionalDataItem: {
    display: "flex",
    alignItems: "baseline",
  },
  additionalDataName: {
    marginRight: theme.spacing(2),
  },
}));

// Panels
const StatusChips = ({contactObj}) => {
  // Styles
  const classes = useStyles();

  const {resolved, type} = contactObj;

  return (
    <Grid item xs={12} className={classes.chipsContainer}>
      <Chip
        icon={<ReceiptIcon />}
        className={
          type === "meeting" ? classes.greenAvatar : classes.yellowAvatar
        }
        label={type && type.toUpperCase()}
      />
      <Chip
        icon={resolved ? <DoneIcon /> : <ErrorIcon />}
        className={resolved ? classes.greenAvatar : classes.redAvatar}
        label={resolved ? "RESOLVED" : "NOT RESOLVED"}
      />
    </Grid>
  );
};

const DatePanel = ({date_time}) => {
  // Styles
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              Date
            </Typography>
            <Typography component="p" variant="h5" gutterBottom>
              {moment(date_time).tz("America/Chicago").format("lll")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const AdditionalDataPanel = ({contactObj}) => {
  // Styles
  const classes = useStyles();

  const {additional_data} = contactObj;

  return (
    <Grid item xs={12}>
      <Paper className={classes.paperContainer}>
        <Typography component="h3" variant="h5" gutterBottom>
          Additional Data
        </Typography>
        {Array.isArray(additional_data) && additional_data.length > 0 ? (
          <>
            <Typography variant="body1" component="ul">
              <>
                {additional_data.map(({name, value}, i) => (
                  <li key={i} className={classes.additionalDataItem}>
                    <span className={classes.additionalDataName}>{name}:</span>
                    <pre className={classes.additionalDataValue}>
                      {JSON.stringify(value)}
                    </pre>
                  </li>
                ))}
              </>
            </Typography>
          </>
        ) : (
          <Typography component="p" gutterBottom>
            No Additional Data to show.
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

// Main Component
const ContactsView = () => {
  // Styles
  const classes = useStyles();

  // Local State
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageContact, isLoading, error} = useSelector(
    state => state.contacts
  );

  let breadcrumbNameMap = {
    "/contacts": "Contacts",
  };
  breadcrumbNameMap[`/contacts/${id}`] = currentPageContact.contact_id;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchContact(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  const toggleResolve = async values => {
    const res = await dispatch(editContact({id, ...values}));

    // If Refund was created successfully close dialog and show user
    if (!res.error) {
      setOpenSnackbar(true);
      setSnackbarContent(`The Status was successfully updated!`);
      dispatch(fetchContact(id));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {!isLoading && (
          <Grid container item xs={6} alignItems="center">
            <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
          </Grid>
        )}

        {!isLoading && (
          <>
            {!currentPageContact.resolved ? (
              <Grid container item xs={6} justify="flex-end">
                <Button
                  variant="contained"
                  className={classes.greenAvatar}
                  size="large"
                  startIcon={<DoneIcon />}
                  onClick={() => toggleResolve({resolved: true})}>
                  Resolve
                </Button>
              </Grid>
            ) : (
              <Grid container item xs={6} justify="flex-end">
                <Button
                  variant="contained"
                  className={classes.redAvatar}
                  size="large"
                  startIcon={<CloseIcon />}
                  onClick={() => toggleResolve({resolved: false})}>
                  Unresolve
                </Button>
              </Grid>
            )}
          </>
        )}

        {/* Main Container  */}
        {isLoading ? (
          <Grid item xs={12}>
            <Paper className={classes.paperContainer}>
              <FullContainerProgress />
            </Paper>
          </Grid>
        ) : (
          <>
            {/* Chips  */}
            <StatusChips contactObj={currentPageContact} />

            {/* Date Created  */}
            {currentPageContact.date_time && (
              <DatePanel date_time={currentPageContact.date_time} />
            )}
            {/*Name */}
            {currentPageContact.firstName && (
              <Grid item xs={12} md={4}>
                <Paper className={classes.paperContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="body1">
                        Name
                      </Typography>
                      <Typography component="p" variant="h5" gutterBottom>
                        {currentPageContact.firstName}{" "}
                        {currentPageContact.lastName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            {/*Email */}
            {currentPageContact.email && (
              <Grid item xs={12} md={4}>
                <Paper className={classes.paperContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="body1">
                        Email
                      </Typography>
                      <Typography component="p" variant="h5" gutterBottom>
                        {currentPageContact.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="body1">
                        Phone
                      </Typography>
                      <Typography component="p" variant="h5" gutterBottom>
                        {currentPageContact.phoneNumber
                          ? currentPageContact.phoneNumber
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper className={classes.paperContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h5" gutterBottom>
                      Email Info
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Subject</Typography>
                    <Typography variant="h5">
                      {currentPageContact.subject}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Message</Typography>
                    <Typography variant="h5">
                      {currentPageContact.message
                        ? currentPageContact.message
                        : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Additional Data  */}
            <AdditionalDataPanel contactObj={currentPageContact} />
          </>
        )}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={error ? "error" : "success"}>
          {snackbarContent}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ContactsView;
