import React, {useState, useEffect} from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchFiles} from "../../../redux/features/media/mediaSlice";

// Components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonWithLoading from "../../Buttons/ButtonWithLoading";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import DeleteButton from "../../Buttons/DeleteButton";
import DeleteDialog from "../../UI/DeleteDialog";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(theme => ({
  uploadFilesContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "80vh",
  },
  uploadLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-start",
  },
  uploadFilesMessage: {
    marginBottom: theme.spacing(2),
  },

  icon: {
    color: green[500],
  },
  imageBox: {
    cursor: "pointer",
    position: "relative",
    minHeight: "150px",
  },

  selectedBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.getContrastText(theme.palette.grey[900]),
  },
  filesContainer: {
    paddingTop: "120px",
    overflowY: "scroll",
    height: "100vh",
    "&::-webkit-scrollbar-thumb": {
      minHeight: "200px",
      border: "6px solid transparent",
      background:
        theme.palette.type === "dark"
          ? theme.palette.grey[700]
          : theme.palette.grey[300],
      borderRadius: "10px",
      backgroundClip: "padding-box",
      "&:hover": {
        minHeight: "200px",
        border: "6px solid transparent",
        background: theme.palette.grey[500],
        borderRadius: "10px",
        backgroundClip: "padding-box",
      },
    },
    "&::-webkit-scrollbar": {
      width: theme.spacing(2.5),
      height: theme.spacing(2),
    },
  },
  dialogPreviewContainer: {
    padding: "120px 10px 0 10px",
    height: "100vh",
  },
  previewContainer: {
    padding: "10px 10px 0 10px",
  },
  previewContainerBox: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 400 : 700],
  },
}));

export const UploadFileTab = ({onUpload}) => {
  // Styles
  const classes = useStyles();

  // Redux
  const {isLoading: isLoadingMedia} = useSelector(state => state.media);

  return (
    <Box className={classes.uploadFilesContainer}>
      {isLoadingMedia && (
        <Typography
          variant="h4"
          component="p"
          className={classes.uploadFilesMessage}>
          Uploading files...
        </Typography>
      )}
      <input
        accept="image/*|video/*"
        hidden
        id="media-file-upload"
        multiple
        type="file"
        onChange={e => onUpload(e.target.files)}
      />
      <InputLabel htmlFor="media-file-upload" className={classes.uploadLabel}>
        <ButtonWithLoading
          component="span"
          variant="contained"
          size="large"
          loading={isLoadingMedia}>
          Select Files to Upload
        </ButtonWithLoading>
      </InputLabel>
    </Box>
  );
};

export const ImagesTab = ({
  selectedFilesArray,
  onFileSelect,
  onEdit,
  onDelete,
  isDialog = false,
}) => {
  // Styles
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const {imageList: imagesList, isLoading: isLoadingImages} = useSelector(
    state => state.media
  );

  useEffect(() => {
    dispatch(fetchFiles("image"));
  }, [dispatch]);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        md={8}
        lg={9}
        spacing={2}
        className={isDialog ? classes.filesContainer : null}>
        {isLoadingImages || imagesList.length < 0 ? (
          Array.from(new Array(30)).map((el, i) => (
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={"load-" + i}>
              <Skeleton variant="rect" width={"auto"} height={150} />
            </Grid>
          ))
        ) : (
          <>
            {imagesList.flatMap(photoObj => {
              if (photoObj.mimetype.split("/")[0] === "image") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    key={photoObj.id}
                    onClick={() => onFileSelect(photoObj)}>
                    <Box className={classes.previewContainerBox}>
                      <Box
                        className={classes.imageBox}
                        style={{
                          backgroundImage: `url('${process.env.REACT_APP_IMG_PATH}/c/150/${photoObj.name}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}>
                        {!!selectedFilesArray.filter(
                          el =>
                            photoObj.id === el.id || photoObj.name === el.name
                        ).length && (
                          <Box className={classes.selectedBar}>
                            <Typography variant="h6" component="p">
                              Selected
                            </Typography>
                            <CheckCircleIcon className={classes.icon} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              } else {
                return [];
              }
            })}
          </>
        )}
      </Grid>
      {/* Preview of the last item selected */}
      <Grid
        container
        item
        xs={12}
        md={4}
        lg={3}
        justify="center"
        alignItems={isDialog ? "center" : null}
        className={
          isDialog ? classes.dialogPreviewContainer : classes.previewContainer
        }>
        {selectedFilesArray.length > 0 ? (
          <PreviewPanel
            selectedFileObj={selectedFilesArray[selectedFilesArray.length - 1]}
            onDelete={onDelete}
            onEdit={onEdit}
            fileType="image"
            isDialog={isDialog}
          />
        ) : (
          <Typography variant="body1" component="p">
            Select a File to Preview
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export const VideosTab = ({
  selectedFilesArray,
  onFileSelect,
  onEdit,
  onDelete,
  isDialog = false,
}) => {
  // Styles
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const {videoList, isLoading: isLoadingMedia} = useSelector(
    state => state.media
  );

  useEffect(() => {
    dispatch(fetchFiles("video"));
  }, [dispatch]);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        md={8}
        lg={9}
        spacing={2}
        className={isDialog ? classes.filesContainer : null}>
        {isLoadingMedia || videoList.length < 0 ? (
          Array.from(new Array(30)).map((el, i) => (
            <Grid item xs={12} sm={6} md={4} key={"load-v-" + i}>
              <Skeleton variant="rect" width={"auto"} height={150} />
            </Grid>
          ))
        ) : (
          <>
            {videoList.flatMap(mediaObj => {
              if (mediaObj.mimetype.split("/")[0] === "video") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={mediaObj.id}
                    onClick={() => {
                      onFileSelect(mediaObj);
                    }}>
                    <Box className={classes.imageBox}>
                      <video width="100%" height="100%" key={mediaObj.name}>
                        <source
                          src={`${process.env.REACT_APP_IMG_PATH}/o/${mediaObj.name}`}
                          type={mediaObj.mimetype}
                        />
                      </video>
                      {!!selectedFilesArray.filter(
                        el => mediaObj.id === el.id || mediaObj.name === el.name
                      ).length && (
                        <Box className={classes.selectedBar}>
                          <Typography variant="h6" component="p">
                            Selected
                          </Typography>
                          <CheckCircleIcon className={classes.icon} />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              } else {
                return [];
              }
            })}
          </>
        )}
      </Grid>
      {/* Preview of the last item selected */}
      <Grid
        container
        item
        xs={12}
        md={4}
        lg={3}
        justify="center"
        alignItems={isDialog ? "center" : null}
        className={
          isDialog ? classes.dialogPreviewContainer : classes.previewContainer
        }>
        {selectedFilesArray.length > 0 ? (
          <PreviewPanel
            selectedFileObj={selectedFilesArray[selectedFilesArray.length - 1]}
            onDelete={onDelete}
            onEdit={onEdit}
            fileType="video"
            isDialog={isDialog}
          />
        ) : (
          <Typography variant="body1" component="p">
            Select a File to Preview
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export const PreviewPanel = ({
  selectedFileObj,
  fileType = "image",
  onEdit,
  onDelete,
  isDialog = false,
}) => {
  const {id, name, mimetype} = selectedFileObj;

  // Local State
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nameField, setNameField] = useState(
    !!name ? name.split(".").slice(0, -1).join(".") : ""
  );

  const fileExtension = !!name ? "." + name.split(".").slice(1).join(".") : "";

  // Redux
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {
    write: writePermission,
    delete: deletePermission,
  } = userPermission.media;

  const {isLoading: isLoadingFiles} = useSelector(state => state.media);

  useEffect(() => {
    if (!!name) setNameField(name.split(".").slice(0, -1).join("."));
  }, [name]);

  const editFile = () => {
    const newName = nameField + fileExtension;
    onEdit(id, newName);
  };

  return (
    //  {/* Preview of the last item selected */}
    <Grid container item xs={12} justify="center">
      <Box bgcolor="background.paper" height="100%" py={3} px={0} ml={3}>
        {isLoadingFiles ? (
          <Grid container item xs={12} justify="center" spacing={1}>
            <CircularProgress size={80} />
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            justify="center"
            spacing={1}
            style={isDialog ? null : {position: "sticky", top: "130px"}}>
            <Grid
              container
              item
              justify="center"
              style={{
                maxHeight: "20em",
              }}>
              <Box maxWidth="100%" maxHeight="100%" minHeight="8em" clone>
                {!!name ? (
                  <>
                    {fileType === "image" ? (
                      <img
                        src={`${process.env.REACT_APP_IMG_PATH}/r/400/${name}`}
                        alt={name}
                        width="100%"
                        height="100%"
                      />
                    ) : fileType === "video" ? (
                      <video controls key={name}>
                        <source
                          src={`${process.env.REACT_APP_IMG_PATH}/o/${name}`}
                          type={mimetype}
                        />
                      </video>
                    ) : (
                      <p>No Preview Available</p>
                    )}
                  </>
                ) : (
                  <p>No Preview Available</p>
                )}
              </Box>
            </Grid>
            {!!name && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2" component="p">
                    File ID: {id}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="p">
                    File Type: {mimetype}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link
                    href={`${process.env.REACT_APP_IMG_PATH}/o/${name}`}
                    target="_blank"
                    rel="noopener">
                    Direct Link
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    name="file-name"
                    label="File Name"
                    multiline
                    fullWidth
                    value={nameField}
                    InputProps={{
                      readOnly: !writePermission,
                    }}
                    onChange={e => setNameField(e.target.value)}
                  />
                </Grid>

                {!!writePermission && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        nameField === name.split(".").slice(0, -1).join(".") ||
                        nameField === ""
                      }
                      onClick={() => editFile()}>
                      Save Changes
                    </Button>
                  </Grid>
                )}
                {!!deletePermission && (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <DeleteButton
                        onClick={() => setDialogOpen(true)}
                        size="small"
                        variant="text">
                        Delete File
                      </DeleteButton>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        )}
      </Box>
      <DeleteDialog
        dialogTitle="Are you sure you want to delete this File?"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={() => onDelete(id)}
      />
    </Grid>
  );
};
