import React, {useState, useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {
  fetchEventCategory,
  editEventCategory,
  deleteEventCategory,
} from "../../../redux/features/events/categoriesSlice";

// Routing
import {useParams, useHistory, Redirect} from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "../../UI/MainPaper";
import EventCategoriesForm from "./EventCategoriesForm";
import FullContainerProgress from "../../UI/FullContainerProgress";
import DeleteButton from "../../Buttons/DeleteButton";
import DeleteDialog from "../../UI/DeleteDialog";
import BreadcrumbsWithEdit from "../../Navigation/BreadcrumbsWithEdit";

const EventCategoriesEdit = () => {
  // Local State
  const [isEditMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Routing
  const {id} = useParams();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const {currentPageEventCategory, isLoading, error} = useSelector(
    state => state.eventCategories
  );
  const {userPermission} = useSelector(state => {
    return state.auth;
  });
  const {write, delete: deletePermission} = userPermission.events;

  const formattedValues = {
    name: currentPageEventCategory.name,
    slug: currentPageEventCategory.slug,
    color: currentPageEventCategory.color,
    background: currentPageEventCategory.background,
    parent_id: currentPageEventCategory.parent_id,
  };

  let breadcrumbNameMap = {
    "/events": "Events",
    "/events/categories": "Event Categories",
  };
  breadcrumbNameMap[`/events/categories/${id}`] = currentPageEventCategory.name;

  useEffect(() => {
    // check if the id is a int to keep going
    // This if is why javascript is broken :)
    if (Number.isInteger(id * 1) && id * 1 < Number.MAX_SAFE_INTEGER) {
      dispatch(fetchEventCategory(id));
    } else {
      history.push("/404");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    setEditMode(write);
  }, [write]);

  const onSubmit = async values => {
    const eventCategoryObj = {
      category_id: id,
      ...values,
    };
    // Logic to show error or success
    const res = await dispatch(editEventCategory(eventCategoryObj));
    if (!res.error) {
      history.push({
        pathname: "/events/categories",
        state: {
          showSuccess: true,
          snackbarContent: `Your changes to ${eventCategoryObj.name} were saved successfully!`,
          snackbarLink: `/events/categories/${eventCategoryObj.category_id}`,
        },
      });
    }
  };

  const onDelete = async () => {
    // Logic to show error or success
    const res = await dispatch(deleteEventCategory(id));
    if (!res.error) {
      history.push({
        pathname: "/events/categories",
        state: {
          showSuccess: true,
          snackbarContent: `The Event Category was successfully deleted!`,
        },
      });
    }
  };

  // Redirect if category doesn't exist'
  if (error && error.errorCode === 404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <BreadcrumbsWithEdit
          isLoading={isLoading}
          breadcrumbNameMap={breadcrumbNameMap}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          writePermission={write}
        />
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <FullContainerProgress />
            ) : (
              <>
                <Typography component="h1" variant="h5" gutterBottom>
                  {currentPageEventCategory.name}
                </Typography>
                <EventCategoriesForm
                  defaultValues={formattedValues}
                  readOnly={!isEditMode}
                  submitButtonLabel="Save Changes"
                  onSubmit={onSubmit}
                  onCancel={() => setEditMode(false)}
                />
              </>
            )}
          </Paper>
        </Grid>
        {isEditMode && !isLoading && !!deletePermission && (
          <Grid item xs={12}>
            <DeleteButton onClick={() => setDialogOpen(true)} size="large">
              Delete Event Category
            </DeleteButton>
          </Grid>
        )}
      </Grid>
      {!!deletePermission && (
        <DeleteDialog
          dialogTitle="Are you sure you want to delete this Event Category?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export default EventCategoriesEdit;
