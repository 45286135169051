import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/coupons";

const couponsInitialState = {
  couponsList: [],
  currentPageCoupon: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const coupons = createSlice({
  name: "coupons",
  initialState: couponsInitialState,
  reducers: {
    getCouponStart: startLoading,
    getCouponsStart: startLoading,
    createCouponStart: startLoading,
    editCouponStart: startLoading,
    deleteCouponStart: startLoading,
    getCouponSuccess(state, {payload}) {
      state.currentPageCoupon = payload;
      state.isLoading = false;
      state.error = null;
    },
    getCouponsSuccess(state, {payload}) {
      state.couponsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createCouponSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editCouponSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteCouponSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getCouponFailure: loadingFailed,
    getCouponsFailure: loadingFailed,
    createCouponFailure: loadingFailed,
    editCouponFailure: loadingFailed,
    deleteCouponFailure: loadingFailed,
  },
});

export const {
  getCouponStart,
  getCouponsStart,
  createCouponStart,
  editCouponStart,
  deleteCouponStart,
  getCouponSuccess,
  getCouponsSuccess,
  createCouponSuccess,
  editCouponSuccess,
  deleteCouponSuccess,
  getCouponFailure,
  getCouponsFailure,
  createCouponFailure,
  editCouponFailure,
  deleteCouponFailure,
} = coupons.actions;

export default coupons.reducer;

export const fetchCoupons = () => async (dispatch, getState) => {
  try {
    dispatch(getCouponsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCouponsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getCouponsFailure(errorObj));
  }
};

export const fetchCoupon = id => async (dispatch, getState) => {
  try {
    dispatch(getCouponStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCouponSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getCouponFailure(errorObj));
  }
};

export const createCoupon = couponObj => async (dispatch, getState) => {
  try {
    dispatch(createCouponStart());
    const res = await axios.post(url, couponObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createCouponSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createCouponFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editCoupon = couponObj => async (dispatch, getState) => {
  try {
    dispatch(editCouponStart());
    const res = await axios.put(url, couponObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editCouponSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editCouponFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteCoupon = couponId => async (dispatch, getState) => {
  try {
    dispatch(deleteCouponStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: couponId},
    });
    dispatch(deleteCouponSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteCouponFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
