import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/customers";

const customersInitialState = {
  customersList: [],
  currentPageCustomer: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const customers = createSlice({
  name: "customers",
  initialState: customersInitialState,
  reducers: {
    getCustomerStart: startLoading,
    getCustomersStart: startLoading,
    createCustomerStart: startLoading,
    editCustomerStart: startLoading,
    deleteCustomerStart: startLoading,
    getCustomerSuccess(state, {payload}) {
      state.currentPageCustomer = payload;
      state.isLoading = false;
      state.error = null;
    },
    getCustomersSuccess(state, {payload}) {
      state.customersList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createCustomerSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editCustomerSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteCustomerSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getCustomerFailure: loadingFailed,
    getCustomersFailure: loadingFailed,
    createCustomerFailure: loadingFailed,
    editCustomerFailure: loadingFailed,
    deleteCustomerFailure: loadingFailed,
  },
});

export const {
  getCustomerStart,
  getCustomersStart,
  createCustomerStart,
  editCustomerStart,
  deleteCustomerStart,
  getCustomerSuccess,
  getCustomersSuccess,
  createCustomerSuccess,
  editCustomerSuccess,
  deleteCustomerSuccess,
  getCustomerFailure,
  getCustomersFailure,
  createCustomerFailure,
  editCustomerFailure,
  deleteCustomerFailure,
} = customers.actions;

export default customers.reducer;

export const fetchCustomers = () => async (dispatch, getState) => {
  try {
    dispatch(getCustomersStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCustomersSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getCustomersFailure(errorObj));
  }
};

export const fetchCustomer = id => async (dispatch, getState) => {
  try {
    dispatch(getCustomerStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCustomerSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getCustomerFailure(errorObj));
  }
};

export const createCustomer = customerObj => async (dispatch, getState) => {
  try {
    dispatch(createCustomerStart());
    const res = await axios.post(url, customerObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createCustomerSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createCustomerFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editCustomer = customerObj => async (dispatch, getState) => {
  try {
    dispatch(editCustomerStart());
    const res = await axios.put(url, customerObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editCustomerSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editCustomerFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteCustomer = customerId => async (dispatch, getState) => {
  try {
    dispatch(deleteCustomerStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: customerId},
    });
    dispatch(deleteCustomerSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteCustomerFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
