import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/packages/cards";

const packageCardsInitialState = {
  packageCardsList: [],
  currentPagePackageCard: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const packageCards = createSlice({
  name: "packageCards",
  initialState: packageCardsInitialState,
  reducers: {
    getPackageCardStart: startLoading,
    getPackageCardsStart: startLoading,
    createPackageCardStart: startLoading,
    editPackageCardStart: startLoading,
    deletePackageCardStart: startLoading,
    getPackageCardSuccess(state, {payload}) {
      state.currentPagePackageCard = payload;
      state.isLoading = false;
      state.error = null;
    },
    getPackageCardsSuccess(state, {payload}) {
      state.packageCardsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createPackageCardSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editPackageCardSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deletePackageCardSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getPackageCardFailure: loadingFailed,
    getPackageCardsFailure: loadingFailed,
    createPackageCardFailure: loadingFailed,
    editPackageCardFailure: loadingFailed,
    deletePackageCardFailure: loadingFailed,
  },
});

export const {
  getPackageCardStart,
  getPackageCardsStart,
  createPackageCardStart,
  editPackageCardStart,
  deletePackageCardStart,
  getPackageCardSuccess,
  getPackageCardsSuccess,
  createPackageCardSuccess,
  editPackageCardSuccess,
  deletePackageCardSuccess,
  getPackageCardFailure,
  getPackageCardsFailure,
  createPackageCardFailure,
  editPackageCardFailure,
  deletePackageCardFailure,
} = packageCards.actions;

export default packageCards.reducer;

export const fetchPackageCards = () => async (dispatch, getState) => {
  try {
    dispatch(getPackageCardsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPackageCardsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getPackageCardsFailure(errorObj));
  }
};

export const fetchPackageCard = id => async (dispatch, getState) => {
  try {
    dispatch(getPackageCardStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPackageCardSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getPackageCardFailure(errorObj));
  }
};

export const createPackageCard = packageObj => async (dispatch, getState) => {
  try {
    dispatch(createPackageCardStart());
    const res = await axios.post(url, packageObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createPackageCardSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createPackageCardFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editPackageCard = packageObj => async (dispatch, getState) => {
  try {
    dispatch(editPackageCardStart());
    const res = await axios.put(url, packageObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editPackageCardSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editPackageCardFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deletePackageCard = packageId => async (dispatch, getState) => {
  try {
    dispatch(deletePackageCardStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: packageId},
    });
    dispatch(deletePackageCardSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deletePackageCardFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
