import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createPackageCategory} from "../../../redux/features/packages/categoriesSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../../UI/MainPaper";
import PackageCategoriesForm from "./PackageCategoriesForm";
import Breadcrumbs from "../../Navigation/Breadcrumbs";

const PackageCategoriesNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.packageCategories);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/packages": "Packages",
    "/packages/categories": "Package Categories",
    "/packages/categories/new": "New Package Category",
  };

  const onCancel = () => {
    history.push("/packages/categories");
  };
  const onSubmit = async values => {
    const packageCategoryObj = {
      category_id: null,
      ...values,
    };

    const res = await dispatch(createPackageCategory(packageCategoryObj));
    // If package was created successfully redirect to packages page and show user
    if (!res.error) {
      history.push({
        pathname: "/packages/categories",
        state: {
          showSuccess: true,
          snackbarContent: `Your Package Category '${packageCategoryObj.name}' was successfully created!`,
          snackbarLink: `/packages/categories/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Package Category
          </Typography>
          <PackageCategoriesForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Package Category"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PackageCategoriesNew;
