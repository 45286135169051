import React, {useState} from "react";

// Redux
import {useSelector} from "react-redux";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FullContainerProgress from "../../../UI/FullContainerProgress";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";

//Styling
const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      minWidth: 250,
    },
  },
  maxWidth: {
    minWidth: 120,
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  dialogContent: {overflow: "visible", paddingBottom: theme.spacing(5)},
}));

const CreateAddressDialog = ({customerId, open, onClose, onSubmit}) => {
  //Styles
  const classes = useStyles();
  // Redux
  const {isLoading, error} = useSelector(state => state.addresses);

  // Local State
  const [line_1, setLine1] = useState("");
  const [line_2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [others_details, setOther] = useState("");

  const formSubmit = () => {
    const values = {
      line_1,
      line_2: line_2 || "",
      city,
      state,
      zip: zip + "",
      country,
      others_details: others_details || "",
    };
    onSubmit(values);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      aria-labelledby="alert-address-view"
      aria-describedby="alert-address-view"
      scroll="body">
      {isLoading ? (
        <>
          <DialogContent>
            <Box width={700} height={500}>
              <FullContainerProgress />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-address-view">Create Address</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <form className={classes.form}>
              <Grid container spacing={4}>
                {error && (
                  <Grid item xs={12}>
                    <MuiAlert className={classes.alert} severity="error">
                      There was an error. Please try again.
                    </MuiAlert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="body1" component="p">
                    Customer ID: {customerId}
                  </Typography>
                </Grid>

                {/* Customer Address  Line 1*/}
                <Grid item xs={12} md={6}>
                  <TextField
                    className={classes.maxWidth}
                    name="line_1"
                    label="Address (Line 1)"
                    value={line_1}
                    onChange={e => setLine1(e.target.value)}
                    required
                  />
                </Grid>

                {/* Customer Address  Line 1*/}
                <Grid item xs={12} md={6}>
                  <TextField
                    className={classes.maxWidth}
                    name="line_2"
                    label="Address (Line 2)"
                    value={line_2}
                    onChange={e => setLine2(e.target.value)}
                  />
                </Grid>

                {/* Customer Address City */}
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    name="city"
                    label="City"
                    fullWidth
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </Grid>

                {/* Customer Address State */}
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    name="state"
                    label="State"
                    fullWidth
                    value={state}
                    onChange={e => setState(e.target.value)}
                  />
                </Grid>

                {/* Customer Address Zip */}
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    name="zip"
                    label="Zip"
                    fullWidth
                    value={zip}
                    InputProps={{
                      type: "text",
                    }}
                    onChange={e => setZip(e.target.value)}
                  />
                </Grid>

                {/* Customer Address Country */}
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    name="country"
                    label="Country"
                    fullWidth
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                  />
                </Grid>

                {/* Customer Address Other Details */}
                <Grid item xs={12}>
                  <TextField
                    name="others_details"
                    label="Other Details"
                    fullWidth
                    multiline
                    value={others_details}
                    onChange={e => setOther(e.target.value)}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                formSubmit();
              }}>
              Create Address
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateAddressDialog;
