import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/events/sources";

const eventSourcesInitialState = {
  eventSourcesList: [],
  currentPageEventSource: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const eventSources = createSlice({
  name: "eventSources",
  initialState: eventSourcesInitialState,
  reducers: {
    getEventSourceStart: startLoading,
    getEventSourcesStart: startLoading,
    createEventSourceStart: startLoading,
    editEventSourceStart: startLoading,
    deleteEventSourceStart: startLoading,
    getEventSourceSuccess(state, {payload}) {
      state.currentPageEventSource = payload;
      state.isLoading = false;
      state.error = null;
    },
    getEventSourcesSuccess(state, {payload}) {
      state.eventSourcesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createEventSourceSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editEventSourceSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteEventSourceSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getEventSourceFailure: loadingFailed,
    getEventSourcesFailure: loadingFailed,
    createEventSourceFailure: loadingFailed,
    editEventSourceFailure: loadingFailed,
    deleteEventSourceFailure: loadingFailed,
  },
});

export const {
  getEventSourceStart,
  getEventSourcesStart,
  createEventSourceStart,
  editEventSourceStart,
  deleteEventSourceStart,
  getEventSourceSuccess,
  getEventSourcesSuccess,
  createEventSourceSuccess,
  editEventSourceSuccess,
  deleteEventSourceSuccess,
  getEventSourceFailure,
  getEventSourcesFailure,
  createEventSourceFailure,
  editEventSourceFailure,
  deleteEventSourceFailure,
} = eventSources.actions;

export default eventSources.reducer;

export const fetchEventSources = () => async (dispatch, getState) => {
  try {
    dispatch(getEventSourcesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventSourcesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventSourcesFailure(errorObj));
  }
};

export const fetchEventSource = id => async (dispatch, getState) => {
  try {
    dispatch(getEventSourceStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventSourceSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventSourceFailure(errorObj));
  }
};

export const createEventSource = sourceObj => async (dispatch, getState) => {
  try {
    dispatch(createEventSourceStart());
    const res = await axios.post(url, sourceObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createEventSourceSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createEventSourceFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editEventSource = sourceObj => async (dispatch, getState) => {
  try {
    dispatch(editEventSourceStart());
    const res = await axios.put(url, sourceObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editEventSourceSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editEventSourceFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteEventSource = eventId => async (dispatch, getState) => {
  try {
    dispatch(deleteEventSourceStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: eventId},
    });
    dispatch(deleteEventSourceSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteEventSourceFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
