import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createCondo} from "../../redux/features/condos/condosSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import CondosForm from "./CondosForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const CondosNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.condos);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/condos": "Condos",
    "/condos/new": "New Condo",
  };

  const onCancel = () => {
    history.push("/condos");
  };
  const onSubmit = async values => {
    const condoObj = {
      ...values,
    };

    const res = await dispatch(createCondo(condoObj));
    // If condo was created successfully redirect to condos page and show user
    if (!res.error) {
      history.push({
        pathname: "/condos",
        state: {
          showSuccess: true,
          snackbarContent: `Your Condo '${condoObj.name}' was successfully created!`,
          snackbarLink: `/condos/${res.id}`,
        },
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Typography component="h1" variant="h5" gutterBottom>
              New Condo
            </Typography>
            <CondosForm
              onSubmit={onSubmit}
              onCancel={onCancel}
              isLoading={isLoading}
              submitButtonLabel="Create Condo"
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CondosNew;
