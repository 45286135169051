import React, {useState} from "react";

// Styles
import {makeStyles} from "@material-ui/core/styles";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {toggleUIMode} from "../../redux/features/ui/uiSlice";
import {
  editUser,
  editUserPassword,
} from "../../redux/features/users/usersSlice";

// Components
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ColorTool from "../UI/ColorTool";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(2),
  },
}));

const ResetPasswordForm = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading, error} = useSelector(state => state.users);
  const {user_id} = useSelector(state => state.auth);

  // Local State
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  const onSubmit = async e => {
    e.preventDefault();

    const userObj = {
      password,
      user_id,
    };

    const res = await dispatch(editUserPassword(userObj));
    if (!res.error) {
      setSnackbarContent(
        "Your Password was changed successfully. The change will show next time you log in."
      );
    } else {
      if (res.errorCode === 400) {
        setSnackbarContent(
          `Password doesn't meet requirements: ${res.errorMessage}`
        );
      } else {
        setSnackbarContent(
          "There was an error updating the values. Please try again."
        );
      }
    }
    setOpenSnackbar(true);
  };

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6} lg={8} spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="body1">Password Requirements:</Typography>
          <Typography variant="body2" component="ul">
            <>
              <li>Must contain at least 10 characters.</li>
              <li>Must contain at least 1 UPPERCASE letter.</li>
              <li>Must contain at least 1 LOWERCASE letter.</li>
              <li>Must contain at least 1 NUMBER.</li>
              <li>Must contain at least 1 SYMBOL.</li>
            </>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ButtonWithLoading
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={password === "" || password !== confirmPassword}>
            Submit Changes
          </ButtonWithLoading>
        </Grid>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={error ? "error" : "success"}>
            {snackbarContent}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </form>
  );
};

const UserInfoForm = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading, error} = useSelector(state => state.users);
  const {user, user_id, email: userEmail} = useSelector(state => state.auth);

  // Local State
  const [username, setUsername] = useState(user);
  const [email, setEmail] = useState(userEmail);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  const onSubmit = async e => {
    e.preventDefault();

    const userObj = {
      email,
      username,
      user_id,
      enabled: 1,
    };

    const res = await dispatch(editUser(userObj));
    if (!res.error) {
      setSnackbarContent(
        "Your changes were successfully submitted. The changes will show next time you log in."
      );
    } else {
      setSnackbarContent(
        "There was an error updating the values. Please try again."
      );
    }
    setOpenSnackbar(true);
  };

  const handleClose = () => {
    setOpenSnackbar(false);
    setSnackbarContent("");
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonWithLoading
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={isLoading}>
            Submit Changes
          </ButtonWithLoading>
        </Grid>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={error ? "error" : "success"}>
            {snackbarContent}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </form>
  );
};

const UserPage = () => {
  // Styling
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const {mode} = useSelector(state => state.ui);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h3">
          User Settings
        </Typography>
      </Grid>

      {/* User info */}
      <Grid item xs={12}>
        <Paper className={classes.paperContainer}>
          <Typography component="h3" variant="h5" gutterBottom>
            User Info
          </Typography>
          <UserInfoForm />
        </Paper>
      </Grid>

      {/*Reset Password */}
      <Grid item xs={12}>
        <Paper className={classes.paperContainer}>
          <Typography component="h3" variant="h5" gutterBottom>
            Reset Password
          </Typography>
          <ResetPasswordForm />
        </Paper>
      </Grid>

      {/* Dashboard Colors  */}
      <Grid item xs={12}>
        <Paper className={classes.paperContainer}>
          <Typography component="h3" variant="h5" gutterBottom>
            Dashboard Colors
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={mode === "dark"}
                onChange={() => dispatch(toggleUIMode())}
                value="dark"
                color="primary"
              />
            }
            label={`Toggle to Dark Mode`}
          />
          <ColorTool />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserPage;
