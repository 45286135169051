import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchEventOrganizers} from "../../../redux/features/events/organizersSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const EventOrganizersPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {eventOrganizersList, isLoading, error} = useSelector(
    state => state.eventOrganizers
  );

  useEffect(() => {
    dispatch(fetchEventOrganizers());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/events/organizers"
        tableTitle="All Organizers"
        tableColumns={[
          {
            title: "ID",
            field: "id",
            type: "numeric",
            width: 30,
          },
          {title: "Name", field: "name"},
          {title: "Phone", field: "phone"},
          {title: "Website", field: "website"},
          {title: "Email", field: "email"},
        ]}
        addButtonLabel="Event Organizer"
        dataList={eventOrganizersList}
        isLoading={isLoading}
        error={error}
        userPermissionKey="events"
      />
    </>
  );
};

export default EventOrganizersPage;
