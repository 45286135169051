import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = `${process.env.REACT_APP_API_BASE_URL}/v1/events`;

const eventsInitialState = {
  eventsList: [],
  currentPageEvent: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const events = createSlice({
  name: "events",
  initialState: eventsInitialState,
  reducers: {
    getEventStart: startLoading,
    getEventsStart: startLoading,
    createEventStart: startLoading,
    editEventStart: startLoading,
    deleteEventStart: startLoading,
    getEventSuccess(state, {payload}) {
      state.currentPageEvent = payload;
      state.isLoading = false;
      state.error = null;
    },
    getEventsSuccess(state, {payload}) {
      state.eventsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createEventSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editEventSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteEventSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getEventFailure: loadingFailed,
    getEventsFailure: loadingFailed,
    createEventFailure: loadingFailed,
    editEventFailure: loadingFailed,
    deleteEventFailure: loadingFailed,
  },
});

export const {
  getEventStart,
  getEventsStart,
  createEventStart,
  editEventStart,
  deleteEventStart,
  getEventSuccess,
  getEventsSuccess,
  createEventSuccess,
  editEventSuccess,
  deleteEventSuccess,
  getEventFailure,
  getEventsFailure,
  createEventFailure,
  editEventFailure,
  deleteEventFailure,
} = events.actions;

export default events.reducer;

export const fetchEvents = () => async (dispatch, getState) => {
  try {
    dispatch(getEventsStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventsFailure(errorObj));
  }
};

export const fetchEvent = id => async (dispatch, getState) => {
  try {
    dispatch(getEventStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventFailure(errorObj));
  }
};

export const createEvent = eventObj => async (dispatch, getState) => {
  try {
    dispatch(createEventStart());
    const res = await axios.post(url, eventObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createEventSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createEventFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editEvent = eventObj => async (dispatch, getState) => {
  try {
    dispatch(editEventStart());
    const res = await axios.put(url, eventObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editEventSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editEventFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteEvent = eventId => async (dispatch, getState) => {
  try {
    dispatch(deleteEventStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: eventId},
    });
    dispatch(deleteEventSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteEventFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
