import React, {useState, useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchRefunds} from "../../../../redux/features/sales/refundsSlice";

// Components
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";

// Icons
import tableIcons from "../../../Table/TableIcons";

const RefundsTableComponent = ({chargeId}) => {
  // Local State
  const [formattedData, setFormattedData] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const {refundsList, isLoading} = useSelector(state => state.refunds);

  useEffect(() => {
    dispatch(fetchRefunds(chargeId));
  }, [dispatch, chargeId]);

  useEffect(() => {
    // Need to spread array so it doesn't fail
    setFormattedData(formatData(refundsList));
  }, [refundsList]);

  // Need to spread array so it doesn't fail
  const formatData = refundsList => {
    return refundsList.map(o => ({
      ...o,
    }));
  };

  return (
    //  {/* Refund Information */}
    <>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <MaterialTable
            title="Refunds"
            isLoading={isLoading}
            icons={tableIcons}
            columns={[
              {
                title: "ID",
                field: "stripe_refund_id",
              },
              {
                title: "Refund Date",
                field: "created",
                render: rowData => (
                  <>
                    {moment
                      .unix(rowData.created)
                      .tz("America/Chicago")
                      .format("lll")}
                  </>
                ),
              },
              {
                title: "Amount",
                field: "amount",
                type: "currency",
                headerStyle: {
                  textAlign: "right",
                },
                render: rowData => <>$ {(rowData.amount / 100).toFixed(2)}</>,
              },
              {
                title: "Reason",
                field: "reason",
                render: rowData => (
                  <>
                    {rowData.reason
                      .split("_")
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")}
                  </>
                ),
              },
              {
                title: "Status",
                field: "status",
                render: rowData => (
                  <>
                    {rowData.status.charAt(0).toUpperCase() +
                      rowData.status.slice(1)}
                  </>
                ),
              },
            ]}
            data={formattedData}
            options={{
              search: true,
              pageSize: 2,
              pageSizeOptions: [2, 5, 10],
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RefundsTableComponent;
