import React from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import RichTextEditor from "../../FormFields/RichTextEditor";
import ButtonsContainer from "./ButtonsContainer";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%", marginBottom: theme.spacing(2)},
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset",
  },
}));

const WidgetsSections = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
  widgetName = "homepage",
  widgetLabel = "Homepage Widgets",
  numberOfSections = 4,
  defaultValues = {
    section1: "",
    section2: "",
    section3: "",
    section4: "",
  },
}) => {
  // Styles
  const classes = useStyles();

  // Hook
  const {handleSubmit, control} = useForm(defaultValues);

  const formSubmit = data => {
    console.log(data);
    const formattedWidgetValueObj = {
      ...settingsList.widgets.value,
      [widgetName]: {
        ...data,
      },
    };

    const settingArray = [
      {...settingsList.widgets, value: JSON.stringify(formattedWidgetValueObj)},
    ];
    onSubmit(settingArray);
  };

  const sections = () => {
    let arr = [];
    for (let index = 1; index <= numberOfSections; index++) {
      arr.push(index);
    }
    return arr;
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography component="p" variant="h5">
            {widgetLabel}
          </Typography>
        </Grid>
        {/* Widgets sections */}
        {sections().map(i => (
          <Controller
            key={`section${i}`}
            name={`section${i}`}
            defaultValue={defaultValues[`section${i}`]}
            control={control}
            render={props => (
              <Grid item xs={12} md={6}>
                <RichTextEditor
                  label={`Section ${i} Widget`}
                  className={classes.maxWidth}
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={false}
                  height={200}
                  customToolbar={"undo redo |code|   help"}
                  customExtendedValidElements={"script[*], link[*], meta[*]"}
                  customValidChildren={"+body[link|meta]"}
                />
              </Grid>
            )} // props contains: onChange, onBlur and value
          />
        ))}
        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

const WidgetsForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  // Style
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const widgetsStructured = [
    {
      tabLabel: "Website",
      types: [
        {
          widgetLabel: "Website Widgets (every page)",
          widgetName: "website",
          numberOfSections: 4,
        },
      ],
    },
    {
      tabLabel: "Homepage",
      types: [
        {
          widgetName: "homepage",
          widgetLabel: "Homepage Widgets",
          numberOfSections: 6,
        },
      ],
    },
    {
      tabLabel: "Packages",
      types: [
        {
          widgetLabel: "Packages List Widgets",
          widgetName: "packages",
          numberOfSections: 3,
        },
        {
          widgetName: "individualPackage",
          widgetLabel: "Individual Page Package Widgets",
          numberOfSections: 9,
        },
      ],
    },
    {
      tabLabel: "Hotels",
      types: [
        {
          widgetName: "hotels",
          widgetLabel: "Hotels List Widgets",
          numberOfSections: 3,
        },
        {
          widgetName: "individualHotel",
          widgetLabel: "Individual Hotel Page Widgets",
          numberOfSections: 10,
        },
      ],
    },
    {
      tabLabel: "Condos",
      types: [
        {
          widgetName: "condos",
          widgetLabel: "Condos List Widgets",
          numberOfSections: 3,
        },
        {
          widgetName: "individualCondo",
          widgetLabel: "Individual Condo Page Widgets",
          numberOfSections: 6,
        },
      ],
    },
    {
      tabLabel: "Events",
      types: [
        {
          widgetName: "events",
          numberOfSections: 3,
          widgetLabel: "Events Calendar Widgets",
        },
        {
          widgetName: "individualEvent",
          widgetLabel: "Individual Event Page Widgets",
          numberOfSections: 6,
        },
      ],
    },
    {
      tabLabel: "Meetings",
      types: [
        {
          widgetName: "meetings",
          numberOfSections: 2,
          widgetLabel: "Meetings Main Page Widgets",
        },
        {
          widgetName: "individualMeeting",
          widgetLabel: "Individual Meeting Page Widgets",
          numberOfSections: 7,
        },
      ],
    },
    {
      tabLabel: "Pages",
      types: [
        {
          widgetName: "pages",
          widgetLabel: "Custom Page Widgets",
          numberOfSections: 2,
        },
      ],
    },
    {
      tabLabel: "Booking",
      types: [
        {
          widgetName: "search",
          widgetLabel: "Regular Booking Page (Both Packages and Condos)",
          numberOfSections: 2,
        },
        {
          widgetName: "searchRange",
          widgetLabel: "Deals Booking Page",
          numberOfSections: 2,
        },
        {
          widgetName: "searchPackages",
          widgetLabel: "Packages Booking Page",
          numberOfSections: 2,
        },
        {
          widgetName: "searchCondos",
          widgetLabel: "Condos Booking Page",
          numberOfSections: 2,
        },
      ],
    },
    {
      tabLabel: "Checkout",
      types: [
        {
          widgetName: "checkout",
          widgetLabel: "Checkout Packages Page",
          numberOfSections: 3,
        },
        {
          widgetName: "checkoutCondos",
          widgetLabel: "Checkout Condos Page",
          numberOfSections: 3,
        },
        {
          widgetName: "confirmation",
          widgetLabel: "Order Confirmation Page (both Package and Condo)",
          numberOfSections: 2,
        },
      ],
    },
    {
      tabLabel: "Order",
      types: [
        {
          widgetName: "order",
          widgetLabel: "Order Lookup Page",
          numberOfSections: 3,
        },
        {
          widgetName: "orderDetails",
          widgetLabel: "Order Details / Payment Portal Page",
          numberOfSections: 4,
        },
      ],
    },
  ];

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}>
        {widgetsStructured.map(({tabLabel}, i) => (
          <Tab
            label={tabLabel}
            id={`vertical-tab-${i}`}
            key={`vertical-tab-${i}`}
            aria-controls={`vertical-tabpanel-${i}`}
          />
        ))}
      </Tabs>
      {widgetsStructured.map(({types}, i) => (
        <TabPanel value={value} index={i} key={`tab-panel-${i}`}>
          {/* // TODO: Delete this  */}
          {/* <pre>{JSON.stringify(settingsList.widgets, 0, 2)}</pre> */}
          {types.map(({widgetLabel, widgetName, numberOfSections}, j) => (
            <React.Fragment key={`widgetSections-${j}`}>
              {j !== 0 && (
                <Grid item xs={12}>
                  <Box mt={8} mb={8}>
                    <Divider />
                  </Box>
                </Grid>
              )}
              <WidgetsSections
                onSubmit={onSubmit}
                onReset={onReset}
                isLoading={isLoading}
                submitButtonLabel={submitButtonLabel}
                settingsList={settingsList}
                widgetName={widgetName}
                widgetLabel={widgetLabel}
                numberOfSections={numberOfSections}
                defaultValues={(function () {
                  let defaultValuesObj = {};
                  for (let index = 1; index <= numberOfSections; index++) {
                    const sectionName = `section${index}`;
                    defaultValuesObj[sectionName] =
                      settingsList.widgets?.value !== null &&
                      settingsList.widgets?.value[widgetName] &&
                      settingsList.widgets?.value[widgetName][sectionName]
                        ? settingsList.widgets?.value[widgetName][sectionName]
                        : "";
                  }
                  return defaultValuesObj;
                })()}
              />
            </React.Fragment>
          ))}
        </TabPanel>
      ))}
    </div>
  );
};

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default WidgetsForm;
