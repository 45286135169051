import React from "react";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {useForm, Controller} from "react-hook-form";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFiles from "../FormFields/UploadFiles";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const GalleriesForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    name: "",
    description: "",
    photos: null,
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {photos, ...restOfData} = data;

    const formattedValues = {
      ...restOfData,
      photos: photos.flatMap(photo => photo.photoId || []),
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      {/* Gallery Name */}
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            className={classes.maxWidth}
            inputRef={register}
            name="name"
            label="Name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Gallery  Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="description"
            label="Description"
            multiline
            required
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* Gallery Pictures*/}
        <Grid item xs={12}>
          <Controller
            name={`photos`}
            control={control}
            defaultValue={defaultValues.photos}
            render={props => (
              <UploadFiles
                required
                label="Images"
                buttonLabel="Set Images"
                onSave={photosArray => {
                  props.onChange(photosArray);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
              />
            )}
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default GalleriesForm;
