import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createPage} from "../../redux/features/pages/pagesSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import PageForm from "./PageForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const PagesNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.pages);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/pages": "Pages",
    "/pages/new": "New Page",
  };

  const onCancel = () => {
    history.push("/pages");
  };

  const onSubmit = async values => {
    const pageObj = {
      id: null,
      ...values,
    };

    const res = await dispatch(createPage(pageObj));
    // If page was created successfully redirect to pages page and show user
    if (!res.error) {
      history.push({
        pathname: "/pages",
        state: {
          showSuccess: true,
          snackbarContent: `Your Page '${pageObj.title}' was successfully created!`,
          snackbarLink: `/pages/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New Page
          </Typography>
          <PageForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create Page"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PagesNew;
