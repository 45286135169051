import React from "react";

//utils
import {useForm} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const CustomersForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    stripe_customer_id: "",
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  // Form hook
  const {handleSubmit, register} = useForm({defaultValues});

  const formSubmit = data => {
    const {stripe_customer_id, ...restOfData} = data;
    const formattedValues = {
      ...restOfData,
      stripe_customer_id: stripe_customer_id || null,
    };
    onSubmit(formattedValues);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Customer First Name */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="first_name"
            label="First Name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>
        {/* Customer Last Name */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="last_name"
            label="Last Name"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Customer Email */}
        <Grid item xs={12} md={6}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="email_address"
            type="email"
            label="Email"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Customer Phone Number */}
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.maxWidth}
            name="phone_number"
            inputRef={register}
            label="Phone"
            InputProps={{
              readOnly,
            }}
            required
          />
        </Grid>

        {/* Customer Stripe Id */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="stripe_customer_id"
            label="Stripe Customer ID (Optional)"
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CustomersForm;
