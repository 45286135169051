import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/events/venues";

const eventVenuesInitialState = {
  eventVenuesList: [],
  currentPageEventVenue: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const eventVenues = createSlice({
  name: "eventVenues",
  initialState: eventVenuesInitialState,
  reducers: {
    getEventVenueStart: startLoading,
    getEventVenuesStart: startLoading,
    createEventVenueStart: startLoading,
    editEventVenueStart: startLoading,
    deleteEventVenueStart: startLoading,
    getEventVenueSuccess(state, {payload}) {
      state.currentPageEventVenue = payload;
      state.isLoading = false;
      state.error = null;
    },
    getEventVenuesSuccess(state, {payload}) {
      state.eventVenuesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createEventVenueSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editEventVenueSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteEventVenueSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getEventVenueFailure: loadingFailed,
    getEventVenuesFailure: loadingFailed,
    createEventVenueFailure: loadingFailed,
    editEventVenueFailure: loadingFailed,
    deleteEventVenueFailure: loadingFailed,
  },
});

export const {
  getEventVenueStart,
  getEventVenuesStart,
  createEventVenueStart,
  editEventVenueStart,
  deleteEventVenueStart,
  getEventVenueSuccess,
  getEventVenuesSuccess,
  createEventVenueSuccess,
  editEventVenueSuccess,
  deleteEventVenueSuccess,
  getEventVenueFailure,
  getEventVenuesFailure,
  createEventVenueFailure,
  editEventVenueFailure,
  deleteEventVenueFailure,
} = eventVenues.actions;

export default eventVenues.reducer;

export const fetchEventVenues = () => async (dispatch, getState) => {
  try {
    dispatch(getEventVenuesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventVenuesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventVenuesFailure(errorObj));
  }
};

export const fetchEventVenue = id => async (dispatch, getState) => {
  try {
    dispatch(getEventVenueStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventVenueSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventVenueFailure(errorObj));
  }
};

export const createEventVenue = venueObj => async (dispatch, getState) => {
  try {
    dispatch(createEventVenueStart());
    const res = await axios.post(url, venueObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createEventVenueSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createEventVenueFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editEventVenue = venueObj => async (dispatch, getState) => {
  try {
    dispatch(editEventVenueStart());
    const res = await axios.put(url, venueObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editEventVenueSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editEventVenueFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteEventVenue = eventId => async (dispatch, getState) => {
  try {
    dispatch(deleteEventVenueStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: eventId},
    });
    dispatch(deleteEventVenueSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteEventVenueFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
