import React, {useState} from "react";

// Routing
import {Switch, Route, NavLink} from "react-router-dom";
import DashboardRoutes from "../Routing/DashboardRoutes";

// Styles
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

// Components
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import MainListItems from "../Navigation/MainListItems";
import Copyright from "../Text/Copyright";
import Logout from "../Auth/Logout";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";

// Routing
import {useHistory} from "react-router-dom";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {display: "flex"},
  toolbar: {paddingRight: 24},
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {marginRight: 36},
  menuButtonHidden: {
    display: "none",
  },
  title: {flexGrow: 1},
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      minHeight: "100px",
      border: "6px solid transparent",
      background:
        theme.palette.type === "dark"
          ? theme.palette.grey[700]
          : theme.palette.grey[300],
      borderRadius: "10px",
      backgroundClip: "padding-box",
      "&:hover": {
        minHeight: "100px",
        border: "6px solid transparent",
        background: theme.palette.grey[500],
        borderRadius: "10px",
        backgroundClip: "padding-box",
      },
    },
    "&::-webkit-scrollbar": {
      width: theme.spacing(2.5),
      height: theme.spacing(2),
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
}));

export default function Dashboard() {
  // Local State
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // Routing
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    try {
      if (event.target.attributes.value.value) {
        history.push(`/${event.target.attributes.value.value}`);
      }
    } catch {}
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}>
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            color="inherit"
            noWrap
            className={classes.title}>
            {/* Page Titles */}
            <Switch>
              {/* Packages */}
              <Route path="/packages/customs">Custom Packages</Route>
              <Route path="/packages/cards">Package Cards</Route>
              <Route path="/packages/categories">Package Categories</Route>
              <Route path="/packages">Packages</Route>

              {/* Events */}
              <Route path="/events/venues">Event Venues</Route>
              <Route path="/events/sources">Event Sources</Route>
              <Route path="/events/organizers">Event Organizers</Route>
              <Route path="/events/categories">Event Categories</Route>
              <Route path="/events">Events</Route>

              {/* Hotels */}
              <Route path="/hotels">Hotels</Route>

              {/* Condos */}
              <Route path="/condos">Condos</Route>

              {/* Addons */}
              <Route path="/addons">Addons</Route>

              {/* Pages */}
              <Route path="/pages">Pages</Route>

              {/* Media */}
              <Route path="/media/galleries">Galleries</Route>
              <Route path="/media">Media</Route>

              {/* Sales */}
              <Route path="/sales/customers">Customers</Route>
              <Route path="/sales/charges">Charges</Route>
              <Route path="/sales/orders">Orders</Route>
              <Route path="/sales">Sales</Route>

              {/* Rates */}
              <Route path="/rates">Rates</Route>
              <Route path="/rateshopper">Rateshopper</Route>

              {/* Users */}
              <Route path="/meetings">Event/Meeting</Route>

              {/* Users */}
              <Route path="/users">Users</Route>

              {/* Settings */}
              <Route path="/settings">Settings</Route>

              {/* Contacts */}
              <Route path="/contacts">Contacts</Route>

              {/* Catch All */}
              <Route path="/">Dashboard</Route>
            </Switch>
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleClick}>
            <PersonIcon />
          </IconButton>
        </Toolbar>

        <Menu
          id="simple-menu"
          variant="selectedMenu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          className={classes.maxWidth}
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
          transformOrigin={{vertical: "top", horizontal: "center"}}>
          <MenuItem onClick={handleClose} component={NavLink} to="/user">
            USER SETTINGS
          </MenuItem>
          <Logout fullwidth />
          {/* Wont need a HandleClose, it rerenders everything */}
        </Menu>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <MainListItems open={open} onSubMenuOpen={() => setOpen(true)} />
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <Tooltip
              title="Settings"
              placement="right"
              arrow
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}>
              <ListItem button component={NavLink} to="/settings">
                <ListItemIcon className={classes.listItemIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </Tooltip>
          </Grid>
        </Grid>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {/* Routes  */}
          <DashboardRoutes />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
