import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchCondos} from "../../redux/features/condos/condosSlice";

// Components
import DisplayTablePage from "../PageWrappers/DisplayTablePage";

const CondosPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {condosList, isLoading, error} = useSelector(state => state.condos);

  useEffect(() => {
    dispatch(fetchCondos());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/condos"
      tableTitle="All Condos"
      tableColumns={[
        {
          title: "ID",
          field: "condos_id",
          type: "numeric",
          width: 30,
        },
        {title: "Name", field: "name"},
        {title: "Permalink", field: "permalink"},
        {title: "Bedrooms", field: "bedrooms"},
        {title: "Bathrooms", field: "bathrooms"},
        {
          title: "Beachfront",
          field: "beachfront",
          type: "boolean",
        },
      ]}
      addButtonLabel="Condo"
      dataList={condosList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="condos"
      idKey="condos_id"
    />
  );
};

export default CondosPage;
