import React, {useState, useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchPaymentMethods} from "../../../../redux/features/sales/paymentMethodsSlice";

// Styling
import {makeStyles} from "@material-ui/core/styles";
import {green, red, yellow} from "@material-ui/core/colors";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import PaymentIcon from "@material-ui/icons/Payment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import ErrorIcon from "@material-ui/icons/Error";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

import tableIcons from "../../../Table/TableIcons";

const useStyles = makeStyles(theme => ({
  detailPanelContainer: {
    padding: theme.spacing(5),
  },

  textWithIcon: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  addressText: {
    display: "flex",
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },

  green: {
    color: green[600],
  },
  red: {
    color: red[500],
  },
  yellow: {
    color: yellow[600],
  },
}));

const BillingDetailsPanel = ({billingDetails}) => {
  // Styles
  const classes = useStyles();

  const {
    address: {city, country, line1, line2, postal_code, state},
    email,
    name,
    phone,
  } = billingDetails;

  return (
    <Grid container item spacing={2} xs={12} lg={4}>
      <Grid item xs={12}>
        <Typography component="p">{name}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography component="span" className={classes.addressText}>
          <LocationOnIcon />
          <Typography component="span" variant="body2">
            {line1}.
            {line2 && (
              <>
                <br />
                {line2}.
              </>
            )}
            <br />
            {city}, {state}.<br />
            {postal_code}
            <br />
            {country}.
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          component="p"
          variant="body2"
          className={classes.textWithIcon}>
          <EmailIcon />
          {email || "N/A"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          component="p"
          variant="body2"
          className={classes.textWithIcon}>
          <PhoneIcon />
          {phone || "N/A"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PaymentMethodPanel = ({paymentMethod}) => {
  // Styles
  const classes = useStyles();

  const {
    brand,
    last4,
    exp_month,
    exp_year,
    funding,
    wallet,
    country,
    checks,
    fingerprint,
  } = paymentMethod.card;

  const CheckStatus = ({status}) => {
    switch (status) {
      case "pass":
        return (
          <Tooltip title="Pass" aria-label="pass">
            <CheckCircleIcon fontSize="small" className={classes.green} />
          </Tooltip>
        );
      case "fail":
        return (
          <Tooltip title="Fail" aria-label="fail">
            <ErrorIcon fontSize="small" className={classes.red} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip
            title={status.charAt(0).toUpperCase() + status.slice(1)}
            aria-label={status}>
            <HelpIcon fontSize="small" className={classes.yellow} />
          </Tooltip>
        );
    }
  };

  return (
    <Grid container item spacing={2} xs={12} lg={8}>
      <Grid item xs={12} lg={6}>
        <Typography component="p" className={classes.textWithIcon}>
          <PaymentIcon fontSize="large" />
          {brand.toUpperCase()}
        </Typography>
        <Typography component="p">
          {"•••• ".repeat(3)}
          {last4}
        </Typography>
        <Typography component="p">
          {exp_month}/{exp_year}
        </Typography>
        <Typography component="p">
          <Typography component="span" variant="body2">
            Funding:
          </Typography>{" "}
          {funding.toUpperCase()}
        </Typography>
        {wallet && (
          <Typography component="p">
            <Typography component="span" variant="body2">
              Wallet Type:
            </Typography>{" "}
            {wallet.type
              .split("_")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        )}
        <Typography component="p">
          <Typography component="span" variant="body2">
            Country:
          </Typography>{" "}
          {country}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography
          component="p"
          variant="body2"
          gutterBottom
          className={classes.textWithIcon}>
          <CheckStatus status={checks.cvc_check || "unchecked"} />
          CVC
        </Typography>
        <Typography
          component="p"
          variant="body2"
          gutterBottom
          className={classes.textWithIcon}>
          <CheckStatus status={checks.address_line1_check || "unchecked"} />
          Address Line 1
        </Typography>
        <Typography
          component="p"
          variant="body2"
          gutterBottom
          className={classes.textWithIcon}>
          <CheckStatus
            status={checks.address_postal_code_check || "unchecked"}
          />
          Address Postal Code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="p">
          <Typography component="span" variant="body2">
            Fingerprint:
          </Typography>{" "}
          {fingerprint}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PaymentMethodsTableComponent = ({customerId, addButton}) => {
  // Styles
  const classes = useStyles();

  // Local State
  const [formattedData, setFormattedData] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const {paymentMethodsList, isLoading} = useSelector(
    state => state.paymentMethods
  );

  useEffect(() => {
    dispatch(fetchPaymentMethods(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    // Need to spread array so it doesn't fail
    setFormattedData(formatData(paymentMethodsList));
  }, [paymentMethodsList]);

  // Need to spread array so it doesn't fail
  const formatData = paymentMethodsList => {
    return paymentMethodsList.map(o => ({
      ...o,
    }));
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MaterialTable
          title="Customer Payment Methods"
          isLoading={isLoading}
          icons={tableIcons}
          columns={[
            {
              title: "Stripe ID",
              field: "id",
            },
            {
              title: "Card Brand",
              field: "card",
              render: rowData => (
                <>
                  {rowData.card.brand.charAt(0).toUpperCase() +
                    rowData.card.brand.slice(1)}
                </>
              ),
            },
            {
              title: "Last 4",
              field: "card",
              render: rowData => <>{rowData.card.last4}</>,
            },
            {
              title: "Card Expiration",
              field: "card",
              render: rowData => (
                <>
                  {rowData.card.exp_month}/{rowData.card.exp_year}
                </>
              ),
            },
            {
              title: "Funding",
              field: "card",
              render: rowData => (
                <>
                  {rowData.card.funding.charAt(0).toUpperCase() +
                    rowData.card.funding.slice(1)}
                </>
              ),
            },
            {
              title: "Wallet Type",
              field: "card",
              render: rowData => {
                if (rowData.card.wallet) {
                  return (
                    <>
                      {rowData.card.wallet.type
                        .split("_")
                        .map(
                          word => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </>
                  );
                } else {
                  return <>Bank</>;
                }
              },
            },
            {
              title: "Date Created",
              field: "created",
              render: rowData => (
                <>
                  {moment
                    .unix(rowData.created)
                    .tz("America/Chicago")
                    .format("lll")}
                </>
              ),
            },
            {
              title: "Live Mode",
              field: "livemode",
              type: "boolean",
            },
          ]}
          detailPanel={[
            {
              tooltip: "Show Details",
              render: rowData => {
                return (
                  <Grid container className={classes.detailPanelContainer}>
                    <BillingDetailsPanel
                      billingDetails={rowData.billing_details}
                    />
                    <PaymentMethodPanel paymentMethod={rowData} />
                  </Grid>
                );
              },
            },
          ]}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          data={formattedData}
          options={{
            search: true,
            pageSize: 2,
            pageSizeOptions: [2, 5, 10],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentMethodsTableComponent;
