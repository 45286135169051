import React from "react";

// utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import RichTextEditor from "../FormFields/RichTextEditor.js";
import UploadFiles from "../FormFields/UploadFiles.js";
import PermalinkTextField from "../FormFields/PermalinkTextField";
import HotelComboBox from "../FormFields/HotelComboBox";
import PackageComboBox from "../FormFields/PackageComboBox.js";
import CondoComboBox from "../FormFields/CondoComboBox.js";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const PageForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    title: "",
    permalink: "",
    description: "",
    page_status: "Published",
    body: "<h1>Page Title</h1>",
    photoObj: null,
    type: "default",
    linked_object: "none",
    pkg: null,
    hotel: null,
    condo: null,
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  // Form
  const {handleSubmit, control, register, watch} = useForm({defaultValues});

  const typeOfLinkedObject = watch("linked_object");

  const formSubmit = data => {
    const {photoObj, hotel, condo, pkg, ...restOfData} = data;
    const values = {
      hotel_id: hotel ? hotel.id : null,
      condo_id: condo ? condo.condos_id : null,
      package_id: pkg ? pkg.id : null,
      picture_id: photoObj ? photoObj.photoId : null,
      ...restOfData,
    };

    onSubmit(values);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* Page Title  */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="title"
            label="Title"
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>
        {/* Page Permalink */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="permalink"
            defaultValue={defaultValues.permalink}
            render={props => (
              <PermalinkTextField
                value={props.value}
                required
                label="Permalink"
                onChange={props.onChange}
                startAdornment={process.env.REACT_APP_DOMAIN + "/page/"}
                fullWidth
                readOnly={readOnly}
              />
            )}
          />
        </Grid>
        {/* Page Description */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            required
            name="description"
            label="Description"
            multiline
            fullWidth
            InputProps={{
              readOnly,
            }}
          />
        </Grid>
        {/* Page body*/}
        <Grid item xs={12}>
          <Controller
            name={`body`}
            control={control}
            defaultValue={defaultValues.body}
            render={props => (
              <Grid item xs={12}>
                <RichTextEditor
                  label="Page Body"
                  withPictureUploader
                  className={classes.maxWidth}
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={readOnly}
                  height={500}
                />
              </Grid>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/* Page Image  */}
        <Grid item xs={12}>
          <Controller
            name={`photoObj`}
            control={control}
            defaultValue={defaultValues.photoObj}
            render={props => (
              <UploadFiles
                required
                label="Featured Image"
                buttonLabel="Set Featured Image"
                singleFile
                onSave={photosArray => {
                  props.onChange(photosArray[0]);
                }}
                readOnly={readOnly}
                defaultValue={props.value}
                itemsWidth={400}
                itemsHeight={200}
              />
            )}
          />
        </Grid>
        {/* Page Type */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`type`}
            control={control}
            defaultValue={defaultValues.type}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Page Type</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"default"}>Default</MenuItem>
                  <MenuItem value={"core"}>Core</MenuItem>
                  <MenuItem value={"posts"}>Post</MenuItem>
                  <MenuItem value={"webcam"}>Webcam</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/* Page Status */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`page_status`}
            control={control}
            defaultValue={defaultValues.page_status}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Status
                </InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Published"}>Published</MenuItem>
                  <MenuItem value={"Private"}>Private</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {/* Linked Object */}
        <Grid item xs={12} lg={6}>
          <Controller
            name={`linked_object`}
            control={control}
            defaultValue={defaultValues.linked_object}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Show Extra Information
                </InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"all"}>Show All Deals</MenuItem>
                  <MenuItem value={"hotel"}>Show Hotel Deals</MenuItem>
                  <MenuItem value={"package"}>Show Package Deals</MenuItem>
                  <MenuItem value={"condo"}>Show a Condo</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        {typeOfLinkedObject === "hotel" && (
          // {/* Linked Hotel */}
          <Grid item xs={12} md={6}>
            <Controller
              name="hotel"
              control={control}
              defaultValue={defaultValues.hotel}
              render={props => (
                <HotelComboBox
                  required
                  label="Linked Hotel"
                  defaultValue={props.value}
                  onChange={props.onChange}
                  readOnly={readOnly}
                />
              )} // props contains: onChange, onBlur and value
            />
          </Grid>
        )}
        {typeOfLinkedObject === "package" && (
          // {/* Linked Package */}
          <Grid item xs={12} md={6}>
            <Controller
              name="pkg"
              control={control}
              defaultValue={defaultValues.pkg}
              render={props => (
                <PackageComboBox
                  required
                  label="Linked Package"
                  defaultValue={props.value}
                  onChange={props.onChange}
                  readOnly={readOnly}
                />
              )} // props contains: onChange, onBlur and value
            />
          </Grid>
        )}
        {typeOfLinkedObject === "condo" && (
          // {/* Linked Condo */}
          <Grid item xs={12} md={6}>
            <Controller
              name="condo"
              control={control}
              defaultValue={defaultValues.condo}
              render={props => (
                <CondoComboBox
                  required
                  label="Linked Condo"
                  defaultValue={props.value}
                  onChange={props.onChange}
                  readOnly={readOnly}
                />
              )} // props contains: onChange, onBlur and value
            />
          </Grid>
        )}
        {!readOnly && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PageForm;
