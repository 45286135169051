import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/charges";

const refundsInitialState = {
  chargeId: null,
  refundsList: [],
  currentPageRefund: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const refunds = createSlice({
  name: "refunds",
  initialState: refundsInitialState,
  reducers: {
    setChargeId(state, {payload}) {
      state.chargeId = payload;
    },
    getRefundStart: startLoading,
    getRefundsStart: startLoading,
    createRefundStart: startLoading,
    editRefundStart: startLoading,
    deleteRefundStart: startLoading,
    getRefundSuccess(state, {payload}) {
      state.currentPageRefund = payload;
      state.isLoading = false;
      state.error = null;
    },
    getRefundsSuccess(state, {payload}) {
      state.refundsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createRefundSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editRefundSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteRefundSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getRefundFailure: loadingFailed,
    getRefundsFailure: loadingFailed,
    createRefundFailure: loadingFailed,
    editRefundFailure: loadingFailed,
    deleteRefundFailure: loadingFailed,
  },
});

export const {
  setChargeId,
  getRefundStart,
  getRefundsStart,
  createRefundStart,
  editRefundStart,
  deleteRefundStart,
  getRefundSuccess,
  getRefundsSuccess,
  createRefundSuccess,
  editRefundSuccess,
  deleteRefundSuccess,
  getRefundFailure,
  getRefundsFailure,
  createRefundFailure,
  editRefundFailure,
  deleteRefundFailure,
} = refunds.actions;

export default refunds.reducer;

export const fetchRefunds = chargeId => async (dispatch, getState) => {
  try {
    dispatch(getRefundsStart());
    const res = await axios.get(`${url}/${chargeId}/refunds`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setChargeId(chargeId));
    dispatch(getRefundsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getRefundsFailure(errorObj));
  }
};

export const fetchRefund = (chargeId, refundId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(getRefundStart());
    const res = await axios.get(`${url}/${chargeId}/refunds/${refundId}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setChargeId(chargeId));
    dispatch(getRefundSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getRefundFailure(errorObj));
  }
};

export const createRefund = (chargeId, refundObj) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createRefundStart());
    const res = await axios.post(`${url}/${chargeId}/refunds`, refundObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createRefundSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createRefundFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editRefund = (chargeId, refundObj) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(editRefundStart());
    const res = await axios.put(`${url}/${chargeId}/refunds`, refundObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editRefundSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editRefundFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteRefund = (chargeId, refundId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(deleteRefundStart());
    const res = await axios.delete(`${url}/${chargeId}/refunds`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {refund_id: refundId},
    });
    dispatch(deleteRefundSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteRefundFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
