import React, {useEffect} from "react";

// Utils
import moment from "moment-timezone";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchOrders} from "../../../redux/features/sales/ordersSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

//Icons
import VisibilityIcon from "@material-ui/icons/Visibility";

const OrdersPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {ordersList, isLoading, error} = useSelector(state => state.orders);

  // Routing
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  return (
    <DisplayTablePage
      baseUrl="/sales/orders/"
      tableTitle="All Orders"
      hideAddButton
      tableColumns={[
        {
          title: "ID",
          field: "id",
          type: "numeric",
          width: 30,
        },

        {
          title: "Customer ID",
          field: "customer_id",
          type: "numeric",
          width: 30,
        },
        {
          title: "Customer Name",
          field: "customer_name",
        },
        {
          title: "Date Placed",
          field: "date_order_placed",
          render: rowData => (
            <>
              {moment(rowData.date_order_placed)
                .tz("America/Chicago")
                .format("lll")}
            </>
          ),
        },
        {
          title: "Check In",
          field: "check_in",
          render: rowData => <>{moment(rowData.check_in).utc().format("ll")}</>,
        },
        {
          title: "Check Out",
          field: "check_out",
          render: rowData => (
            <>{moment(rowData.check_out).utc().format("ll")}</>
          ),
        },
        {
          title: "Subtotal",
          field: "subtotal",
          type: "currency",
          headerStyle: {
            textAlign: "right",
          },
        },
        {
          title: "Tax",
          field: "tax",
          type: "currency",
          headerStyle: {
            textAlign: "right",
          },
        },
        {
          title: "Total",
          field: "total",
          type: "currency",
          headerStyle: {
            textAlign: "right",
          },
        },
        {
          title: "Type",
          field: "type",
        },
        {
          title: "Status",
          field: "order_status",
        },
      ]}
      customActions={[
        {
          icon: VisibilityIcon,
          tooltip: "View",
          onClick: (event, rowData) => {
            // Go to Edit page
            history.push(`/sales/orders/${rowData.id}`);
          },
        },
      ]}
      customOptions={{
        search: true,
        actionsColumnIndex: -1,
        pageSize: 20,
        thirdSortClick: false,
        pageSizeOptions: [20, 50, 100],
      }}
      dataList={ordersList}
      isLoading={isLoading}
      error={error}
      userPermissionKey="sales"
    />
  );
};

export default OrdersPage;
