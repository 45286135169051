import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/packages/categories";

const packageCategoriesInitialState = {
  packageCategoriesList: [],
  currentPagePackageCategory: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const packageCategories = createSlice({
  name: "packageCategories",
  initialState: packageCategoriesInitialState,
  reducers: {
    getPackageCategoryStart: startLoading,
    getPackageCategoriesStart: startLoading,
    createPackageCategoryStart: startLoading,
    editPackageCategoryStart: startLoading,
    deletePackageCategoryStart: startLoading,
    getPackageCategorySuccess(state, {payload}) {
      state.currentPagePackageCategory = payload;
      state.isLoading = false;
      state.error = null;
    },
    getPackageCategoriesSuccess(state, {payload}) {
      state.packageCategoriesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createPackageCategorySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editPackageCategorySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deletePackageCategorySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getPackageCategoryFailure: loadingFailed,
    getPackageCategoriesFailure: loadingFailed,
    createPackageCategoryFailure: loadingFailed,
    editPackageCategoryFailure: loadingFailed,
    deletePackageCategoryFailure: loadingFailed,
  },
});

export const {
  getPackageCategoryStart,
  getPackageCategoriesStart,
  createPackageCategoryStart,
  editPackageCategoryStart,
  deletePackageCategoryStart,
  getPackageCategorySuccess,
  getPackageCategoriesSuccess,
  createPackageCategorySuccess,
  editPackageCategorySuccess,
  deletePackageCategorySuccess,
  getPackageCategoryFailure,
  getPackageCategoriesFailure,
  createPackageCategoryFailure,
  editPackageCategoryFailure,
  deletePackageCategoryFailure,
} = packageCategories.actions;

export default packageCategories.reducer;

export const fetchPackageCategories = () => async (dispatch, getState) => {
  try {
    dispatch(getPackageCategoriesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPackageCategoriesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPackageCategoriesFailure(errorObj));
  }
};

export const fetchPackageCategory = id => async (dispatch, getState) => {
  try {
    dispatch(getPackageCategoryStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getPackageCategorySuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getPackageCategoryFailure(errorObj));
  }
};

export const createPackageCategory = categoryObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createPackageCategoryStart());
    const res = await axios.post(url, categoryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createPackageCategorySuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createPackageCategoryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editPackageCategory = categoryObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(editPackageCategoryStart());
    const res = await axios.put(url, categoryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editPackageCategorySuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editPackageCategoryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deletePackageCategory = packageId => async (
  dispatch,
  getState
) => {
  try {
    dispatch(deletePackageCategoryStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: packageId},
    });
    dispatch(deletePackageCategorySuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deletePackageCategoryFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
