import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/packages/customs";

const customPackagesInitialState = {
  customPackagesList: [],
  currentPageCustomPackage: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const customPackages = createSlice({
  name: "customPackages",
  initialState: customPackagesInitialState,
  reducers: {
    getCustomPackageStart: startLoading,
    getCustomPackagesStart: startLoading,
    createCustomPackageStart: startLoading,
    editCustomPackageStart: startLoading,
    deleteCustomPackageStart: startLoading,
    getCustomPackageSuccess(state, {payload}) {
      state.currentPageCustomPackage = payload;
      state.isLoading = false;
      state.error = null;
    },
    getCustomPackagesSuccess(state, {payload}) {
      state.customPackagesList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createCustomPackageSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editCustomPackageSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteCustomPackageSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getCustomPackageFailure: loadingFailed,
    getCustomPackagesFailure: loadingFailed,
    createCustomPackageFailure: loadingFailed,
    editCustomPackageFailure: loadingFailed,
    deleteCustomPackageFailure: loadingFailed,
  },
});

export const {
  getCustomPackageStart,
  getCustomPackagesStart,
  createCustomPackageStart,
  editCustomPackageStart,
  deleteCustomPackageStart,
  getCustomPackageSuccess,
  getCustomPackagesSuccess,
  createCustomPackageSuccess,
  editCustomPackageSuccess,
  deleteCustomPackageSuccess,
  getCustomPackageFailure,
  getCustomPackagesFailure,
  createCustomPackageFailure,
  editCustomPackageFailure,
  deleteCustomPackageFailure,
} = customPackages.actions;

export default customPackages.reducer;

export const fetchCustomPackages = () => async (dispatch, getState) => {
  try {
    dispatch(getCustomPackagesStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCustomPackagesSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getCustomPackagesFailure(errorObj));
  }
};

export const fetchCustomPackage = id => async (dispatch, getState) => {
  try {
    dispatch(getCustomPackageStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getCustomPackageSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(getCustomPackageFailure(errorObj));
  }
};

export const createCustomPackage = categoryObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createCustomPackageStart());
    const res = await axios.post(url, categoryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createCustomPackageSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(createCustomPackageFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editCustomPackage = categoryObj => async (dispatch, getState) => {
  try {
    dispatch(editCustomPackageStart());
    const res = await axios.put(url, categoryObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editCustomPackageSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(editCustomPackageFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteCustomPackage = packageId => async (dispatch, getState) => {
  try {
    dispatch(deleteCustomPackageStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: packageId},
    });
    dispatch(deleteCustomPackageSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);

    dispatch(deleteCustomPackageFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
