import React, {useState, useEffect} from "react";

// Components
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Icons
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  maxWidth: {
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    width: "100%",
  },

  disclaimerContainer: {
    paddingTop: theme.spacing(3),
  },
}));

const RowSet = ({id, defaultValues, onChange, onDelete, readOnly}) => {
  // Styles
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.disclaimerContainer}
        justify="space-between">
        <Typography variant="body1" component="label">
          Disclaimer #{id + 1}
        </Typography>
        {!readOnly && (
          <IconButton aria-label="delete" value={id} onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12}>
        {/* title: Joi.string().required(), */}
        <TextField
          className={classes.maxWidth}
          name={`${id}-d-title`}
          label="Disclaimer Title"
          value={defaultValues.title || ""}
          InputProps={{readOnly}}
          onChange={e => onChange(id, "title", e.currentTarget.value)}
          required
        />
      </Grid>

      {/* link: Joi.string().uri().allow(null).required(), */}
      <Grid item xs={12}>
        <TextField
          className={classes.maxWidth}
          name={`${id}-d-link`}
          label="Disclaimer Link"
          index="Url"
          type="url"
          value={defaultValues.link || ""}
          InputProps={{readOnly}}
          onChange={e => onChange(id, "link", e.currentTarget.value)}
        />
      </Grid>

      {/* content: Joi.string().required(), */}
      <Grid item xs={12}>
        <TextField
          className={classes.maxWidth}
          name={`${id}-d-content`}
          label="Disclaimer Content"
          value={defaultValues.content || ""}
          InputProps={{readOnly}}
          onChange={e => onChange(id, "content", e.currentTarget.value)}
          required
          multiline
        />
      </Grid>
    </>
  );
};

const DisclaimerRowBox = ({title, value, onChange, readOnly}) => {
  // Styles
  const classes = useStyles();
  // Local State
  const [disclaimer, setDisclaimer] = useState(
    Array.isArray(value) && value.length > 0
      ? value.map(disclaimerObj => ({
          link: disclaimerObj.link || null,
          title: disclaimerObj.title,
          content: disclaimerObj.content,
        }))
      : []
  );

  useEffect(() => {
    onChange([...disclaimer]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclaimer]);

  const updateDisclaimer = (id, index, value) => {
    let temp = [...disclaimer];
    temp[id][index] = value || null;
    setDisclaimer(temp);
  };

  const removeDisclaimer = id => {
    let temp = [...disclaimer];
    let value = id.currentTarget.value;
    temp.splice(value, 1);
    setDisclaimer(temp);
  };

  const newRowSet = () => {
    let temp = [...disclaimer];
    temp.push({link: null, title: "", content: ""});
    setDisclaimer(temp);
  };

  return (
    <>
      {title && (
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Typography variant="h6" component="label" gutterBottom>
            {title}
          </Typography>
        </Grid>
      )}

      {disclaimer && disclaimer.length > 0 ? (
        disclaimer.map((element, key) => {
          return (
            <Grid container item xs={12} key={key}>
              {key !== 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <RowSet
                id={key}
                defaultValues={element}
                onChange={updateDisclaimer}
                onDelete={removeDisclaimer}
                readOnly={readOnly}
              />
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1" component="p" gutterBottom>
            No Disclaimers Added
          </Typography>
        </Grid>
      )}

      {!readOnly && (
        <Grid item xs={12}>
          <Button
            className={classes.maxWidth}
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={newRowSet}>
            {""}
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default DisclaimerRowBox;
