import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/events/organizers";

const eventOrganizersInitialState = {
  eventOrganizersList: [],
  currentPageEventOrganizer: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const eventOrganizers = createSlice({
  name: "eventOrganizers",
  initialState: eventOrganizersInitialState,
  reducers: {
    getEventOrganizerStart: startLoading,
    getEventOrganizersStart: startLoading,
    createEventOrganizerStart: startLoading,
    editEventOrganizerStart: startLoading,
    deleteEventOrganizerStart: startLoading,
    getEventOrganizerSuccess(state, {payload}) {
      state.currentPageEventOrganizer = payload;
      state.isLoading = false;
      state.error = null;
    },
    getEventOrganizersSuccess(state, {payload}) {
      state.eventOrganizersList = payload;
      state.isLoading = false;
      state.error = null;
    },
    createEventOrganizerSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editEventOrganizerSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteEventOrganizerSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getEventOrganizerFailure: loadingFailed,
    getEventOrganizersFailure: loadingFailed,
    createEventOrganizerFailure: loadingFailed,
    editEventOrganizerFailure: loadingFailed,
    deleteEventOrganizerFailure: loadingFailed,
  },
});

export const {
  getEventOrganizerStart,
  getEventOrganizersStart,
  createEventOrganizerStart,
  editEventOrganizerStart,
  deleteEventOrganizerStart,
  getEventOrganizerSuccess,
  getEventOrganizersSuccess,
  createEventOrganizerSuccess,
  editEventOrganizerSuccess,
  deleteEventOrganizerSuccess,
  getEventOrganizerFailure,
  getEventOrganizersFailure,
  createEventOrganizerFailure,
  editEventOrganizerFailure,
  deleteEventOrganizerFailure,
} = eventOrganizers.actions;

export default eventOrganizers.reducer;

export const fetchEventOrganizers = () => async (dispatch, getState) => {
  try {
    dispatch(getEventOrganizersStart());
    const res = await axios.get(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventOrganizersSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventOrganizersFailure(errorObj));
  }
};

export const fetchEventOrganizer = id => async (dispatch, getState) => {
  try {
    dispatch(getEventOrganizerStart());
    const res = await axios.get(`${url}/${id}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(getEventOrganizerSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getEventOrganizerFailure(errorObj));
  }
};

export const createEventOrganizer = organizerObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(createEventOrganizerStart());
    const res = await axios.post(url, organizerObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createEventOrganizerSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createEventOrganizerFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editEventOrganizer = organizerObj => async (
  dispatch,
  getState
) => {
  try {
    dispatch(editEventOrganizerStart());
    const res = await axios.put(url, organizerObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editEventOrganizerSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editEventOrganizerFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteEventOrganizer = eventId => async (dispatch, getState) => {
  try {
    dispatch(deleteEventOrganizerStart());
    const res = await axios.delete(url, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {id: eventId},
    });
    dispatch(deleteEventOrganizerSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteEventOrganizerFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
