import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getErrorObjFormat} from "../../../helpers/errorObjFormat";

const url = process.env.REACT_APP_API_BASE_URL + "/v1/hotels";

const roomsInitialState = {
  hotelId: null,
  roomsList: [],
  currentPageRoom: {},
  isLoading: false,
  error: null,
};

function startLoading(state) {
  state.isLoading = true;
}
function loadingFailed(state, {payload}) {
  state.isLoading = false;
  state.error = payload;
}

const rooms = createSlice({
  name: "rooms",
  initialState: roomsInitialState,
  reducers: {
    setHotelId(state, {payload}) {
      state.hotelId = payload;
    },
    setRoom(state, {payload}) {
      state.currentPageRoom = payload;
    },
    getRoomStart: startLoading,
    getRoomsStart: startLoading,
    syncRoomsStart: startLoading,
    createRoomStart: startLoading,
    editRoomStart: startLoading,
    deleteRoomStart: startLoading,
    getRoomSuccess(state, {payload}) {
      state.currentPageRoom = payload;
      state.isLoading = false;
      state.error = null;
    },
    getRoomsSuccess(state, {payload}) {
      state.roomsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    syncRoomsSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    createRoomSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    editRoomSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteRoomSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    getRoomFailure: loadingFailed,
    getRoomsFailure: loadingFailed,
    syncRoomsFailure: loadingFailed,
    createRoomFailure: loadingFailed,
    editRoomFailure: loadingFailed,
    deleteRoomFailure: loadingFailed,
  },
});

export const {
  setHotelId,
  setRoom,
  getRoomStart,
  getRoomsStart,
  syncRoomsStart,
  createRoomStart,
  editRoomStart,
  deleteRoomStart,
  getRoomSuccess,
  getRoomsSuccess,
  syncRoomsSuccess,
  createRoomSuccess,
  editRoomSuccess,
  deleteRoomSuccess,
  getRoomFailure,
  getRoomsFailure,
  syncRoomsFailure,
  createRoomFailure,
  editRoomFailure,
  deleteRoomFailure,
} = rooms.actions;

export default rooms.reducer;

export const fetchRooms = hotelId => async (dispatch, getState) => {
  try {
    dispatch(getRoomsStart());
    const res = await axios.get(`${url}/${hotelId}/rooms`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setHotelId(hotelId));
    dispatch(getRoomsSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRoomsFailure(errorObj));
  }
};

export const syncRooms = hotelId => async (dispatch, getState) => {
  try {
    dispatch(syncRoomsStart());

    const res = await axios.put(
      `${url}/${hotelId}/rooms/sync`,
      {},
      {
        headers: {Authorization: `Bearer ${getState().auth.token}`},
      }
    );
    dispatch(setHotelId(hotelId));
    dispatch(syncRoomsSuccess(res.data.response));
    return {error: false};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(syncRoomsFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const fetchRoom = (hotelId, roomId) => async (dispatch, getState) => {
  try {
    dispatch(getRoomStart());
    const res = await axios.get(`${url}/${hotelId}/rooms/${roomId}`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(setHotelId(hotelId));
    dispatch(getRoomSuccess(res.data.response));
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(getRoomFailure(errorObj));
  }
};

export const createRoom = (hotelId, roomObj) => async (dispatch, getState) => {
  try {
    dispatch(createRoomStart());
    const res = await axios.post(`${url}/${hotelId}`, roomObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(createRoomSuccess());
    return {error: false, id: res.data.response.insertId};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(createRoomFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const editRoom = (hotelId, roomObj) => async (dispatch, getState) => {
  try {
    dispatch(editRoomStart());
    const res = await axios.put(`${url}/${hotelId}/rooms`, roomObj, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
    });
    dispatch(editRoomSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(editRoomFailure(errorObj));
    return {error: true, ...errorObj};
  }
};

export const deleteRoom = (hotelId, roomId) => async (dispatch, getState) => {
  try {
    dispatch(deleteRoomStart());
    const res = await axios.delete(`${url}/${hotelId}/rooms`, {
      headers: {Authorization: `Bearer ${getState().auth.token}`},
      data: {room_id: roomId},
    });
    dispatch(deleteRoomSuccess());
    return {error: false, id: res.data.response.id};
  } catch (err) {
    let errorObj = getErrorObjFormat(err);
    dispatch(deleteRoomFailure(errorObj));
    return {error: true, ...errorObj};
  }
};
