import React, {useEffect} from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchCustomers} from "../../../redux/features/sales/customersSlice";

// Components
import DisplayTablePage from "../../PageWrappers/DisplayTablePage";

const CustomersPage = () => {
  // Redux
  const dispatch = useDispatch();
  const {customersList, isLoading, error} = useSelector(
    state => state.customers
  );

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  return (
    <>
      <DisplayTablePage
        baseUrl="/sales/customers"
        tableTitle="All Customers"
        tableColumns={[
          {
            title: "ID",
            field: "customer_id",
            type: "numeric",
            width: 30,
          },
          {title: "Stripe ID", field: "stripe_customer_id"},
          {title: "First Name", field: "first_name"},
          {title: "Last Name", field: "last_name"},
          {title: "Phone", field: "phone_number"},
          {title: "Create Date", field: "create_date", type: "date"},
        ]}
        addButtonLabel="Customer"
        dataList={customersList}
        isLoading={isLoading}
        idKey="customer_id"
        error={error}
        userPermissionKey="sales"
      />
    </>
  );
};

export default CustomersPage;
