import React from "react";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {createUser} from "../../redux/features/users/usersSlice";

// Routing
import {useHistory} from "react-router-dom";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "../UI/MainPaper";
import UserForm from "./UserForm";
import Breadcrumbs from "../Navigation/Breadcrumbs";

const UsersNew = () => {
  // Redux
  const dispatch = useDispatch();
  const {isLoading} = useSelector(state => state.users);

  // Routing
  const history = useHistory();

  const breadcrumbNameMap = {
    "/users": "Users",
    "/users/new": "New User",
  };

  const onCancel = () => {
    history.push("/users");
  };
  const onSubmit = async values => {
    const userObj = {
      ...values,
    };

    const res = await dispatch(createUser(userObj));
    // If user was created successfully redirect to users page and show user
    if (!res.error) {
      history.push({
        pathname: "/users",
        state: {
          showSuccess: true,
          snackbarContent: `The user '${userObj.username}' was successfully created!`,
          snackbarLink: `/users/${res.id}`,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography component="h1" variant="h5" gutterBottom>
            New User
          </Typography>
          <UserForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
            submitButtonLabel="Create User"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UsersNew;
