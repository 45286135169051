import React from "react";

//Utils
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ButtonsContainer from "./ButtonsContainer";
import RichTextEditor from "../../FormFields/RichTextEditor";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  maxWidth: {width: "100%"},
}));

const CustomCodeForm = ({
  onSubmit,
  onReset,
  isLoading,
  submitButtonLabel = "Save Settings",
  settingsList,
}) => {
  //Styles
  const classes = useStyles();

  const {handleSubmit, control} = useForm();

  const formSubmit = data => {
    const settingArray = [
      {...settingsList.header_script, value: data.header_script},
      {...settingsList.footer_script, value: data.footer_script},
    ];
    onSubmit(settingArray);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography>
            {`Click the code (<>) button to see and edit html tags`}
          </Typography>
        </Grid>
        {/* Header Scripts */}
        <Controller
          name={`header_script`}
          control={control}
          defaultValue={settingsList.header_script.value}
          render={props => (
            <Grid item xs={12}>
              <RichTextEditor
                label="Header Tags"
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={false}
                height={100}
                customToolbar={"undo redo |code|   help"}
                customExtendedValidElements={"script[*], link[*], meta[*]"}
                customValidChildren={"+body[link|meta]"}
              />
            </Grid>
          )} // props contains: onChange, onBlur and value
        />
        {/* Footer Scripts */}
        <Controller
          name={`footer_script`}
          control={control}
          defaultValue={settingsList.footer_script.value}
          render={props => (
            <Grid item xs={12}>
              <RichTextEditor
                label="Footer Tags"
                className={classes.maxWidth}
                value={props.value}
                onChange={props.onChange}
                readOnly={false}
                height={100}
                customToolbar={"undo redo |code| help"}
                customExtendedValidElements={"script[*]"}
              />
            </Grid>
          )} // props contains: onChange, onBlur and value
        />

        <ButtonsContainer
          onReset={onReset}
          isLoading={isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </form>
  );
};

export default CustomCodeForm;
