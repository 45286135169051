import React from "react";

//utils
import cronstrue from "cronstrue";
import {useForm, Controller} from "react-hook-form";

// Styling
import {makeStyles} from "@material-ui/core/styles";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {minWidth: 250},
  },
  buttonsContainer: {
    display: "flex",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
  },
  wrapper: {position: "relative"},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  maxWidth: {width: "100%"},
}));

const EventSourcesForm = ({
  onSubmit,
  onCancel,
  isLoading,
  submitButtonLabel = "Submit",
  defaultValues = {
    src: "",
    name: "",
    event_status: "Published",
    import_type: "JSON",
    frequency: "",
  },
  readOnly = false,
}) => {
  // Styles
  const classes = useStyles();

  const {handleSubmit, control, register, watch} = useForm({defaultValues});

  const frequencySet = watch("frequency");

  const formSubmit = data => {
    onSubmit(data);
  };

  const regexSource = /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/;

  return (
    <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
      <Grid container spacing={5}>
        {/* EventSource  Name */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="name"
            label="Name"
            required
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* EventSource  */}
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            className={classes.maxWidth}
            name="src"
            label="Source (URL)"
            type="url"
            required
            InputProps={{
              readOnly,
            }}
          />
        </Grid>

        {/* EventSource Frequency */}
        <Grid container item xs={12} alignItems="flex-start" spacing={5}>
          <Grid item xs={12} md={6}>
            <TextField
              inputRef={register({
                pattern: regexSource,
                // pattern: /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/,
              })}
              // inputRef={register({
              //   validate: value => cron(value).isValid(),
              // })}
              className={classes.maxWidth}
              name="frequency"
              label="Source Frequency (Cron Time String)"
              required
              autoComplete="frequency"
              InputProps={{
                readOnly,
              }}
              helperText={
                <Link
                  target="_blank"
                  rel="noopener"
                  color="primary"
                  href="https://crontab.guru/examples.html">
                  Cron reference
                </Link>
              }
            />
          </Grid>

          <Grid container item xs={12} md={6} direction="column">
            <Typography variant="caption" component="label">
              Frequency
            </Typography>
            {frequencySet && (
              <Typography
                variant="body1"
                component="p"
                color={regexSource.test(frequencySet) ? "inherit" : "error"}>
                {regexSource.test(frequencySet)
                  ? cronstrue.toString(frequencySet)
                  : "Type A Valid Frequency Cron Time String!"}
              </Typography>
            )}
          </Grid>
        </Grid>

        {/* Import Type  */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`import_type`}
            control={control}
            defaultValue={defaultValues.import_type}
            render={props => (
              <FormControl className={classes.maxWidth} required>
                <InputLabel shrink>Import Type</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"JSON"}>JSON</MenuItem>
                  <MenuItem value={"CSV"}>CSV</MenuItem>
                  <MenuItem value={"iCal"}>iCal</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {/* Event Status */}
        <Grid item xs={12} md={6}>
          <Controller
            name={`event_status`}
            control={control}
            defaultValue={defaultValues.event_status}
            render={props => (
              <FormControl className={classes.maxWidth}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  value={props.value}
                  inputProps={{readOnly}}
                  onChange={props.onChange}>
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Published"}>Published</MenuItem>
                  <MenuItem value={"Private"}>Private</MenuItem>
                </Select>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>

        {!readOnly && (
          <Grid item className={classes.buttonsContainer} xs={12}>
            <Button variant="outlined" onClick={() => onCancel()}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}>
                {submitButtonLabel}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default EventSourcesForm;
